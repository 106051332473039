@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin page {
    @include common.flexColumn;
    box-shadow: 1px 1px 5px theme.$color-french;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    padding: 20px;
}

.analysisWrapper {
    @include common.flexRow;
    margin-bottom: 10px;
    .splitWrapper {
        @include common.flexRow;
        .documentAnalysisWrapper {
            @include page;
            margin: 0 10px;
        }
        .datasetAnalysisWrapper {
            @include page;
            margin: 0 10px;
        }
    }
}

.spinnerWrapper {
    align-self: center;
    height: 100%;
    width: 100%;
}
