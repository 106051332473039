@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.expandIcon {
    transform: rotate(45deg);
    display: flex;
    align-self: center;
}

.thirdPartyCoverageChartWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    position: relative;
    width: 100%;
    .thirdPartyCoverageChartHeader {
        @include common.centreFlexRow;
        align-items: center;
        height: 40px;
        margin-bottom: 10px;
        width: 100%;
        .centreGroup {
            @include common.centreFlexRow;
            position: fixed;
            .thirdPartyCoverageChartTitle {
                font-size: 20px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
                margin-right: 10px;
            }
            .thirdPartyCoverageChartDescription {
                margin-right: 5px;
            }
        }
    }
    .chartContents {
        height: calc(100% - 50px);
        width: 100%;
        justify-content: center;
        @include common.flexRow;
        .chartAndMeta {
            @include common.flexRow;
            align-content: center;
            width: 100%;
            .barChartWrapper {
                @include common.flexColumn;
                margin: 10px;
            }
            .chartContentsMetaWrapper {
                @include common.flexRow;
                align-items: center;
                padding: 5px;
                border-radius: 5px;
                margin: 10px;
            }
        }
    }
}

.pieChartWrapper {
    @include common.flexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    justify-content: flex-start;
    .tableWrapper {
        height: 250px;
    }
    .pieChartHeader {
        @include common.centreFlexRow;
        .pieChartTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
        }
    }
}

.networkDiagramWrapper {
    @include common.centreFlexColumn;
    padding: 10px;
    position: relative;
    .networkDiagramHeader {
        @include common.centreFlexRow;
        height: 30px;
        margin-bottom: 5px;
        .networkDiagramTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            margin-right: 10px;
        }
        .networkDiagramDescription {
            @include common.centreFlexRow;
            margin-right: 5px;
        }
    }
    .networkDiagramIcon {
        @include common.centreFlexRow;
        color: theme.$color-primary-night;
        position: absolute;
        border-radius: 50%;
        margin-top: 35px;
    }
}

.networkChartMetaWrapper {
    @include common.centreFlexRow;
    width: 100%;
    justify-content: space-between;
    height: calc(100% - 70px);
}

.metaWrapper {
    width: calc(40% - 20px);
    height: calc(100% - 20px);
    @include common.centreFlexColumn;
    justify-content: flex-start;
    .descriptionWrapper {
        height: calc(40% - 20px);
        padding: 10px 0px;
        .description {
            font-weight: theme.$font-family;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h4;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    .nodeDetailsWrapper {
        @include common.flexColumn;
        height: calc(60% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .nodeDetails {
            @include common.flexRow;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h4;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
        }
        .scrollableNodeDetails {
            @include common.flexRow;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h4;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 10px;
        }
        .nodeDetailsLabel {
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 10px;
            min-width: 50%;
            text-align: left;
            align-self: baseline;
        }
        .nodeDetailsValue {
            font-weight: theme.$font-family;
            width: calc(100% - 20px);
            text-align: left;
        }
        .nodeLinks {
            @include common.flexColumn;
        }
        .nodeDetailsLabelValue {
            width: calc(50% - 15px);
        }
        .bulletPointWrapper {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            .bulletPoint {
                display: inline-block;
                height: 15px;
                width: 15px;
                min-width: 15px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
}

.networkDiagramChart {
    @include common.centreFlexColumn;
    margin-bottom: 10px;
}

.nodeLabel {
    font-size: 20px;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
}

.zoomLegendWrapper {
    @include common.flexRow;
    width: 100%;
}

.zoomWrapper {
    @include common.flexRow;
    margin-top: 5px;
    align-items: center;
}

.legendWrapper {
    @include common.flexRow;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    height: 36px;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .legendLabel {
        font-weight: 600;
        font-size: 14px;
        color: theme.$color-primary-night;
        margin-left: 20px;
    }
    .indicatorWrapper {
        @include common.flexRow;
        align-items: center;
        margin: 0 5px;
        width: fit-content;
        max-width: calc(20% - 20px);
        &:last-of-type {
            margin-right: 20px;
        }
        .indicatorLabel {
            margin-left: 5px;
            font-weight: 600;
            font-size: 14px;
            color: theme.$color-primary-night;
        }
        .indicator {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            min-width: 15px;
        }
        .linkIndicator {
            height: 7.5px;
            width: 15px;
            border-radius: 0%;
        }
    }
}

.noNetworkDataWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
}

.noNetworkDataButton {
    @include common.transparentButton;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h3;
    font-weight: theme.$font-bold;
    width: 80%;
    margin: auto;
}

.contractualInfoModalWrapper {
    @include common.centreFlexColumn;
    justify-content: flex-start;
    padding: 0 15px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    height: 50vh;
    width: 600px;
    overflow: auto;
}

.contractualInfoCardWrapper {
    width: calc(100% - 30px);
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    padding: 0 10px;
    margin: 5px 0;
    .contractualInfoCardHeader {
        font-weight: 400;
        font-size: 14px;
        color: theme.$color-primary-night;
        margin: 10px 5px;
        span {
            font-weight: 600;
            word-break: break-word;
        }
    }
    .contractualInfoCardDescription {
        font-weight: 400;
        font-size: 12px;
        color: theme.$color-primary-night;
        margin: 10px 5px;
        span {
            font-weight: 600;
            word-break: break-word;
        }
    }
}

.contractualInfoFilterWrapper {
    @include common.flexRow;
    justify-content: flex-end;
    width: calc(100% - 20px);
}

.noDataMessage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme.$color-primary-night;
}

:export {
    french: theme.$color-french;
    ocean: theme.$color-ocean;
    amethyst: theme.$color-amethyst;
    primary: theme.$color-primary-night;
    primaryBorder: theme.$color-primary-night-hover;
    aqua: theme.$color-aqua;
    red: theme.$color-red;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    grey: theme.$color-light-grey;
    yellowGreen: theme.$color-yellow-green;
    green: theme.$color-mandatory;
    white: theme.$color-white;
    lightestGrey: theme.$color-lightest-grey;
    darkFrench: theme.$color-french-spectrum-5;
}
