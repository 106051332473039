@use "../../styles/common" as common;
@use "../../styles/theme" as theme;

@mixin label {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-right: 5px;
    color: theme.$color-primary-night;
}

.attestationsWrapper {
    @include common.tablePageWrapper;
    .attestationsContainer {
        @include common.spaceBetweenTablePageTitleContainer;
        .attestationsTitle {
            @include common.tablePageTitle;
        }
    }
}

.editUserAttestationButton {
    @include common.tableIconButton;
}

.attestationInstanceWrapper {
    @include common.flexColumn;
    width: 600px;
    .questionsWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        min-height: 30vh;
        max-height: 60vh;
        .attestationWrapper {
            margin-bottom: 10px;
            .questionAndDescriptionWrapper {
                @include common.flexRow;
                align-items: center;
                margin-bottom: 5px;
                .questionLabel {
                    @include label;
                }
            }
            .redFlagExplanationWrapper {
                @include common.flexColumn;
                margin-top: 5px;
                .forceRedFlagExplanation {
                    font-size: 12px;
                    align-self: flex-end;
                    color: theme.$color-red;
                    font-weight: theme.$font-medium;
                }
            }
        }
    }
}

.alertModalWrapper {
    @include common.flexColumn;
    padding: 10px 0;
    max-width: 600px;
    .alertTitle {
        font-size: 13px;
    }
    .alertAnswer {
        margin-bottom: 10px;
    }
    .alertMessage {
        border-top: 1px solid theme.$color-grey;
        padding-top: 10px;
        font-weight: theme.$font-medium;
        color: theme.$color-red;
    }
}
