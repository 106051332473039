@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.functionsModalWrapper {
    @include common.flexColumn;
    align-items: center;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    height: fit-content;
    max-height: 70vh;
    width: 500px;
    .functionTextInput {
        width: 100%;
    }
    .isSystemFunctionWrapper {
        @include common.flexColumn;
        align-items: flex-start;
        padding: 10px;
        width: 100%;
        .isSystemFunctionLabelWrapper {
            @include common.flexRow;
            align-items: center;
            .isSystemFunctionLabel {
                font-weight: theme.$font-medium;
                text-decoration: underline;
                margin-bottom: 5px;
                color: theme.$color-primary-night;
                margin-right: 5px;
            }
        }
    }
}

.doraTableWrapper {
    @include common.tablePageWrapper;
    .doraTableTitleContainer {
        @include common.tablePageTitleContainer;
        height: 50px;
        margin-bottom: 30px;
        .tableTitle {
            @include common.tablePageTitle;
            min-width: fit-content;
        }
    }
}

.functionsConfigModalWrapper {
    @include common.flexColumn;
    height: 100%;
    padding: 5px;
    .functionsConfigWrapper {
        @include common.flexColumn;
        height: calc(100% - 50px);
        .functionConfigTitle {
            text-align: left;
            font-size: 18px;
            font-family: theme.$font-family;
            margin-right: 10px;
            font-weight: 600;
            padding-bottom: 3px;
            border-bottom: 1px solid theme.$color-light-grey;
        }
        .configurationTimelineWrapper {
            height: fit-content;
            width: 95%;
            margin: 20px auto;
        }
        .functionWysiwygWrapper {
            width: calc(100% - 10px);
            padding: 5px;
            height: 100%;
        }
    }
    .configButtonWrapper {
        @include common.flexRow;
        justify-content: space-between;
    }
}

.deleteFunctionConfirmationWrapper {
    @include common.flexColumn;
    padding: 5px;
    width: 500px;
    .deleteFunctionWarning {
        font-size: 16px;
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .functionUsageWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        max-height: 300px;
        .functionUsageLabel {
            font-size: 16px;
            font-weight: theme.$font-medium;
            margin-bottom: 10px;
        }
        .scrollableWrapper {
            @include common.flexColumn;
            .companyWrapper {
                @include common.flexRow;
                font-size: 16px;
                font-weight: theme.$font-family;
                margin-bottom: 10px;
                align-items: center;
                .companyName {
                    margin-right: 5px;
                }
            }
        }
    }
}

.sliderMarker {
    height: 40px;
    width: 40px;
    position: relative;
    top: -30px;
    left: 0px;
}
