@use "./theme" as theme;

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin centreFlex($direction) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}

@mixin centreFlexRow {
    @include centreFlex(row);
}

@mixin centreFlexColumn {
    @include centreFlex(column);
}

@mixin spaceBetweenFlexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@mixin flexStartSpaceBetweenFlexRow {
    @include spaceBetweenFlexRow;
    align-items: flex-start;
}

@mixin backgroundButton($backgroundColor: theme.$color-french, $color: theme.$color-primary-night, $border: none) {
    background-color: $backgroundColor;
    font-family: theme.$font-family;
    color: $color;
    outline: none;
    border: $border;
    cursor: pointer;
}

@mixin transparentButton($color: theme.$color-primary-night, $border: none) {
    @include backgroundButton(transparent, $color, $border);
}

@mixin overflowText {
    font-family: theme.$font-family;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin tableIconButton {
    @include centreFlexRow;
    width: 100%;
    cursor: pointer;
    color: theme.$color-primary-night;
    &:hover {
        color: theme.$color-french;
    }
}

@mixin inputHeader {
    font-size: 16px;
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

@mixin inputField {
    font-family: theme.$font-family;
    outline: none;
    padding: 5px;
    border: 1px solid theme.$color-primary-night;
    color: theme.$color-primary-night;
    border-radius: 5px;
    font-weight: 500;
    &:focus {
        border: 1px solid theme.$color-french;
    }
}

@mixin inputFieldDisabled {
    @include inputField;
    &:disabled {
        background-color: rgba(theme.$color-grey, 0.1);
        border: 1px solid rgba(theme.$color-grey, 0.5);
    }
}

@mixin inputFieldInstance {
    @include inputField;
    &:disabled {
        color: theme.$color-primary-night;
        background-color: rgba(theme.$color-grey, 0.05);
        border: 1px solid rgba(theme.$color-grey, 0.5);
        cursor: not-allowed;
    }
}

@mixin logoWrapper {
    width: fit-content;
    height: 200px;
    margin: 0 0 20px 0;
}

@mixin loginButton {
    width: 130px;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: theme.$color-amethyst;
    color: theme.$color-white;
    font-size: 18px;
    font-weight: theme.$font-medium;
    margin: 10px 0;
    cursor: pointer;
}

@mixin loginBackground {
    background-image: linear-gradient(to bottom left, theme.$color-aqua, theme.$color-ocean 45%);
}

@mixin loginInput {
    width: 250px;
    padding: 15px;
    margin: 5px;
    border: none;
    color: theme.$color-amethyst;
    font-weight: theme.$font-medium;
    border-radius: 5px;
    &:focus {
        outline: none;
    }
    font-family: theme.$font-family;
}

@mixin labelModalTextAreaWrapper {
    @include flexColumn;
    width: 100%;
    height: 100%;
    color: theme.$color-primary-night;
}

@mixin labelModalTextArea {
    border-radius: 5px;
    padding: 5px;
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    outline: none;
    height: 100%;
}

@mixin checkbox($height: 12px) {
    height: $height;
    width: $height;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    transition-duration: 0.3s;
    background-color: theme.$color-white;
    cursor: pointer;
    &:checked {
        background-color: theme.$color-french;
        border: 1px solid theme.$color-french;
    }
    &:active {
        border: 1px solid theme.$color-white;
    }
}

@mixin tablePageWrapper {
    @include flexColumn;
    height: 100%;
    width: 100%;
    margin: 20px 0;
}

@mixin tablePageTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 8vw;
}

// for tables with a wrapper that includes a button to the right hand side as well as header
@mixin spaceBetweenTablePageTitleContainer {
    @include spaceBetweenFlexRow;
    padding: 0 8vw;   
}

@mixin tablePageTitle {
    font-size: 22px;
    font-weight: theme.$font-bold;
    margin-right: 10px;
    color: theme.$color-primary-night;
}
