@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.entityWrapper {
    @include common.tablePageWrapper;
    .entityTitleContainer {
        @include common.tablePageTitleContainer;
        .entityTitle {
            @include common.tablePageTitle;
        }
    }
}

.entityClassificationWrapper {
    @include common.tablePageWrapper;
    .entityTitleContainer {
        @include common.spaceBetweenFlexRow;
        padding: 0 8vw;
        width: calc(100% - 16vw);
        .entityTitle {
            @include common.tablePageTitle;
        }
    }
}

.entityModalWrapper {
    @include common.flexColumn;
    align-items: center;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    height: fit-content;
    max-height: 70vh;
    .entityContentWrapper {
        height: 100%;
        padding: 5px 0;
        padding-left: 10px;
    }
}

.editEntityButton {
    @include common.tableIconButton;
}

.entityTypeButton {
    @include common.spaceBetweenFlexRow;
    width: 450px;
    padding: 15px;
    height: 67px;
    margin-bottom: 10px;
    border: 1px solid theme.$color-grey;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 5px theme.$color-french;
    }
    &__textWrapper {
        padding-left: 20px;
        flex-grow: 1;
    }
    &__title {
        font-size: 18px;
        font-weight: theme.$font-medium;
    }
    &__subtext {
        font-size: 12px;
    }
    &__disabled {
        opacity: 0.4;
        background-color: lightgray;
        &:hover {
            cursor: not-allowed;
            box-shadow: none;
        }
    }
}

.entityClassificationButton {
    @include common.transparentButton;
    padding: 0;
    position: relative;
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
    }
    .entityClassificationOverlay {
        position: absolute;
        background-color: theme.$color-white;
        font-family: theme.$font-family;
        font-weight: theme.$font-bold;
        font-size: 20px;
        border-radius: 20px;
        top: -2px;
        left: 5px;
    }
}

.entityClassificationModal {
    @include common.flexColumn;
    width: 70vw;
    height: 70vh;
    .classificationEntityWrapper {
        @include common.flexColumn;
        width: 100%;
        height: 100%;
        &:not(:last-child) {
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px dotted theme.$color-grey;
        }
        .classificationEntityHeader {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            cursor: pointer;
            .sectionOpenIcon {
                margin-right: 10px;
            }
            .classificationEntityTitle {
                font-weight: theme.$font-medium;
            }
            .classificationEntityIndicator {
                @include common.centreFlexRow;
                margin-left: 10px;
            }
        }
    }
}

.filterWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    .showCompletedWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        margin-left: 20px;
        width: calc(50% - 20px);
        .showCompletedLabel {
            @include common.flexRow;
            align-items: center;
            margin-right: 10px;
        }
    }
}

.classificationEntityFields {
    @include common.flexColumn;
    margin-top: 5px;
    width: calc(100% - 25px);
    padding-left: 25px;
    .entityTypeInputWrapper {
        @include common.flexColumn;
        .entityTypeInputLabel {
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            margin-right: 10px;
        }
        .entityTypeWrapper {
            @include common.flexRow;
            width: 100%;
            height: 100%;
            .dropdownWrapper {
                width: calc(100% - 180px);
                margin-right: 10px;
            }
            .button {
                @include common.transparentButton;
                width: 170px;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h4;
                color: theme.$color-amber;
                margin-bottom: 10px;
                padding: 0;
            }
        }
    }
}

:export {
    green: theme.$color-mandatory;
    amber: theme.$color-amber;
}
