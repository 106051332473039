@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.datasetsWrapper {
    @include common.tablePageWrapper;
    .datasetsTitleContainer {
        @include common.spaceBetweenTablePageTitleContainer;
        .datasetsTitleButtonWrapper {
            @include common.flexRow;
            align-items: center;
            .datasetsTitle {
                @include common.tablePageTitle;
            }
        }
    }
}

.typeSelectorWrapper {
    @include common.flexColumn;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    color: theme.$color-primary-night;
    .typeSelectorTitle {
        font-weight: theme.$font-medium;
        font-size: 30px;
        margin-bottom: 10px;
    }
    .typeSelectorSubtitle {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .tilesWrapper {
        @include common.centreFlexColumn;
        width: 100%;
        height: calc(100% - 100px);
    }
}

.tableHeader {
    color: theme.$color-primary-night;
}

.tableRow {
    display: flex !important;
    align-items: center;
    height: 100% !important;
    color: theme.$color-primary-night;
    font-size: 14px;
}

.formBuilder {
    width: 100%;
    height: calc(100% - 2px);
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .sectionSelection {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 20px);
        padding: 0 10px;
        height: 75px;
        border-bottom: 1px solid theme.$color-grey;
        .sectionPlaceholder {
            margin: auto;
        }
        .sectionWrapper {
            @include common.flexRow;
            align-items: center; 
        }
    }
}

.allButtonsWrapper {
    @include common.spaceBetweenFlexRow;
}

.publishWrapper {
    @include common.flexColumn;
    padding: 5px;
    .publishError {
        display: flex;
        align-items: center;
        height: 30px;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
    }
}

.helpWrapper {
    @include common.flexColumn;
    font-family: theme.$font-family;
    width: 500px;
    height: 400px;
    padding: 0 10px;
    .helpTitle {
        font-size: 25px;
        text-decoration: underline;
        font-weight: theme.$font-bold;
        align-self: center;
    }
    .helpContent {
        @include common.flexColumn;
        height: calc(100% - 45px);
        margin-top: 10px;
        .helpItemWrapper {
            @include common.flexRow;
            margin: 5px 0;
            align-items: center;
            .helpBulletPoint {
                @include common.centreFlexRow;
                min-width: 20px;
                min-height: 20px;
                width: 20px;
                height: 20px;
                font-size: 14px;
                font-weight: theme.$font-bold;
                border: 1px solid theme.$color-french;
                border-radius: 50%;
                color: theme.$color-french;
                margin-right: 10px;
            }
            .helpItem {
                font-size: 14px;
            }
        }
    }
}

.reorderModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 40vw;
    height: 70vh;
    .reorderWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .scrollableWrapper {
            @include common.flexColumn;
            height: calc(100% - 114px);
            width: 100%;
            margin-bottom: 10px;
            .reorderTile {
                background: theme.$color-french;
                border-radius: 5px;
                padding: 10px;
                margin: 10px;
                &:active {
                    box-shadow: 1px 1px 5px theme.$color-french;
                    background: theme.$color-french-spectrum-2;
                }
                .reorderTileTitle {
                    color: theme.$color-white;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                }
            }
            .instepTile {
                margin-left: 40px;
            }
        }
    }
    .buttonWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
}

.leftButtonWrapper {
    @include common.flexRow;
    align-items: center;
}

.datasetHistoryModalWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    max-height: 400px;
    .previousDatasetWrapper {
        @include common.flexRow;
        align-items: center;
        height: 20px;
        padding: 5px;
        width: 300px;
        font-weight: 600;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h5;
        color: theme.$color-primary-night;
        margin-bottom: 2px;
        &:hover {
            color: theme.$color-french;
            cursor: pointer;
        }
    }
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    aqua: theme.$color-aqua;
    whiteColor: theme.$color-white;
    grey: theme.$color-grey;
}
