@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.listItemWrapper {
    @include common.flexRow;
    align-items: center;
    width: fit-content;
    .dragButton {
        @include common.transparentButton;
        cursor: grab;
        width: 100%;
        padding: 0;
    }
}

.disabledDragging {
    opacity: 0.8;
}

.listContainer {
    @include common.flexRow;
    width: calc(100% - 10px);
    padding: 5px;
    height: calc(100% - 10px);
}

.horizontalWrapper {
    @include common.flexRow;
    width: 100%;
    height: 100%;
}

.draggingItem {
    @include common.flexRow;
    background-color: theme.$color-white;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    padding: 10px;
}

.edgeIndicator {
    height: 100%;
}

:export {
    french: theme.$color-french;
}
