@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.wrapper {
    @include common.flexColumn;
    align-items: center;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
}

.textInput {
    @include common.inputFieldDisabled;
    height: 26px;
    width: calc(100% - 12px);
}

.boldPlaceholder {
    &::placeholder {
        color: theme.$color-grey;
        font-weight: 800;
    }
}

.textWithIconInput {
    border: none;
    outline: none;
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    padding: 0;
    margin: 0;
}

.inputLabel {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

.inputWrapper {
    @include common.flexColumn;
}

.inputWithIconWrapper {
    @include common.flexColumn;
}

.inputFieldWrapper {
    @include common.flexRow;
    @include common.inputFieldDisabled;
    height: 26px;
    margin-top: 5px;
    margin-bottom: 10px;
    &.disabled {
        @include common.inputFieldDisabled;
        background-color: rgba(theme.$color-grey, 0.1);
        border: 1px solid rgba(theme.$color-grey, 0.5);
    }

    &:not(.disabled):hover {
        outline: none;
        border: 1px solid theme.$color-french;
    }
}

.iconPlacer {
    padding-left: 15px;
    display: flex;
    align-items: center;
}
