@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.pieChart {
    @include common.centreFlexColumn;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.answerIndicator {
    @include common.centreFlexRow;
}

.analyticsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    color: theme.$color-primary-night;
    .analyticsHeader {
        @include common.spaceBetweenFlexRow;
        padding: 0 20px;
        margin-bottom: 10px;
        .analyticsTitle {
            font-size: 22px;
            font-weight: theme.$font-bold;
        }
    }
    .analyticsChartsWrapper {
        @include common.flexRow;
        height: 100%;
        padding: 0 20px;
        .analyticsColumnWrapper {
            @include common.flexColumn;
            height: 100%;
            &:not(:last-child) {
              margin-right: 20px;  
            }
        }
        .chartWrapper {
            @include common.centreFlexRow;
            border-radius: 5px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            background-color: theme.$color-lightest-grey;
            &:hover {
                color: theme.$color-french;
                box-shadow: 1px 1px 5px theme.$color-french;
            }
        }
    }
}

.wholePageChartWrapper {
    @include common.centreFlexRow;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
    background-color: theme.$color-lightest-grey;
}

.xAxisWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-top: 3px;
    .xAxis {
        height: 1px;
        background-color: theme.$color-primary-night;
        width: 100%;
    }
    .xAxisPoints {
        @include common.flexRow;
        width: 100%;
        .xAxisPointWrapper {
            @include common.flexColumn;
            color: theme.$color-primary-night;
            .xAxisTick {
                height: 4px;
                width: 1px;
                background-color: theme.$color-primary-night;
            }
            .xAxisPointLabel {
                font-size: 12px;
                font-weight: theme.$font-medium;
            }
        }
    }
    .xAxisLabel { 
        color: theme.$color-primary-night;
        font-size: 14px;
        font-weight: theme.$font-medium;
    }
}

:export {
    french: theme.$color-french;
    ocean: theme.$color-ocean;
    amethyst: theme.$color-amethyst;
    primary: theme.$color-primary-night;
    aqua: theme.$color-aqua;
    red: theme.$color-red;
    amber: theme.$color-amber;
    green: theme.$color-green;
    white: theme.$color-white;
    lightestGrey: theme.$color-lightest-grey;
    lightGrey: theme.$color-light-grey;
    grey: theme.$color-grey;
    fontFamily: theme.$font-family;
}
