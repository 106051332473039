@use '../../../../styles/common' as common;
@use '../../../../styles/theme' as theme;

@mixin label {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

.managerWrapper {
    @include common.tablePageWrapper;
    .managerHeaderWrapper {
        @include common.spaceBetweenTablePageTitleContainer;
        .managerLeftHeaderWrapper {
            @include common.flexRow;
            align-items: center;
            .managerHeaderTitle {
                @include common.tablePageTitle;
            }
        }
    }
}

.newAttestationWrapper {
    @include common.flexColumn;
    width: 725px;
    padding-bottom: 10px;
    .attestationFormSelect {
        @include common.flexColumn;
        margin-bottom: 10px;
        .attestationFormSelectLabel {
            @include label;
        }
    }
}

.wizardTabContentWrapper {
    width: 60vw;
    height: 50vh;
}

.informationTooltipIcon {
    @include common.centreFlexRow;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    color: inherit;
    background-color: transparent;
    font-weight: theme.$font-bold;
    height: 14px;
    width: 14px;
    font-size: 13px;
}

.detailsWrapper {
    @include common.flexColumn;
    height: 100%;
    .attestationFormName {
        font-weight: theme.$font-medium;
        font-size: 20px;
        text-decoration: underline;
        margin-bottom: 10px;
        color: theme.$color-primary-night;
    }
    .detailsTableWrapper {
        @include common.flexColumn;
        height: 75%;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        .relevantToWrapper,
        .redFlagWrapper,
        .completionWrapper {
            @include common.flexRow;
            align-items: center;
            height: calc(100% / 3);
            width: calc(100% - 2px);
        }
        .relevantToWrapper,
        .redFlagWrapper {
            border-bottom: 1px dotted theme.$color-grey;
        }
        .relevantToHeader,
        .redFlagHeader,
        .completionHeader {
            @include common.centreFlexRow;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            width: 50%;
            height: 100%;
            border-right: 1px solid theme.$color-grey;
        }
        .usernamesWrapper {
            @include common.centreFlexColumn;
            height: 100%;
            flex: 1;
            .usernameLabel {
                @include common.centreFlexColumn;
                margin-bottom: 5px;
                font-weight: theme.$font-medium;
            }
        }
        .redFlagAnswers,
        .percentageComplete {
            @include common.centreFlexRow;
            height: 100%;
            flex: 1;
            font-weight: theme.$font-medium;
        }
    }
}

.attestationsWrapper {
    @include common.flexColumn;
    height: 100%;
    .attestationsHeader {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        padding: 0 16px 5px 16px;
        border-bottom: 1px solid theme.$color-primary-night;
        font-weight: theme.$font-medium;
    }
    .attestationsBody {
        @include common.flexColumn;
        flex: 1;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        .attestationRow {
            @include common.flexRow;
            align-items: center;
            width: calc(100% - 42px);
            border: 1px dotted theme.$color-french;
            border-radius: 5px;
            margin: 5px;
            padding: 15px;
            .attestationQuestion {
                width: 40%;
            }
            .attestationUser {
                width: 20%;
            }
            .attestationAnswer {
                width: 30%;
            }
            .attestationUserNotes {
                @include common.centreFlexRow;
                width: 10%;
            }
        }
    }
}

.configureWrapper {
    @include common.flexColumn;
    height: 100%;
    .deadlineSelect {
        @include common.flexColumn;
        margin-bottom: 10px;
        .deadlineSelectLabel {
            @include label;
        }
    }
    .attestationUserStatus {
        @include common.flexColumn;
        margin-bottom: 10px;
        .attestationUserStatusLabel {
            @include label;
        }
        .userStatusWrapper {
            @include common.flexColumn;
            border-radius: 5px;
            .userStatusHeader {
                @include common.flexRow;
                align-items: center;
                background-color: theme.$color-french;
                color: theme.$color-white;
                font-weight: theme.$font-medium;
                padding: 5px;
                border-radius: 5px 5px 0 0;
                text-align: center;
                .usernameLabel,
                .userStatusLabel {
                    width: 50%;
                }
            }
            .userStatusTable {
                @include common.flexColumn;
                max-height: 300px;
                border: 1px solid theme.$color-grey;
                border-radius: 0 0 5px 5px;
                .userStatusRow {
                    @include common.flexRow;
                    width: calc(100% - 10px);
                    align-items: center;
                    padding: 5px;
                    .username {
                        font-weight: theme.$font-medium;
                        text-align: center;
                        width: 50%;
                    }
                    .userStatusDropdown {
                        width: 50%;
                    }
                }
                .rowBorder {
                    border-bottom: 1px solid rgba(theme.$color-grey, 0.5);
                }
            }
        }
    }
}
