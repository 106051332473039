@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin inputLabel {
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    margin-right: 10px;
}

.companyEntityContentWrapper {
    @include common.flexRow;
    height: calc(70vh - 56px);
    width: 50vw;
    .sideMenuWrapper {
        @include common.flexColumn;
        border-right: 1px solid theme.$color-grey;
        min-width: 100px;
        max-width: 100px;
        padding: 10px 0px;
        .entitySection {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-weight: theme.$font-bold;
            font-size: theme.$font-size-h4;
            cursor: pointer;
            &:hover {
                color: theme.$color-french;
            }
            .contentsPageSectionLabel {
                cursor: pointer !important;
            }
        }
    }
    .scrollableContentWrapper {
        @include common.flexColumn;
        padding: 10px 10px;
        min-width: calc(100% - 110px);
        max-width: calc(100% - 110px);
    }
}

.generalWrapper,
.contactWrapper,
.detailsWrapper {
    height: 100%;
}

.inputWrapper {
    @include common.flexColumn;
    margin-bottom: 10px;
    .tooltipWrapper {
        margin-bottom: 5px;
    }
    .inputLabelWrapper {
        @include common.flexRow;
        align-items: center;
        .inputLabel {
            @include inputLabel;
            margin-right: 5px;
        }
    }
}

.bicWrapper {
    @include common.flexColumn;
    width: 100%;
    height: fit-content;
    max-height: 215px;
}

.doraWrapper {
    height: 100%;
    @include common.flexColumn;
    .entityTypeInputWrapper {
        @include common.flexColumn;
        margin: 5px 0;
        .entityTypeInputLabel {
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            margin-right: 10px;
        }
        .entityTypeWrapper {
            @include common.flexRow;
            width: 100%;
            height: 100%;
            .dropdownWrapper {
                width: calc(100% - 180px);
                margin-right: 10px;
            }
            .button {
                @include common.transparentButton;
                width: 170px;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h4;
                color: theme.$color-amber;
                margin-bottom: 10px;
                padding: 0;
            }
        }
    }
    .datesWrapper {
        @include common.flexRow;
        justify-content: space-between;
        margin-bottom: 10px;
        .dateInputWrapper {
            @include common.flexColumn;
            .dateLabel {
                @include inputLabel;
            }
        }
    }
    .inputWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        .inputLabelWrapper {
            @include common.flexRow;
            align-items: center;
            .inputLabel {
                @include inputLabel;
                margin-right: 5px;
            }
        }
    }
}

.euidWrapper {
    @include common.flexColumn;
    .euidLabelWrapper {
        @include common.flexRow;
        align-items: center;
        .euidLabel {
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 5px;
            color: theme.$color-primary-night;
        }
    }
}

.personEntityContentWrapper {
    height: calc(70vh - 56px);
    width: 500px;
    margin-top: 10px;
}

.groupEntityContentWrapper {
    height: 'fit-content';
    width: 500px;
    margin-top: 10px;
}

.togglesWrapper {
    @include common.flexColumn;
    border-top: 1px dashed theme.$color-grey;
    margin-top: 20px;
    padding-top: 20px;
}

.toggleWrapper {
    @include common.flexRow;
    align-items: center;
    .toggleLabel {
        color: theme.$color-primary-night;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
        margin-right: 10px;
    }
    .tooltipWrapper {
        margin-left: 5px;
    }
}

.branchesWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .addBranchWrapper {
        margin-bottom: 10px;
        @include common.flexRow;
        align-items: center;
        justify-content: space-between;
    }
    .currentBranches {
        height: calc(100% - 70px);
        .branchWrapper {
            @include common.flexColumn;
            margin-bottom: 10px;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            padding: 5px;
            .headerWrapper {
                @include common.flexRow;
                justify-content: flex-end;
                height: 25px;
                .branchHeader {
                    @include common.flexRow;
                    min-width: calc(100% - 60px);
                    max-width: calc(100% - 60px);
                    .branchDetails {
                        color: theme.$color-primary-night;
                        font-family: theme.$font-family;
                        font-weight: theme.$font-medium;
                    }
                }
                .branchIcons {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 80px;
                }
            }
            .branchInputWrapper {
                @include common.flexRow;
                align-items: flex-end;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }
}

.fuzzyMatchesModalWrapper {
    @include common.flexColumn;
    .fuzzyMatchWrapper {
        display: flex;
        width: 300px;
        align-items: center;
        margin-bottom: 2px;
        cursor: pointer;
    }
    .fuzzyMatchTitle {
        display: flex;
        max-width: 85%;
        align-items: center;
        font-weight: theme.$font-family;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h5;
        color: theme.$color-primary-night;
        &:hover {
            color: theme.$color-french;
        }
    }
    .fuzzyMatchType {
        @include common.centreFlexRow;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
        color: theme.$color-french;
        background-color: theme.$color-white;
    }
}

.addressInputWrapper {
    @include common.flexColumn;
}

.addressSpacer {
    @include common.spaceBetweenFlexRow;
    width: 100%;
}

.narrowInput {
    width: 30%;
    margin: 0 5px;
}

.firstNarrowInput {
    margin-left: 0;
}

.lastNarrowInput {
    margin-right: 0;
}

.otherAddressWrapper {
    @include common.flexColumn;
}

.addOtherAddressWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
}

.addressLabel {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
}

.inputLabel {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

.bicHeader {
    @include common.flexRow;
    align-items: center;
    margin-bottom: 5px;
    .bicLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        color: theme.$color-primary-night;
        margin-right: 5px;
    }
}

.bicCodeWrapper {
    @include common.flexRow;
    align-items: center;
    margin-bottom: 5px;
}

.sectionIconButton {
    @include common.transparentButton;
    height: 100%;
    &:hover {
        color: theme.$color-french;
    }
}

.sectionDividerWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    padding: 5px 0;
    &__buttons {
        @include common.centreFlexRow;
    }
    .entityAddressLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
    }
}

.addressTypeSelection {
    @include common.flexRow;
    margin-bottom: 10px;
    .addressType {
        @include common.flexRow;
        align-items: center;
        margin-right: 10px;
        .addressTypeLabel {
            font-size: 14px;
        }
        .addressTypeCheckbox {
            @include common.checkbox;
        }
    }
}

.aiDropdownFieldWrapper {
    @include common.flexColumn;
    width: 100%;
    .aiDropdownFieldLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
    }
    .aiDropdownToggleWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .aiDropdownField {
            @include common.flexRow;
            align-items: center;
        }
        .toggleWrapper {
            @include common.centreFlexRow;
            margin-left: 10px;
        }
    }
}

:export {
    grey: theme.$color-grey;
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    green: theme.$color-mandatory;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    red: theme.$color-red;
}
