@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.subCounterpartyTypesWrapper {
    @include common.tablePageWrapper;
    align-items: center;
    justify-content: center;
    .subCounterpartyTypesHeader {
        @include common.tablePageTitleContainer;
        height: 40px;
        .subCounterpartyTypesHeaderTitle {
            @include common.tablePageTitle;
        }
    }
    .subCounterpartyTypesMainWrapper {
        @include common.flexRow;
        height: calc(100% - 40px);
        width: 90vw;
        text-align: left;
        .subCounterpartyTypesSectionWrapper {
            @include common.flexColumn;
            margin: 10px 20px;
            padding: 10px;
            flex: 1;
            height: calc(100% - 40px);
            .subCounterpartyTypesListWrapper {
                height: calc(100% - 75px);
                border-radius: 5px;
                max-width: 100%;
            }
            .dropdownWrapper {
                width: 100%;
                text-align: left;
                height: 65px;
            }
        }
    }
}

.newInputWrapper {
    @include common.flexRow;
    .newInputWrapperButtonWrapper {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
    }
    .newInputWrapperToggleWrapper {
        @include common.centreFlexRow;
        padding: 5px;
        margin-left: 10px;
        justify-content: flex-end;
        margin-top: 12px;
        font-size: 12px;
    }
}

.parentCounterpartyButton {
    @include common.flexRow;
    margin: 5px;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    width: 90%;
    font-size: 14px;
    border-radius: 3px;
    &:hover {
        border: 1px solid theme.$color-amethyst;
    }
}

.subCounterpartyWrapper {
    @include common.flexRow;
    margin: 5px;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid theme.$color-light-grey;
    width: 40vw;
    overflow: hidden;
    &:hover {
        box-shadow: 0 4px 10px rgba(theme.$color-primary-night, 0.15);
        background-color: theme.$color-lightest-grey;
    }
    .subCounterpartyTypeInput {
        height: 40px;
        margin: 5px;
        width: 100%;
        align-items: center;
        justify-content: center;
        border: none;
        border-bottom: dashed 1px black;
        font-size: 14px;
        outline: none;
    }
    .subCounterpartyButtonWrapper {
        cursor: pointer;
        overflow-wrap: break-word;
        max-width: 100%;
    }
}

.selectedButton {
    background-color: theme.$color-light-grey;
}

.selectMessage {
   @include common.flexRow;
    justify-content: center;
    height: 100%;
}
