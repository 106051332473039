@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.scopeDropdown {
    width: calc((100% / 3) - (20px / 3) - 6px);
}

.jurisdictionChartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .jurisdictionChartHeader {
        @include common.centreFlexRow;
        height: 30px;
        width: 100%;
        margin-bottom: 5px;
        .smallTileQuestion {
            height: 30px;
            width: calc(100% - 65px);
            margin-right: 10px;
            color: theme.$color-primary-night;
            font-weight: 500;
            text-align: left;
        }
        .jurisdictionChartTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            margin-right: 10px;
        }
        .jurisdictionChartDescription {
            @include common.centreFlexRow;
            margin-top: 2.5px;
            margin-right: 5px;
        }
    }
}

.jurisdictionChartDropdownWrapper {
    @include common.flexRow;
    width: 100%;
    justify-content: space-between;
    .jurisdictionChartDropdown {
        @include common.flexRow;
        align-self: flex-end;
        width: 45%;
        margin-top: 5px;
    }
}

.splitViewWrapper {
    @include common.flexRow;
    width: 100%;
    .singleDataPointDetailsWrapper {
        @include common.flexColumn;
        flex-grow: 1;
        padding-left: 20px;
        .dropdownWrapper {
            @include common.flexColumn;
            align-items: flex-start;
            width: 100%;
            .dropdownLabel {
                font-weight: 600;
                margin-bottom: 5px;
            }
            .dropdownContent {
                width: 100%;
            }
        }
    }
}

.divider {
    width: 100%;
    border-bottom: 2px solid theme.$color-grey;
    margin: 20px 0;
}

.subDivider {
    width: 100%;
    border-bottom: 1px solid theme.$color-grey;
    margin: 10px 0;
}

.indicatorWrapper {
    @include common.flexRow;
    .indicatorTile {
        @include common.centreFlexRow;
        flex: 1;
        margin: 2px;
        position: relative;
        height: 100px;
        &:hover {
            box-shadow: 0 3px 5px theme.$color-french;
            cursor: pointer;
        }
    }
    .selectedIndicatorTile {
        box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
    }
}

.logoWrapper {
    @include common.logoWrapper;
    position: absolute;
    top: 15%;
    transform: translateY(-50%);
    height: 20px;
}


.jurisdictionChartZoomWrapper {
    @include common.flexRow;
    margin-top: 5px;
    align-items: center;
    .zoomLabel {
        font-weight: 600;
        margin-right: 10px;
    }
}

.jurisdictionChart {
    @include common.centreFlexColumn;
    height: 100%;
    width: 100%;
}

.extensiveDataWrapper {
    @include common.flexColumn;
    width: 100%;
    .jurisdiction {
        font-size: 20px;
        font-weight: 600;
    }
    .scope,
    .commissionedBy {
        font-weight: 600;
    }
    .question {
        margin-bottom: 10px;
        align-self: flex-start;
        text-align: left;
        font-weight: 600;
        font-size: 18px;
    }
    .permittedOpinionWrapper {
        @include common.flexColumn;
        width: 100%;
        .dropdownWrapper {
            @include common.flexRow;
            align-items: center;
        }
        .wysiwygDetailsWrapper {
            @include common.flexColumn;
            border-bottom: 1px dotted theme.$color-light-grey;
            padding-bottom: 5px;
            .wysiwygHeader {
                @include common.flexRow;
                align-items: center;
                height: 38px;
                .wysiwygOpenIcon {
                    margin-right: 5px;
                }
            }
        }
    }
    .unauthorisedOpinionWrapper {
        @include common.flexColumn;
        width: 100%;
        .warningHeader {
            font-size: 18px;
            font-weight: 600;
            color: theme.$color-red;
        }
        .unauthorisedOpinionAccess {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 600;
            color: theme.$color-primary-night;
        }
        .enquireButtonWrapper {
            @include common.centreFlexRow;
            width: 100%;
        }
    }
}

.expandIcon {
    transform: rotate(45deg);
    display: flex;
    align-self: center;
}

.opinionAverageChartWrapper {
    @include common.centreFlexColumn;
    position: relative;
    .opinionAverageChartHeader {
        @include common.centreFlexRow;
        align-items: center;
        height: 40px;
        margin-bottom: 10px;
        width: 100%;
        .centreGroup {
            @include common.centreFlexRow;
            position: fixed;
            .opinionAverageChartTitle {
                font-size: 20px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
                margin-right: 10px;
            }
            .opinionAverageChartDescription {
                margin-right: 5px;
            }
        }
    }
    .chartContents {
        height: calc(100% - 50px);
        width: 100%;
        justify-content: center;
        @include common.flexRow;
        .chartAndMeta {
            @include common.flexColumn;
            align-content: center;
            .gaugeChartWrapper {
                @include common.flexColumn;
                justify-content: center;
                position: relative;
                .acceptableLabel {
                    font-size: theme.$font-size-h6;
                    font-weight: theme.$font-medium;
                    color: theme.$color-primary-night;
                    position: absolute;
                    top: -10px;
                    width: 100%;
                }
                .highlyDesirableLabel {
                    font-size: theme.$font-size-h6;
                    font-weight: theme.$font-medium;
                    color: theme.$color-primary-night;
                    width: fit-content;
                    position: absolute;
                    right: 10%;
                }
                .ofSeriousConcernLabel {
                    font-size: theme.$font-size-h6;
                    font-weight: theme.$font-medium;
                    color: theme.$color-primary-night;
                    position: absolute;
                    left: 10%;
                }
                .overallRiskLevel {
                    font-size: theme.$font-size-h2;
                    font-weight: theme.$font-bold;
                    height: 25px;
                }
                .overallRiskLevelFullScreen {
                    font-size: theme.$font-size-h1;
                    font-weight: theme.$font-bold;
                    height: 33px;
                }
                .totalOpinionsWrapper {
                    display: flex;
                    height: 25px;
                    justify-content: center;
                    align-items: center;
                    font-size: theme.$font-size-h5;
                    font-weight: theme.$font-family;
                    color: theme.$color-primary-night;
                }
            }
        }
    }
}

.selectedAverage {
    @include common.flexRow;
    align-items: center;
    width: calc(100% - 12px);
    padding: 5px;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    margin: 10px 0;
    .selectedAverageIcon {
        margin-right: 10px;
    }
    .selectedAverageDetails {
        @include common.flexColumn;
        width: calc(100% - 70px);
        color: theme.$color-primary-night;
        .jurisdiction {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            font-weight: 600;
        }
        .focusAndBespoke {
            @include common.flexRow;
            align-items: center;
            .bespoke,
            .focus {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-start;
                font-size: 18px;
                font-weight: 600;
                width: 50%;
            }
        }
        .commissionedBy {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.tableWrapper {
    margin-left: 10px;
}

.breakdownWrapper {
    margin-left: 10px;
    .breakdownFieldWrapper {
        margin-bottom: 10px;
        @include common.flexRow;
        border-radius: 5px;
        height: 100%;
        box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
        .contentWrapper {
            @include common.flexColumn;
            height: calc(100% - 10px);
            width: calc(95% - 10px);
            padding: 5px;
            .question {
                @include common.flexRow;
                text-align: left;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .dropdownWrapper {
                @include common.flexRow;
                align-items: center;
            }
            .wysiwygDetailsWrapper {
                @include common.flexColumn;
                border-bottom: 1px dotted theme.$color-light-grey;
                padding-bottom: 5px;
                .wysiwygHeader {
                    @include common.flexRow;
                    align-items: center;
                    height: 38px;
                    .wysiwygOpenIcon {
                        margin-right: 5px;
                    }
                }
            }
        }
        .score {
            min-height: 100%;
            width: 5%;
        }
    }
}

.viewSignOffToggleWrapper {
    @include common.flexRow;
    align-items: center;
    margin-bottom: 5px;
    .toggleLabel {
        font-weight: 600;
        margin-right: 5px;
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    primaryBorder: theme.$color-primary-night-hover;
    lightestGrey: theme.$color-lightest-grey;
    grey: theme.$color-grey;
    lightGrey: theme.$color-light-grey;
    fontFamily: theme.$font-family;
    green: theme.$color-green;
    red: theme.$color-red;
    amber: theme.$color-amber;
    white: theme.$color-white;
    oceanBlue: theme.$color-french-spectrum-2;
    gold: theme.$color-gold;
    yellowGreen: theme.$color-yellow-green;
    mandatory: theme.$color-mandatory;
}
