@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

@mixin label($marginBottom: 5px) {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: $marginBottom;
    color: theme.$color-primary-night;
}

@mixin attestationHeaderCell {
    @include common.flexRow;
    align-items: center;
    height: calc(100% - 10px);
    padding: 5px;
}

@mixin attestationCell {
    @include common.centreFlexRow;
    height: calc(100% - 4px);
    padding: 2px 5px;
}

.attestationInstancesWrapper {
    @include common.flexColumn;
    .attestationInstancesTitleWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .attestationInstancesTitle {
            @include label(0);
            margin-right: 10px;
        }
    }
    .attestationInstancesTable {
        @include common.flexColumn;
        width: fit-content;
        max-width: 100%;
        .attestationInstancesHeader {
            @include common.flexRow;
            width: fit-content;
            align-items: center;
            background-color: theme.$color-french;
            color: theme.$color-white;
            font-weight: theme.$font-medium;
            font-size: 14px;
            border-radius: 5px 5px 0 0;
            border: 1px solid theme.$color-french;
            .headerAttestation {
                @include attestationHeaderCell;
                width: 30px;
                border-right: 1px solid theme.$color-white;
            }
            .headerUserAnswerWrapper {
                @include common.flexRow;
                align-items: center;
                height: calc(100% - 10px);
                border-right: 1px solid theme.$color-white;
                padding: 5px;
                width: 150px;
                .headerUserAnswerLabel {
                    line-height: 28px;
                    height: 100%;
                    width: 100%;
                }
            }
            .headerDeadline {
                @include attestationHeaderCell;
                border-right: 1px solid theme.$color-white;
            }
            .headerRemoveRow {
                @include attestationHeaderCell;
                width: 30px;
            }
        }
        .attestationInstancesBody {
            @include common.flexColumn;
            width: fit-content;
            border-radius: 0 0 5px 5px;
            max-height: calc(50vh - 145px);
            border: 1px solid theme.$color-grey;
            .attestationInstanceRow {
                @include common.flexRow;
                align-items: center;
                width: fit-content;
                color: theme.$color-primary-night;
                text-align: center;
                .rowUserIdentifier {
                    @include attestationCell;
                    width: 30px;
                    font-weight: theme.$font-medium;
                    border-right: 1px solid theme.$color-grey;
                }
                .rowUserAnswerDropdown {
                    @include attestationCell;
                    width: 150px;
                    border-right: 1px solid theme.$color-grey;
                }
                .rowDeadlineWrapper {
                    @include attestationCell;
                    border-right: 1px solid theme.$color-grey;
                    .intervalValueDropdown {
                        margin-right: 5px;
                        width: 100px;
                    }
                    .intervalDropdown {
                        flex: 1;
                    }
                    .datePickerDropdown {
                        width: 100%;
                    }
                }
                .rowDeleteAttestation {
                    @include attestationCell;
                    width: 30px;
                }
            }
            .rowBorder {
                border-bottom: 1px solid theme.$color-grey;
            }
        }
    }
}

:export {
    white: theme.$color-white;
    lightGrey: theme.$color-light-grey;
}
