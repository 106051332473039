@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.conversationScrollableWrapper {
    @include common.flexColumn;
    width: calc(100% - 4px);
    margin: 0 2px;
    overflow: hidden;
    align-items: center;
    .message {
        height: fit-content;
        width: 100%;
        margin: 0 auto;
        .messageWrapper {
            margin-bottom: 15px;
        }
        .messageWrapperAdmin {
            margin-bottom: 15px;
            margin-left: 10%;
            background-color: theme.$color-mandatory-light;
        }
        .messageResponseWrapper {
            background-color: theme.$color-body-disabled-light;
            margin-left: 0%;
            margin-right: 10%;
        }
    }
    .messageMetaData {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .displayName {
            font-weight: theme.$font-medium;
            font-size: 14px;
            color: theme.$color-primary-night;
        }
        .messageDate {
            text-align: center;
            font-weight: theme.$font-medium;
            color: theme.$color-grey;
            font-size: theme.$font-size-h7;
        }
        .responseDate {
            margin-right: 10%;
        }
    }
    .centralisedData {
        justify-content: flex-end;
    }
}

:export {
    amethyst: theme.$color-amethyst;
    red: theme.$color-red;
    green: theme.$color-green;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
}
