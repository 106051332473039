@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.spinnerWrapper {
    @include common.centreFlexColumn;
    width: 100%;
    height: 100%;
}

.hourglass {
    display: block;
    background: theme.$color-primary-night;
    width: 10px;
    height: 20px;
    animation: whiteHourglassAnimation 2s linear infinite;
}

.outer {
    fill: theme.$color-white;
}

.middle {
    fill: theme.$color-primary-night;
}

@keyframes whiteHourglassAnimation {
    0% {
        transform: rotate(0deg);
        box-shadow:
            inset theme.$color-white 0 -0em 0 0,
            inset theme.$color-primary-night 0 -10px 0 0,
            inset theme.$color-white 0 -20px 0 0;
    }

    80% {
        transform: rotate(0deg);
        box-shadow:
            inset theme.$color-white 0 -10px 0 0,
            inset theme.$color-primary-night 0 -10px 0 0,
            inset theme.$color-white 0 -10px 0 0;
    }

    100% {
        transform: rotate(180deg);
        box-shadow:
            inset theme.$color-white 0 -10px 0 0,
            inset theme.$color-primary-night 0 -10px 0 0,
            inset theme.$color-white 0 -10px 0 0;
    }
}

:export {
    primary: theme.$color-primary-night;
}
