@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.agencyDatasetsWrapper {
    @include common.tablePageWrapper;
    .datasetsTitleContainer {
        @include common.spaceBetweenTablePageTitleContainer;
        min-height: 48px;
        .datasetsTitle {
            @include common.tablePageTitle;
        }
    }
}

.settingsModalWrapper {
    @include common.flexColumn;
    justify-content: space-evenly;
    height: 100%;
    width: calc(100% - 20px);
    padding: 10px;
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    .settingsContent {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        margin-bottom: 10px;
        .settingsLabel {
            font-weight: theme.$font-medium;
            font-size: 14px;
        }
        .settingsLabelDisabled {
            color: theme.$color-grey;
        }
    }
    .dropdownFieldWrapper {
        width: 100%;
        max-width: 280px;
        margin-bottom: 10px;
        .dropdownHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            .dropdownLabel {
                font-weight: theme.$font-medium;
                font-size: 14px;
                margin-right: 10px;
                color: theme.$color-primary-night;
            }
            .optionalLabel {
                font-weight: 100;
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
    .agencyEntityWrapper {
        @include common.flexColumn;
        width: 100%;
        margin-bottom: 10px;
        .agencyEntityTitle {
            font-size: 14px;
            font-weight: theme.$font-medium;
            margin-bottom: 5px;
        }
    }
}

.confirmationHeader {
    font-size: 18px;
    font-weight: theme.$font-medium;
}

.confirmationSecondaryMessage {
    font-size: 16px;
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    aqua: theme.$color-aqua;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.7);
    disabledGrey: rgba(theme.$color-grey, 0.5);
    disabledBackground: rgba(theme.$color-grey, 0.05);
}
