@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.usersWrapper {
    @include common.tablePageWrapper;
    .usersTitleContainer {
        @include common.tablePageTitleContainer;
        .usersTitle {
            @include common.tablePageTitle;
        }
    }
}

.userContentWrapper {
    @include common.flexRow;
    margin-bottom: 10px;
    width: 40vw;
    .profilePictureWrapper {
        margin-right: 10px;
    }
    .inputsWrapper {
        display: flex;
        flex-direction: column;
        height: 50vh;
        width: calc(100% - 210px);
    }
}

.permissionsCell {
    @include common.overflowText;
    width: 100%;
    font-family: theme.$font-family;
    font-size: 14px;
}

.featurePermissionOptionsWrapper {
    @include common.flexColumn;
    margin-bottom: 10px;
    .featurePermissionHeader {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
        cursor: pointer;
        .sectionOpenIcon {
            margin-right: 10px;
        }
        .featurePermissionOptionsTitle {
            font-weight: theme.$font-medium;
        }
    }
    .featurePermissionOptionsHeader {
        @include common.spaceBetweenFlexRow;
        align-items: center;
        width: calc(100% - 10px);
        padding: 5px;
        font-weight: theme.$font-medium;
        background-color: theme.$color-french;
        color: theme.$color-white;
        border-radius: 5px 5px 0 0;
        font-size: 14px;
    }
    .featurePermissionOptionsTable {
        max-height: 250px;
        border: 1px solid theme.$color-grey;
        border-radius: 0 0 5px 5px;
        .featurePermissionOptionRow {
            @include common.spaceBetweenFlexRow;
            padding: 5px;
            font-size: 14px;
            &:last-of-type {
                border-radius: 0 0 5px 5px;
            }
            .featurePermissionInfo {
                @include common.spaceBetweenFlexRow;
                width: calc(100% - 46px);
                margin-right: 10px;
                font-weight: theme.$font-medium;
            }
        }
    }
}

:export {
    grey: theme.$color-light-grey;
    white: theme.$color-white;
}
