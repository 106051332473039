@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

@mixin bookStyle($lightColor, $darkColor, $fontColor: theme.$color-antique-white) {
    background: radial-gradient(
        ellipse at top,
        $lightColor,
        $darkColor,
    );
    color: $fontColor;
    position: relative;
}

@mixin bookStyleBefore($path) {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url($path);
    opacity: 0.6;
}

.playbooksWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.simpleSearchWrapper {
    @include common.flexRow;
    width: 90%;
    margin: 0 auto;
}

.playbookSearch {
    display: flex;
    height: fit-content;
    width: 100%;
    .searchInputWrapper {
        @include common.centreFlexRow;
        width: 100%;
        .inputTextWrapper {
            width: 50%;
            margin-right: 10px;
        }
    }
}

.systemAdminViewWrapper {
    @include common.centreFlexRow;
}

.playbookSmartSearch {
    width: 45%;
    margin: 0 0 0 auto;
    height: 100%;
}

.smartInputWrapper {
    align-items: center;
    .smartInputRowsWrapper {
        max-height: 50%;
    }
    .buttonWrapper {
        @include common.centreFlexRow;
        width: calc(100% - 40px);
        align-self: center;
        justify-content: space-between;
        .smartSearchButtonWrapper {
            @include common.flexRow;
        }
    }
}

.conditionSeparator {
    margin: 0px 45px 5px 15px;
    padding: 2px 0;
    border-bottom: dashed theme.$color-french 3px;
}

.smartInputRow {
    @include common.centreFlexRow;
    width: 100%;
    margin-bottom: 5px;
    padding: 2px 0;
    .logicalOperatorColumn {
        width: calc(15% - 20px);
        margin-right: 10px;
        .firstRowTitle {
            text-align: left;
            font-weight: theme.$font-medium;
        }
    }
    .conditionColumn {
        width: calc(20% - 20px);
        margin-right: 10px;
    }
    .contentTypeColumn {
        width: calc(20% - 20px);
        margin-right: 10px;
    }
    .inputWrapper {
        width: calc(45% - 20px);
        margin-right: 10px;
    }
    .deletePlaceholder {
        width: 20px;
    }
}

.bookCaseSpinner {
    display: flex;
    min-height: 100%;
}

.bookshelfSearchWrapper {
    display: flex;
    min-height: 90%;
}

.bookCaseWrapper {
    @include common.flexRow;
    height: 100%;
    background-image: url('../../../assets/playbooks/ARK51_WOOD_PATTERN_HORIZONTAL.png');
    .bookCaseSideWrapper {
        min-width: 20px;
        background: linear-gradient(#48321a, #8c7964);
        background: linear-gradient(to left, #48321a, #8c7964);
        .bookCaseSide {
            height: 100%;
            width: 100%;
            background-image: url('../../../assets/playbooks/ARK51_WOOD_PATTERN_VERTICAL.png');
        }
    }
    .bookCase {
        height: 100%;
        width: fit-content;
        min-width: 100%;
        @include common.flexColumn;
        margin: 0 auto;
        .bookShelf {
            @include common.flexRow;
            padding-top: 40px;
            padding-bottom: 3px;
            padding-left: 60px;
            width: calc(100% - 60px);
        }
        .shelfWrapper {
            height: 20px;
            width: 100%;
            overflow: hidden;
            background: linear-gradient(#48321a, #8c7964);
            .shelf {
                height: 100%;
                background-image: url('../../../assets/playbooks/ARK51_WOOD_PATTERN_HORIZONTAL.png');
            }
        }
    }
}

.bookWrapper {
    overflow: hidden;
    height: 100%;
    margin: 2px;
    cursor: pointer;
    transition: margin 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    filter: grayscale(25%);
    border-radius: 4px;
    background: grey;
    box-shadow: 0 0.5rem 1rem 0rem rgba(0, 0, 0, 0.4);
    border-top-style: solid;
    border-top-width: 1px;
    border-image: linear-gradient(
        to right,
        #333,
        #333 15%,
        theme.$color-antique-white 30%,
        theme.$color-antique-white 70%,
        #333 85%,
        #333
    );
    border-image-slice: 1;
    width: 100px;
    align-items: center;
    &:hover {
        box-shadow: 0 0.5rem 3rem -0.5rem rgba(0, 0, 0, 0.4);
        z-index: 10;
        margin-top: -15px;
        transform: scale(1.03, 1.03);
    }
}
  
.book {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    box-shadow: inset -0.35rem 0 0.5rem rgba(0, 0, 0, 0.4), inset 0.35rem 0 0.5rem rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    font-family: theme.$font-family;
    .bookTitle {
        max-width: 70px;
        height: 80%;
        font-size: 18px;
        font-weight: theme.$font-medium;
        text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
        padding: 15px;
        writing-mode: vertical-rl;
        vertical-align: text-bottom;
        margin: 0 auto;
        text-overflow: ellipsis;
        z-index: 1;
    }
    .draftWrapper {
        @include common.flexColumn;
        justify-content: center;
        align-items: center;
        width: 90%;
        font-size: 18px;
        font-weight: theme.$font-medium;
    }
    .authorAndVolumeWrapper {
        @include common.flexColumn;
        justify-content: center;
        align-items: center;
        min-height: 20%;
        width: 90%;
        border-top: 3px solid theme.$color-gold;
        .bookAuthor {
            width: 99%;
            font-size: 12px;
            font-weight: theme.$font-medium;
            text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
            z-index: 1;
        }
        .bookVolume {
            font-size: 12px;
            font-weight: theme.$font-medium;
            text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
            z-index: 1;
        }
    }
}

.bookCoverModal {
    width: 50vh;
    height: 70vh;
    padding: 0px;
    margin: auto;
    outline: none;
    border: none;
}

.bookCoverWrapper {
    @include common.flexRow;
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: margin 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    filter: grayscale(25%);
    border-radius: 4px;
    background: grey;
    box-shadow: 0 0.5rem 1rem 0rem rgba(0, 0, 0, 0.4);
    border-top-style: solid;
    border-top-width: 1px;
    border-image: linear-gradient(
        to right,
        #333,
        #333 15%,
        theme.$color-antique-white 30%,
        theme.$color-antique-white 70%,
        #333 85%,
        #333
    );
    border-image-slice: 1;
    align-items: center;
    &:hover {
        box-shadow: 0 0.5rem 3rem -0.5rem rgba(0, 0, 0, 0.4);
        z-index: 10;
        transform: scale(1.1, 1.1);
    }
    .bookSpine {
        width: 10px;
        height: 100%;
        background-color: theme.$color-gold;
    }
    .bookCover {
        @include common.flexColumn;
        align-items: center;
        justify-content: flex-end;
        height: calc(100% - 60px);
        width: calc(100% - 50px);
        padding: 40px 20px 20px 20px;
        border-left: 1px dotted theme.$color-antique-white;
        .bookTitle {
            @include common.centreFlexRow;
            font-size: 40px;
            font-weight: theme.$font-bold;
            margin-bottom: 40px;
            z-index: 1;
        }
        .authorAndVolumeWrapper {
            @include common.centreFlexColumn;
            width: 100%;
            font-weight: theme.$font-medium;
            z-index: 1;
            .divider {
                width: 100%;
                height: 3px;
                background-color: theme.$color-gold;
            }
            .bookAuthor {
                font-size: 24px;
            }
        }
    }
}
  
.bookTiltedRight > .bookWrapper {
    float: left;
    width: 100px;
    margin-left: 6px;
    margin-right: 46px;
    transform: translateY(-3px) translateX(20px) rotate(10deg);
}

.bookTiltedRight:hover {
    transform: translateY(3px) translateX(20px) rotate(-10deg);
    transition: transform 0.4s ease;
}
  
.bookTiltedLeft > .bookWrapper {
    float: right;
    width: 100px;
    margin-left: 6px;
    margin-right: 50px;
    transform: translateY(-3px) translateX(20px) rotate(-10deg);
}

.bookTiltedLeft:hover {
    transform: translateY(3px) translateX(20px) rotate(10deg);
    transition: transform 0.4s ease;
}
  
.bookDefault {
    @include bookStyle(theme.$color-french, theme.$color-french-spectrum-7);
}

.bookDefault::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_ARABESQUE.png');
}

.bookOne {
    @include bookStyle(theme.$color-red-spectrum-1, theme.$color-red-spectrum-4);
}

.bookOne::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_FLOWERS.png');
}

.bookOne::after {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_FLOWERS.png');
}

.bookTwo {
    @include bookStyle(theme.$color-french-spectrum-2, theme.$color-primary-night);
}

.bookTwo::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_LUXURY.png');
}
  
.bookThree {
    @include bookStyle(theme.$color-french, theme.$color-primary-night);
}

.bookThree::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_WEAVE.png');
}
  
.bookFour {
    @include bookStyle(theme.$color-red-spectrum-1, theme.$color-red-spectrum-3);
}

.bookFour::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_LYONNETTE.png');
}
  
.bookFive {
    @include bookStyle(theme.$color-mandatory-spectrum-2, theme.$color-mandatory-spectrum-4);
}

.bookFive::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_LEATHER_LARGE.png');
}
  
.bookSix {
    @include bookStyle(theme.$color-french-spectrum-5, theme.$color-primary-night-spectrum-4);
}

.bookSix::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_LEATHER.png');
}
  
.bookSeven {
    @include bookStyle(theme.$color-french-spectrum-4, theme.$color-primary-night-spectrum-4);
}

.bookSeven::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_ARGYLE.png');
}
  
.bookEight {
    @include bookStyle(theme.$color-ocean, theme.$color-amethyst-spectrum-1);
}

.bookEight::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_LEATHER_DARK.png');
}
  
.bookNine {
    @include bookStyle(theme.$color-french-spectrum-3, theme.$color-french-spectrum-8, theme.$color-white);
}

.bookNine::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_EXA.png');
}
  
.bookTen {
    @include bookStyle(theme.$color-mandatory-spectrum-2, theme.$color-mandatory-spectrum-4);
}

.bookTen::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_BINDING_LIGHT.png');
}
  
.bookEleven {
    @include bookStyle(theme.$color-red-spectrum-3, theme.$color-red-spectrum-4);
}

.bookEleven::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_CLIMPEK.png');
}
  
.bookTwelve {
    @include bookStyle(theme.$color-french-spectrum-6, theme.$color-primary-night-spectrum-4);
}

.bookTwelve::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_BASKETBALL.png');
}
  
.bookThirteen {
    @include bookStyle(theme.$color-french-spectrum-1, theme.$color-french-spectrum-5, theme.$color-white);
}

.bookThirteen::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_HONEYCOMB_DARK.png');
}
  
.bookFourteen {
    @include bookStyle(theme.$color-ocean, theme.$color-mandatory-spectrum-4,);
}

.bookFourteen::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_BLACK_SCALES.png');
}
  
.bookFifteen {
    @include bookStyle(theme.$color-amethyst-spectrum-1, theme.$color-primary-night-spectrum-4);
}

.bookFifteen::before {
    @include bookStyleBefore('../../../assets/playbooks/ARK51_HONEYCOMB_LIGHT.png');
}

.pottedPlantWrapper {
    position: relative;
    width: 200px;
}

.pottedPlant {
    background-image: url('../../../assets/playbooks/ARK51_POTTED_PLANT.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 360px;
    position: absolute;
    bottom: -63px;
    left: -15px;
    width: 200px;
    height: calc(100% + 63px);
    overflow: visible;
}

.plantContainer {
	position: relative;
    width: 150px;
    bottom: 0px;
}
