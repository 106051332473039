@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.numberField {
    @include common.inputFieldInstance;
    width: calc(100% - 12px);
    height: 26px;
}

.currencyNumberWrapper {
    @include common.flexColumn;
    width: 100%;
    .currencyNumberInputLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
    }
    .inputWrapper {
        @include common.flexRow;
        width: 100%;
        .labelWrapper {
            @include common.flexRow;
            align-items: center;
            width: 30%;
            &:last-of-type {
                width: 70%;
            }
            .inputLabel {
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h4;
                color: theme.$color-primary-night;
                text-decoration: underline;
                width: fit-content;
                margin-right: 10px;
            }
            &:first-of-type {
                margin-right: 30px;
            }
        }
    }
}

:export {
    primary: theme.$color-primary-night;
}
