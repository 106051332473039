@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.sectionHeader {
    @include common.flexRow;
    align-items: center;
    color: theme.$color-primary-night;
    border-bottom: 1px dashed theme.$color-grey;
    padding: 10px 0;
    &:hover {
        color: theme.$color-french;
    }
    .sectionOpenIcon {
        margin-right: 10px;
        cursor: pointer;
    }
    .sectionUpdated {
        @include common.centreFlexRow;
        padding: 3px 5px;
        border: 1px solid theme.$color-amethyst;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 800;
        color: theme.$color-amethyst;
        box-shadow: inset 0 0 2px theme.$color-amethyst;
    }
    .unverifiedSectionIcon {
        color: theme.$color-red;
    }
    .sectionLabel {
        font-size: 20px;
        font-family: theme.$font-family;
        margin-right: 10px;
        font-weight: 600;
    }
    .sectionDescription {
        margin-left: 10px;
    }
}

.subSectionHeader {
    @include common.flexRow;
    align-items: center;
    color: theme.$color-primary-night;
    border-bottom: 1px dashed theme.$color-light-grey;
    padding: 5px 0;
    &:hover {
        color: theme.$color-french;
    }
    .sectionOpenIcon {
        margin-right: 10px;
        cursor: pointer;
    }
    .subSectionLabel {
        font-size: 16px;
        font-family: theme.$font-family;
        margin-right: 10px;
        font-weight: 500;
    }
}

.sectionGroup {
    @include common.flexRow;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.allFieldsWrapper {
    margin-top: 10px;
}

.sectionHeaderLabel {
    @include common.flexRow;
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: 18px;
    font-family: theme.$font-family;
    margin-right: 10px;
    font-weight: 600;
    padding-bottom: 3px;
    border-bottom: 1px solid theme.$color-light-grey;
}

.agreementCoverageWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .agreementCoverageHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 2) - 10px);
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
    }
    .searchWrapper {
        @include common.flexRow;
        width: 50%;
        margin-bottom: 10px;
    }
    .agreementCoverageOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
    }
    .groupOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        margin-bottom: 5px;
    }
    .toggleOptionWrapper {
        @include common.spaceBetweenFlexRow;
        align-items: center;
        padding: 5px;
        .agreementCoverageLabel {
            @include common.flexRow;
            justify-content: flex-start;
            width: calc(100% - 46px);
        }
        .toggleOptionInput {
            width: calc(100% - 46px);
            border: none;
            border-bottom: 1px dotted theme.$color-grey;
            font-family: theme.$font-family;
            font-size: 16px;
            margin-right: 10px;
            &:hover {
                border-bottom: 1px dotted theme.$color-primary-night;
            }
            &:focus {
                outline: none !important;
            }
        }
    }
}

.transactionCoverageWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .transactionCoverageHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 3) - 10px);
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: calc(50% - 10px);
            margin-left: 10px;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
            }
        }
    }
    .transactionCoverageOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            padding: 5px;
            .transactionCoverageLabel {
                @include common.flexRow;
                justify-content: flex-start;
                width: calc(100% - 46px);
            }
            .toggleOptionInput {
                width: calc(100% - 46px);
                border: none;
                border-bottom: 1px dotted theme.$color-grey;
                font-family: theme.$font-family;
                font-size: 16px;
                margin-right: 10px;
                &:hover {
                    border-bottom: 1px dotted theme.$color-primary-night;
                }
                &:focus {
                    outline: none !important;
                }
            }
        }
    }
}

.counterpartyCoverageWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .counterpartyCoverageHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 4px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc(50% - 10px);
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 92px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: calc(50% - 10px);
            margin-left: 10px;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
                margin-right: 5px;
            }
        }
    }
    .counterpartyCoverageOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            padding: 5px;
            .counterpartyCoverageLabel {
                @include common.flexRow;
                justify-content: flex-start;
                width: calc(100% - 92px);
            }
            .toggleOptionInput {
                width: calc(100% - 92px);
                border: none;
                border-bottom: 1px dotted theme.$color-grey;
                font-family: theme.$font-family;
                font-size: 16px;
                margin-right: 10px;
                &:hover {
                    border-bottom: 1px dotted theme.$color-primary-night;
                }
                &:focus {
                    outline: none !important;
                }
            }
        }
    }
}

.coverageWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .signOffConversationWrapper {
        @include common.flexRow;
        justify-content: flex-end;
        margin-bottom: 5px;
    }
    .optionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            padding: 5px;
            .annexCoverageLabel {
                @include common.flexRow;
                justify-content: flex-start;
                width: calc(100% - 46px);
            }
        }
    }
}

.columnHeaderWrapper {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    .columnHeaders {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        padding: 0 5px;
        .columnHeader {
            @include common.centreFlexRow;
            width: 36px;
            &:not(:last-child) {
                margin-right: 10px;
            }
            .aetHeader {
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}

.multiToggleWrapper {
    @include common.flexRow;
    align-items: center;
    .toggleWrapper {
        @include common.centreFlexRow;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.noneRequiredSectionWrapper {
    @include common.spaceBetweenFlexRow;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid theme.$color-light-grey;
    .noneRequiredWrapper {
        @include common.flexRow;
        .noneRequiredLabel {
            margin-right: 10px;
            font-weight: 500;
            text-align: left;
        }
    }
}

.clauseTableWrapper {
    display: flex;
    justify-content: center;
}
.clauseLibraryTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    .clauseLibraryTitle {
        font-size: theme.$font-size-h4;
        font-weight: theme.$font-family;
        margin-right: 10px;
        color: theme.$color-primary-night;
    }
}

.clauseModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 80vh;
}

.linkClauseModalWrapper {
    @include common.flexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
}

.eligibleCollateralWrapper {
    @include common.flexColumn;
    .collateralWrapper {
        @include common.flexColumn;
        .allIncludedWrapper {
            @include common.flexRow;
            align-items: center;
            .allIncludedLabel {
                font-weight: 500;
                text-align: left;
                margin-right: 10px;
            }
            .allIncludedDropdown {
                @include common.flexRow;
                width: calc(100% - 181px);
                max-width: 400px;
            }
        }
        .collateralTableWrapper {
            @include common.flexColumn;
            width: calc(100% - 2px);
            border: 1px solid theme.$color-french;
            border-radius: 5px;
            font-family: theme.$font-family;
            margin: 5px 0;
            .collateralTableHeader {
                @include common.flexRow;
                width: 100%;
                border-radius: 5px 5px 0 0;
                .collateralHeaderCell {
                    @include common.spaceBetweenFlexRow;
                    border-bottom: 5px solid theme.$color-french;
                    border-top: 5px solid theme.$color-french;
                    padding: 0 8px;
                    background-color: theme.$color-french;
                    color: theme.$color-white;
                    text-align: left;
                    font-size: 14px;
                    font-weight: theme.$font-bold;
                    &:not(:last-child) {
                        border-right: 1px solid rgba(theme.$color-white, 0.4);
                    }
                }
            }
            .collateralTableBody {
                @include common.flexColumn;
                max-height: 40vh;
            }
        }
    }
}

.collateralRowWrapper {
    @include common.flexRow;
    width: 100%;
    &:not(:last-child) {
        border-bottom: 1px solid theme.$color-light-grey;
    }
    .collateralCell {
        @include common.flexRow;
        align-items: center;
        min-height: 38px;
        width: calc(25% - 17px);
        border-right: 1px solid theme.$color-light-grey;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        padding: 3px 8px;
    }
    .emptyCell {
        background-color: rgba(theme.$color-light-grey, 0.8);
        padding: 3px 8px;
        &:not(:last-child) {
            width: calc(15% - 17px);
            border-right: 1px solid theme.$color-light-grey;
        }
        &:last-child {
            width: calc(15% - 16px);
        }
    }
    .dropdownCell {
        @include common.centreFlexRow;
        padding: 3px 8px;
        &:not(:last-child) {
            width: calc(15% - 17px);
            border-right: 1px solid theme.$color-light-grey;
        }
        &:last-child {
            width: calc(15% - 16px);
        }
    }
}

.subCounterpartyTypesModalWrapper {
    padding: 10px 10px 5px 10px;
    @include common.flexColumn;
    .subCounterpartyTypesModalBody {
        @include common.flexRow;
        width: 100%;
        height: calc(100% - 104px);
        border-bottom: 1px solid theme.$color-light-grey;
        .subCounterpartyTypesIncludedItemsSectionWrapper {
            @include common.flexColumn;
            width: 67%;
            .addNewSubCounterpartyTypeWrapper {
                @include common.centreFlexRow;
                width: 100%;
                .addNewSubCounterpartyTypeButtonWrapper {
                    @include common.centreFlexRow;
                }
            }
        }
        .subCounterpartyTypesSelectColumn {
            @include common.centreFlexRow;
            height: 100%;
            width: 33%;
            .selected {
                background-color: theme.$color-french;
                color: theme.$color-white;
            }
        }
    }
    .subCounterpartyTypesModalButtonWrapper {
        @include common.flexRow;
        width: 100%;
        justify-content: flex-end;
    }
}

.parentCounterpartyCard {
    @include common.flexColumn;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    font-family: theme.$font-family;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    color: theme.$color-primary-night;
    .parentCounterpartyCardTooltip {
        width: calc(100% - 20px);
        text-align: left;
    }
    &:hover {
        box-shadow: 1px 1px 5px theme.$color-french;
        transform: scale(1.02);
        cursor: pointer;
    }
}

.subCounterpartyConfigCardsContentWrapper {
    @include common.flexRow;
    height: calc(100% - 48px);
    width: 100%;
}

.subCounterpartyTypesColumnWrapper {
    @include common.flexRow;
    width: 50%;
    height: 100%;
    .subCounterpartyTypesColumnSection {
        @include common.centreFlexColumn;
        height: calc(100% - 10px);
        width: 100%;
        .subCounterpartyTypesColumnHeader {
            @include common.flexRow;
            justify-content: space-between;
            width: calc(100% - 14px);
            margin: 0 2px 10px 2px;
            padding: 0 5px;
            .subCounterpartyTypesColumnHeaderSection {
                @include common.flexRow;
                align-items: center;
                width: calc(100% - 100px);
            }
            .subCounterpartyTypesColumnHeaderSelectAll {
                @include common.flexRow;
                align-items: center;
            }
            .subCounterpartyTypesColumnTitle {
                margin-right: 10px;
                font-weight: bold;
                color: theme.$color-primary-night;
            }
        }
        .subCounterpartyTypesColumnListWrapper {
            width: calc(100% - 2px);
            height: calc(100% - 32px);
            border: 1px solid theme.$color-light-grey;
            @include common.centreFlexRow;
            border-radius: 5px;
            .subCounterpartyConfigToggleCard {
                @include common.flexRow;
                width: calc(100% - 14px);
                align-items: center;
                flex-wrap: wrap;
                border-bottom: 1px solid theme.$color-white;
                font-size: 16px;
                justify-content: space-between;
                margin: 2px;
                padding: 5px;
                color: theme.$color-primary-night;
            }
        }
    }
}

.included {
    background-color: rgba(theme.$color-mandatory, 0.1);
}

.excluded {
    background-color: rgba(theme.$color-red, 0.1);
}

:export {
    french: theme.$color-french;
    grey: rgba(theme.$color-grey, 0.7);
    disabledGrey: rgba(theme.$color-grey, 0.5);
    primary: theme.$color-primary-night;
    lightGrey: theme.$color-light-grey;
    amethyst: theme.$color-amethyst;
    gold: theme.$color-gold;
}
