@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.simpleSearchWrapper {
    display: flex;
    height: fit-content;
    width: 100%;
    .searchInputWrapper {
        @include common.centreFlexRow;
        width: 100%;
        .inputTextWrapper {
            width: 50%;
            margin-right: 10px;
        }
    }
}
