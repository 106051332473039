@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wizard {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    min-width: 50vw;
}

.wrapper {
    @include common.flexColumn;
    justify-content: space-evenly;
    height: 100%;
    .tabWrapper {
        @include common.flexRow;
        width: 100%;
    }
    .buttonWrapper {
        @include common.flexRow;
        justify-content: space-between;
        .leftButtonWrapper {
            @include common.flexRow;
            justify-content: flex-start;  
        }
        .rightButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;  
        }
    }
}

.wizardTabWrapper {
    @include common.flexRow;
    flex: 1;
    margin-bottom: 10px;
    .wizardTab {
        @include common.centreFlexRow;
        @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-grey);
        border-radius: 5px;
        width: 100%;
        height: 35px;
        font-size: 14px;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        margin: 0 2px;
        &:hover {
            background-color: theme.$color-lightest-grey;
        }
        &:disabled {
            cursor: not-allowed;
        }
        .content {
            @include common.flexRow;
            align-items: center;
        }
    }
    .selectedTab {
        background-color: theme.$color-french;
        color: theme.$color-white;
        border: 1px solid theme.$color-french;
        font-weight: theme.$font-bold;
        &:hover {
            background-color: theme.$color-french;
        }
    }
}
