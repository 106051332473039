@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.pdfViewerWrapper {
    height: 100%;
    width: 100%;
    .controlBarWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        border-bottom: 1px solid theme.$color-light-grey;
        .sideMenuButton {
            @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
            @include common.centreFlexRow;
            padding: 0;
            flex-wrap: wrap;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            margin-left: 10px;
            &:hover {
                color: theme.$color-french;
                border-color: theme.$color-french;
            }
            .thumbnailTile {
                width: 6px;
                height: 6px;
                margin: 1px 2px;
                border: 1px solid;
                border-radius: 2px;
            }
        }
    }
    .pdfViewer {
        @include common.flexRow;
        height: calc(100% - 49px);
        width: 100%;
        .pdfContent {
            @include common.flexRow;
            height: 100%;
            width: 100%;
        }
    }
    .documentWrapper,
    .sidebarWrapper {
        height: 100%;
    }
    .paginationWrapper {
        @include common.centreFlexRow;
        height: 40px;
        min-width: 100px;
        margin: 0 10px;
        padding: 0 10px;
        .buttonAndIconWrapper {
            @include common.centreFlexRow;
            .buttonWrapper {
                @include common.flexRow;
                align-items: center;
                border-left: 1px solid theme.$color-light-grey;
                border-right: 1px solid theme.$color-light-grey;
                .paginationButton {
                    @include common.transparentButton;
                    font-size: 24px;
                    &:hover {
                        color: theme.$color-french;
                    }
                    &:disabled {
                        color: theme.$color-grey;
                    }
                }
                .pageNumber {
                    margin: 0 5px;
                    font-size: 20px;
                }
            }
            .iconWrapper {
                @include common.centreFlexRow;
                margin-left: 10px;
            }
        }
    }
}

.documentPdfWrapper {
    width: 100%;
}

::-webkit-scrollbar {
    width: '4px';
    height: '0px';
    border-radius: '5px';
}
::-webkit-scrollbar-track {
    background: rgba(theme.$color-grey, 0.05);
    border-radius: '5px';
}
::-webkit-scrollbar-thumb {
    background: rgba(theme.$color-grey, 0.5);
    border-radius: '5px';
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(theme.$color-grey, 0.7);
}
.thumbnailWrapper {
    position: relative;
    .thumbnail {
        width: calc(100% - 20px);
        padding: 2px;
        margin: 8px 2px;
        border: 1px solid theme.$color-light-grey;
        border-radius: 5px;
        min-height: 40px
    }
    .thumbnailPageNumber {
        @include common.centreFlexRow;
        color: theme.$color-primary-night;
        position: absolute;
        z-index: 10;
        background-color: theme.$color-white;
        border-radius: 50%;
        border: 1px solid theme.$color-grey;
        font-size: 12px;
        height: 20px;
        width: 20px;
    }
    .selectedThumbnail {
        box-shadow: 1px 1px 5px theme.$color-french;
    }
}
