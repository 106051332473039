@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.singleFieldWrapper {
    @include common.flexColumn;
    margin: 10px 0 15px 0;
    .fieldTitleWrapper {
        @include common.flexRow;
        margin-bottom: 5px;
        align-items: center;
        line-height: 22px;
        min-height: 24px;
        .fieldOpenWrapper {
            margin-right: 10px;
        }
        .fieldLabel {
            font-weight: 500;
            text-align: left;
            margin-right: 10px;
        }
        .datasetSectionText {
            font-size: 20px;
        }
    }
    .fieldCanCollapse {
        &:hover {
            color: theme.$color-french;
        }
    }
}

.pageRefsWrapper {
    @include common.flexColumn;
    max-height: 129px;
    .pageRefWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .deletePlaceholder {
            width: 20px;
        }
    }
}

.refButtonWrapper {
    @include common.flexRow;
    align-items: center;
}

.pageRefHeaderWrapper {
    @include common.flexRow;
    align-items: center;
    margin: 4px 0 4px 0;
}

.textField {
    @include common.inputFieldInstance;
    resize: none;
    height: 26px;
    flex-grow: 1;
    width: calc(100% - 12px);
    font-size: 13px;
    font-weight: 500;
}

.longTextField {
    @include common.inputFieldInstance;
    resize: none;
    width: calc(100% - 12px);
    line-height: 1.2;
    height: 80px;
    font-size: 13px;
    font-weight: 500;
}

.dateFieldWrapper {
    max-width: 230px;
}

.dateField {
    @include common.inputFieldInstance;
    height: 26px;
    background: inherit;
    padding: 5px;
    max-width: 230px;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    &::-webkit-input-placeholder {
        color: theme.$color-primary-night;
    }
}

.numberField {
    @include common.inputFieldInstance;
    width: calc(100% - 12px);
    height: 26px;
}

.toggleFieldWrapper {
    display: flex;
    height: 23px;
    align-items: flex-end;
}

.entityWrapper {
    @include common.spaceBetweenFlexRow;
}

.textWrapper {
    @include common.spaceBetweenFlexRow;
}

.dropdownAndNotesWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    .dropdownFieldWrapper {
        @include common.flexRow;
        width: calc(100% - 42px);
        .dropdownField {
            width: 100%;
            max-width: 400px;
        }
    }
}

.details {
    @include common.flexColumn;
    width: 100%;
    .fieldOpenWrapper {
        margin-bottom: 5px;
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
        }
    }
}

.updatedInputField {
    border: 1px solid theme.$color-amethyst !important;
}

.multiToggleField {
    @include common.flexRow;
    align-items: center;
    width: 100%;
}

.multiToggleLabel {
    width: calc(100% - 46px);
    margin-right: 10px;
    text-align: left;
}

.multiToggleGroupLabel {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
}

.multiToggleWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .multiToggleHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 3) - 21px);
            margin-right: 10px;
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
            }
        }
    }
    .multiToggleOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.flexRow;
            align-items: center;
            padding: 5px;
            .toggleOptionInput {
                width: calc(100% - 46px);
                border: none;
                border-bottom: 1px dotted theme.$color-grey;
                font-family: theme.$font-family;
                font-size: 16px;
                margin-right: 10px;
                &:hover {
                    border-bottom: 1px dotted theme.$color-primary-night;
                }
                &:focus {
                    outline: none !important;
                }
            }
        }
    }
}

.refButton {
    @include common.transparentButton(theme.$color-primary-night);
    padding: 2px;
    &:hover {
        color: theme.$color-french;
        border-color: theme.$color-french;
    }
}

.unverifiedRefButton {
    color: theme.$color-red;
}

.readOnlyPageRef {
    color: theme.$color-primary-night;
    cursor: pointer;
    &:hover {
        color: theme.$color-french;
    }
}

.pageRefContent {
    @include common.flexColumn;
    width: fit-content;
    min-width: 100px;
    .pageRefWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        color: theme.$color-primary-night;
        padding: 3px 0;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
        }
    }
    .unverifiedPageRef {
        color: theme.$color-red;
    }
    .relevantPages {
        text-decoration: underline;
        margin-bottom: 3px;
        font-size: 13px;
    }
}

.disabledRefButton {
    @include common.centreFlexRow;
    height: 22px;
    margin: 0 3px;
}

.dropdownDetailsWrapper {
    @include common.flexColumn;
    .wysiwygDetailsWrapper {
        @include common.flexColumn;
        border-bottom: 1px dotted theme.$color-light-grey;
        padding-bottom: 5px;
        .wysiwygHeader {
            @include common.flexRow;
            align-items: center;
            height: 38px;
            .wysiwygOpenIcon {
                margin-right: 5px;
            }
        }
    }
}

.wysiwygWrapper {
    @include common.flexStartSpaceBetweenFlexRow;
    width: 100%;
}

.datePickerWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
}

.refInput {
    @include common.labelModalTextArea;
}

.refInputTitle {
    font-size: 14px;
    font-weight: theme.$font-medium;
    margin-top: -5px;
}

.pageRefTitle {
    font-size: 14px;
    font-weight: theme.$font-medium;
    margin-right: 5px;
}

.answerIndicator {
    @include common.flexRow;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.signOffAnswerIndicator {
    @include common.flexRow;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 5px 0;
    .indicatorWrapper {
        opacity: 0.5;
        &:hover {
            opacity: 0.25;
            cursor: pointer;
        }
    }
    .onClickOpacity {
        opacity: 1;
    }
}

.addedHeight {
    height: 100%;
    font-size: 100px;
}

.dropdownContentWrapper {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    .dropdownAndIndicatorWrapper {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 42px);
        .dropdownWrapper {
            width: 400px;
        }
    }
}

.informationTooltipIcon {
    @include common.centreFlexRow;
    font-family: theme.$font-family;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    font-weight: theme.$font-bold;
    height: 13px;
    width: 13px;
    min-width: 13px;
    min-height: 13px;
    font-size: 12px;
    &:not(:last-child) {
        margin-right: 5px;
    }
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        color: theme.$color-light-grey;
    }
}

:export {
    french: theme.$color-french;
    grey: theme.$color-grey;
    disabledGrey: rgba(theme.$color-grey, 0.5);
    primary: theme.$color-primary-night;
    lightGrey: theme.$color-light-grey;
    lightestGrey: theme.$color-lightest-grey;
    amethyst: theme.$color-amethyst;
    green: theme.$color-green;
    amber: theme.$color-amber;
    red: theme.$color-red;
    gold: theme.$color-gold;
    white: theme.$color-white;
}
