@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.notificationWrapper {
    @include common.flexColumn;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 20px;
    .titleContainer {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        .title {
            @include common.tablePageTitle;
        }
    }
    .notificationContent {
        @include common.flexColumn;
        height: calc(100% - 304px);
        .notificationTitleWrapper {
            @include common.spaceBetweenFlexRow;
            margin-bottom: 10px;
            .notificationTitleLabel {
                margin-right: 10px;
                font-weight: theme.$font-medium;
            }
            .notificationTitleInput {
                flex: 1;
            }
        }
        .contentWrapper {
            height: calc(100% - 50px);
        }
    }
    .notificationRecipientWrapper {
        @include common.spaceBetweenFlexRow;
        align-items: flex-start;
        width: 100%;
        height: 200px;
        .clientSelectWrapper {
            @include common.flexColumn;
            width: 50%;
        }
        .adminOnlyWrapper {
            @include common.flexRow;
            align-items: center;
            margin: 10px 0;
            .adminOnlyTitle {
                font-weight: theme.$font-medium;
                margin-right: 10px;
            }
        }
    }
    .buttonWrapper {
        @include common.flexRow;
        width: 100%;
        justify-content: flex-end;
        align-self: flex-end;
    }
}

.previewModal {
    width: 50vw;
    padding: 5px;
    .previewContent {
        width: 100%;
    }
    .clientNamesWrapper {
        @include common.flexColumn;
        width: 100%;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid theme.$color-light-grey;
        .clientNames {
            @include common.flexRow;
            width: 100%;
            flex-wrap: wrap;
            font-size: 14px;
        }
    }
}

.previewModalHeader {
    font-weight: 600;
    font-size: 20px;
}

:export {
    white: theme.$color-white;
}
