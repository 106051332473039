@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin page {
    @include common.flexColumn;
    box-shadow: 1px 1px 5px theme.$color-french;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    padding: 20px;
}

.nettingEngineDocumentsWrapper {
    @include common.tablePageWrapper;
    .nettingEngineDocumentsTitleContainer {
        @include common.tablePageTitleContainer;
        height: 35px;
        margin-bottom: 10px;
        .nettingEngineDocumentsTitle {
            @include common.tablePageTitle;
        }
    }
}

.summaryWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    .summaryTitle {
        font-size: theme.$font-size-h1;
        text-decoration: underline;
        margin-bottom: 20px;
        width: 100%;
    }
    .analysisWrapper {
        @include common.flexRow;
        margin-bottom: 10px;
        .sideMenuWrapper {
            @include common.flexColumn;
            align-items: center;
            width: 40px;
            margin: 0 -5px 0 5px;
            .sectionWrapper {
                @include common.flexColumn;
                align-items: center;
                width: 100%;
                .divider {
                    justify-self: center;
                    width: 100%;
                    height: 2px;
                    background-color: theme.$color-primary-night;
                    margin: 6px 0;
                }
            }
        }
        .splitWrapper {
            @include common.flexRow;
            .documentAnalysisWrapper {
                @include page;
                margin: 0 10px;
            }
            .datasetAnalysisWrapper {
                @include page;
                margin: 0 10px;
                    .surveyQuestionAndAnswerWrapper {
                        @include common.flexColumn;
                        .surveySummaryWrapper {
                            @include common.flexRow;
                            padding: 10px;
                            border: solid 1px theme.$color-light-grey;
                            border-radius: 5px;
                            align-items: center;
                            margin-bottom: 5px;
                            font-size: theme.$font-size-h4;
                            background-color: theme.$color-white;
                            .surveySummary {
                                @include common.flexColumn;
                                width: 60%;
                            }
                            .surveySummaryQuestion {
                                width: calc(100% - 10px);
                                margin-right: 10px;
                                text-align: left;
                            }
                            .surveySummaryMessage {
                                @include common.flexRow;
                                text-align: left;
                                color: theme.$color-amber;
                            }
                            .surveySummaryAnswer {
                                width: 40%;
                                text-align: left;
                            }
                        }
                    }
            }
        }
    }
    .surveyButtonCalculate {
        display: flex;
        align-items: center;
        min-width: 30%;
        justify-content: space-between;
        width: calc(100% - 60px);
        margin-left: 50px;
        margin-right: 10px;
        height: 50px;
    }
}
