@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.selectWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    color: theme.$color-primary-night;
    .selectionWrapper {
        @include common.flexRow;
        justify-content: center;
        flex: 1;
        .selectButton {
            @include common.centreFlexColumn;
            width: 45%;
            margin: 20px;
            color: theme.$color-primary-night;
            background-color: white;
            border: 1px solid theme.$color-grey;
            border-radius: 10px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            cursor: pointer;
            &:hover {
                color: theme.$color-french;
                box-shadow: 1px 1px 5px theme.$color-french;
            }
            .selectIcon {
                display: flex;
                align-items: center;
            }
            .selectLabel {
                margin-top: 20px;
                font-size: 50px;
                font-weight: theme.$font-medium;
                font-family: theme.$font-family;
            }
            .allDescriptionsWrapper {
                @include common.flexColumn;
                margin-top: 20px;
                height: 120px;
                .selectDescription {
                    font-family: theme.$font-family;
                    margin-top: 5px;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-height: 800px) {
    .selectLabel {
        font-size: 30px !important;
    }
    .allDescriptionsWrapper {
        height: 100px !important;
        .selectDescription {
            font-size: 14px !important;
        }
    }
}
