@use "../../../../styles/theme" as theme;
@use "../../../../styles/common" as common;

.historicalSliderWrapper {
    width: calc(100% - 70px);
    margin: 5px 35px 35px 35px;
}

.marker {
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    font-size: 14px;
    overflow: visible;
    font-weight: theme.$font-medium;
    white-space: nowrap;
}

.iconWrapper {
    @include common.flexRow;
}

.overlayWrapper {
    @include common.flexColumn;
    width: fit-content;
    min-width: 160px;
    .contentDescription {
        color: theme.$color-primary-night;
        padding-bottom: 3px;
        font-weight: theme.$font-bold;
        font-size: 14px;
    }
    .contentUsername,
    .contentDateOfOpinion,
    .contentModifiedDate {
        padding-top: 4px;
        font-weight: theme.$font-medium;
    }
}

:export {
    french: theme.$color-french;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.3);
    restatementGrey: theme.$color-grey;
}
