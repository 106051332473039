@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin britishOpinion {
    @include common.flexColumn;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: calc(50% - 22px);
    height: calc(100% - 10px);
    padding: 5px;
    border: 1px solid theme.$color-grey;
    margin: 5px 0px;
    color: theme.$color-primary-night;
    font-size: 20px;
    font-weight: theme.$font-family;
    text-decoration: underline;
}

.opinionsWrapper {
    @include common.tablePageWrapper;
    .opinionsTitleContainer {
        height: 50px;
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 16vw);
        margin-left: 8vw;
        margin-right: 8vw;
        justify-content: space-between;
        .titleAndPlusButton {
            @include common.flexRow;
            .opinionsTitle {
                @include common.tablePageTitle;
            }
        }
        .opinionsView {
            display: flex;
        }
    }
}

.opinionsMapWrapper {
    @include common.centreFlex(column);
    .opinionsMapDropdownWrapper {
        @include common.flexRow;
        width: calc(100% - 16vw);
        justify-content: space-between;
        .opinionsMapZoomWrapper {
            @include common.flexRow;
            margin-top: 5px;
            align-items: center;
            width: 84px;
        }
        .legendsWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc(50% - 84px);
            .legendWrapper {
                @include common.flexRow;
                .legendColour {
                    border-radius: 50%;
                    min-height: 20px;
                    min-width: 20px;
                    border: 1px solid theme.$color-primary-night;
                    margin: 0 5px;
                }
                .legendLabel {
                    color: theme.$color-body;
                    font-weight: theme.$font-bold;
                    font-family: theme.$font-family;
                }
            }
        }
        .opinionsMapDropdown {
            @include common.flexRow;
            align-self: flex-end;
            width: calc(50% - 84px);
            margin-top: 5px;
        }
    }
}

.multiJurisdictionModalWrapper {
    @include common.flexColumn;
    width: 450px;
    height: 150px;
    .jurisdictionOptions {
        @include common.flexRow;
        align-items: center;
        justify-content: space-between;
        height: calc(100% - 61px);
        .enabledOpinion {
            @include britishOpinion;
            &:hover {
                cursor: pointer;
                box-shadow: 1px 1px 3px theme.$color-french;
            }
        }
        .disabledOpinion {
            @include britishOpinion;
            &:hover {
                cursor: not-allowed;
                box-shadow: none;
            }
        }
    }
}

.unauthorisedOpinionWrapper {
    @include common.flexColumn;
    width: 40vw;
    .warningHeader {
        font-size: 18px;
        font-weight: 600;
        color: theme.$color-red;
    }
    .unauthorisedOpinion {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 12px);
        padding: 5px;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        margin: 10px 0;
        .unauthorisedOpinionIcon {
            margin-right: 10px;
        }
        .unauthorisedOpinionDetails {
            @include common.flexColumn;
            width: calc(100% - 70px);
            color: theme.$color-primary-night;
            .jurisdiction {
                font-size: 20px;
                font-weight: 600;
            }
            .focusAndAgreementType {
                @include common.flexRow;
                align-items: center;
                .agreementType,
                .focus {
                    font-size: 18px;
                    font-weight: 600;
                    width: 50%;
                }
            }
            .scopeAndCommissionedBy {
                @include common.flexRow;
                align-items: center;
                .scope,
                .commissionedBy {
                    width: 50%;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    .unauthorisedOpinionAccess {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: 600;
        color: theme.$color-primary-night;
    }
}

.summaryPreviewModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .summaryPreviewWrapper {
        @include common.flexColumn;
        width: 50vw;
        .summaryPreview {
            @include common.centreFlexColumn;
            border-top: none;
            margin: 10px;
            height: 80vh;
        }
        .buttonWrapper {
            @include common.flexRow;
            width: calc(100% - 20px);
            align-items: center;
            justify-content: flex-end;
            margin: 0 10px 10px 10px;
        }
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    primaryBorder: theme.$color-primary-night-hover;
    lightestGrey: theme.$color-lightest-grey;
    grey: theme.$color-grey;
    lightGrey: theme.$color-light-grey;
    fontFamily: theme.$font-family;
    permittedToView: theme.$color-permitted-to-view;
    white: theme.$color-white;
    oceanBlue: theme.$color-french-spectrum-2;
    gold: theme.$color-gold;
}
