@use "../../styles/theme" as theme;
@use "../../styles/common" as common;

.wrapper {
    @include common.flexColumn;
    height: calc(100% - 10px);
    width: calc(100% - 20px);
    padding: 0 10px;
    margin-bottom: 10px;
    .title {
        @include common.flexRow;
        height: 40px;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        text-align: start;
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: theme.$font-bold;
        color: theme.$color-primary-night;
    }
    .iFrame {
        @include common.flexRow;
        height: calc(100% - 50px);
        width: 100%;
    }
}
