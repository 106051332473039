@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.adminOpinionWrapper {
    @include common.flexRow;
    height: 100%;
    width: 100%;
    .adminOpinionSectionWrapper {
        @include common.centreFlexColumn;
        justify-content: flex-start;
        flex: 1;
        margin: 20px;
        border-radius: 10px;
        padding: 20px;
        color: theme.$color-primary-night;
    }
    .additionalTableStyling {
        height: 100%;
        width: 100%;
    }
}

.noDataMessage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme.$color-primary-night;
}

.pieChartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .tableWrapper {
        height: 250px;
    }
}

.sectionHeader {
    @include common.centreFlexColumn;
    margin-bottom: 20px;
    .sectionTitle {
        font-size: 20px;
        margin-bottom: 5px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
    }
}

:export {
    grey: theme.$color-grey;
    red: theme.$color-red;
    amber: theme.$color-amber;
    green: theme.$color-mandatory;
}
