@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.opinionDetailsWrapper {
    .inputWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        .inputHeader {
            @include common.inputHeader;
        }
        .reviewedByWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            .reviewedByHeader {
                font-size: 16px;
                font-weight: theme.$font-medium;
                text-decoration: underline;
                margin-right: 10px;
            }
        }
        .selectedDocument {
            @include common.overflowText;
        }
    }
    .industryStandardWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        .industryStandardLabel {
            font-size: 12px;
            font-weight: theme.$font-medium;
            margin-right: 5px;
        }
    }
}

.potentialBaseOpinionWrapper {
    @include common.flexColumn;
    width: 70vw;
    .warningHeader {
        font-size: 18px;
        font-weight: 600;
        color: theme.$color-french;
    }
    .potentialBaseOpinion {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 12px);
        padding: 5px;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        margin: 10px 0;
        .potentialBaseOpinionIcon {
            margin-right: 10px;
        }
        .potentialBaseOpinionDetails {
            @include common.flexColumn;
            width: calc(100% - 70px);
            color: theme.$color-primary-night;
            .jurisdiction {
                font-size: 20px;
                font-weight: 600;
            }
            .focusAndAgreementType {
                @include common.flexRow;
                align-items: center;
                .agreementType,
                .focus {
                    font-size: 18px;
                    font-weight: 600;
                    width: 50%;
                }
            }
            .scopeAndCommissionedBy {
                @include common.flexRow;
                align-items: center;
                .scope,
                .commissionedBy {
                    width: 50%;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .typeAndDate {
                @include common.flexRow;
                align-items: center;
                .type,
                .dateOfOpinion {
                    width: 50%;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    .notesWrapper {
        @include common.flexColumn;
        margin: 10px 0;
        border-bottom: 1px dotted theme.$color-grey;
        padding-bottom: 5px;
        .potentialBaseOpinionNote {
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 500;
            color: theme.$color-primary-night;
        }
    }
    .optionDivider {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 5px;
    }
    .assertionWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
        .assertionLabel {
            font-size: 16px;
            margin-right: 10px;
            font-weight: 600;
        }
    }
}
