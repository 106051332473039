@use '../../styles/theme' as theme;
@use '../../styles/common' as common;

.clauseLibraryWrapper {
    @include common.tablePageWrapper;
    .clauseLibraryTitleContainer {
        @include common.tablePageTitleContainer;
        height: 35px;
        .clauseLibraryTitle {
            @include common.tablePageTitle;
        }
    }
}
