@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.customFiltersWrapper {
    @include common.flexColumn;
    margin: auto;
    background-color: theme.$color-white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 60vw;
    height: 80vh;
    overflow: hidden;
    padding: 5px;
    .customFiltersContentWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 55px);
        .customFiltersContentBody {
            @include common.flexRow;
            width: 100%;
            .customFiltersNames {
                min-height: calc(80vh - 109px);
                padding: 0 5px;
                .customFiltersLabel {
                    font-family: theme.$font-family;
                    color: theme.$color-primary-night;
                    font-size: theme.$font-size-h3;
                    font-weight: theme.$font-medium;
                    cursor: pointer !important;
                    border: 1px solid theme.$color-grey;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    padding: 5px;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .selectedCustomFilter {
                    background-color: theme.$color-french-spectrum-1;
                }
                .evenFilterLabel {
                    background-color: theme.$color-lightest-grey;
                }
            }
            .customFiltersDetails {
                width: 60%;
                min-height: calc(80vh - 109px);
                padding: 0 5px;
            }
        }
    }
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        background-color: theme.$color-white;
        padding-top: 5px;
    }
}
