@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.thirdPartyCompaniesWrapper {
    @include common.tablePageWrapper;
    .thirdPartyCompaniesTitleContainer {
        @include common.tablePageTitleContainer;
        height: 50px;
        .thirdPartyCompaniesTitle {
            @include common.tablePageTitle;
        }
    }
}

.otherAddressWrapper {
    @include common.flexColumn;
}

.addOtherAddressWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
}

.thirdPartyCompaniesContentWrapper {
    height: calc(60vh - 56px);
    max-width: 700px;
    .thirdPartyCompaniesLoadingWrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .toggleWrapper {
        @include common.flexRow;
        align-items: center;
        .toggleLabel {
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
}

.warningMessage {
    color: theme.$color-amber;
    font-size: theme.$font-size-h7;
}
