@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.dropdownFieldWrapper {
    width: 100%;
}

.singleSelectDropdown {
    @include common.flexColumn;
    margin-bottom: 10px;
    .singleSelectDropdownSelect {
        width: calc(100% - 2px);
        margin: auto;
    }
}

.dropdownLabel {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

:export {
    french: theme.$color-french;
    frenchSelected: theme.$color-french-spectrum-2;
    grey: rgba(theme.$color-grey, 0.7);
    disabledGrey: rgba(theme.$color-grey, 0.5);
    disabledBackground: rgba(theme.$color-grey, 0.05);
    primary: theme.$color-primary-night;
    white: theme.$color-white;
    fontFamily: theme.$font-family;
    amethyst: theme.$color-amethyst;
    green: theme.$color-green;
    lightGrey: theme.$color-light-grey;
    backgroundGrey: rgba(theme.$color-light-grey, 0.5);
}
