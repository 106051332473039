@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.button {
    @include common.centreFlexRow;
    background-color: theme.$color-french;
    color: theme.$color-white;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-weight: theme.$font-bold;
    margin: 5px 2px;
    font-family: theme.$font-family;
    border: none;
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: 0.9;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-white;
    }
    .content {
        @include common.centreFlexRow;
        width: fit-content;
        max-width: 100%;
    }
}

.selectedButton {
    background-color: theme.$color-french;
    color: theme.$color-white;
}

.icon {
    margin-right: 10px;
}

.plusButton {
    @include common.transparentButton(theme.$color-primary-night, 2px solid theme.$color-primary-night);
    border-radius: 50%;
    padding: 1px;
    height: fit-content;
    width: fit-content;
    &:hover {
        color: theme.$color-french;
        border: 2px solid theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
        border: 2px solid theme.$color-grey;
    }
}

.iconButton {
    @include common.transparentButton;
    padding: 0;
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
    }
    .indicator {
        position: absolute;
        background-color: theme.$color-amber;
        color: theme.$color-white;
        font-family: theme.$font-family;
        padding: 2px;
        border-radius: 50%;
    }
}

.iconButtonWithBorder {
    @include common.transparentButton(theme.$color-primary-night, 2px solid theme.$color-primary-night);
    border-radius: 50%;
    padding: 2px;
    height: fit-content;
    width: fit-content;
    &:hover {
        border: 2px solid theme.$color-french;
        color: theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
        border: 2px solid theme.$color-grey;
    }
}

.iconButtonWithBackground {
    @include common.centreFlexRow;
    @include common.backgroundButton(theme.$color-french, theme.$color-white);
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
    padding: 2px;
    text-align: center;
    margin: 5px 2px;
    &:hover {
        color: theme.$color-white;
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.iconButtonSquareBorder {
    @include common.transparentButton(theme.$color-primary-night, 2px solid theme.$color-primary-night);
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
    padding: 1px;
    text-align: center;
    margin: 5px 2px;
    &:hover {
        color: theme.$color-primary-night;
        opacity: 0.6;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.toggleButtonWithBorder {
    @include common.transparentButton(theme.$color-french, 2px solid theme.$color-light-grey);
    border-radius: 50px;
    height: 17px;
    width: 17px;
    padding: 2px;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
        border: 2px solid theme.$color-grey;
    }
}

.toggleButtonWithBackground {
    @include common.backgroundButton(theme.$color-green, theme.$color-white, 2px solid theme.$color-green);
    border-radius: 50px;
    height: fit-content;
    width: fit-content;
    padding: 2px;
    text-align: center;
    &:hover {
        color: theme.$color-white;
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.countButton {
    @include common.transparentButton;
    padding: 0;
    position: relative;
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
    }
    .countValue {
        position: absolute;
        background-color: theme.$color-red;
        color: theme.$color-white;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        font-size: 12px;
        padding: 0 3px;
        border-radius: 5px;
        top: -8px;
    }
}

.hourglassButton {
    @include common.transparentButton;
    padding: 0;
    position: relative;
    .hourglass {
        position: absolute;
        color: theme.$color-white;
        font-size: 12px;
        padding: 0 3px;
        border-radius: 5px;
    }
    &:disabled {
        color: theme.$color-grey;
    }
}

.deleteButton {
    @include common.transparentButton;
    padding: 0;
    &:hover {
        color: theme.$color-red;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
    }
}

.deleteButtonWithBorder {
    @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
    border-radius: 50%;
    padding: 2px;
    height: fit-content;
    width: fit-content;
    &:hover {
        border: 1px solid theme.$color-red;
        color: theme.$color-red;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
        border: 1px solid theme.$color-grey;
    }
}

.duplicateButton {
    @include common.transparentButton;
    padding: 0;
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-grey;
    }
}

.documentLinkWrapper {
    @include common.flexColumn;
    margin-top: 10px;
    .documentLinkHeader {
        @include common.inputHeader;
    }
    .buttonWrapper {
        @include common.flexRow;
        align-items: center;
        .linkButton {
            @include common.transparentButton;
            color: theme.$color-primary-night;
            border: 1px solid theme.$color-primary-night;
            padding: 3px;
            border-radius: 5px;
            font-family: theme.$font-family;
            font-size: 14px;
            flex: 1;
            height: 30px;
            width: 120px;
            &:hover {
                color: theme.$color-french;
                box-shadow: 1px 1px 5px theme.$color-french;
                border: 1px solid theme.$color-french;
            }
            &:disabled {
                color: theme.$color-grey;
                background-color: rgba(theme.$color-grey, 0.1);
                border: 1px solid rgba(theme.$color-grey, 0.5);
                box-shadow: none;
                cursor: not-allowed;
            }
        }
        .removeLink {
            @include common.transparentButton;
            padding-right: 0;
            &:hover {
                color: theme.$color-red;
            }
            &:disabled {
                cursor: not-allowed;
                color: rgba(theme.$color-grey, 0.5);
            }
        }
    }
}

.pageToggleButtonWrapper {
    @include common.spaceBetweenFlexRow;
    &:hover {
        color: theme.$color-french;
        transform: scale(1.05);
        cursor: pointer;
    }
    .pageToggleButtonLabel {
        font-size: 18px;
        font-weight: theme.$font-bold;
        margin-right: 10px;
    }
}

.iconButtonWrapper {
    @include common.centreFlexRow;
    width: 50px;
    height: 38px;
    margin: 5px 2px;
    background-color: theme.$color-french;
    border-radius: 5px;
    outline: none;
    border: none;
    color: theme.$color-white;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-white;
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    aqua: theme.$color-aqua;
    white: theme.$color-white;
}
