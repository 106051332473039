@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.radioButtonWrapper {
    @include common.flexRow;
    margin-bottom: 4px;
    justify-content: space-between;
    align-items: flex-start;
    .radioLabelWrapper {
        @include common.flexRow;
        align-items: flex-start;
    }
    .radioWarningLabel {
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-size: 12px;
        font-style: italic;
    }
    .radioButtonLabel {
        margin-right: 5px;
        font-weight: theme.$font-medium;
        font-size: 12px;
        justify-self: flex-start;
    }
}

.radioListWrapper {
    @include common.flexColumn;
    width: 100%;
    .optionWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        color: theme.$color-primary-night;
        height: fit-content;
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
        .radioInputWrapper {
            @include common.centreFlexRow;
            margin-right: 8px;
            height: 100%;
            .radioInput {
                margin: auto;
                cursor: pointer;
            }
        }
        .radioButtonIcon {
            @include common.centreFlexRow;
            margin-right: 5px;
        }
        .radioButtonLabel {
            font-weight: theme.$font-medium;
            justify-self: flex-start;
        }
        .radioButtonDescription {
            @include common.centreFlexRow;
            margin-left: 5px;
        }
    }
}

.radioRowWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    .rowOptionWrapper {
        @include common.flexRow;
        width: 100%;
        height: fit-content;
        align-items: center;
        color: theme.$color-primary-night;
        &:not(:last-of-type) {
            margin-right: 10px;
        }
        .radioInputWrapper {
            @include common.centreFlexRow;
            margin-right: 8px;
            height: 100%;
            .radioInput {
                margin: auto;
                cursor: pointer;
            }
        }
        .radioButtonIcon {
            @include common.centreFlexRow;
            margin-right: 5px;
        }
        .radioButtonLabel {
            font-weight: theme.$font-medium;
            justify-self: flex-start;
        }
        .radioButtonDescription {
            @include common.centreFlexRow;
            margin-left: 5px;
        }
    }
}

.disabledOptionWrapper {
    color: theme.$color-grey;
}
