@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

@mixin paginationButton {
    @include common.transparentButton;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    vertical-align: middle;
    &:hover {
        color: rgba(theme.$color-french, 0.5);
        font-weight: theme.$font-bold;
    }
}

.wrapper {
    @include common.centreFlexRow;
    height: 20px;
    width: 100%;
    padding: 5px;
}

.paginationButton {
    @include paginationButton;
    font-size: 16px;
}

.selectedButton {
    color: theme.$color-french;
    font-weight: theme.$font-bold;
}

.previousButton,
.nextButton {
    @include common.centreFlexRow;
    @include paginationButton;
    width: 25px;
    font-size: 20px;
}

.placeholder {
    width: 25px;
}
