@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.annexDefinitionsWrapper {
    @include common.flexColumn;
    align-self: center;
    flex: 1;
    width: calc(100% - 20px);
    padding: 10px;
    .scrollableWrapper {
        @include common.flexColumn;
    }
}

.documentName {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 10px;
}

.capsuleWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    padding: 5px 15px 5px 15px;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    margin-bottom: 10px;
    width: calc(100% - 32px);
    max-height: 300px;
    height: fit-content;
    .buttonContainer {
        @include common.flexRow;
        align-self: flex-end;
        margin-right: -3px;
    }
}

.annexWrapper {
    @include common.flexRow;
    width: fit-content;
    justify-content: flex-start;
    border-radius: 5px;
    border-bottom: 1px solid theme.$color-grey;
    .annexCellWrapper {
        padding: 10px;
        border-left: 1px solid theme.$color-grey;
        border-right: 1px solid theme.$color-grey;
        height: 35px;
    }
    .firstCell {
        border-left: 1px solid theme.$color-grey;
        border-radius: 0 0 0 5px;
    }
    .lastCell {
        border-radius: 0 0 5px 0;
    }
    .annexHeaderWrapper {
        @include common.flexRow;
        background-color: theme.$color-french;
        align-items: center;
        padding: 10px;
        color: theme.$color-white;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        border-left: 1px solid theme.$color-white;
        .annexLabel {
            @include common.overflowText;
            font-size: 14px;
            font-weight: theme.$font-medium;
            padding-right: 5px;
        }
    }
    .headerLastCell {
        border-right: 1px solid theme.$color-french;
        border-radius: 0 5px 0 0;
    }
    .headerFirstCell {
        border-left: 1px solid theme.$color-french;
        border-radius: 5px 0 0 0;
    }
}
.topSectionWrapper {
    @include common.flexRow;
    width: 100%;
    justify-content: space-between;
    .annexName {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
        font-weight: theme.$font-medium;
        padding-bottom: 5px;
        font-size: 18px;
    }
    .userDateWrapper {
        @include common.flexColumn;
        width: calc(30% - 10px);
        margin-left: 10px;
        align-items: flex-end;
        .userDetails {
            font-weight: theme.$font-medium;
            padding-bottom: 5px;
            font-size: 12px;
            text-align: right;
        }
    }
}

:export {
    green: theme.$color-mandatory;
    white: theme.$color-white;
}
