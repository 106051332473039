@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin tableHeaderIcons {
    @include common.flexRow;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5px;
}

@mixin assessmentQuestionLabel {
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    font-size: theme.$font-size-h4;
    color: theme.$color-primary-night;
    text-decoration: underline;
    margin-bottom: 10px;
    width: fit-content;
}

.supplyChainWrapper {
    @include common.tablePageWrapper;
    .supplyChainTitleContainer {
        @include common.tablePageTitleContainer;
        height: 50px;
        margin-bottom: 30px;
        .supplyChainTitle {
            @include common.tablePageTitle;
        }
        .tableButtonsWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            width: calc((100% - 180px) - 16vw);
        }
    }
}

.supplyChainTableWrapper {
    @include common.flexColumn;
    font-family: theme.$font-family;
    margin: 0 auto;
    .tableHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        height: 50px;
        background-color: theme.$color-french;
        border: solid theme.$color-french 1px;
        border-radius: 5px 5px 0 0;
        color: theme.$color-white;
        .tableHeaderCell {
            width: 50%;
            height: 100%;
            @include common.flexRow;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;
            &:not(:last-of-type) {
                border-right: white solid 2px;
            }
            .tableHeaderName {
                text-align: left;
                font-family: theme.$font-family;
                font-weight: theme.$font-bold;
                font-size: theme.$font-size-h6;
                padding: 5px;
            }
            .tableHeaderIcons {
                @include tableHeaderIcons;
                margin-right: 5px;
            }
        }
    }
    .tableBody {
        @include common.flexRow;
        height: calc(100% - 34px);
        width: 100%;
        border: 1px solid theme.$color-french;
        border-radius: 0px 0px 5px 5px;
        .tableColumn {
            @include common.flexRow;
            height: 100%;
            width: 100%;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
        .rowBorder {
            border-left: 1px solid theme.$color-french;
        }
        .emptyTable {
            @include common.centreFlexRow;
            height: 100%;
            width: 100%;
            .emptyPlaceholder {
                @include common.centreFlexColumn;
                height: 100%;
                width: 100%;
                position: relative;
                .emptyTableButton {
                    @include common.transparentButton;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h3;
                    font-weight: theme.$font-bold;
                    width: 100%;
                    position: absolute;
                    margin: auto;
                    top: calc(50% - 23px);
                    left: 0;
                    z-index: 10;
                }
                .logoWrapper {
                    height: 50%;
                    opacity: 0.1;
                    position: absolute;
                    top: calc(50% - 150px);
                    left: calc(50% - 150px);
                }
            }
        }
    }
}

.supplyChainCard {
    @include common.centreFlexRow;
    width: calc(30% - 20px);
    height: 100px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    font-size: theme.$font-size-h3;
    color: theme.$color-primary-night;
    background-color: theme.$color-lightest-grey;
    &:hover {
        transform: scale(1.04);
        color: theme.$color-french;
    }
}

.isSelected {
    background-color: theme.$color-french;
    color: theme.$color-white;
    &:hover {
        color: theme.$color-white;
    }
}

.supplyChainConfigureWrapper {
    @include common.flexColumn;
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    padding: 0 10px 10px 10px;
    .tabSelectWrapper {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 10px);
        height: 35px;
        padding: 5px;
        .wizardTabWrapper {
            @include common.centreFlexRow;
            width: 50%;
        }
    }
}

.supplyChainDetailsWrapper {
    @include common.flexColumn;
    height: 75px;
}

.detailsWrapper {
    @include common.spaceBetweenFlexRow;
    height: 100%;
    width: 100%;
    .criticalOrImportantWrapper {
        @include common.flexColumn;
        height: 100%;
        width: 60%;
        .criticalOrImportantQuestionWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            .criticalOrImportantQuestionLabel {
                font-weight: 600;
                margin-right: 5px;
            }
        }
        .criticalOrImportantRadioWrapper {
            width: 200px;
        }
    }
    .supplyChainInfoWrapper {
        @include common.flexColumn;
        width: calc(40% - 10px);
        margin-left: 10px;
        align-items: flex-end;
        height: 100%;
        .supplyChainInfo {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            .supplyChainInfoLabel {
                width: 50%;
                font-weight: 600;
                margin-right: 10px;
                text-align: left;
            }
        }
    }
}

.supplyChainContentWrapper {
    @include common.flexColumn;
    justify-content: center;
    width: 100%;
    height: calc(100% - 160px);
    .supplyChainConfigWrapper {
        @include common.flexRow;
        width: 100%;
        height: 100%;
    }
}

.buttonWrapper {
    @include common.spaceBetweenFlexRow;
    .leftButtonWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-start;
        .legendWrapper {
            @include common.flexRow;
            align-items: center;
            margin-left: 50px;
            height: 36px;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            .legendLabel {
                font-weight: 600;
                font-size: 14px;
                color: theme.$color-primary-night;
                margin-left: 20px;
            }
            .indicatorWrapper {
                @include common.flexRow;
                align-items: center;
                margin: 0 20px;
                .indicatorLabel {
                    margin-left: 5px;
                    font-weight: 600;
                    font-size: 14px;
                    color: theme.$color-primary-night;
                }
                .thirdPartyIndicator {
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: theme.$color-gold;
                }
                .intraGroupIndicator {
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    background-color: theme.$color-ocean;
                }
            }
        }
    }
    .rightButtonWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
    }
}

.configWrapper {
    @include common.flexRow;
    width: calc(100% - 210px);
    height: calc(100% - 22px);
    padding: 10px;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
}

.config {
    @include common.flexRow;
    width: fit-content;
    height: fit-content;
}

.availableCompanies {
    @include common.flexRow;
    width: 190px;
}

.droppableWrapper {
    @include common.flexColumn;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 220px;
}

.parentAndJoinWrapper {
    @include common.flexRow;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.parentWrapper {
    @include common.flexColumn;
    justify-content: center;
    position: absolute;
}

.scrollableDroppableWrapper {
    @include common.flexColumn;
    height: 100%;
    position: relative;
    width: 100%;
}

.supplyChainCompanyWrapper {
    @include common.flexRow;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.supplyChainAdditionalCompanyWrapper {
    @include common.centreFlexRow;
}

.supplyChainPlaceholderWrapper {
    @include common.centreFlexRow;
    height: 100%;
    .placeholder {
        @include common.centreFlexRow;
        height: 100%;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: theme.$color-amber;
    }
}

.supplyChainCompany {
    @include common.centreFlexRow;
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 2px 3px theme.$color-french;
    background-color: theme.$color-lightest-grey;
    border: 1px solid theme.$color-grey;
    width: 158px;
    height: 40px;
    transition-duration: 0.5s;
    cursor: grab;
}

.parentSupplyChainCompany {
    cursor: default;
}

.horizontalJoiningLine {
    width: 25px;
    height: 3px;
}

.horizontalFill {
    background-color: theme.$color-french;
}

.verticalJoiningLine {
    position: absolute;
    width: 3px;
    left: 0;
    background-color: theme.$color-french;
}

.supplyChainDragging {
    opacity: 0.2;
    cursor: grabbing;
}

.supplyChainDisabled {
    cursor: default;
}

.supplyChainOver {
    box-shadow: 1px 1px 5px theme.$color-mandatory;
    transform: scale(1.1) rotate(-8deg);
}

@keyframes placeholderPulse {
    0% {
        box-shadow: inset 1px 1px 5px theme.$color-gold;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: inset 1px 1px 5px theme.$color-gold;
    }
}

.placeholderOver {
    animation: placeholderPulse 2s infinite;
}

.doraSupplyChainElement {
    @include common.flexColumn;
    width: 100%;
    .elementIdentifier {
        height: 4px;
        margin-bottom: 4px;
        width: 100%;
        border-radius: 5px;
    }
    .isIntraGroup {
        background-color: theme.$color-ocean;
    }
    .isThirdParty {
        background-color: theme.$color-gold;
    }
    .headerWrapper {
        @include common.spaceBetweenFlexRow;
        height: 15px;
        .rankOneIcons {
            @include common.flexRow;
            width: 100%;
            justify-content: space-evenly;
            .iconWrapper {
                position: relative;
                .iconValue {
                    position: absolute;
                    @include common.centreFlexRow;
                    justify-content: center;
                    top: -3px;
                    left: 9px;
                    width: 10px;
                    height: 10px;
                    border: solid theme.$color-primary-night 0.8px;
                    border-radius: 50%;
                    background-color: theme.$color-white;
                }
            }
        }
    }
}

.modal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: theme.$color-white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
}

.deleteConfirmationWrapper {
    @include common.flexColumn;
    padding: 5px;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    .warningTitle {
        font-size: 18px;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .warningInfo {
        font-size: 18px;
        margin-bottom: 20px;
        color: theme.$color-primary-night;
    }
}

.labelWrapper {
    @include common.flexRow;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
}

.elementDetailsWrapper {
    @include common.flexColumn;
    width: 70vw;
    height: 85vh;
    padding: 10px;
    .elementDetails {
        @include common.flexColumn;
        height: calc(100% - 104px);
        width: 100%;
    }
}

.elementDetailsWizardWrapper {
    height: 100%;
    .elementDetailsContent {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 45px);
    }
}

.newElementCompanyWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px dashed theme.$color-grey;
    color: theme.$color-primary-night;
    .companyTypeTitle {
        font-size: 16px;
        font-weight: theme.$font-medium;
    }
    .companyTypeWrapper {
        @include common.flexRow;
        width: calc(100% - 20px);
        margin: 10px;
    }
    .companySelectWrapper {
        @include common.flexColumn;
        margin: 5px 0;
        width: 100%;
        .companySelectLabel {
            font-size: 16px;
            font-weight: theme.$font-medium;
            margin-bottom: 5px;
        }
    }
}

.isMaterialWrapper {
    @include common.flexRow;
    align-items: center;
    margin-bottom: 5px;
    .isMaterialLabel {
        margin-right: 10px;
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        font-size: theme.$font-size-h4;
        color: theme.$color-primary-night;
    }
    .isMaterialRadioWrapper {
        @include common.flexRow;
        width: 200px;
    }
}

.detailsInputWrapper {
    @include common.flexColumn;
    margin: 5px 0;
    .detailsInputLabel {
        font-family: theme.$font-family;
        font-weight: theme.$font-medium;
        font-size: theme.$font-size-h4;
        color: theme.$color-primary-night;
        margin-bottom: 5px;
    }
    .serviceProvidedWrapper {
        @include common.flexRow;
        width: 100%;
        height: 100%;
        .dropdownWrapper {
            width: 70%;
            margin-right: 10px;
        }
        .button {
            @include common.transparentButton;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            font-size: theme.$font-size-h4;
            color: theme.$color-amber;
        }
    }
}

.supplyChainElementDetailsWrapper {
    @include common.flexColumn;
    height: calc(100% - 10px);
}

.summaryPreviewModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .summaryPreviewWrapper {
        @include common.flexColumn;
        width: 50vw;
        .summaryPreview {
            @include common.centreFlexColumn;
            border-top: none;
            margin: 10px;
            height: 80vh;
        }
        .buttonWrapper {
            @include common.flexRow;
            width: calc(100% - 20px);
            align-items: center;
            justify-content: flex-end;
            margin: 0 10px 10px 10px;
        }
    }
}

.optionalLabel {
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    font-size: theme.$font-size-h5;
    color: theme.$color-amber;
    margin-bottom: 10px;
}

.radioWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 10px;
    .radioToggleLabel {
        @include assessmentQuestionLabel;
    }
}

.exitPlanWrapper {
    @include common.flexRow;
    margin-bottom: 10px;
    align-items: center;
    .exitPlanLabel {
        @include assessmentQuestionLabel;
        margin-right: 10px;
        margin-bottom: 0px;
    }
}

.elementDetailsQuestionWrapper {
    @include common.flexColumn;
    margin-bottom: 10px;
    .referenceTooltipWrapper {
        margin-left: 5px;
    }
    .elementDetailsQuestion {
        @include common.flexRow;
        @include assessmentQuestionLabel;
        align-items: center;
    }
    .contractReferenceWrapper {
       @include common.centreFlexRow;
       justify-content: space-between;
       width: 100%;
       .contractReferenceInputWrapper {
           width: 100%;
       }
    }
}

.lineBreak {
    border-bottom: 1px dashed theme.$color-grey;
    width: 100%;
    margin: 20px 0;
}

.dateInputWrapper {
    @include common.flexRow;
    justify-content: space-between;
    width: 70%;
    margin: 5px 0 10px 0;
}

.timelineWrapper {
    height: fit-content;
    width: 95%;
    margin: 20px auto;
}

.sliderMarker {
    height: 40px;
    width: 40px;
    position: relative;
    top: -30px;
    left: 0px;
}

.modalWrapper {
    @include common.flexColumn;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 400px;
    .modalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .modalWarningTitle {
            margin-left: 10px;
        }
    }
    .unsavedChanges {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .modalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
}

.documentsWrapper {
    @include common.flexColumn;
    width: 100%;
    .documentsHeader {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
        text-decoration: underline;
    }
    .dropzone {
        @include common.centreFlexRow;
        border: 2px dashed theme.$color-french;
        padding: 50px 20px;
        color: theme.$color-primary-night;
        margin: 10px 0;
        cursor: pointer;
        width: calc(100% - 44px);
        &:hover {
            color: theme.$color-french;
        }
    }
    .tableWrapper {
        @include common.flexColumn;
        width: 100%;
        .tableHeaderWrapper {
            @include common.flexRow;
            width: 100%;
            border-radius: 5px 5px 0 0;
            background-color: theme.$color-french;
            color: theme.$color-white;
            height: 30px;
            .tableHeader {
                @include common.flexRow;
                align-items: center;
                height: calc(100% - 10px);
                padding: 5px;
            }
        }
        .tableBodyWrapper {
            @include common.flexColumn;
            width: calc(100% - 2px);
            max-height: 240px;
            min-height: 60px;
            border-radius: 0 0 5px 5px;
            border: 1px solid theme.$color-grey;
            .tableRowWrapper {
                @include common.flexRow;
                width: 100%;
                color: theme.$color-primary-night;
                height: 30px;
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
                .tableCell {
                    @include common.flexRow;
                    align-items: center;
                    height: calc(100% - 10px);
                    padding: 5px;
                    font-size: 14px;
                }
            }
            .oddDocument {
                background-color: theme.$color-light-grey;
            }
            .pendingDocument {
                background-color: rgba(theme.$color-gold, 0.9);
            }
            .pendingOdd {
                background-color: rgba(theme.$color-yellow-green, 0.6);
            }
        }
    }
}

.documentPreviewModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .documentPreviewWrapper {
        @include common.flexColumn;
        width: 50vw;
        margin: 10px;
        .documentPreview {
            @include common.centreFlexColumn;
            border-top: none;
            height: 80vh;
        }
        .buttonWrapper {
            @include common.flexRow;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.contractualInformationDetailsWrapper {
    @include common.flexColumn;
    align-items: center;
    height: calc(100% - 10px);
    width: 100%;
    .contractualInformationDetailsButtonWrapper {
        @include common.flexRow;
        width: 100%;
        justify-content: space-between;
        .contractualInformationToggleTableButtonWrapper {
            height: 48px;
        }
    }
}

.datasetInstanceWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .datasetInstanceFormWrapper {
        z-index: 1;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.documentAnalysisWrapper {
    @include common.flexColumn;
    margin: 0 10px;
}

:export {
    lightestGrey: theme.$color-lightest-grey;
    white: theme.$color-white;
    green: theme.$color-mandatory;
    red: theme.$color-red;
    primary: theme.$color-primary-night;
    grey: theme.$color-grey;
}
