@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin reportWrapper($widthAdjustment: 40px) {
    @include common.flexColumn;
    width: calc(100% - $widthAdjustment);
    height: 100%;
    color: theme.$color-primary-night;
    margin: 0 20px;
    border-radius: 5px;
    border: 1px solid theme.$color-grey;
}

@mixin circle($marginRight: 20px) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: $marginRight;
}

.reportsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin: 20px 0;
}

.wizardWrapper {
    @include reportWrapper;
}

.templatesWrapper {
    @include reportWrapper(60px);
    padding: 10px;
    border: 1px solid theme.$color-french;
}

.contentWrapper {
    @include common.flexColumn;
    height: 60vh;
    width: 100%;
}

.documentNameWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    width: 700px;
}

.reportTypeWrapper {
    @include common.flexRow;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px dotted theme.$color-grey;
    .reportTypeTitle {
        font-size: 20px;
        font-weight: theme.$font-medium;
    }
    .radioWrapper {
        @include common.flexColumn;
        .reportType {
            @include common.flexRow;
            align-items: baseline;
            margin: 0 20px;
            .reportTypeRadio {
                margin-right: 10px;
                cursor: pointer;
            }
            .reportTypeLabel {
               font-weight: 600;
               font-size: 18px;
            }
        }
    }
}

.sectionWrapper {
    .sectionHeader {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-primary-night;
        border-bottom: 1px dashed theme.$color-grey;
        padding: 10px 0;
        &:hover {
            color: theme.$color-french;
        }
        .sectionOpenIcon {
            margin-right: 10px;
            cursor: pointer;
        }
        .sectionLabel {
            font-size: 20px;
            font-family: theme.$font-family;
            margin-right: 10px;
            font-weight: 600;
        }
        .sectionDescription {
            margin-left: 10px;
        }
    }
}

.reportFieldsWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    .fieldsHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 5px;
        .fieldsTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            font-family: theme.$font-family;
        }
        .keyWrapper {
            @include common.flexRow;
            align-items: center;
            font-size: 16px;
            font-weight: theme.$font-medium;
            .keyTitle {
                margin-right: 10px;
            }
            .tableColorIndicator {
                @include circle(5px);
                background-color: theme.$color-amethyst;
            }
            .formColorIndicator {
                @include circle(5px);
                background-color: theme.$color-ocean;
            }
        }
    }
    .fieldsSubheaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        .fieldsSubheader {
            margin-bottom: 10px;
            font-weight: theme.$font-medium;
            color: theme.$color-grey;
        }
    }
    .allFieldsWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 78px);
        .reportFields {
            @include common.flexColumn;
            height: fit-content;
            .reportFieldItemWrapper {
                @include common.spaceBetweenFlexRow;
                align-items: center;
                @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
                border-radius: 5px;
                margin: 5px 0;
                font-family: theme.$font-family;
                padding: 10px;
                .leftFieldItemWrapper {
                    @include common.flexRow;
                    align-items: center;
                    justify-content: flex-start;
                    .colorIndicator {
                        @include circle;
                    }
                    .tableField {
                        background-color: theme.$color-amethyst;
                    }
                    .formField {
                        background-color: theme.$color-ocean;
                    }
                    .disabledField {
                        opacity: 0.5;
                    }
                    .reportFieldLabel {
                        font-size: 14px;
                        font-weight: theme.$font-medium;
                        margin-right: 10px;
                    }
                }
                .reportFieldDataset {
                    margin-right: 0;
                    font-weight: theme.$font-medium;
                }
                &:disabled {
                    cursor: not-allowed;
                    background-color: theme.$color-lightest-grey;
                    color: theme.$color-grey;
                    border-color: theme.$color-grey;
                }
            }
            .selectedField {
                background-color: rgba(theme.$color-ocean, 0.2);
            }
        }
    }
}

.sectionsWrapper {
    @include common.flexColumn;
    width: 100%;
    height: calc(100% - 32px);
    .reportFieldItemWrapper {
        @include common.spaceBetweenFlexRow;
        width: calc(100% - 22px);
        align-items: center;
        @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
        border-radius: 5px;
        margin: 5px 0;
        font-family: theme.$font-family;
        padding: 10px;
        .reportFieldLabel {
            font-size: 16px;
            font-weight: theme.$font-medium;
            margin-right: 10px;
        }
    }
    .selectedField {
        background-color: rgba(theme.$color-ocean, 0.2);
    }
    .mandatoryField {
        background-color: rgba(theme.$color-mandatory, 0.2);
        cursor: default;
    }
}

.datasetWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    .sectionHeaderWrapper {
        width: 100%;
        @include common.spaceBetweenFlexRow;
        border-bottom: 1px dashed theme.$color-grey;
        .sectionHeader {
            @include common.flexRow;
            align-items: center;
            padding: 5px 0 10px 0;
            color: theme.$color-primary-night;
            width: calc(100% - 140px);
            margin-right: 10px;
            .sectionOpenIcon {
                margin-right: 10px;
            }
            .colorIndicator {
                @include circle;
            }
            .sectionLabel {
                font-size: 18px;
                font-weight: theme.$font-medium;
            }
        }
    }
}

.selectAllToggleWrapper {
    @include common.flexRow;
    align-items: center;
    font-weight: theme.$font-medium;
    .selectAllToggleLabel {
        margin-right: 5px;
        font-size: theme.$font-size-h5;
    }
}

.documentNameHeader,
.filterHeader {
    font-size: 20px;
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 10px;
    font-family: theme.$font-family;
}

.configureFieldsWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    height: 100%;
    .fieldsHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: theme.$font-medium;
        text-decoration: underline;
        font-family: theme.$font-family;
    }
    .reportDroppableWrapper {
        @include common.flexRow;
        width: calc(100% - 14px);
        height: 50vh;
        border: 2px dashed theme.$color-light-grey;
        padding: 20px 5px;
    }
}

.columnWrapper {
    @include common.flexColumn;
    align-items: center;
    width: 60px;
    height: calc(50vh - 38px);
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 10px 10px 10px 0;
    margin: 3px;
    box-shadow: 1px 1px 5px theme.$color-light-grey;
    .columnLabel {
        @include common.overflowText;
        padding-right: 20px;
        text-align: left;
        font-size: 16px;
        font-weight: theme.$font-medium;
        height: 100%;
        width: 100%;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
    }
}
.formColumn {
    border-left: 15px solid theme.$color-ocean;
}
.tableColumn {
    border-left: 15px solid theme.$color-amethyst;
}
.mandatoryColumn {
    border-left: 15px solid theme.$color-mandatory;
}
.draggingColumn {
    box-shadow: 1px 1px 5px theme.$color-french;
    background-color: theme.$color-white;
}

.reportFilterWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    .filtersDescription {
        margin-bottom: 20px;
        font-weight: theme.$font-medium;
    }
    .filterWrapper {
        width: 550px;
    }
}

.backModalWrapper {
    @include common.flexColumn;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 400px;
    .backModalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .backModalWarningTitle {
            margin-left: 10px;
        }
    }
    .unsavedChanges {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .backModalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
}

.allTemplatesWrapper {
    flex: 1;
    .noTemplates {
        margin-top: 20px;
        font-size: 22px;
        font-weight: theme.$font-medium;
    }
}

.templatesTitle {
    @include common.flexRow;
    align-items: center;
    font-size: 25px;
    font-weight: theme.$font-bold;
}
.templatesButtonWrapper {
    @include common.flexRow;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.templateWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    border-radius: 5px;
    margin: 10px 20px;
    padding: 20px;
    font-family: theme.$font-family;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    color: theme.$color-primary-night;
    cursor: pointer;
    &:hover {
        box-shadow: 1px 1px 5px theme.$color-french;
        transform: scale(1.02);
    }
    .templateHeader {
        @include common.spaceBetweenFlexRow;
        margin-bottom: 20px;
        width: 100%;
        .templateHeaderColumn {
            @include common.flexColumn;
            min-width: 20%;
        }
    }
    .templateTitle {
        @include common.flexRow;
        align-items: center;
        text-align: left;
        font-size: 22px;
        font-weight: theme.$font-bold;
        .templateName {
            margin-right: 10px;
        }
    }
    .templateDocumentName {
        text-align: left;
        font-size: 16px;
        font-weight: theme.$font-medium;
    }
    .templateModifiedBy,
    .templateModifiedDate {
        text-align: end;
        font-size: 14px;
        font-weight: theme.$font-medium;
    }
    .templateFieldsHeader {
        @include common.flexRow;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 3px;
        border-bottom: 1px solid rgba(theme.$color-grey, 0.5);
        font-weight: theme.$font-medium;
        text-align: left;
    }
    .templateFieldsWrapper {
        @include common.overflowText;
        width: 100%;
        text-align: left;
    }
    .templateButtonWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
    }
}

.deleteConfirmationWrapper {
    @include common.flexColumn;
    padding: 5px;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    .warningTitle {
        font-size: 18px;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .name {
        font-size: 18px;
        color: theme.$color-french;
        margin-bottom: 10px;
    }
}

.reportTemplatesWrapper {
    @include common.flexColumn;
    height: 100%;
    .reportsHeader {
        @include common.flexRow;
        align-items: flex-end;
        justify-content: space-between;
        margin: 0 30px;
    }
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    ocean: theme.$color-ocean;
    amethyst: theme.$color-amethyst;
    amber: theme.$color-amber;
    grey: theme.$color-light-grey;
}
