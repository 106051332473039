@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.linkedProvisionModalWrapper {
    @include common.flexColumn;
    align-items: flex-start;
}

.linkIconWrapper {
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.linkedProvisionModalWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    align-items: center;
    .linkedProvisionWrapper {
        display: flex;
        width: 300px;
        align-items: center;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h5;
        color: theme.$color-primary-night;
        margin-bottom: 2px;
        &:hover {
            color: theme.$color-french;
            cursor: pointer;
        }
        .linkedProvisionTitleWrapper {
            @include common.flexColumn;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 2px;
            justify-content: space-between;
            .linkedPlaybookTitle {
                @include common.overflowText;
                font-size: theme.$font-size-h6;
                font-weight: theme.$font-medium;
                max-height: 20px;
                width: 95%;
            }
            .linkedProvisionTitle {
                @include common.flexColumn;
                align-items: flex-start;
                width: 90%;
                font-size: theme.$font-size-h7;
                font-weight: theme.$font-light;
                margin-bottom: 3px;
            }
        }
    }
}

.provisionLinkModal {
    width: 80vw;
    height: 70vh;
    .provisionLinkWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;
        margin-bottom: 10px;
        .scrollableWrapper {
            @include common.flexRow;
            height: calc(65% - 48px);
            .availablePlaybooksWrapper {
                @include common.flexColumn;
                margin: 10px 10px 10px 20px;
                align-self: center;
                .playbookNameWrapper {
                    @include common.flexRow;
                    border-radius: 5px;
                    margin: 5px;
                    height: 40px;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h3;
                    align-items: center;
                    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                    border: 1px solid theme.$color-grey;
                    cursor: pointer;
                    &:hover {
                        color: theme.$color-french;
                        box-shadow: 1px 1px 5px theme.$color-french;
                        background-color: theme.$color-french-spectrum-6 !important;
                    }
                    .availablePlaybookName {
                        display: flex;
                        margin-left: 5px;
                        width: 85%;
                        align-items: center;
                        color: theme.$color-white;
                    }
                    .totalLinkedProvisions {
                        display: flex;
                        width: 30px;
                        height: 30px;
                        border-radius:50%;
                        align-items: center;
                        justify-content: center;
                        margin-left: 10px;
                        color: theme.$color-french;
                        background-color: theme.$color-white;
                    }
                }
            }
            .availableProvisionsWrapper {
                @include common.flexColumn;
                margin: 10px 20px 10px 10px;
                .provisionTitleWrapper {
                    @include common.flexRow;
                    border-radius: 5px;
                    margin: 5px;
                    height: 40px;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h3;
                    align-items: center;
                    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                    border: 1px solid theme.$color-grey;
                    cursor: pointer;
                    &:hover {
                        color: theme.$color-french;
                        box-shadow: 1px 1px 5px theme.$color-french;
                        background-color: theme.$color-french-spectrum-6 !important;
                    }
                    .availableProvisionName {
                        display: flex;
                        margin-left: 5px;
                        width: 95%;
                        align-items: center;
                        color: theme.$color-white;
                    }
                }
            }
        }
        .currentLinkedProvisionsScrollableWrapper {
            height: calc(35% - 48px);
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            padding: 5px;
            width: calc(100% - 60px);
            align-self: center;
            margin-top: 10px;
            .currentLinkedProvisionsWrapper {
                display: grid;
                grid-template-columns: 33% 33% 33%;
                .linkedProvisionWrapper {
                    @include common.flexRow;
                    border-radius: 5px;
                    margin: 5px;
                    height: 80px;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h3;
                    align-items: center;
                    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                    border: 1px solid theme.$color-grey;
                    cursor: pointer;
                    &:hover {
                        color: theme.$color-french;
                        box-shadow: 1px 1px 5px theme.$color-french;
                    }
                    .linkedProvisionLabelWrapper {
                        @include common.flexColumn;
                        width: 90%;
                        margin: 0 auto;
                        height: 100%;
                        justify-content: center;
                        .linkedProvisionLabel {
                            margin-left: 5px;
                            width: 95%;
                            align-items: center;
                            color: theme.$color-french;
                        }
                    }
                    .removeProvisionLinkIconWrapper {
                        display: flex;
                        width: 10%;
                        justify-content: center;
                        align-items: flex-start;
                        margin-top: 5px;
                        height: 100%;
                    }
                }
            }
        }
    }
}

:export {
    playbookSelected: theme.$color-french-spectrum-6;
    french: theme.$color-french;
    frenchLight: theme.$color-french-spectrum-2;
    primary: theme.$color-primary-night;
    included: theme.$color-mandatory-spectrum-1;
    grey: theme.$color-grey;
}
