@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@keyframes ragScaling {
    0%, 100%{
        transform: scale(0.3);
        background-color: theme.$color-red;
    }
    40%{
        transform: scale(1);
        background-color: theme.$color-gold;
    }
    50%{
        transform: scale(1);
        background-color: theme.$color-green;
    }
}

@keyframes frenchScaling {
    0%, 100%{
        transform: scale(0.3);
        background-color: theme.$color-french-spectrum-1;
    }
    40%{
        transform: scale(1);
        background-color: theme.$color-french;
    }
    50%{
        transform: scale(1);
        background-color: theme.$color-french-spectrum-4;
    }
}

.loadingDotsWrapper {
    @include common.centreFlexRow;
    .loadingDot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        transform: scale(0);
        background-color: theme.$color-red;
        animation: frenchScaling 2.5s ease-in-out infinite;
        display: inline-block;
        margin: 5px;
    }
    .ragLoadingDot {
        animation: ragScaling 2.5s ease-in-out infinite;
    }
}
  
.loadingDot:nth-child(0),
.ragLoadingDot:nth-child(0) {
    animation-delay: 0s;
}
.loadingDot:nth-child(1),
.ragLoadingDot:nth-child(1) {
    animation-delay: 0.2s;
}
.loadingDot:nth-child(2),
.ragLoadingDot:nth-child(2) {
    animation-delay: 0.4s;
}
.loadingDot:nth-child(3),
.ragLoadingDot:nth-child(3) {
    animation-delay: 0.6s;
}
.loadingDot:nth-child(4),
.ragLoadingDot:nth-child(4) {
    animation-delay: 0.8s;
}
.loadingDot:nth-child(5),
.ragLoadingDot:nth-child(5) {
    animation-delay: 1s;
}
