@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.opinionInstanceWrapper {
    @include common.flexColumn;
    height: 100%;
    .opinionInstanceHeader {
        @include common.spaceBetweenFlexRow;
        margin-bottom: 10px;
        border-top: 1px solid rgba(theme.$color-grey, 0.5);
        padding-top: 10px;
        height: 48px;
        width: 100%;
        .opinionInstanceTitleWrapper {
            width: 100%;
            color: theme.$color-primary-night;
            font-size: 20px;
            font-weight: theme.$font-medium;
            flex: 1;
            text-align: right;
            .signOffOpinionInstanceTitle {
                height: 10px;
                margin: 20px 0;
                font-weight: theme.$font-bold;
                font-size: theme.$font-size-h2;
                color: theme.$color-green;
            }
            .opinionInstanceTitle {
                text-decoration: underline;
            }
        }
    }
    .footerWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    .opinionInstanceButtonWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 48px;
        width: fit-content;
        .unsavedOpinionWarning {
            @include common.flexRow;
            align-items: center;
            margin-left: 0;
            margin-right: auto;
            color: theme.$color-red;
            width: 170px;
            .unsavedOpinionWarningTitle {
                margin: 0 5px;
            }
        }
    }
}

.iconButtonWrapper {
    @include common.centreFlexRow;
    @include common.backgroundButton(theme.$color-french, theme.$color-white);
    width: 50px;
    height: 38px;
    margin: 5px 2px;
    background-color: theme.$color-french;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-white;
    }
}

.linkedOpinionModalWrapper {
    @include common.flexColumn;
    width: 500px;
    height: 100%;
    .linkedOpinionTabs {
        @include common.flexRow;
        align-items: center;
    }
    .linkedOpinionsWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 101px);
        .linkedOpinion {
            @include common.flexColumn;
            border-radius: 5px;
            width: calc(100% - 12px);
            padding: 5px;
            border: 1px solid theme.$color-grey;
            margin: 5px 0;
            .linkedOpinionScopeWrapper {
                @include common.spaceBetweenFlexRow;
                .iconWrapper {
                    @include common.flexRow;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            &:hover {
                cursor: pointer;
                box-shadow: 1px 1px 3px theme.$color-french;
            }
            .scope {
                @include common.flexRow;
                align-items: center;
                font-size: 18px;
                font-weight: 600;
            }
            .focus {
                @include common.flexRow;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
            }
            .agreementTypeAndCommissionedBy {
                @include common.flexRow;
                align-items: center;
                .agreementType,
                .commissionedBy {
                    width: 50%;
                    font-size: 14px;
                }
            }
        }
    }
}

.spinnerWrapper {
    align-self: center;
    height: 100%;
    width: 100%;
}

.instanceWrapper {
    @include common.flexColumn;
}

.finalSignOffWrapper {
    width: 30vw;
}

.opinionInstanceModalLabel {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
    color: theme.$color-primary-night;
}

.opinionInstanceModalWarning {
    font-size: 14px;
    color: theme.$color-red;
    margin: 10px 0 10px 0;
    max-width: 600px;
    font-weight: 400;
}

.uploadDocumentWrapper {
    @include common.flexColumn;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 500px;
    margin-bottom: 10px;
    .dropzone {
        @include common.centreFlexRow;
        border: 2px dashed theme.$color-french;
        padding: 60px 20px;
        color: theme.$color-primary-night;
        cursor: pointer;
        width: 456px;
        &:hover {
            color: theme.$color-french;
        }
    }
    .dropzoneDisabled {
        border: 2px dashed theme.$color-grey;
        color: theme.$color-grey;
    }
    .dropzonePlaceholder {
        text-align: center;
    }
    .selectedDocumentWrapper {
        @include common.flexColumn;
        width: 460px;
        margin-top: 10px;
        .selectedDocumentHeader {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            color: theme.$color-primary-night;
        }
        .selectedDocument {
            @include common.overflowText;
            color: theme.$color-primary-night;
        }
    }
}

.confirmSaveModalWrapper {
    width: 600px;
    margin-bottom: 10px;
    padding: 5px;
    .newDocumentWrapper {
        margin-bottom: 10px;
        padding: 5px 0;
        .newDocumentHeader {
            @include common.flexRow;
            align-items: center;
            .newDocumentTitle {
                @include common.overflowText;
                margin-left: 10px;
                font-weight: theme.$font-medium;
            }
        }
    }
    .updatedInstanceWrapper {
        margin-bottom: 10px;
        padding: 5px 0;
        .updatedInstanceWarning {
            font-weight: theme.$font-medium;
            padding: 5px 0;
        }
        .updatedInstanceInfo {
            padding: 5px 0;
        }
    }
    .completeWarningWrapper {
        @include common.flexRow;
        align-items: center;
        margin: 10px 0;
        .completeWarningTitle {
            font-weight: theme.$font-medium;
            margin-right: 20px;
        }
    }
    .completeMessage {
        font-weight: theme.$font-medium;
        font-size: 14px;
        color: theme.$color-primary-night;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid theme.$color-light-grey;
    }
    .completeWarningMessage {
        font-weight: theme.$font-medium;
        font-size: 14px;
        color: theme.$color-red;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

:export {
    green: theme.$color-mandatory;
    red: theme.$color-red;
}
