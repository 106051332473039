@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.mlDatasetWrapper {
    @include common.flexColumn;
    padding: 10px;
    color: theme.$color-primary-night;
    .tabWrapper {
        @include common.flexRow;
        width: 100%;
    }
    .mlDatasetContent {
        @include common.flexColumn;
        .mlDatasetTitle {
            margin-bottom: 5px;
            font-weight: theme.$font-medium;
            color: theme.$color-amber;
        }
        .mlDatasetInfo {
            margin-bottom: 5px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
        }
        .mlPreviousQueriesWrapper {
            @include common.flexRow;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 10px;
            .mlPreviousQueriesHeader {
                font-size: 14px;
                font-weight: theme.$font-medium;
                margin-right: 10px;
                color: theme.$color-primary-night;
            }
        }
    }
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        .groupedButtonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.mlDatasetFindings {
    @include common.flexColumn;
    width: calc(100% - 10px);
    padding: 0 5px;
    height: fit-content;
}

.mlDatasetAnswer {
    @include common.flexColumn;
    padding: 5px 0;
    font-weight: theme.$font-family;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h5;
}

.mlUnknownAnswer {
    @include common.flexColumn;
    padding: 5px 0;
    font-weight: theme.$font-family;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h5;
    color: theme.$color-red !important;
}

.mlFieldLeftBlank {
    @include common.flexColumn;
    padding: 5px 0;
    font-weight: theme.$font-family;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h5;
    color: theme.$color-mandatory !important;
}

.mlDatasetObjectTable {
    display: table;
    padding: 5px 0;
    height: fit-content;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
    table-layout: fixed;
    .mlDatasetObjectRow {
        display: table-row;
        width: 100%;
        .mlDatasetObjectKey {
            display: table-cell;
            width: 40%;
            height: 100%;
            padding: 2px;
            border-right: 1px solid theme.$color-light-grey;
        }
        .mlDatasetObjectValue {
            display: table-cell;
            width: 60%;
            padding: 2px;
            height: 100%;
            max-height: 150px;
            text-align: right;
        }
    }
}

.rightAlignText {
    text-align: end;
}

.currentInstanceValue {
    @include common.flexRow;
    align-items: flex-end;
    justify-content: center;
    width: 30%;
    padding: 5px 0;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
    text-align: end;
}

.mlDatasetTableHeader {
    @include common.flexRow;
    align-items: center;
    height: 40px;
    margin-bottom: 5px;
    border-bottom: 1px solid theme.$color-grey;
    background-color: theme.$color-french;
    color: theme.$color-white;
    border-radius: 5px 5px 0 0;
    .headerCell {
        @include common.flexRow;
        align-items: center;
        padding: 5px;
        font-weight: 600;
        width: 30%;
    }
}

.mlDatasetQueryWrapper {
    @include common.flexRow;
    align-items: center;
    border-bottom: 1px dotted theme.$color-grey;
    min-height: 50px;
    height: fit-content;
    .mlDatasetQuestion {
        font-weight: 500;
        padding: 5px 0;
        color: theme.$color-primary-night;
    }
    .link {
        display: flex;
        width: 5%;
        justify-content: center;
    }
}

:export {
    green: theme.$color-mandatory;
    red: theme.$color-red;
    grey: theme.$color-light-grey;
    gold: theme.$color-gold;
    yellowGreen: theme.$color-yellow-green;
    green: theme.$color-mandatory;
    amber: theme.$color-amber;
}
