@use '../../../../../styles/theme' as theme;
@use '../../../../../styles/common' as common;

.formPreviewWrapper {
    @include common.flexColumn;
    padding-top: 10px;
    .sectionHeader {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-primary-night;
        border-bottom: 1px dashed theme.$color-grey;
        padding: 10px 0;
        &:hover {
            color: theme.$color-french;
        }
        .sectionOpenIcon {
            margin-right: 10px;
            cursor: pointer;
        }
        .sectionLabel {
            font-size: 20px;
            font-family: theme.$font-family;
            margin-right: 10px;
        }
        .sectionDescription {
            margin-left: 10px;
        }
    }
    .allFieldsWrapper {
        margin-top: 10px;
        flex-grow: 1;
    }
}

.groupFieldWrapper {
    @include common.flexStartSpaceBetweenFlexRow;
    width: 100%;
}

.singleFieldWrapper {
    @include common.flexColumn;
    margin: 10px 0 10px 5px;
    .fieldTitleWrapper {
        @include common.flexRow;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 5px;
        .fieldLabel {
            margin-right: 10px;
            line-height: 28px;
            height: 100%;
            width: 100%;
        }
    }
}

.multiToggleField {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    .multiToggleLabel {
        width: calc(100% - 46px);
        margin-right: 10px;
        text-align: left;
    }
}

.isLabelField {
    margin-bottom: -10px !important;
}

.lastFieldInGroup {
    padding-right: 0px !important;
}

.emptyFieldLabel {
    color: rgba(theme.$color-grey, 0.5);
    font-style: italic;
}

.unknownFieldType {
    color: theme.$color-red;
    border: 1px solid theme.$color-red;
    border-radius: 5px;
    padding: 10px;
}

.multiToggleWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .multiToggleHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 3) - 21px);
            margin-right: 10px;
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
            }
        }
    }
    .multiToggleOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.flexRow;
            align-items: center;
            padding: 5px;
        }
    }
}

:export {
    french: theme.$color-french;
    grey: rgba(theme.$color-grey, 0.7);
    lightGrey: theme.$color-light-grey;
}
