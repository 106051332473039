@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.myDatasetsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin: 20px 0;
    color: theme.$color-primary-night;
    .myDatasetsHeader {
        text-align: left;
        padding-left: 8vw;
        font-size: 22px;
        font-weight: theme.$font-bold;
    }
    .myDatasetsResultsWrapper {
        border-radius: 5px;
        margin: 20px 20px;
        .noResults {
            margin-top: 20px;
            font-size: 22px;
            font-weight: theme.$font-medium;
        }
    }
    .tilesWrapper {
        @include common.flexRow;
        height: 100%;
        width: 100%;
        .tilesColumnWrapper {
            @include common.flexRow;
            height: 100%;
            width: 100%;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
    }
}

.datasetTileWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    border-radius: 5px;
    margin: 10px 0;
    padding: 20px;
    font-family: theme.$font-family;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    color: theme.$color-primary-night;
    width: calc(50% - 80px);
    height: 200px;
    &:hover {
        box-shadow: 1px 1px 5px theme.$color-french;
        transform: scale(1.02);
    }
    .documentNamesWrapper {
        @include common.flexColumn;
        height: 80%;
        width: 100%;
        .documentNamesHeader {
            width: 100%;
            font-weight: theme.$font-medium;
            text-align: left;
            margin-bottom: 5px;
        }
        .documentNamesList {
            height: 80%;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            text-align: left;
            .documentName {
                font-family: theme.$font-family;
                margin: 10px;
            }
        }
    }
    .buttonWrapper {
        width: 100%;
        height: 20%;
        @include common.flexRow;
        justify-content: flex-end;
    }
}

.wrapper {
    @include common.centreFlexColumn;
    height: 100%;
    .definitionWrapper {
        @include common.flexColumn;
        .titleWrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 35px;
            border-bottom: 1px solid theme.$color-primary-night;
            margin-bottom: 20px;
            justify-content: space-between;
            .title {
                font-size: 22px;
                font-weight: theme.$font-bold;
                margin-right: 10px;
                color: theme.$color-primary-night;
            }
            .agreementWrapper {
                @include common.flexRow;
                align-items: center;
                .agreementLabel {
                    font-size: 22px;
                    font-weight: theme.$font-bold;
                    margin-right: 10px;
                    color: theme.$color-primary-night;
                }
                .documentNamesIcon {
                    @include common.centreFlexRow;
                    border-radius: 50%;
                    border-width: 1px;
                    border-style: solid;
                    color: inherit;
                    background-color: transparent;
                    font-weight: theme.$font-bold;
                    height: 14px;
                    width: 14px;
                    font-size: 13px;
                }
            }
        }
    }
}

.buttonWrapper {
    @include common.flexRow;
    justify-content: space-between;
    margin-top: 10px;
    .rightButtonsWrapper {
        @include common.flexRow;
    }
}

.formDefinitionWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .sectionWrapper {
        align-items: center;
        .sectionHeaderWrapper {
            @include common.flexRow;
            align-items: center;
            border-bottom: 1px dashed theme.$color-grey;
            .sectionHeader {
                @include common.flexRow;
                align-items: center;
                color: theme.$color-primary-night;
                padding: 10px 0;
                &:hover {
                    color: theme.$color-french;
                }
                .sectionOpenIcon {
                    margin-right: 10px;
                    cursor: pointer;
                }
                .sectionLabel {
                    font-size: 20px;
                    font-family: theme.$font-family;
                    margin-right: 10px;
                }
                .sectionDescription {
                    margin-right: 10px;
                }
            }
        }
        .allFieldsWrapper {
            margin-top: 10px;
        }
    }
}

.singleFieldWrapper {
    @include common.flexColumn;
    margin: 10px 0;
    .fieldTitleWrapper {
        @include common.flexRow;
        margin-bottom: 10px;
        align-items: center;
        .fieldOpenWrapper {
            margin-right: 10px;
        }
        .fieldLabel {
            margin-right: 10px;
        }
        .datasetSectionText {
            font-size: 20px;
        }
    }
    .fieldCanCollapse {
        &:hover {
            color: theme.$color-french;
        }
    }
}

.isLabelField {
    margin-bottom: -10px !important;
}

.groupFieldWrapper {
    @include common.flexStartSpaceBetweenFlexRow;
    width: 100%;
}

.lastFieldInGroup {
    padding-right: 0px !important;
}

.tableDefinitionWrapper {
    width: 100%;
}

.tableHeader {
    @include common.flexRow;
    border-radius: 5px 5px 0 0;
    background-color: theme.$color-french;
    width: fit-content;
    .firstCell {
        border-left: 1px solid theme.$color-french;
        border-radius: 5px 0 0 0;
    }
}

.tableBody {
    @include common.flexColumn;
    width: fit-content;
}

.tableRow {
    @include common.flexRow;
    border-bottom: 1px solid theme.$color-grey;
    width: fit-content;
    .cellWrapper {
        @include common.centreFlexRow;
        padding: 5px;
        border-right: 1px solid theme.$color-grey;
        border-left: 1px solid theme.$color-white;
        .openCellWrapper {
            @include common.centreFlexRow;
            height: 100%;
            width: 100%;
            min-height: 38px;
        }
        .collapsedPlaceholder {
            @include common.centreFlexRow;
            height: 100%;
            width: 100%;
            min-height: 38px;
        }
    }
    .firstCell {
        border-left: 1px solid theme.$color-grey;
    }
}

.tableHeaderWrapper {
    @include common.flexRow;
    align-items: center;
    padding: 10px 5px;
    height: 30px;
    color: theme.$color-white;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    border-right: 1px solid theme.$color-white;
    border-left: 1px solid theme.$color-white;
    .cellHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        .headerLabel {
            font-size: 12px;
            margin-right: 10px;
        }
        .headerIconWrapper {
            @include common.flexRow;
            align-items: center;
            color: theme.$color-primary-night;
        }
    }
}

.datasetLinkField {
    @include common.centreFlexRow;
    height: calc(100% - 5px);
    width: 140px;
    margin: auto;
    .datasetLinkButton {
        @include common.transparentButton;
        border: 1px solid theme.$color-primary-night;
        padding: 3px;
        border-radius: 5px;
        font-family: theme.$font-family;
        font-size: 14px;
        flex: 1;
        height: 30px;
        width: 140px;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
            border: 1px solid theme.$color-french;
        }
        &:disabled {
            cursor: not-allowed;
            color: theme.$color-primary-night;
            box-shadow: none;
            border: 1px solid theme.$color-primary-night;
        }
    }
}

.modal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 30vh;
    width: fit-content;
    min-width: 650px;
}

.modalDefinitionWrapper {
    @include common.flexColumn;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    padding: 10px;
    .buttonWrapper {
        align-items: center;
    }
}

.multiToggleWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .multiToggleHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 3) - 21px);
            margin-right: 10px;
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
            }
        }
    }
    .multiToggleOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.flexRow;
            align-items: center;
            padding: 5px;
        }
    }
}

.multiToggleGroupLabelWrapper {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
}

.multiToggleGroupLabel {
    @include common.flexRow;
    align-items: center;
    text-align: left;
    font-size: 18px;
    margin-right: 10px;
}

:export {
    white: theme.$color-white;
    lightGrey: theme.$color-light-grey;
}
