@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin titleWrapper {
    margin-bottom: 10px;
    text-align: center;
    font-weight: theme.$font-bold;
    color: theme.$color-french;
}

.contentsPageWrapper {
    @include common.flexRow;
    border-right: 1px solid theme.$color-grey;
    .iconButtonsWrapper {
        @include common.flexColumn; 
        padding: 5px;
        .iconWrapper {
            margin-bottom: 5px;
            color: theme.$color-primary-night;
            position: relative;
            &:hover {
                color: rgba(theme.$color-french, 0.5);
            }
            .resolvedChangesIcon {
                position: absolute;
                top: 14px;
                right: -1px;
                height: 15px;
                width: 15px;
                background-color: theme.$color-mandatory;
                border-radius: 50%;
            }
        }
    }
    .sideMenuOpenIconsWrapper {
        border-right: 1px solid theme.$color-grey;
    }
    .sideMenuContentWrapper {
        @include common.flexColumn;
        width: calc(100% - 42px);
        .sideMenuTitleWrapper {
            @include common.spaceBetweenFlexRow;
            margin-bottom: 5px;
            padding: 5px 0;
            border-bottom: 1px solid theme.$color-grey;
            .sideMenuLeftTitle {
                @include common.flexRow;
                align-items: center;
                .sortTagsWrapper {
                    @include common.centreFlexRow;
                    margin-left: 10px;
                }
            }
            .sideMenuTitle {
                padding-left: 5px;
                font-weight: theme.$font-bold;
                color: theme.$color-primary-night;
                font-size: theme.$font-size-h3;
            }
            .sideMenuCollapse {
                @include common.centreFlexRow;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
        .sideMenuScrollableWrapper {
            @include common.flexColumn;
            padding: 0 5px;
            height: calc(100% - 40px);
        }
    }
    .contentsPageSection {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
        font-weight: theme.$font-medium;
        font-size: theme.$font-size-h6;
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
        }
        .contentsPageSectionIcon {
            margin-right: 5px;
        }
    }
    .instepContentsTitle {
        margin-left: 10px;
    }
    .suggestedChangesSection {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .suggestedChangesSectionIcon {
            margin-right: 5px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h6;
        }
        .suggestedChangesSectionLabelWrapper {
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h6;
            @include common.flexColumn;
            width: 90%;
            cursor: pointer;
            &:hover {
                color: theme.$color-french;
            }
        }
    }
}

.viewButtonsWrapper {
    @include common.flexColumn;
    width: 100%;
    align-items: center;
    border-top: 1px solid theme.$color-primary-night;
    padding-top: 5px;
    .viewButton {
        @include common.transparentButton(theme.$color-primary-night);
        @include common.centreFlexRow;
        width: 100%;
        padding: 0;
    }
    .selected {
        color: theme.$color-french;
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    white: theme.$color-white;
}
