@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.documentsWrapper {
    @include common.tablePageWrapper;
    .documentsTitleContainer {
        @include common.tablePageTitleContainer;
        .documentsTitle {
            @include common.tablePageTitle;
        }
        .agreementTypeButton {
            @include common.centreFlexRow;
            margin-left: 10px;
        }
    }
}

.modalWrapper {
    @include common.flexColumn;
    width: 400px;
}

.inputsWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.editDocumentButton {
    @include common.tableIconButton;
}

.modalInputSpacer {
    margin-bottom: 10px;
}

.clientDatasetModalWrapper {
    @include common.flexColumn;
    .clientOptionsWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        color: theme.$color-primary-night;
        .clientOptionsTitle {
            font-weight: theme.$font-medium;
            margin-bottom: 15px;
        }
        .clientOptionsTable {
            max-height: 150px;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            .clientOptionRow {
                @include common.spaceBetweenFlexRow;
                padding: 5px;
                .clientName {
                    font-weight: theme.$font-medium;
                }
            }
        }
    }
}

.myDocumentGroupsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin: 20px 0;
    color: theme.$color-primary-night;
    .myDocumentGroupsHeaderWrapper {
        @include common.flexRow;   
        .myDocumentGroupsHeader {
            text-align: left;
            padding-left: 8vw;
            font-size: 22px;
            font-weight: theme.$font-bold;
            margin-right: 10px;
        }
    }
    .myDocumentGroupsResultsWrapper {
        border-radius: 5px;
        margin: 20px 20px;
        .emptyDocumentGroupMessage {
            position: relative;
            top: 50%;
        }
        .tilesWrapper {
            @include common.flexRow;
            height: 100%;
            width: auto;
            justify-content: center;
            .tilesColumnWrapper {
                @include common.flexRow;
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-left: 40px;
            }
        }
    }
}

.tileWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    font-family: theme.$font-family;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    color: theme.$color-primary-night;
    width: calc(50% - 80px);
    height: 200px;
    &:hover {
        box-shadow: 1px 1px 5px theme.$color-french;
        transform: scale(1.02);
    }
    .documentNamesWrapper {
        @include common.flexColumn;
        height: 80%;
        width: 100%;
        .documentNamesHeader {
            width: 100%;
            font-weight: theme.$font-medium;
            text-align: left;
            margin-bottom: 5px;
        }
        .documentNamesList {
            height: 80%;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            text-align: left;
            .documentName {
                font-family: theme.$font-family;
                margin: 10px;
            }
        }
    }
    .buttonWrapper {
        width: 100%;
        height: 20%;
        @include common.flexRow;
        justify-content: flex-end;
    }
}

.groupModalWrapper {
    @include common.flexColumn;
    width: 70vw;
    color: theme.$color-primary-night;
}

.documentOptionsWrapper {
    @include common.flexColumn;
    margin-bottom: 10px;
    .documentsHeader {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
        cursor: pointer;
        .sectionOpenIcon {
            margin-right: 10px;
            cursor: pointer;
        }
        .documentOptionsTitle {
            font-weight: theme.$font-medium;
        }
    }
    .documentOptionsHeader {
        @include common.spaceBetweenFlexRow;
        align-items: center;
    }
    .documentOptionsTable {
        max-height: 250px;
        border: 1px solid theme.$color-grey;
        border-radius: 0 0 5px 5px;
        .documentOptionRow {
            @include common.spaceBetweenFlexRow;
            padding: 5px;
            font-size: 14px;
            &:last-of-type {
                border-radius: 0 0 5px 5px;
            }
            .documentName {
                font-weight: theme.$font-medium;
            }
        }
    }
}
.agreementTypesModalWrapper {
    @include common.flexColumn;
    width: 40vw;
    height: 60vh;
    .modalHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid theme.$color-grey;
        color: theme.$color-primary-night;
        margin-bottom: 10px;
        .modalHeaderLabel {
            font-size: 22px;
            font-weight: 800;
        }
    }
    .addNewAgreementType {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    .agreementTypesWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 52px);
        .agreementType {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            .agreementTypeInput {
                @include common.flexRow;
            }
            .agreementTypeRemove {
                @include common.centreFlexRow;
                margin-left: 5px;
            }
        }
    }
}

:export {
    grey: theme.$color-light-grey;
    white: theme.$color-white;
    primary: theme.$color-primary-night;
    red: theme.$color-red;
}
