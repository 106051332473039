@use '../../styles/theme' as theme;
@use '../../styles/common' as common;

.doraWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .doraTilesWrapper {
        @include common.flexRow;
        height: 100%;
        padding: 0 20px;
        .doraTilesColumnWrapper {
            @include common.flexColumn;
            height: 100%;
            width: calc((100% / 2) - (20px / 3));
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
        .doraTileWrapper {
            @include common.centreFlexRow;
            height: calc(50% - 14px);
            border-radius: 5px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            background-color: theme.$color-lightest-grey;
        }
    }
}

.doraLinkButton {
    @include common.centreFlexColumn;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.doraHomeButton {
    @include common.centreFlexColumn;
    @include common.transparentButton;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    &:hover {
        color: theme.$color-ocean;
    }
    .doraHomeButtonIcon {
        @include common.centreFlexColumn;
        color: inherit;
        padding: 20px;
    }
}

.doraHomeButtonLabel {
    position: absolute;
    font-family: theme.$font-family;
    color: inherit;
    font-size: 30px;
    font-weight: theme.$font-bold;
    top: 85%;
}
