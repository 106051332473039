@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wrapper {
    @include common.flexColumn;
    width: 100%;
    height: fit-content;
}

.calculatorWrapper {
    @include common.flexRow;
    width: 100%;
    height: fit-content;
    .outputRowsWrapper {
        @include common.flexColumn;
    }
}

.blockWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    width: calc(100% - 10px);
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 4px;
    .blockHeader {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        height: 36px;
        margin-bottom: 5px;
        .calculationConditionWrapper {
            @include common.flexRow;
            align-items: center;
            flex: 1;
            height: 100%;
            .calculationCondition {
                @include common.centreFlexRow;
                width: 80px;
                border-radius: 5px;
                height: 100%;
                font-weight: theme.$font-medium;
            }
        }
    }
    .builderWithConditionalWrapper {
        @include common.flexColumn;
        align-items: flex-start;
        width: 100%;
        .conditionOption {
            @include common.centreFlexColumn;
            height: fit-content;
            min-height: 36px;
            margin-bottom: 5px;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
        }
    }
}

.removeBuilderRow,
.clearBuilderRow {
    @include common.centreFlexColumn;
    @include common.transparentButton;
    height: 100%;
    padding: 0 3px;
    border-left: 1px solid theme.$color-grey;
    &:hover {
        color: theme.$color-red;
    }
}

.selectedBlock {
    border-color: theme.$color-french;
}

.editorWrapper {
    @include common.flexColumn;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    width: calc(50% - 5px);
    height: fit-content;
    .inputItemsWrapper {
        @include common.flexRow;
        margin: 3px;
        width: calc(100% - 6px);
        align-items: center;
        flex-wrap: wrap;
        .inputWrapper {
            @include common.flexColumn;
            align-items: center;
            justify-content: space-between;
            background-color: theme.$color-french;
            border: none;
            border-radius: 5px;
            outline: none;
            height: 70px;
            padding: 4px 2px;
            margin: 2px;
            &:hover {
                opacity: 0.9;
            }
            &:disabled {
                opacity: 0.7;
            }
            .inputLabel {
                color: theme.$color-white;
                font-family: theme.$font-family;
                margin-bottom: 2px;
                font-weight: theme.$font-bold;
                line-height: 1;
                margin: auto;
            }
            .dropdownOption {
                width: calc(100% - 4px);
                margin: 0 2px;
            }
            .numberOption {
                @include common.spaceBetweenFlexRow;
                width: calc(100% - 4px);
                margin: 0 2px;
                .numberInput {
                    height: 30px;
                    width: calc(100% - 29px);
                    margin-right: 3px;
                    padding: 0 3px;
                    font-family: theme.$font-family;
                    outline: none;
                    border: 1px solid theme.$color-primary-night;
                    color: theme.$color-primary-night;
                    border-radius: 5px;
                    &:focus {
                        border: 1px solid theme.$color-french;
                    }
                    &:disabled {
                        background: none;
                        border: 1px solid theme.$color-grey;
                        color: theme.$color-white;
                    }
                }
                .numberButton {
                    @include common.transparentButton;
                    padding: 0;
                    color: theme.$color-white;
                    &:hover {
                        color: theme.$color-green;
                    }
                }
            }
        }
    }
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        width: calc(100% - 6px);
        padding: 0 3px;
        .conditionButton {
            @include common.centreFlexRow;
            background-color: theme.$color-ocean;
            color: theme.$color-white;
            border-radius: 5px;
            width: calc((100% / 2) - 4px);
            padding: 10px;
            text-align: center;
            font-weight: theme.$font-bold;
            margin: 5px 2px;
            font-family: theme.$font-family;
            border: none;
            cursor: pointer;
            outline: none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            &:hover {
                opacity: 0.9;
            }
            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
                color: theme.$color-white;
            }
        }
    }
}

.builderItemsWrapper {
    @include common.spaceBetweenFlexRow;
    align-items: center;
    width: calc(100% - 2px);
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    min-height: 36px;
    .outputLabel {
        @include common.centreFlexRow;
        height: 100%;
        padding: 0 2px;
        margin-right: 2px;
        border-right: 1px solid theme.$color-grey;
        font-size: 13px;
        width: 38px;
    }
    .gracePeriodLabel {
        @include common.centreFlexRow;
        height: 100%;
        padding: 0 2px;
        margin-right: 2px;
        border-right: 1px solid theme.$color-grey;
        font-size: 12px;
        width: 38px;
    }
    .builderItems {
        @include common.flexRow;
        height: calc(100% - 4px);
        align-items: center;
        padding: 2px 5px;
        flex-wrap: wrap;
        .builderPlaceholder {
            @include common.overflowText;
            font-size: 13px;
        }
        .builderWrapper {
            @include common.transparentButton;
            @include common.spaceBetweenFlexRow;
            max-height: 100px;
            height: fit-content;
            width: fit-content;
            padding: 2px;
            margin: 1px 0;
            border-radius: 5px;
            margin-right: 3px;
            background-color: white;
            border: 1px solid theme.$color-light-grey;
            .builderValue {
                @include common.flexRow;
                align-items: center;
                height: 24px;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                white-space: nowrap;
            }
            .otherOutput {
                min-height: 20px;
                resize: none;
                font-size: 13px;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                outline: none;
                border: none;
                &:disabled {
                    background-color: theme.$color-white;
                    color: theme.$color-primary-night;
                }
            }
            .deleteItem {
                color: theme.$color-red;
                padding-left: 2px;
            }
        }
    }
}

.builderMarginBottom {
    margin-bottom: 5px;
}

.selectedBuilder {
    background-color: rgba(theme.$color-ocean, 0.2);
}

.invalidBrackets {
    border: 1px solid theme.$color-red;
}

.calculatorHelpWrapper {
    @include common.flexColumn;
    font-family: theme.$font-family;
    width: 60vw;
    height: 70vh;
    padding: 0 10px;
    color: theme.$color-primary-night;
    .helpContent {
        @include common.flexColumn;
        height: calc(100% - 56px);
        .generalHelpContent {
            @include common.flexColumn;
            border-bottom: 1px solid theme.$color-primary-night;
            font-size: 15px;
            height: 220px;
            .generalHelp {
                margin-bottom: 10px;
            }
        }
        .inputHelpContent {
            @include common.flexColumn;
            height: calc(100% - 220px);
            .inputHelpTitle {
                font-size: 18px;
                font-weight: 600;
                margin: 10px 0;
            }
            .inputHelpWrapper {
                @include common.flexRow;
                width: 100%;
                height: calc(100% - 44px);
                .helpKeypad {
                    @include common.flexRow;
                    width: 50%;
                    height: 100%;
                    .inputHelpItems {
                        @include common.flexRow;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        flex-wrap: wrap;
                        .inputHelp {
                            @include common.flexColumn;
                            align-items: center;
                            justify-content: center;
                            background-color: theme.$color-french;
                            color: theme.$color-white;
                            border: none;
                            border-radius: 5px;
                            outline: none;
                            height: 70px;
                            padding: 4px 2px;
                            margin: 2px;
                            width: calc((100% / 4) - 4px);
                            font-weight: 600;
                            cursor: pointer;
                            &:hover {
                                opacity: 0.9;
                            }
                        }
                        .selectedInput {
                            background-color: theme.$color-french-spectrum-2;
                            border: 2px solid theme.$color-french;
                            box-shadow: inset 0 5px 20px theme.$color-french;
                        }
                    }
                }
                .selectedHelpWrapper {
                    @include common.flexColumn;
                    height: 100%;
                    width: calc(50% - 21px);
                    align-items: flex-start;
                    margin-right: 10px;
                    padding-right: 10px;
                    border-right: 1px dotted theme.$color-grey;
                    .selectedHelp {
                        @include common.flexColumn;
                        align-items: flex-start;
                        height: 100%;
                        width: 100%;
                        .selectedHelpButton {
                            @include common.flexColumn;
                            align-items: center;
                            justify-content: center;
                            background-color: theme.$color-french;
                            color: theme.$color-white;
                            border-radius: 5px;
                            height: 110px;
                            padding: 4px 2px;
                            margin-bottom: 20px;
                            width: 175px;
                            font-weight: 800;
                        }
                        .selectedHelpExplanation {
                            color: theme.$color-primary-night;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

:export {
    white: theme.$color-white;
    red: theme.$color-red;
    grey: theme.$color-grey;
    primary: theme.$color-primary-night;
    amber: theme.$color-amber;
    green: theme.$color-green;
    lightGrey: theme.$color-light-grey;
    lightOcean: rgba(theme.$color-ocean, 0.2);
}
