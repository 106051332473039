@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.dateFieldWrapper {
    max-width: 230px;
}

.dateField {
    @include common.inputField;
    background: inherit;
    padding: 5px;
    max-width: 230px;
    width: calc(100% - 12px);
    height: 26px;
    font-size: 13px;
    &:disabled {
        background-color: rgba(theme.$color-grey, 0.1);
        border: 1px solid rgba(theme.$color-grey, 0.5);
    }
    &::-webkit-input-placeholder {
        color: rgba(theme.$color-grey, 0.7);
    }
}

.dateFieldLightBorder {
    @include common.inputField;
    background: inherit;
    padding: 5px;
    max-width: 230px;
    width: calc(100% - 12px);
    height: 26px;
    font-size: 13px;
    border: 1px solid theme.$color-light-grey;
    &::-webkit-input-placeholder {
        color: rgba(theme.$color-grey, 0.7);
    }
}