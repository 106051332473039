// Colors
// Night - Menu Header and backgrounds
$color-primary-night: #000839;
$color-primary-night-hover: rgba(0, 8, 57, 0.397);
//Amethyst - Icons and highlights
$color-amethyst: #6930C3;
//French - unselected text and icon
$color-french: #5390d9;
// Aqua - highlights and alerts
$color-aqua: #80ffdb;
$color-aqua-hover: rgba(128, 255, 219, 0.61);
// Ocean - additional callouts
$color-ocean: #56cFe1;
// Others (these are left over from corterum)
$color-white: #ffffff;
$color-red: #ff3449;
$color-amber: #ff814d;
$color-amber-soft: rgb(255, 129, 77, 0.3);
$color-green: #aad31c;
$color-grey: #999999;
$color-light-grey: #d6d6d6;
$color-lightest-grey: #FAF9F6;
$color-info: rgb(71, 151, 210);
$color-mandatory: #349b3a;
$color-mandatory-light: rgb(52, 155, 58, 0.2);
$color-body: #222222;
$color-body-disabled: #999999;
$color-body-disabled-light: rgb(153, 153, 153, 0.1);
$color-color-body-disabled-soft: #DDDDDD;
$color-unselected-middle-tab: #ecece8;
$color-antique-white: #faebd7;
$color-gold: #cfb53b;
$color-bookshelf-brown-light: #8c7964;
$color-bookshelf-brown-dark: #48321a;
$color-dark-charcoal: #333;
$color-yellow-green: #bccb19;

//Opinions Map
$color-permitted-to-view: #91c955;

// Analytics French Spectrum
$color-french-spectrum-1: #bad2ef;
$color-french-spectrum-2: #97bce8;
$color-french-spectrum-3: #75a6e0;
$color-french-spectrum-4: #5390d9;
$color-french-spectrum-5: #4a81c3;
$color-french-spectrum-6: #3a6497;
$color-french-spectrum-7: #29486c;
$color-french-spectrum-8: #213a57;


// Playbook Primary Night Spectrum
$color-primary-night-spectrum-1: #527493;
$color-primary-night-spectrum-2: #083965;
$color-primary-night-spectrum-3: #062e51;
$color-primary-night-spectrum-4: #05223d;

// Playbook Red Spectrum
$color-red-spectrum-1: #cc2a3a;
$color-red-spectrum-2: #b82634;
$color-red-spectrum-3: #8f1d29;
$color-red-spectrum-4: #521117;

// Playbook Mandatory Spectrum
$color-mandatory-spectrum-1: #349b3a;
$color-mandatory-spectrum-2: #2a7c2e;
$color-mandatory-spectrum-3: #1f5d23;
$color-mandatory-spectrum-4: #153e17;

// Playbook Amethyst Spectrum
$color-amethyst-spectrum-1: #6930c3;
$color-amethyst-spectrum-2: #5f2bb0;
$color-amethyst-spectrum-3: #4a2289;
$color-amethyst-spectrum-4: #351862;

// Font Weights
$font-very-light: 200;
// Body
$font-light: 500;
// Sub / h2-h5
$font-medium: 600;
// Header / h1
$font-bold: 800;

// Font Size
$font-size-h7: 12px;
$font-size-h6: 14px;
$font-size-h5: 15px;
$font-size-h4: 16px;
$font-size-h3: 18px;
$font-size-h2: 20px;
$font-size-h1: 24px;
$font-size-body: 15px;

//Font Family
$font-family: 'Manrope', sans-serif;

//Stroke Width
$stroke-width-icon: 1.5em;

// Margin
$margin-xsmall: 8px;
$margin-small: 14px;
$margin-medium: 16px;
$margin-large:  36px;
// Padding
$padding-xsmall: 8px;
$padding-small:  14px;
$padding-medium: 16px;
$padding-large:  200px;
