@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.dropdownListsWrapper {
    @include common.tablePageWrapper;
    .dropdownListsTitleContainer {
        @include common.tablePageTitleContainer;
        .dropdownListsTitle {
            @include common.tablePageTitle;
        }
    }
}

.createDropdownListWrapper {
    @include common.flexColumn;
    align-items: center;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    .createDropdownListHeader {
        @include common.spaceBetweenFlexRow;
        width: 95%;
        font-size: 20px;
        font-weight: theme.$font-bold;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid theme.$color-grey;
    }
    .createDropdownListTitle {
        font-size: 24px;
        font-weight: theme.$font-bold;
        color: theme.$color-primary-night;
        margin-bottom: 20px;
    }
}

.dropdownListInputLabel {
    margin-bottom: 5px;
    font-weight: theme.$font-medium;
    text-decoration: underline;
}

.dropdownListTextArea {
    padding: 10px;
    width: 700px;
    margin-bottom: 10px;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    &:focus {
        outline: none;
        border: 1px solid theme.$color-french;
    }
}

.dropdownListBuilder {
    width: 700px;
    height: 220px;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    margin-bottom: 10px;
    .dropdownListBuilderDroppable {
        width: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
}

.listItemWrapper {
    @include common.flexRow;
    margin: 5px;
    padding: 5px;
    width: 97%;
    justify-content: space-evenly;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .listItemInput {
        width: 90%;
        border: none;
        border-bottom: 1px dotted theme.$color-grey;
        font-family: theme.$font-family;
        &:hover {
            border-bottom: 1px dotted theme.$color-primary-night;
        }
        &:focus {
            outline: none !important;
        }
    }
    .deleteItem {
        @include common.transparentButton(inherit);
        padding: 0 !important;
        &:hover {
            color: theme.$color-red;
        }
    }
}

.dropdownListLabelContainer {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    margin-bottom: 5px;
    font-weight: theme.$font-medium;
}

.dropdownListPreviewWrapper {
    width: 550px;
    .dropdownListPreviewTitle {
        font-size: 20px;
        font-weight: theme.$font-medium;
        padding: 5px;
        border-bottom: 1px solid rgba(theme.$color-grey, 0.5);
    }
    .dropdownListPreview {
        @include common.flexColumn;
        margin: 0 10px 20px 10px;
        .previewTitleWrapper {
            @include common.flexRow;
            margin: 10px 0;
            width: 98%;
            align-items: center;
            .previewTitle {
                margin-right: 10px;
            }
        }
    }
}

.emptyTitle {
    color: rgba(theme.$color-grey, 0.5);
    font-style: italic;
}

.editDropdownListButton {
    @include common.tableIconButton;
}
