@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.nettingEngineWrapper {
    @include common.flexColumn;
    min-height: 100%;
    width: 100%;
    margin: 20px 0;
}

.nettingResultsWrapper {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    .titleContainer {
        height: 50px;
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 56px);
        margin: 0 28px 10px 28px;
        justify-content: space-between;
        border-bottom: 2px solid theme.$color-primary-night;
        .title {
            @include common.tablePageTitle;
        }
    }
    .nettingOverallSummary {
        @include common.spaceBetweenFlexRow;
        align-items: center;
        width: calc(100% - 56px);
        margin: 0 28px 10px 28px;
        .backButtonWrapper {
            @include common.flexRow;
            width: calc(20% - 10px);
            .backButton {
                text-align: left;
                margin-right: 10px;
                color: theme.$color-french-spectrum-6;
                font-weight: theme.$font-bold;
                font-size: theme.$font-size-h3;
                cursor: pointer;
                text-decoration: none;
            }
        }
        .nettingSummary {
            @include common.centreFlexRow;
            justify-content: center;
            width: 60%;
            align-self: center;
            font-weight: theme.$font-bold;
            font-size: theme.$font-size-h3;
            .nettingOverallSpan {
                margin: 0 10px;
                font-weight: theme.$font-bold;
            }
        }
        .helpIconsWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            width: calc(20% - 10px);
            margin-left: 10px;
            align-items: center;
            .helpIcon {
                text-align: right;
                color: theme.$color-french-spectrum-6;
                font-weight: theme.$font-bold;
                font-size: theme.$font-size-h3;
                cursor: pointer;
                &:hover {
                    color: theme.$color-french-spectrum-4;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .whatIsThis {
                text-align: left;
                color: theme.$color-french-spectrum-6;
                font-weight: theme.$font-bold;
                font-size: theme.$font-size-h3;
                cursor: pointer;
                &:hover {
                    color: theme.$color-french-spectrum-4;
                }
                text-decoration: none;
            }
        }
    }
}

.nettingJurisdictionResults {
    @include common.flexColumn;
    margin-right: 10px;
    border: solid 1px theme.$color-light-grey;
    border-radius: 5px;
    padding: 10px;
    background-color: theme.$color-lightest-grey;
    .jurisdictionWrapper {
        @include common.flexRow;
        align-items: center;
        font-size: theme.$font-size-h2;
        font-weight: theme.$font-bold;
        .counterpartyOfficesWrapper {
            @include common.flexRow;
            margin-left: 10px;
            font-size: theme.$font-size-h3;
            font-weight: theme.$font-medium;
            .counterpartyOfficesLabel {
                min-width: fit-content;
            }
            .counterpartyOffices {
                margin-left: 10px;
                width: calc(100% - 188px);
            }
        }
    }
    .resultsWrapper {
        @include common.flexRow;
        height: calc(100% - 30px);
        .leftHandSummary {
            width: 45%;
            height: 100%;
        }
        .noClientSignOffDetailsMessage {
            @include common.flexColumn;
            width: 55%;
            .message {
                color: theme.$color-amber;
                font-weight: theme.$font-bold;
            }
            .rightHandSummary {
                width: 100%;
                height: calc(100% - 30px);
                margin-top: 30px;
                .questionAndAnswerWrapper {
                    @include common.flexRow;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .summaryAndProductsCoveredAnswers {
                    @include common.flexColumn;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 100%;
                }
                .questionAndAnswer {
                    margin-bottom: 8px;
                    width: 20vw;
                    .question {
                        font-weight: theme.$font-bold;
                        @include common.centreFlexRow;
                        .questionIcon {
                            margin-left: 5px;
                        }
                    }
                    .answer {
                        @include common.centreFlexRow;
                        .answerText {
                            margin-right: 5px;
                        }
                        .unsureIcon {
                            color: theme.$color-amber;
                            font-weight: theme.$font-bold;
                        }
                        .productsCoveredWrapper {
                            border: 1px solid theme.$color-grey;
                            border-radius: 5px;
                            height: 15vh;
                            width: 80%;
                            padding: 5px;
                            .productColumns {
                                @include common.flexRow;
                                height: 100%;
                                width: 100%;
                                justify-content: space-between;
                                .productColumn {
                                    @include common.flexColumn;
                                    width: calc(50% - 10px);
                                    .productsCovered {
                                        @include common.flexRow;
                                        align-items: center;
                                        justify-content: space-between;
                                        .product {
                                            max-width: calc(100% - 30px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .jurisdictionAndProductsCoveredWidth {
                    width: 100%;
                }
            }
        }
    }
    .notPermittedJurisdiction {
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%);
        border: solid theme.$color-grey 1px;
        border-radius: 5px;
        background-color: theme.$color-white;
        padding: 20px;
        font-size: theme.$font-size-h4;
        font-weight: theme.$font-medium;
        .notPermittedJurisdictionImpact {
            color: theme.$color-amethyst;
        }
        .notPermittedButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.nettingEngineAssumptionModalWrapper {
    @include common.flexColumn;
    padding-bottom: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 750px;
    .modalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .modalWarningTitle {
            margin-left: 10px;
        }
    }
    .modalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        margin-left: 10px;
    }
}

.sectionTenAModalWrapper {
    @include common.flexColumn;
    padding-bottom: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 750px;
    .modalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .modalWarningTitle {
            margin-left: 10px;
        }
    }
    .modalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 7px;
        margin-left: 10px;
        .modalImpact {
            color: theme.$color-red;
        }
    }
}

.whatIsThisModalWrapper {
    @include common.flexColumn;
    padding-bottom: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 550px;
    .whatIsThisCard {
        @include common.flexColumn;
        justify-content: space-between;
        align-items: center;
        width: 474px;
        margin-right: 28px;
        padding: 0 10px;
        height: 300px;
        .whatIsThisTitle {
            font-size: theme.$font-size-h2;
        }
        .whatIsThisText {
            margin-top: 20px;
        }
    }
}

:export {
    primary: theme.$color-primary-night;
    red: theme.$color-red;
    darkRed: theme.$color-red-spectrum-2;
    amethyst: theme.$color-amethyst;
    french: theme.$color-french;
    green: theme.$color-green;
    grey: rgba(theme.$color-grey, 0.3);
    amber: theme.$color-amber;
}
