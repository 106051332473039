@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.riskToleranceWrapper {
    @include common.flexColumn;
    height: calc(100% - 40px);
    width: 100%;
    margin: 20px 0;
    color: theme.$color-primary-night;
    .riskToleranceHeader {
        text-align: left;
        padding-left: 40px;
        font-size: 22px;
        font-weight: theme.$font-bold;
    }
    .riskToleranceDatasetsWrapper {
        border-radius: 5px;
        margin: 20px;
    }
}

.tilesWrapper {
    @include common.flexRow;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    .datasetTileWrapper {
        @include common.flexColumn;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 5px;
        margin: 10px;
        padding: 10px;
        font-family: theme.$font-family;
        box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
        color: theme.$color-primary-night;
        height: 160px;
        &:hover {
            box-shadow: 1px 1px 5px theme.$color-french;
            transform: scale(1.02);
        }
        .documentNamesWrapper {
            @include common.flexColumn;
            width: 100%;
            .agreementType {
                width: 100%;
                font-weight: theme.$font-medium;
                text-align: left;
                border-bottom: 1px solid theme.$color-light-grey;
                margin-bottom: 5px;
                padding-bottom: 5px;
            }
            .documentNamesList {
                @include common.flexRow;
                width: 100%;
                height: 80px;
                font-size: 14px;
                text-align: left;
            }
        }
        .buttonWrapper {
            @include common.flexRow;
            width: 100%;
            justify-content: flex-end;
        }
    }
}

.buttonWrapper {
    @include common.spaceBetweenFlexRow;
    height: 50px;
    padding: 5px;
    width: calc(100% - 10px);
    border-top: 1px solid theme.$color-grey;
}

:export {
    grey: rgba(theme.$color-grey, 0.3);
}
