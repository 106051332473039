@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.myCompanyDropdown {
    width: calc((100% / 3) - (20px / 3) - 6px);
}

.pieChartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .pieChartHeader {
        @include common.centreFlexRow;
        height: 30px;
        margin-bottom: 5px;
        .pieChartTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            margin-right: 10px;
        }
        .pieChartDescription {
            @include common.centreFlexRow;
            margin-top: 2.5px;
            margin-right: 5px;
        }
    }
    .pieChartIcon {
        @include common.centreFlexRow;
        color: theme.$color-primary-night;
        position: absolute;
        border-radius: 50%;
        margin-top: 35px;
    }
}

.jurisdictionChartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .jurisdictionChartHeader {
        @include common.centreFlexRow;
        position: relative;
        height: 30px;
        margin-bottom: 5px;
        width: 100%;
        .jurisdictionChartTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            margin-right: 10px;
        }
        .jurisdictionChartDescription {
            @include common.centreFlexRow;
            margin-top: 2.5px;
            margin-right: 5px;
        }
        .expandIcon {
            @include common.centreFlexRow;
            position: absolute;
            z-index: 10;
            right: 0;
            top: 0;
            padding: 5px;
        }
    }
    .jurisdictionChartDropdownWrapper {
        @include common.flexRow;
        width: 100%;
        justify-content: space-between;
        .jurisdictionChartZoomWrapper {
            @include common.flexRow;
            margin-top: 5px;
            align-items: center;
        }
        .jurisdictionChartDropdown {
            @include common.flexRow;
            align-self: flex-end;
            width: 45%;
            margin-top: 5px;
        }
    }
}

.jurisdictionChart {
    @include common.centreFlexColumn;
    height: 100%;
    width: 100%;
}

.jurisdictionEntityModalWrapper {
    @include common.flexColumn;
    width: 70vw;
    height: 50vh;
    .jurisdictionEntityModalInformationWrapper {
        @include common.flexRow;
        height: calc(100% - 61px);
        margin-bottom: 5px;
        .jurisdictionEntityModalLeft {
            @include common.flexColumn;
            width: calc(50% - 27px);
            height: calc(100% - 12px);
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            margin-right: 5px;
            padding: 5px 10px;
            .jurisdictionEntityModalEntity {
                @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
                width: 100%;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                border-radius: 5px;
                padding: 10px;
                margin-bottom: 5px;
                &:hover {
                    color: theme.$color-french;
                    border-color: theme.$color-french;
                }
            }
            .selectedJurisdictionEntity {
                background-color: theme.$color-french;
                color: theme.$color-white;
                font-weight: theme.$font-bold;
                &:hover {
                    color: theme.$color-white;
                    border-color: theme.$color-white;
                }
            }
            .entityNameWrapper {
                @include common.centreFlexRow;
                width: 100%;
                .entityName {
                    width: calc(100% - 20px);
                }
            }
        }
        .jurisdictionEntityModalRight {
            @include common.flexColumn;
            height: calc(100% - 12px);
            width: calc(50% - 27px);
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            padding: 5px 10px;
            margin-left: 5px;
            .documentsWrapper {
                @include common.flexColumn;
                height: calc(100% - 40px);
                margin-bottom: 10px;
                .jurisdictionEntityModalEntityDocument {
                    @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
                    @include common.flexColumn;
                    width: 100%;
                    font-family: theme.$font-family;
                    font-weight: theme.$font-medium;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-bottom: 5px;
                    &:hover {
                        color: theme.$color-french;
                        border-color: theme.$color-french;
                    }
                    .documentDescription {
                        @include common.overflowText;
                        width: 100%;
                        text-align: left;
                        font-weight: theme.$font-bold;
                        font-size: 16px;
                    }
                    .documentName {
                        @include common.overflowText;
                        width: 100%;
                        text-align: left;
                    }
                    .entitiesWrapper {
                        @include common.spaceBetweenFlexRow;
                        width: 100%;
                        .entityAName {
                            @include common.overflowText;
                            width: calc(50% - 3px);
                            margin-right: 6px;
                            text-align: left;
                        }
                        .entityBName {
                            @include common.overflowText;
                            width: calc(50% - 3px);
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

.expandIcon {
    transform: rotate(45deg);
    display: flex;
    align-self: center;
}

.documentRiskToleranceChartWrapper {
    @include common.centreFlexColumn;
    position: relative;
    .documentRiskToleranceChartHeader {
        @include common.centreFlexRow;
        align-items: center;
        height: 40px;
        margin-bottom: 10px;
        width: 100%;
        .centreGroup {
            @include common.centreFlexRow;
            position: fixed;
            .documentRiskToleranceChartTitle {
                font-size: 20px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
                margin-right: 10px;
            }
            .documentRiskToleranceChartDescription {
                margin-right: 5px;
            }
            .toggleTableIcon {
                display: flex;
                margin-left: 5px;
                align-self: center;
            }
        }
        .toggleDataButton {
            display: flex;
            position: fixed;
            right: 40px;
        }
    }
    .chartContents {
        height: calc(100% - 50px);
        width: 100%;
        justify-content: center;
        @include common.flexRow;
        .chartAndMeta {
            @include common.flexColumn;
            align-content: center;
        }
    }
}

.gaugeChartWrapper {
    @include common.flexColumn;
    justify-content: center;
    position: relative;
    .acceptableLabel {
        font-size: theme.$font-size-h6;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        position: absolute;
        top: -10px;
        width: 100%;
    }
    .highlyDesirableLabel {
        font-size: theme.$font-size-h6;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        width: fit-content;
        position: absolute;
        left: 10%;
    }
    .ofSeriousConcernLabel {
        font-size: theme.$font-size-h6;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        position: absolute;
        right: 10%;
    }
    .overallRiskLevel {
        font-size: theme.$font-size-h2;
        font-weight: theme.$font-bold;
        height: 25px;
    }
    .overallRiskLevelFullScreen {
        font-size: theme.$font-size-h1;
        font-weight: theme.$font-bold;
        height: 33px;
    }
    .totalDocumentsWrapper {
        display: flex;
        height: 25px;
        justify-content: center;
        align-items: center;
        font-size: theme.$font-size-h5;
        font-weight: theme.$font-family;
        color: theme.$color-primary-night;
    }
}

.documentMeta {
    margin-top: 10px;
    .documentNameWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 10px;
        .documentName {
            max-width: 80%;
        }
    }
    .documentDescriptionWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
    }
    .documentDetailLabel {
        display: flex;
        justify-content: flex-start;
        font-size: theme.$font-size-h3;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        margin-right: 10px;
        min-width: fit-content;
        text-decoration: underline;
    }
    .documentDetail {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        font-size: theme.$font-size-h4;
        font-weight: theme.$font-family;
        color: theme.$color-primary-night;
    }
    .documentEntitiesWrapper {
        @include common.flexRow;
        margin-bottom: 10px;
        .entityWrapper {
            @include common.flexRow;
            align-items: center;
            width: 50%;
            .entityName {
                max-width: 80%;
            }
        }
    }
}

.tableWrapper {
    margin-left: 10px;
}

.riskFieldLabelWrapper {
    @include common.flexRow;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    .riskFieldLabel {
        font-size: 20px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        margin-right: 10px;
    }
}

.breakdownWrapper {
    margin-left: 10px;
    .riskFieldWrapper {
        margin-bottom: 10px;
        @include common.flexRow;
        border-radius: 5px;
        height: 100%;
        box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
        .fieldAndWeightWrapper {
            @include common.flexColumn;
            height: 100%;
            width: 95%;
            .fieldWrapper {
                @include common.flexColumn;
                width: calc(100% - 20px);
                margin-bottom: 10px;
                margin-top: 10px;
                .fieldLabel {
                    width: 90%;
                    color: theme.$color-primary-night;
                    font-size: theme.$font-size-h3;
                    font-weight: theme.$font-medium;
                    text-decoration: underline;
                    text-align: left;
                    margin-left: 5px;
                    margin-bottom: 5px;
                }
                .documentAnswerWrapper {
                    @include common.flexRow;
                    .fieldAnswerWrapper {
                        @include common.flexRow;
                        align-items: center;
                        margin-left: 5px;
                        width: 100%;
                        color: theme.$color-primary-night;
                        font-size: theme.$font-size-h4;
                        font-weight: theme.$font-family;
                        .fieldAnswerLabel {
                            font-weight: theme.$font-bold;
                            min-width: fit-content;
                        }
                        .rowLabel {
                            text-align: left;
                            margin-left: 5px;
                        }
                        .fieldAnswer {
                            text-align: left;
                            margin-left: 5px;
                        }
                        .fieldAnswerNoValue {
                            color: theme.$color-grey;
                            text-align: left;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        .riskLevel {
            min-height: 100%;
            width: 5%;
        }
    }
}

.datasetRiskToleranceWrapper {
    width: 50%;
    margin-left: 10px;
    .riskToleranceDatasetWrapper {
        @include common.flexColumn;
        height: 90%;
        width: 100%;
        color: theme.$color-primary-night;
        .riskToleranceDatasetHeader {
            text-align: left;
            cursor: default !important;
            font-size: 22px;
            font-weight: theme.$font-bold;
        }
        .contentWrapper {
            @include common.flexRow;
            height: calc(100% - 80px);
            width: 100%;
            border-top: 1px solid theme.$color-grey;
            .fieldsWrapper {
                @include common.flexColumn;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                padding-right: 10px;
                .datasetWrapper {
                    @include common.flexColumn;
                    padding: 5px 0;
                    color: theme.$color-primary-night;
                    .datasetHeader {
                        @include common.flexRow;
                        align-items: center;
                        width: 100%;
                        font-weight: 600;
                        cursor: pointer;
                        .datasetOpenIcon {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

.analyticsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    color: theme.$color-primary-night;
    .analyticsHeader {
        @include common.spaceBetweenFlexRow;
        padding: 0 20px;
        margin-bottom: 10px;
        .analyticsTitle {
            font-size: 22px;
            font-weight: theme.$font-bold;
        }
    }
    .analyticsChartsWrapper {
        @include common.flexRow;
        height: 100%;
        padding: 0 20px;
        .leftChartsWrapper {
            @include common.flexColumn;
            width: calc(60% - 20px);
            margin-right: 20px;
            height: 100%;
            .wideChartWrapper {
                @include common.flexColumn;
                height: 50%;
                width: 100%;
            }
        }
        .rightChartsWrapper {
            @include common.flexColumn;
            width: 40%;
        }
        .chartWrapper {
            @include common.centreFlexRow;
            border-radius: 5px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            background-color: theme.$color-lightest-grey;
            &:hover {
                color: theme.$color-french;
                box-shadow: 1px 1px 5px theme.$color-french;
            }
            .spreadChartWrapper {
                @include common.flexColumn;
                height: calc(100% - 20px);
                padding: 10px;
            }
        }
    }
}

.documentEntitySpreadWrapper {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    .expandIcon {
        @include common.centreFlexRow;
        position: absolute;
        z-index: 10;
        right: 0;
        top: 0;
        padding: 10px;
    }
}

.allRiskToleranceChartsWrapper {
    @include common.flexColumn;
    .allRiskToleranceChartsHeader {
        font-size: 20px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        height: 30px;
        width: 100%;
        .allRiskToleranceCharts {
            @include common.flexColumn;
            height: calc(100% - 30px);
        }
    }
    .minifiedRiskToleranceChartWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: flex-start;
        position: relative;
        .riskChart {
            @include common.flexColumn;
            width: 100%;
            height: 100%;
            .gaugeChartWrapper {
                @include common.flexColumn;
                width: 100%;
                height: calc(100% - 50px);
            }
            .overallRiskLevel {
                font-size: theme.$font-size-h2;
                font-weight: theme.$font-bold;
                height: 25px;
            }
            .totalDocumentsWrapper {
                display: flex;
                height: 25px;
                justify-content: center;
                align-items: center;
                font-size: theme.$font-size-h5;
                font-weight: theme.$font-family;
                color: theme.$color-primary-night;
            }
        }
        .chartHeader {
            @include common.flexRow;
            align-items: center;
            position: absolute;
            z-index: 10;
            right: 0;
            .chartTitle {
                font-size: 16px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
                margin-right: 5px;
            }
            .chartDescription {
                margin-right: 5px;
            }
        }
    }
}

.wholePageChartWrapper {
    @include common.centreFlexRow;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
    background-color: theme.$color-lightest-grey;
}

:export {
    french: theme.$color-french;
    ocean: theme.$color-ocean;
    amethyst: theme.$color-amethyst;
    primary: theme.$color-primary-night;
    primaryBorder: theme.$color-primary-night-hover;
    aqua: theme.$color-aqua;
    red: theme.$color-red;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    yellowGreen: theme.$color-yellow-green;
    green: theme.$color-mandatory;
    white: theme.$color-white;
    lightestGrey: theme.$color-lightest-grey;
    grey: theme.$color-grey;
    lightGrey: theme.$color-light-grey;
    fontFamily: theme.$font-family;
    heatMap0: #3945ad;
    heatMap1: #398cad;
    heatMap2: #91c955;
    heatMap3: #d6da67;
    heatMap4: #debb2e;
    heatMap5: #f4cc3a;
    heatMap6: #f4a73a;
    heatMap7: #f4883a;
    heatMap8: #f4843a;
    heatMap9: #dd512d;
    heatMap10: #bc1d15;
    white: theme.$color-white;
    watchlistBorder: theme.$color-red-spectrum-1;
    watchlistBorderSelected: theme.$color-red-spectrum-3;
}
