@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin tableHeader {
    width: calc(100% - 10px);
    padding: 5px;
    font-weight: theme.$font-medium;
    background-color: theme.$color-french;
    color: theme.$color-white;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
}

.clientsWrapper {
    @include common.tablePageWrapper;
    .clientsTitleContainer {
        @include common.tablePageTitleContainer;
        .clientsTitle {
            @include common.tablePageTitle;
        }
    }
}

.filterHeader {
    font-weight: 600;
    font-size: 14px;
}

.clientWrapper {
    @include common.flexColumn;
    width: 70vw;
    max-height: 70vh;
    color: theme.$color-primary-night;
    .clientNameWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        .clientNameTitle {
            font-weight: theme.$font-medium;
            margin-right: 5px;
            width: 120px;
        }
        .clientName {
            @include common.flexRow;
            align-items: center;
            width: calc(100% - 125px);
        }
    }
    .clientDescriptionWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        .clientDescriptionTitle {
            font-weight: theme.$font-medium;
            margin-right: 5px;
            width: 120px;
        }
        .clientDescription {
            @include common.flexRow;
            align-items: center;
            width: calc(100% - 125px);
        }
    }
    .demoClientWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        margin: 5px 0 10px 0;
        .demoClientTitle {
            font-weight: theme.$font-medium;
            margin-right: 5px;
        }
        .demoClient {
            @include common.flexRow;
            align-items: center;
        }
    }
    .usersWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        .usersTitleWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            cursor: pointer;
            .usersTitle {
                font-weight: theme.$font-medium;
                margin-right: 5px;
            }
            .usersNote {
                font-size: theme.$font-size-h7;
                margin-left: 5px;
                font-weight: theme.$font-medium;
                color: theme.$color-red;
            }
        }
        .usersHeader {
            @include common.flexRow;
            align-items: center;
            @include tableHeader;
        }
        .usersTable {
            max-height: 250px;
            min-height: 60px;
            border: 1px solid theme.$color-grey;
            border-radius: 0 0 5px 5px;
            .userRow {
                @include common.flexRow;
                width: calc(100% - 10px);
                align-items: center;
                padding: 5px;
                font-size: 14px;
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
                .username {
                    font-weight: theme.$font-medium;
                    width: 50%;
                }
                .role {
                    width: 45%;
                }
                .userActionButton {
                    @include common.centreFlexRow;
                    width: 5%;
                }
            }
        }
    }
    .disabled {
        cursor: not-allowed;
    }
    .clientFeaturePermissionOptionsWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        .featurePermissionHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            cursor: pointer;
            .featurePermissionOptionsTitle {
                font-weight: theme.$font-medium;
            }
        }
        .featurePermissionOptionsHeader {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            @include tableHeader;
        }
        .featurePermissionOptionsTable {
            max-height: 250px;
            border: 1px solid theme.$color-grey;
            border-radius: 0 0 5px 5px;
            .featurePermissionOptionRow {
                @include common.spaceBetweenFlexRow;
                padding: 5px;
                font-size: 14px;
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
                .featurePermissionInfo {
                    @include common.spaceBetweenFlexRow;
                    width: calc(100% - 46px);
                    margin-right: 10px;
                    font-weight: theme.$font-medium;
                }
            }
        }
    }
    .datasetOptionsWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        .datasetsHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            cursor: pointer;
            .datasetOptionsTitle {
                font-weight: theme.$font-medium;
            }
        }
        .datasetOptionsHeader {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            @include tableHeader;
        }
        .datasetOptionsTable {
            max-height: 250px;
            border: 1px solid theme.$color-grey;
            border-radius: 0 0 5px 5px;
            .datasetOptionRow {
                @include common.spaceBetweenFlexRow;
                padding: 5px;
                font-size: 14px;
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
                .documentName {
                    font-weight: theme.$font-medium;
                }
            }
        }
    }
    .opinionsWrapper {
        @include common.flexColumn;
        margin-bottom: 10px;
        .opinionsHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            cursor: pointer;
            .opinionsTitle {
                font-weight: theme.$font-medium;
            }
        }
        .opinionsTableHeader {
            @include common.flexRow;
            align-items: center;
            @include tableHeader;
            .opinionHeaderCell {
                @include common.flexRow;
                align-items: center;
            }
        }
        .opinionsTable {
            max-height: 250px;
            min-height: 60px;
            border: 1px solid theme.$color-grey;
            border-radius: 0 0 5px 5px;
            .opinionTableRow {
                @include common.spaceBetweenFlexRow;
                padding: 5px;
                font-size: 14px;
                &:last-of-type {
                    border-radius: 0 0 5px 5px;
                }
                .jurisdiction,
                .focus {
                    width: 20%;
                    font-weight: theme.$font-medium;
                }
                .toggleCell {
                    @include common.centreFlexRow;
                    width: 15%;
                }
            }
        }
    }
}

.sectionOpenIcon {
    margin-right: 10px;
}

.tableFilterInput {
    font-family: theme.$font-family;
    font-size: 13px;
    outline: none;
    padding: 3px;
    border: 1px solid theme.$color-primary-night;
    color: theme.$color-primary-night;
    border-radius: 5px;
    &:focus {
        border: 1px solid theme.$color-french;
    }
}

.editClientButton {
    @include common.tableIconButton;
}

.documentPageLimitsWrapper {
    @include common.flexColumn;
    width: 100%;
    .documentPageLimitsHeader {
        @include common.spaceBetweenFlexRow;
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
        .documentPageLimitsBar {
            @include common.flexRow;
            width: 50%;
            border: 1px solid theme.$color-light-grey;
            border-radius: 5px;
            height: 10px;
            cursor: pointer;
            .freeUsageBar {
                height: 100%;
            }
            .paidBar {
                height: 100%;
            }
        }
    }
    .documentPageLimits {
        @include common.flexRow;
        width: 100%;
        margin-bottom: 10px;
        .numberInputWrapper {
            @include common.flexRow;
            width: 50%;
        }
    }
    .freePagesWrapper {
        @include common.flexColumn;
        width: 50%;
        .freePagesHeader {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            margin-bottom: 5px;
            .freePagesTitle {
                font-weight: theme.$font-medium;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
    .maxPagesWrapper {
        @include common.flexColumn;
        width: calc(50% - 10px);
        margin-right: 10px;
        .maxPagesHeader {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            margin-bottom: 5px;
            .maxPagesTitle {
                font-weight: theme.$font-medium;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
}

:export {
    grey: theme.$color-light-grey;
    lightestGrey: theme.$color-lightest-grey;
    red: theme.$color-red;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    green: theme.$color-mandatory;
    white: theme.$color-white;
}
