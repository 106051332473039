@use '../../../../../styles/theme' as theme;
@use '../../../../../styles/common' as common;

.mlTableDatasetQueryWrapper {
    @include common.flexRow;
    align-items: flex-start;
    align-items: center;
    height: fit-content;
    margin: 5px;
    font-weight: theme.$font-family;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h6;
    padding: 0 8px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    .mlTableDatasetQuestion {
        width: fit-content;
        max-width: calc(65% - 10px);
        margin-right: 10px;
        font-weight: 500;
        padding: 5px 0;
        color: theme.$color-primary-night;
    }
}

.mlFormDatasetQueryWrapper {
    @include common.flexRow;
    align-items: flex-start;
    align-items: center;
    height: fit-content;
    font-weight: theme.$font-family;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h5;
    .aiAnswerLabel {
        min-width: fit-content;
        margin-right: 10px;
        font-weight: 500;
        padding: 5px 0;
        color: theme.$color-mandatory;
    }
}
