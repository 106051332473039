@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.datasetWrapper {
    @include common.centreFlexColumn;
    height: 100%;
    .datasetInstanceWrapper {
        @include common.flexColumn;
        .datasetInstanceHeader {
            @include common.spaceBetweenFlexRow;
            margin-bottom: 10px;
            border-top: 1px solid rgba(theme.$color-grey, 0.5);
            padding-top: 10px;
            height: 48px;
            .datasetInstanceTitle {
                width: calc(60% - 10px);
                margin-left: 10px;
                color: theme.$color-primary-night;
                font-size: 20px;
                font-weight: theme.$font-medium;
                text-decoration: underline;
                text-align: right;
            }
            .datasetInstanceSearchWrapper {
                @include common.flexRow;
                width: 40%;
                height: 100%;
                .fieldSearchWrapper {
                    @include common.flexRow;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .datasetInstanceButtonWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 10px;
            height: 48px;
            .unsavedDatasetWarning {
                @include common.flexRow;
                align-items: center;
                margin-left: 0;
                margin-right: auto;
                color: theme.$color-red;
                min-width: 160px;
                .unsavedDatasetWarningTitle {
                    margin-left: 5px;
                }
            }
        }
    }
}

.annexDefinitionWrapper {
    @include common.flexColumn;
    width: calc(100% - 22px);
    padding: 10px 10px 5px 10px;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    height: fit-content;
    margin-bottom: 10px;
    .annexDefinitionLabelWrapper {
        @include common.flexRow;
        align-items: center;
        .labelIconWrapper {
            @include common.centreFlexRow;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .fieldLabelInput {
            font-family: theme.$font-family;
            outline: none;
            color: theme.$color-primary-night;
            background-color: theme.$color-white;
            border: none;
            font-size: 20px;
            width: calc(100% - 30px);
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px dotted theme.$color-primary-night;
        }
    }
    .buttonWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
        .leftButtonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
        }
        .rightButtonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.spinnerWrapper {
    @include common.centreFlexColumn;
    width: 100%;
    height: 100%;
    .spinnerLabel {
        margin-top: 10px;
    }
}

.modalWrapper {
    @include common.flexColumn;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 400px;
    .modalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .modalWarningTitle {
            margin-left: 10px;
        }
    }
    .unsavedChanges {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .modalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
}

.unlinkedWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    color: theme.$color-primary-night;
    .unlinkedHeader {
        @include common.flexRow;
        margin-bottom: 50px;
        color: theme.$color-red;
        .unlinkedTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            margin-left: 10px;
        }
    }
    .entityLinkWrapper,
    .documentNameWrapper {
        @include common.flexColumn;
        width: 100%;
        .entityLinkHeader,
        .documentNameHeader {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin: 10px 0;
            align-self: flex-start;
        }
    }
    .entityLinkDropdownWrapper {
        @include common.spaceBetweenFlexRow;
        margin-bottom: 20px;
        .entityLinkDropdownA {
            width: 100%;
            padding-right: 10px;
        }
        .entityLinkDropdownB {
            width: 100%;
            padding-left: 10px;
        }
    }
    .documentNameDropdown {
        margin-bottom: 20px;
    }
}

.uploadDocumentWrapper {
    @include common.flexColumn;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 480px;
    .uploadDocumentHeader {
        @include common.spaceBetweenFlexRow;
        font-size: 20px;
        font-weight: theme.$font-bold;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid theme.$color-grey;
    }
    .uploadDocumentDescriptionLabel {
        margin-bottom: 5px;
        font-weight: theme.$font-medium;
        text-decoration: underline;
        font-size: 16px;
    }
    .dropzone {
        @include common.centreFlexRow;
        border: 2px dashed theme.$color-french;
        padding: 40px 20px;
        color: theme.$color-primary-night;
        cursor: pointer;
        width: 420px;
        &:hover {
            color: theme.$color-french;
        }
    }
    .dropzoneDisabled {
        border: 2px dashed theme.$color-grey;
        color: theme.$color-grey;
    }
    .dropzonePlaceholder {
        text-align: center;
    }
    .executedDateWrapper,
    .selectedDocumentWrapper {
        @include common.flexColumn;
        width: 460px;
        margin-top: 10px;
        .executedDateHeader,
        .selectedDocumentHeader {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            color: theme.$color-primary-night;
        }
        .selectedDocument {
            @include common.overflowText;
            color: theme.$color-primary-night;
        }
    }
}

.secondaryDocumentWrapper {
    @include common.centreFlexRow;
    width: 50px;
    height: 38px;
    margin: 5px 2px;
    background-color: rgba(theme.$color-french, 0.5);
    border-radius: 5px;
}

.iconButtonWrapper,
.exportButtonWrapper {
    @include common.centreFlexRow;
    width: 50px;
    height: 38px;
    margin: 5px 2px;
    background-color: theme.$color-french;
    border-radius: 5px;
    outline: none;
    border: none;
    color: theme.$color-white;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: theme.$color-white;
    }
}
.linkedDocumentModalWrapper {
    @include common.flexColumn;
    height: calc(100% - 56px);
    .linkedDocumentButton {
        @include common.transparentButton;
        @include common.flexColumn;
        border-radius: 5px;
        width: calc(100% - 2px);
        padding: 5px;
        border: 1px solid theme.$color-grey;
        margin: 5px 0;
        &:hover {
            cursor: pointer;
            box-shadow: 1px 1px 3px theme.$color-french;
        }
        .documentNameDetails {
            @include common.flexColumn;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 3px;
            padding-bottom: 3px;
            border-bottom: 1px solid theme.$color-light-grey;
            .documentName {
                font-size: 16px;
                font-weight: 800;
            }
            .agreementType {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .documentDescriptionWrapper {
            @include common.flexRow;
            width: 100%;
            .documentDescription {
                font-weight: 600;
                font-size: 14px;
            }
        }
        .partyDetails {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            width: 100%;
        }
        .partyLabels {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            width: 100%;
            font-size: 10px;
            font-weight: 600;
            margin-top: 5px;
            text-decoration: underline;
        }
        .splitWidthLeftWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
        }
        .splitWidthRightWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: calc(50% - 10px);
            margin-left: 10px;
        }
        .entityName {
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.executedDateWrapper {
    @include common.flexColumn;
    margin-top: 10px;
    .executedDateHeader {
        font-size: 16px;
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
    }
}

.confirmSaveModalWrapper {
    width: 500px;
    margin-bottom: 10px;
    padding: 5px;
    .newDocumentWrapper {
        margin-bottom: 10px;
        padding: 5px 0;
        .emptyDocumentPlaceholder {
            font-weight: theme.$font-medium;
            color: theme.$color-red;
        }
        .newDocumentHeader {
            @include common.flexRow;
            align-items: center;
            .newDocumentTitle {
                @include common.overflowText;
                margin-left: 10px;
                font-weight: theme.$font-medium;
            }
        }
    }
    .updatedInstanceWrapper {
        margin-bottom: 10px;
        padding: 5px 0;
        .updatedInstanceWarning {
            font-weight: theme.$font-medium;
            padding: 5px 0;
        }
        .updatedInstanceInfo {
            padding: 5px 0;
        }
    }
    .completeWarningWrapper {
        @include common.spaceBetweenFlexRow;
        margin-top: 10px;
        margin-bottom: 4px;
        .completeWarningTitle {
            font-weight: theme.$font-medium;
        }
    }
    .completeWarningMessage {
        font-weight: theme.$font-medium;
        font-size: 14px;
        color: theme.$color-red;
    }
}

.spinnerWrapper {
    height: 100%;
    width: 100%;
}

.clauseInput {
    @include common.labelModalTextArea;
}

.clauseInputTitle {
    font-size: 12px;
    font-weight: theme.$font-medium;
    margin-top: -5px;
}

.clauseButton {
    @include common.transparentButton(inherit, 1px solid);
    @include common.centreFlexRow;
    border-radius: 50%;
    font-weight: theme.$font-bold;
    font-size: 12px;
    min-width: 13px;
    max-height: 13px;
    padding: 0;
    &:not(:last-child) {
        margin-right: 5px;
    }
    &:hover {
        color: theme.$color-french;
        margin-left: 0;
        cursor: pointer;
    }
}

.disabledClauseButton {
    &:hover {
        color: theme.$color-primary-night;
    }
}

.linkedDocumentModalWrapper {
    @include common.flexColumn;
    align-items: flex-start;
}

.cdmPreviewWrapper {
    padding: 10px 10px 5px 10px;
    .cdmPreview {
        margin: 0 15px 5px 15px;
        background-color: theme.$color-dark-charcoal;
        color: theme.$color-white;
    }
    .buttonWrapper {
        @include common.flexRow;
        justify-content: flex-end;
    }
}

.preExecutionLabel {
    height: 14px;
    width: calc(100% - 70px);
    margin: 5px 35px 35px 35px;
    font-weight: theme.$font-bold;
    font-size: theme.$font-size-h2;
    color: theme.$color-amber;
}

.annexConfigurationWrapper {
    @include common.flexColumn;
    padding: 10px;
    .annexConfiguration {
        @include common.flexColumn;
        .buttonWrapper {
            @include common.spaceBetweenFlexRow;
            .leftButtonWrapper {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-start;
            }
            .rightButtonWrapper {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

.schedulePageInfo {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .schedulePageWrapper {
        @include common.flexColumn;
        .schedulePageLabelWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            .schedulePageLabel {
                font-size: 16px;
                font-weight: theme.$font-medium;
                text-decoration: underline;
                color: theme.$color-primary-night;
                margin-right: 5px;
            }
            .schedulePageTooltip {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .schedulePageInput {
            @include common.flexRow;
        }
    }
}

.fuzzyFieldMatchModalWrapper {
    @include common.flexColumn;
    .fuzzyMatchesWrapper {
        @include common.flexColumn;
        width: 100%;
        .fuzzyMatchWrapper {
            @include common.flexRow;
            align-items: center;
            border-radius: 5px;
            padding: 5px;
            width: calc(100% - 10px);
            cursor: pointer;
            .fuzzyMatchIconWrapper {
                @include common.centreFlexRow;
                margin-right: 5px;
            }
            .fuzzyMatch {
                @include common.flexColumn;
                width: calc(100% - 25px);
                .sectionLabel {
                    @include common.flexRow;
                    width: 100%;
                    border-bottom: 1px solid theme.$color-light-grey;
                }
                .fuzzyMatchTitle {
                    width: 100%;
                    .boldHighlight {
                        font-weight: theme.$font-bold;
                    }
                }
                &:not(:last-of-type) {
                    border-bottom: 1px dotted theme.$color-lightest-grey;
                }
            }
        }
        .selectedFuzzyMatch {
            background-color: rgba(theme.$color-french, 0.2);
        }
    }
}

:export {
    green: theme.$color-mandatory;
    red: theme.$color-red;
    grey: theme.$color-grey;
    amber: theme.$color-amber;
    lightGrey: theme.$color-light-grey;
    primary: theme.$color-primary-night;
    lightGreen: theme.$color-green;
}
