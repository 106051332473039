@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.playbookHistoryWrapper {
    display: flex;
    height: 70px;
    width: calc(100% - 200px);
    margin: 0 auto;
    align-items: center;
}

.marker {
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    font-size: 14px;
    overflow: visible;
    font-weight: theme.$font-medium;
    white-space: nowrap;
}

.iconWrapper {
    @include common.flexRow;
}

.overlayWrapper {
    @include common.flexColumn;
    word-wrap: break-word;
    .playbookTitle {
        color: theme.$color-primary-night;
        padding-bottom: 3px;
        font-weight: theme.$font-bold;
        font-size: 14px;
    }
    .playbookAuthor,
    .playbookCreatedDate,
    .playbookVersion {
        padding-top: 4px;
        font-weight: theme.$font-medium;
    }
}

:export {
    french: theme.$color-french;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.3);
    restatementGrey: theme.$color-grey;
}
