@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.registrationWrapper {
    @include common.flexColumn;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to top right,
        theme.$color-aqua,
        theme.$color-ocean 45%
    );
    .logoWrapper {
        margin-top: 10vh;
    }
    .registrationInfo {
        width: 50%;
        font-size: 24px;
        color: theme.$color-white;
        font-weight: theme.$font-medium;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .registrationTitle {
        font-size: 24px;
        color: theme.$color-white;
        font-weight: theme.$font-medium;
        margin-top: 30px;
    }
    .registrationInput {
        width: 250px;
        padding: 15px;
        margin: 5px;
        border: none;
        color: theme.$color-amethyst;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        border-radius: 5px;
        &:focus {
            outline: none;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.registrationButtonsWrapper {
    @include common.flexRow;
    width: 350px;
    margin-top: 30px;
    justify-content: space-between;
    .registrationButton {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 150px;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: theme.$color-amethyst;
        cursor: pointer;
        .registrationButtonText {
            color: theme.$color-white;
            font-size: 18px;
            font-weight: theme.$font-medium;
        }
    }
}
