@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.agencyECSWrapper {
    @include common.spaceBetweenFlexRow;
    width: calc(100% - 25px);
    .fieldLabelInput {
        font-family: theme.$font-family;
        outline: none;
        color: theme.$color-primary-night;
        background-color: theme.$color-white;
        border: none;
        font-size: 20px;
        max-width: calc(60% - 10px);
        margin-right: 10px;
    }
    .rightECSWrapper {
        @include common.flexRow;
        align-items: center;
        width: 40%;
        flex: 1;
        .principlesWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            margin-right: 10px;
        }
    }
}

.textField {
    @include common.inputFieldInstance;
    resize: none;
    height: 26px;
    flex-grow: 1;
    width: calc(100% - 12px);
}

.longTextField {
    @include common.inputFieldInstance;
    resize: none;
    width: calc(100% - 12px);
    line-height: 1.2;
}

.dateFieldWrapper {
    max-width: 230px;
}

.dateField {
    @include common.inputFieldInstance;
    height: 26px;
    background: inherit;
    padding: 5px;
    max-width: 230px;
    width: calc(100% - 12px);
    &::-webkit-input-placeholder {
        color: theme.$color-primary-night;
    }
}

.dateFieldWithClauseLabel {
    width: calc(100% - 30px);
    margin-right: 5px;
}

.numberField {
    @include common.inputFieldInstance;
    width: calc(100% - 12px);
    height: 26px;
}

.numberFieldWithClauseLabel {
    max-width: 130px;
    margin-right: 5px;
}

.checkboxFieldWrapper {
    display: flex;
    height: 23px;
    align-items: flex-end;
}

.tableCheckBoxFieldWrapper {
    height: 20px;
}

.dropdownFieldWrapper {
    width: 100%;
    max-width: 400px;
}

.fieldsWrapper {
    @include common.flexColumn;
    height: calc(50vh - 56px);
}

.datasetLinkField {
    @include common.centreFlexRow;
    height: calc(100% - 5px);
    width: 140px;
    .datasetLinkButton {
        @include common.transparentButton;
        border: 1px solid theme.$color-primary-night;
        padding: 3px;
        border-radius: 5px;
        font-family: theme.$font-family;
        font-size: 14px;
        flex: 1;
        height: 30px;
        width: 140px;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
            border: 1px solid theme.$color-french;
        }
        &:disabled {
            color: theme.$color-primary-night;
            background-color: rgba(theme.$color-grey, 0.05);
            border: 1px solid rgba(theme.$color-grey, 0.5);
            box-shadow: none;
            cursor: not-allowed;
        }
    }
    .removeDatasetLink {
        @include common.transparentButton;
        &:hover {
            color: theme.$color-red;
        }
        &:disabled {
            cursor: not-allowed;
            color: rgba(theme.$color-grey, 0.5);
        }
    }
}

.tableDatasetLink {
    margin: auto;
}

.wizardField {
    @include common.centreFlexRow;
    height: calc(100% - 5px);
    width: calc(100% - 10px);
    .wizardButton {
        @include common.transparentButton;
        border: 1px solid theme.$color-primary-night;
        padding: 3px;
        border-radius: 5px;
        font-family: theme.$font-family;
        font-size: 14px;
        height: 30px;
        width: 140px;
        margin-right: 10px;
        flex: 1;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
            border: 1px solid theme.$color-french;
        }
        &:disabled {
            color: theme.$color-primary-night;
            background-color: rgba(theme.$color-grey, 0.05);
            border: 1px solid rgba(theme.$color-grey, 0.5);
            box-shadow: none;
            cursor: not-allowed;
        }
    }
    .informationTooltipIcon {
        @include common.centreFlexRow;
        border-radius: 50%;
        border-width: 1px;
        border-style: solid;
        color: inherit;
        background-color: transparent;
        font-weight: theme.$font-bold;
        height: 14px;
        width: 14px;
        min-height: 14px;
        min-width: 14px;
        font-size: 13px;
    }
}

.wizardModal {
    @include common.flexColumn;
    margin: auto;
    padding: 10px;
    background-color: theme.$color-white;
    box-shadow: 1px 1px 5px theme.$color-french;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    .wizardWrapper {
        @include common.flexColumn;
        width: calc(50vw - 80px);
        height: 50vh;
        .singleFieldWrapper {
            @include common.flexColumn;
            margin: 10px 0;
            .fieldTitleWrapper {
                @include common.flexRow;
                margin: 0 0 10px 5px;
                align-items: center;
                .fieldLabel {
                    @include common.overflowText;
                    margin-right: 10px;
                }
            }
        }
    }
}

.timeField {
    @include common.flexRow;
    width: 100%;
    max-width: 400px;
    .timeValue {
        @include common.inputFieldInstance;
        margin-right: 10px;
        height: 26px;
        width: 80px;
    }
}

.citySelectWrapper {
    width: calc(100% - 52px);
    max-width: 300px;
}

.wysiwygWrapper {
    @include common.flexColumn;
    width: 100%;
    .fieldOpenWrapper {
        margin-bottom: 5px;
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
        }
    }
}

.calculatorWrapper {
    @include common.flexColumn;
    .fieldOpenWrapper {
        @include common.flexRow;
        .readOnlyCalculatorField {
            width: calc(100% - 40px);
        }
    }
}

.tableCalculatorWrapper {
    @include common.flexRow;
    width: 100%;
    max-height: 200px;
    align-content: center;
}

.inputAndIconWrapper {
    @include common.flexRow;
}

.calculatorModalWrapper {
    width: 100%;
}

.calculatorModalButtons {
    @include common.flexRow;
    width: 100%;
    justify-content: flex-start;
}

.modal {
    @include common.centreFlexColumn;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: fit-content;
}

.readOnlyCalculatorModal {
    @include common.centreFlexColumn;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .calculatorReadOnlyModalWrapper {
        @include common.flexColumn;
        justify-content: space-between;
        height: 60vh;
        width: 60vw;
        padding: 10px;
        .closeModalWrapper {
            position: relative;
            bottom: 10;
        }
    }
}

.updatedInputField,
.updatedDatasetLink,
.updatedWizardButton {
    border: 1px solid theme.$color-amethyst !important;
}

.newInputField,
.newDatasetLink,
.newWizardButton {
    border: 1px solid theme.$color-green !important;
    border-radius: 5px;
}

.aiInputField {
    border: 1px solid theme.$color-gold !important;
    border-radius: 5px;
}

.aiCorrectedInputField {
    border: 1px solid theme.$color-amber !important;
    border-radius: 5px;
}

.withClauseLabel {
    margin-right: 5px;
}

.mlQueryWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 10px;
    .mlQuery {
        font-weight: 600;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px dotted theme.$color-grey;
    }
}

.currencyAmountField {
    @include common.flexRow;
    width: 100%;
    max-width: 400px;
    .inputWrapper {
        @include common.flexRow;
        width: 100%;
        .labelWrapper {
            @include common.flexRow;
            align-items: center;
            &:last-of-type {
                width: 70%;
            }
            &:first-of-type {
                margin-right: 10px;
            }
        }
    }
}

:export {
    french: theme.$color-french;
    grey: rgba(theme.$color-grey, 0.7);
    green: theme.$color-green;
    disabledGrey: rgba(theme.$color-grey, 0.5);
    primary: theme.$color-primary-night;
    lightGrey: theme.$color-light-grey;
    white: theme.$color-white;
    amethyst: theme.$color-amethyst;
    gold: theme.$color-gold;
    amber: theme.$color-amber;
}
