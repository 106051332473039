@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.formInstanceWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .sectionWrapper {
        .sectionHeader {
            @include common.flexRow;
            align-items: center;
            color: theme.$color-primary-night;
            border-bottom: 1px dashed theme.$color-grey;
            padding: 10px 0;
            height: fit-content;
            &:hover {
                color: theme.$color-french;
            }
            .sectionOpenIcon {
                margin-right: 10px;
                cursor: pointer;
            }
            .sectionLabel {
                font-size: 20px;
                font-family: theme.$font-family;
                margin-right: 10px;
            }
            .sectionDescription {
                margin-right: 10px;
            }
            .redirectWrapper {
                @include common.centreFlexRow;
                margin-top: 4px;
            }
            .configureAnnexDefinitionWrapper {
                @include common.centreFlexRow;
                margin-top: 2px;
                margin-right: 10px;
            }
        }
        .allFieldsWrapper {
            margin-top: 10px;
        }
    }
    .searchedSectionWrapper {
        background-color: rgba(theme.$color-mandatory, 0.2) !important;
        border-radius: 5px;
    }
}

.ecsButtonsWrapper {
    @include common.flexRow;
    align-items: center;
    padding-left: 10px;
    .addECSButtonWrapper {
        margin-right: 5px;
    }
}

.groupFieldWrapper {
    @include common.flexStartSpaceBetweenFlexRow;
    width: 100%;
}

.multiToggleGroupLabel {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
}

.multiToggleWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .multiToggleHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-bottom: 10px;
        .selectAllWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc((100% / 3) - 21px);
            margin-right: 10px;
            padding: 0 5px;
            .selectAllLabel {
                width: calc(100% - 46px);
                margin-right: 10px;
                text-align: left;
            }
        }
        .addOptionWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            width: 50%;
            .addOptionLabel {
                margin-right: 10px;
            }
            .addOptionButton {
                @include common.centreFlexRow;
            }
        }
    }
    .multiToggleOptionsWrapper {
        @include common.flexRow;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid theme.$color-light-grey;
        border-bottom: 1px solid theme.$color-light-grey;
        .toggleOptionWrapper {
            @include common.flexRow;
            align-items: center;
            padding: 5px;
            .toggleOptionInput {
                width: calc(100% - 46px);
                border: none;
                border-bottom: 1px dotted theme.$color-grey;
                font-family: theme.$font-family;
                font-size: 16px;
                margin-right: 10px;
                &:hover {
                    border-bottom: 1px dotted theme.$color-primary-night;
                }
                &:focus {
                    outline: none !important;
                }
            }
        }
    }
}

.singleFieldWrapper {
    @include common.flexColumn;
    margin: 10px 0;
    .fieldTitleWrapper {
        @include common.flexRow;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 5px;
        .fieldOpenWrapper {
            @include common.flexRow;
            align-items: center;
            margin-right: 10px;
        }
        .fieldLabel {
            margin-right: 10px;
        }
        .datasetSectionText {
            font-size: 20px;
        }
        .calculatorFieldLabel {
            font-size: 18px;
        }
    }
    .fieldCanCollapse {
        &:hover {
            color: theme.$color-french;
        }
    }
}

.searchedFieldWrapper {
    background-color: rgba(theme.$color-mandatory, 0.2) !important;
    border-radius: 5px;
}

.isLabelField {
    margin-bottom: -10px !important;
}

.lastFieldInGroup {
    padding-right: 0px !important;
}

.emptyFieldLabel {
    color: rgba(theme.$color-grey, 0.5);
    font-style: italic;
}

.unknownFieldType {
    color: theme.$color-red;
    border: 1px solid theme.$color-red;
    border-radius: 5px;
    padding: 10px;
}

.multiToggleField {
    @include common.flexRow;
    align-items: center;
    width: 100%;
}

.multiToggleLabelWrapper {
    @include common.flexRow;
    align-items: center;
    width: calc(100% - 46px);
    margin-right: 10px;
    text-align: left;
}

.multiToggleLabel {
    width: 100%;
}

.riskFieldMultiToggleLabel {
    width: calc(100% - 25px);
    margin-right: 5px;
}

.tableInstanceWrapper {
    width: 100%;
}

.tableHeader {
    @include common.flexRow;
    border-radius: 5px 5px 0 0;
    width: fit-content;
    .firstCell {
        border-left: 1px solid theme.$color-french;
        border-radius: 5px 0 0 0;
    }
}

.tableBody {
    @include common.flexColumn;
    width: fit-content;
}

.tableRow {
    @include common.flexRow;
    border-bottom: 1px solid theme.$color-grey;
    width: fit-content;
    .cellWrapper {
        @include common.centreFlexRow;
        padding: 5px;
        border-right: 1px solid theme.$color-grey;
        border-left: 1px solid theme.$color-white;
        .openCellWrapper {
            @include common.centreFlexRow;
            height: 100%;
            width: 100%;
            min-height: 38px;
        }
        .collapsedPlaceholder {
            @include common.centreFlexRow;
            height: 100%;
            width: 100%;
            min-height: 38px;
        }
    }
    .firstCell {
        border-left: 1px solid theme.$color-grey;
    }
    .mlCellHighlight {
        background-color: rgba(theme.$color-mandatory, 0.3);
    }
}

.addTableRow {
    @include common.flexRow;
    align-items: center;
    height: 30px;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    border: 1px solid theme.$color-grey;
    border-top: none;
}

.tableHeaderWrapper {
    @include common.flexRow;
    background-color: theme.$color-french;
    align-items: center;
    padding: 10px 5px;
    height: 30px;
    color: theme.$color-white;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    border-right: 1px solid theme.$color-white;
    border-left: 1px solid theme.$color-white;
    .cellHeader {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-white;
        width: 100%;
        .headerLabel {
            font-size: 12px;
            margin-right: 10px;
        }
        .headerIconWrapper {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        .iconsWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: fit-content;
            .flippedIcon {
                @include common.flexRow;
                align-items: center;
                transform: rotate(180deg);
            }
        }
    }
}

.selectedTableHeader {
    opacity: 0.8;
}

.actionRowHeader {
    @include common.flexRow;
    width: 30px;
    align-items: center;
    padding: 10px 5px;
    height: 30px;
    color: theme.$color-white;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    border-left: 1px solid theme.$color-white;
    border-right: 1px solid theme.$color-french;
    background-color: theme.$color-french;
    &:last-of-type {
        border-radius: 0 5px 0 0;
    }
}

.actionRowCell {
    @include common.centreFlexRow;
    width: 30px;
    font-family: theme.$font-family;
    border-right: 1px solid theme.$color-grey;
    border-left: 1px solid theme.$color-white;
    padding: 0 5px;
}

:export {
    mandatory: theme.$color-mandatory;
    white: theme.$color-white;
}
