@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

@mixin label($marginBottom: 5px) {
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: $marginBottom;
    color: theme.$color-primary-night;
}

@mixin attestationHeaderCell {
    @include common.flexRow;
    align-items: center;
    height: calc(100% - 10px);
    padding: 5px;
}

@mixin attestationCell {
    @include common.centreFlexRow;
    height: calc(100% - 4px);
    padding: 2px 5px;
}

.workflowWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    color: theme.$color-primary-night;
}

.calendarWrapper,
.workflowListWrapper {
    @include common.flexColumn;
    margin: 20px 0;
    height: 100%;
    width: calc(100% - 16vw);
    padding: 0 8vw;
}

.workflowSelectWrapper {
    @include common.flexColumn;
    margin: 20px 0;
    height: 100%;
}

.tableIconWrapper {
    @include common.centreFlexRow;
    width: 100%;
}
.actionCellWrapper {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    .actionCellLabel {
        font-family: theme.$font-family;
        font-size: 14px;
    }
}

.workflowHeader {
    @include common.spaceBetweenFlexRow;
    align-items: center;
    margin-bottom: 5px;
    .workflowLeftHeader {
        @include common.flexRow;
        align-items: center;
        .workflowTitle {
            @include common.tablePageTitle;
        }
    }
}

.toolbarWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    margin-bottom: 10px;
    .workflowTitleWrapper {
        @include common.flexRow;
        align-items: center;
        width: 230px;
        .buttonGroupLeft {
            @include common.flexRow;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .calendarLabel {
        @include common.centreFlexRow;
        font-family: theme.$font-family;
        color: theme.$color-primary-night;
        font-weight: theme.$font-medium;
        font-size: 22px;
        align-self: center;
    }
    .calendarDatepicker {
        @include common.flexRow;
        align-items: center;
        width: 230px;
    }
}

.calendarDateHeaderWrapper {
    @include common.flexRow;
    justify-content: flex-end;
    height: 100%;
    width: calc(100% - 10px);
    padding: 5px;
    pointer-events: none;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    font-size: 16px;
}

.eventWrapper {
    @include common.spaceBetweenFlexRow;
    @include common.transparentButton(theme.$color-white);
    width: calc(100% - 10px);
    height: 20px;
    padding: 2px 5px;
    border-radius: 5px;
    .eventInfo {
        @include common.flexRow;
        align-items: center;
        .iconWrapper {
            @include common.centreFlexRow;
            height: 100%;
        }
        .eventTitle {
            margin: 0 5px;
            font-weight: theme.$font-medium;
        }
    }
    .repeatWrapper {
        @include common.centreFlexRow;
    }
}
.eventHasPassed {
    opacity: 0.7;
}
.eventIsSkipped {
    opacity: 0.7;
    background-color: theme.$color-body-disabled !important;
}

.wizardTabContentWrapper {
    width: 60vw;
    height: 50vh;
    padding: 2px;
}

.workflowCalendar {
    font-family: theme.$font-family;
    color: theme.$color-french;
}

.scheduleTabWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .topWrapper {
        @include common.flexColumn;
        .actionWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 10px;
            .actionTitle {
                width: 150px;
                margin-right: 10px;
                font-weight: theme.$font-medium;
            }
            .actionDropdown {
                flex: 1;
            }
        }
        .dateWrapper {
            @include common.spaceBetweenFlexRow;
            margin-bottom: 10px;
            .firstDateWrapper {
                @include common.flexRow;
                flex: 1;
                align-items: center;
                .firstDateTitle {
                    width: 150px;
                    margin-right: 10px;
                    font-weight: theme.$font-medium;
                }
            }
            .repeatWrapper {
                @include common.flexRow;
                align-items: center;
                .repeatTitle {
                    margin-right: 10px;
                    font-weight: theme.$font-medium;
                }
            }
        }
        .intervalWrapper {
            @include common.flexRow;
            align-items: center;
            .intervalTitle {
                width: 150px;
                margin-right: 10px;
                font-weight: theme.$font-medium;
            }
            .intervalValueDropdown {
                margin-right: 10px;
                width: 100px;
            }
            .intervalDropdown {
                width: 120px;
            }
        }
    }
    .deleteSkipWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding-bottom: 6px;
        margin-bottom: 5px;
        border-bottom: 1px solid theme.$color-grey;
    }
}

.contentTabWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.createAttestationWrapper {
    @include common.flexColumn;
    height: 100%;
    .attestationFormSelect {
        @include common.flexColumn;
        margin-bottom: 10px;
        .attestationFormSelectLabel {
            @include label;
        }
    }
}

.emailContentTabWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .emailContentLabel {
        @include label(0);
        width: 10%;
    }
    .emailUsersWrapper {
        @include common.flexRow;
        width: 100%;
        margin-bottom: 10px;
        align-items: center;
        .emailUsersDropdown {
            width: 90%;
        }
    }
    .emailSubjectWrapper {
        @include common.flexRow;
        width: 100%;
        margin-bottom: 10px;
        align-items: center;
        .emailSubjectInput {
            width: 90%;
        }
    }
    .emailBodyWrapper {
        width: 100%;
        height: 100%;
        margin-bottom: 5px;
    }
}

.confirmTabWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    .summaryWrapper {
        @include common.flexRow;
        width: 100%;
        height: 10%;
        margin-bottom: 10px;
        align-items: center;
        .summaryLabel {
            @include label(0);
            width: 15%;
        }
        .summaryContent {
            width: 85%;
        }
    }
    .collapseWrapper {
        @include common.flexColumn;
        height: 80%;
        .collapseIconWrapper {
            @include common.flexRow;
            margin-bottom: 5px;
            align-items: center;
            .collapseSectionLabel {
                @include label(0);
                margin-right: 10px;
            }
        }
        .collapseContent {
            height: 100%;
        }
    }
    .toggleWrapper {
        @include common.flexRow;
        height: 5%;
        align-items: center;
        .toggleLabel {
            @include label(0);
            margin-right: 10px;
        }
    }
    .updatedWarningMessage {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-size: 13px;
        height: 5%;
    }
}

.confirmEmailWrapper {
    @include common.flexColumn;
    height: 95%;
    .emailSubjectWrapper {
        @include common.flexRow;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 10px;
        .summaryLabel {
            @include label(0);
            width: 15%;
        }
        .emailSubject {
            font-weight: theme.$font-medium;
            width: 85%;
            overflow-x: hidden;
        }
    }
    .emailUsersWrapper {
        @include common.flexRow;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 10px;
        .summaryLabel {
            @include label(0);
            margin-right: 5px;
            width: 15%;
        }
        .emailUsers {
            @include common.flexRow;
            width: 85%;
            .emailUsername {
                margin-right: 5px;
                max-height: 30px;
                @include common.overflowText;
            }
        }
    }
    .emailContentWrapper {
        width: 90%;
        align-self: center;
        max-height: 75%;
        margin-bottom: 5px;
        .emailHeader {
            height: 40%;
            align-items: center;
            background-color: theme.$color-primary-night;
            justify-content: center;
            display: flex;
            .emailHeaderImage {
                outline: none;
                text-decoration: none;
                -ms-interpolation-mode: bicubic;
                clear: both;
                display: inline-block !important;
                border: none;
                height: auto;
                float: none;
                width: 41%;
                max-width: 237.8px;
            }
        }
        .emailBody {
            margin-left: 5%;
            margin-right: 5%;
        }
        .emailFooterIcon {
            margin-left: 5%;
            min-height: 20%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
            clear: both;
            display: inline-block !important;
            border: none;
            height: auto;
            float: none;
            width: 19%;
            max-width: 105.26px;
            width: 105.26px;
        }
    }
}

.confirmAttestationWrapper {
    height: 90%;
    .attestationTitleWrapper {
        @include common.flexRow;
        width: 100%;
        align-items: center;
        height: 10%;
        .attestationTitleLabel {
            @include label(0);
            width: fit-content;
            margin-right: 10px;
        }
        .attestationTitle {
            display: flex;
            align-items: center;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .attestationInstancesWrapper {
        height: 90%;
        .attestationInstanceWrapper {
            @include common.flexColumn;
            align-items: flex-start;
            border-radius: 5px;
            margin: 10px 40px;
            padding: 5px;
            font-family: theme.$font-family;
            box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
            color: theme.$color-primary-night;
            cursor: pointer;
            &:hover {
                box-shadow: 1px 1px 5px theme.$color-french;
                transform: scale(1.02);
            }
            .instanceUsers {
                @include common.flexColumn;
                width: 100%;
                .instanceUsersHeader {
                    @include common.flexRow;
                    align-items: center;
                    background-color: theme.$color-french;
                    color: theme.$color-white;
                    font-weight: theme.$font-medium;
                    padding: 5px;
                    border-radius: 5px 5px 0 0;
                    text-align: center;
                }
                .instanceUsersDetails {
                    @include common.flexRow;
                    align-items: center;
                    font-weight: theme.$font-medium;
                    text-align: center;
                }
                .personToAnswer {
                    width: 50%;
                }
                .userAssigned {
                    width: 50%;
                    max-height: 24px;
                    @include common.overflowText;
                }
            }
            .deadlineWrapper {
                @include common.flexRow;
                width: 100%;
                align-items: center;
                border-top: 1px solid theme.$color-grey;
                .deadlineLabel {
                    @include label(0);
                    width: fit-content;
                    margin-right: 10px;
                    margin-top: 5px;
                }
                .deadlineValue {
                    display: flex;
                    align-items: center;
                    width: 80%;
                    margin-top: 5px;
                }
            }
        }
    }
}

:export {
    white: theme.$color-white;
    ocean: theme.$color-ocean;
    amethyst: theme.$color-amethyst;
    lightGrey: theme.$color-light-grey;
}
