@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.sectionModalWrapper {
    @include common.flexColumn;
    color: theme.$color-primary-night;
    .sectionModalTitle {
        font-size: 20px;
        padding-left: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid theme.$color-grey;
        padding-bottom: 3px;
        font-family: theme.$font-family;
    }
    .sectionModalInput {
        padding: 10px;
        margin: 10px;
        display: inline-block;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: theme.$font-family;
        &:focus {
            outline: none;
            border: 1px solid theme.$color-french;
        }
    }
}

.sectionButtonWrapper {
    flex: 0 0 auto;
    border-radius: 5px;
    min-width: 100px;
    max-width: 200px;
    padding: 5px;
    text-align: center;
    margin: 5px;
    color: theme.$color-primary-night;
    background-color: theme.$color-white;
    border: 1px solid theme.$color-primary-night;
    display: flex;
    flex-direction: column;
    .sectionButtonActionWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 5px;
        .deleteSection {
            @include common.transparentButton(inherit);
            padding: 0 !important;
            &:hover {
                color: theme.$color-red;
            }
        }
    }
    .sectionButton {
        @include common.flexRow;
        @include common.transparentButton(inherit);
        justify-content: space-between;
        padding: 0 2px 0 0 !important;
        .sectionButtonLabel {
            @include common.overflowText;
            font-weight: theme.$font-medium;
        }
    }
}

.selectedSectionButtonWrapper {
    background-color: rgba(theme.$color-aqua, 0.1);
    border: 2px solid theme.$color-french;
    color: theme.$color-french;
}

.incompleteSectionButtonWrapper {
    background-color: rgba(theme.$color-red, 0.1);
    border: 1px solid theme.$color-red;
}

.settingsWrapper {
    @include common.flexColumn;
    .settingsContent {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        padding: 2px;
        .settingsLabel {
            font-size: 14px;
        }
        .settingsLabelDisabled {
            color: theme.$color-grey;
        }
    }
}

.sectionWrapper {
    width: 100%;
}

.groupContentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .headerWrapper {
        @include common.spaceBetweenFlexRow;
        .groupHeader {
            @include common.flexRow;
            align-items: center;
            padding: 5px 8px;
            cursor: pointer;
            flex: 1;
            justify-content: flex-start;
            .multiToggleLabel {
                width: 50%;
                padding: 8px;
                margin: 2px 5px;
                display: inline-block;
                border: 1px solid theme.$color-grey;
                border-radius: 5px;
                box-sizing: border-box;
                &:focus {
                    outline: none;
                    border: 1px solid theme.$color-french;
                }
            }
            .groupHeaderLabel {
                font-weight: theme.$font-medium;
                margin-left: 10px;
                margin-right: 20px;
            }
        }
    }
}

.groupRightButtons {
    @include common.flexRow;
    align-items: center;
}

.groupFieldLimitWarning {
    color: theme.$color-red;
    font-size: 14px;
}

.selectedGroupWrapper {
    background-color: rgba(theme.$color-aqua, 0.1);
    border: 1px solid theme.$color-amethyst;
}

.selectedGroupWrapperRequired {
    border: 1px solid theme.$color-red;
}

.linkButtonWrapper {
    @include common.flexRow;
    width: 100%;
    margin-top: 2px;
    .linkButton {
        background-color: transparent;
        color: theme.$color-primary-night;
        border: 1px solid theme.$color-primary-night;
        outline: none;
        cursor: pointer;
        padding: 3px;
        border-radius: 5px;
        font-family: theme.$font-family;
        font-size: 14px;
        flex: 1;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
            border: 1px solid theme.$color-french;
        }
    }
}

.removeLink {
    @include common.transparentButton;
    &:hover {
        color: theme.$color-red;
    }
}

.datasetLinkWrapper {
    @include common.flexColumn;
    width: 300px;
    margin-bottom: 10px;
    color: theme.$color-primary-night;
    .datasetLinkTitle {
        font-size: 20px;
        font-weight: theme.$font-medium;
    }
    .datasetLinkTypeWrapper {
        @include common.flexRow;
        width: 100%;
        margin: 10px;
    }
}

.settingsModalLinkWrapper {
    @include common.flexColumn;
    width: 300px;
    margin-bottom: 10px;
    color: theme.$color-primary-night;
    .settingsModalLinkTitle {
        font-size: 20px;
        font-weight: theme.$font-medium;
    }
    .settingsModalLinkDropdown {
        margin: 10px 0px;
    }
    .calculatorSettingsModalLinkDropdown {
        margin: 10px 0px;
        @include common.flexRow;
    }
}

.calculatorRadioWrapper {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    margin: 3px 0;
    .calculatorRadioTitle {
        font-size: 14px;
        font-weight: theme.$font-medium;
    }
}

.refInput,
.systemIdInput {
    @include common.labelModalTextArea;
}

.labelContentWrapper {
    @include common.flexColumn;
    width: 700px;
    padding: 5px;
    .labelContentTitle {
        font-size: 20px;
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
    }
}

.paletteWrapper {
    @include common.flexRow;
    flex-wrap: wrap;
    width: 96px;
}

.paletteSquare {
    @include common.centreFlexRow;
    border: 1px solid theme.$color-light-grey;
    border-radius: 5px;
    height: 18px;
    width: 18px;
    margin: 2px;
    cursor: pointer;
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    grey: rgba(theme.$color-grey, 0.7);
    white: theme.$color-white;
}
