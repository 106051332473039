@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.dataManagementWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    .tabSelectWrapper {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 10px);
        height: 35px;
        padding: 5px;
        .wizardTabWrapper {
            @include common.centreFlexRow;
            width: 25%;
        }
    }
    .pageWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 45px);
    }
}
