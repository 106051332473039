@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.tableWrapper {
    @include common.centreFlex(column);
}

.emptyPlaceholder {
    @include common.centreFlexRow;
    height: 100%;
    .logoWrapper {
        height: 50%;
        opacity: 0.1;
    }
}

.tooltipCellWrapper {
    @include common.flexRow;
    width: 100%;
    .tooltipValue {
        text-align: left;
        font-size: 14px;
        width: 100%;
    }
    .tooltipIcon {
        @include common.centreFlexRow;
        width: 100%;
    }
}

.entityCellWrapper {
    @include common.flexRow;
    width: 100%;
    .entityValue {
        text-align: left;
        font-size: 14px;
        width: 100%;
    }
    .entityValueWithIcon {
        text-align: left;
        font-size: 14px;
        width: calc(100% - 20px);
    }
    .icon {
        @include common.centreFlexRow;
        width: 100%;
    }
}

.colourCellWrapper {
    width: 80%;
    height: 80%;
    border-radius: 5px;
}

.iconTooltipCellWrapper {
    @include common.centreFlexRow;
    width: 100%;
}

.tooltipListLabelCellWrapper {
    @include common.flexRow;
    width: 100%;
    align-items: center;
    .tooltipValue {
        text-align: left;
        font-size: 14px;
        width: 100%;
    }
    .tooltipLength {
        margin-left: 3px;
        text-align: left;
        font-size: 14px;
    }
}

.addressCellWrapper {
    @include common.flexRow;
    width: 100%;
    .addressCell {
        @include common.overflowText;
        width: 100%;
        text-align: left;
    }
}

.flagCellWrapper {
    @include common.centreFlexRow;
    width: 100%;
    .flagCell {
        font-size: 20px;
    }
}

.selectCell {
    @include common.centreFlexRow;
    height: 100%;
    color: theme.$color-french;
}

.checkboxCell {
    @include common.centreFlexRow;
    height: 100%;
}

.tickWrapper {
    @include common.centreFlexRow;
    width: 100%;
}

.analysisWrapper {
    @include common.centreFlexRow;
    width: 100%;
    .statusIncomplete {
        color: theme.$color-red;
    }
    .statusComplete {
        color: theme.$color-french;
    }
}

.tableCellWrapper {
    @include common.flexColumn;
    justify-content: center;
    width: 100%;
    height: 100%;
    .tableCellRowWrapper {
        @include common.flexColumn;
        justify-content: center;
        height: 90%;
    }
    .tableCellRowBorder {
        border-bottom: 1px dashed theme.$color-light-grey;
    }
}

.editDatasetButton {
    @include common.tableIconButton;
}

.actionWrapper {
    @include common.centreFlexRow;
    width: 100%;
}

.iconCellWrapper {
    @include common.centreFlexRow;
    width: 100%;
}

.deadlineWrapper {
    @include common.centreFlexRow;
    width: 100%;
    font-size: 13px;
    font-weight: theme.$font-medium;
}

.tableTabsWrapper {
    @include common.flexRow;
    margin-right: 10px;
    justify-content: flex-end;
    .tab {
        @include common.centreFlexRow;
        padding: 10px;
        cursor: pointer;
        border: 1px solid theme.$color-french;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        background-color: theme.$color-lightest-grey;
        color: theme.$color-primary-night;
        min-width: 110px;
        max-width: 200px;
        height: 30px;
        .tabTitle {
            @include common.overflowText;
            height: 18px;
            max-width: 200px;
            min-width: 90px;
        }
    }
    .isSelected {
        background-color: theme.$color-french;
        color: theme.$color-white;
        font-weight: theme.$font-medium;
    }
}

.arkTableWrapper {
    @include common.flexColumn;
    margin: 0 auto;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    font-family: theme.$font-family;
    cursor: default;
    .tableHeaderWrapper {
        @include common.flexRow;
        width: 100%;
        border-radius: 5px 5px 0 0;
        .tableHeaderCell {
            @include common.spaceBetweenFlexRow;
            height: 30px;
            border-bottom: 10px solid theme.$color-french;
            border-top: 10px solid theme.$color-french;
            padding: 0 8px;
            background-color: theme.$color-french;
            color: theme.$color-white;
            .tableHeaderName {
                text-align: left;
                font-size: 14px;
                font-weight: theme.$font-bold;
            }
            .tableHeaderIcons {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    .tableBodyWrapper {
        @include common.flexColumn;
        height: calc(100% - 81px);
        .tableRowWrapper {
            @include common.flexRow;
            .tableRowCell {
                @include common.centreFlexRow;
                padding: 0 8px;
                color: theme.$color-primary-night;
                font-size: 14px;
                width: 100%;
                .tableTextCell {
                    @include common.overflowText;
                    width: 100%;
                    text-align: left;
                }
            }
            &:hover {
                background-color: rgba(theme.$color-light-grey, 0.6) !important;
            }
        }
        .rowBorder {
            border-bottom: 1px solid theme.$color-light-grey;
        }
        .isSelected {
            background-color: rgba(theme.$color-light-grey, 0.8) !important;
        }
    }
    .tableFooter {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        border-radius: 5px;
        background-color: rgba(theme.$color-aqua, 0.3);
        color: theme.$color-primary-night;
        border-top: 1px solid theme.$color-french;
        font-size: 13px;
        height: 31px;
        .leftTableFooter {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            .pageSizeWrapper {
                @include common.centreFlexRow;
                margin: 0 5px;
                .pageSizeLabel {
                    @include common.flexRow;
                    align-items: center;
                    justify-content: flex-start;
                    margin-right: 5px;
                }
                .pageSizeOptions {
                    @include common.flexRow;
                    align-items: center;
                    justify-content: flex-start;
                    .pageSizeButton {
                        @include common.transparentButton;
                        padding: 0 3px;
                        &:hover {
                            color: theme.$color-french;
                        }
                    }
                    .selectedPageSizeButton {
                        font-weight: theme.$font-medium;
                        cursor: default;
                        &:hover {
                            color: theme.$color-primary-night;
                        }
                    }
                }
            }
            .clearAllFiltersWrapper {
                @include common.flexRow;
                height: 100%;
                align-items: center;
                padding-left: 5px;
                border-left: 1px solid theme.$color-french;
                .clearAllFilters {
                    @include common.transparentButton;
                    padding: 0 5px 0 3px;
                    &:hover {
                        color: theme.$color-french;
                    }
                }
                .saveCustomFilters {
                    @include common.flexRow;
                    @include common.transparentButton;
                    align-items: center;
                    padding: 0 3px;
                    border-left: 1px solid theme.$color-primary-night;
                    &:hover {
                        color: theme.$color-french;
                    }
                }
            }
        }
        .rightTableFooter {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            .pageInfo {
                text-align: right;
                min-width: 100px;
                margin-right: 15px;
            }
            .paginationWrapper {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-end;
                height: 31px;
                min-width: 120px;
                .paginationButton {
                    @include common.transparentButton;
                    &:hover {
                        color: theme.$color-french;
                    }
                    &:disabled {
                        color: theme.$color-grey;
                    }
                }
                .pageCount {
                    margin: 0 5px;
                }
            }
        }
    }
}

.customFiltersSaveModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    height: 75vh;
    .customFiltersSaveModalWrapper {
        @include common.flexColumn;
        width: 500px;
        padding: 10px;
        height: calc(100% - 20px);
        .contentWrapper {
            @include common.flexColumn;
            width: 100%;
            height: calc(100% - 104px);
        }
        .warningMessage {
            padding-bottom: 10px;
            color: theme.$color-amber;
            font-size: theme.$font-size-h7;
        }
        .scrollableWrapper {
            height: calc(100% - 114px);
        }
        .buttonWrapper {
            @include common.spaceBetweenFlexRow;
            .leftButtonWrapper {
                @include common.flexRow;
            }
            .rightButtonWrapper {
                @include common.flexRow;
            }
        }
    }
}

.readOnlyCustomFilterInformation {
    @include common.flexColumn;
    width: 100%;
    .readOnlyCustomFilterSection {
        @include common.flexColumn;
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        .readOnlyCustomFilterSectionHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            font-size: theme.$font-size-h3;
            cursor: pointer;
            .sectionOpenIcon {
                margin-right: 10px;
            }
        }
        .filterValuesWrapper {
            @include common.flexColumn;
            width: calc(100% - 20px);
            margin-left: 20px;
            margin-bottom: 5px;
            .filterValue {
                font-size: 15px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

:export {
    red: theme.$color-red;
    amber: theme.$color-amber;
    green: theme.$color-green;
    french: theme.$color-french;
    white: theme.$color-white;
    fadedWhite: rgba(theme.$color-white, 0.4);
    grey: theme.$color-lightest-grey;
    primary: theme.$color-primary-night;
}
