@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin page {
    @include common.flexColumn;
    box-shadow: 1px 1px 5px theme.$color-french;
    border: 1px solid theme.$color-french;
    border-radius: 5px;
    padding: 20px;
}

.analysisWrapper {
    @include common.flexRow;
    margin-bottom: 10px;
    .splitWrapper {
        @include common.flexRow;
        .documentAnalysisWrapper {
            @include page;
            margin: 0 10px;
        }
        .datasetAnalysisWrapper {
            @include page;
            margin: 0 10px;
        }
    }
}

.supportDocumentsSelectWrapper {
    @include common.flexRow;
    justify-content: flex-end;
    border-left: 1px solid rgba(theme.$color-grey, 0.5);
    border-right: 1px solid rgba(theme.$color-grey, 0.5);
    padding: 5px;
    margin-bottom: -48px;
    .supportDocumentsDropdownWrapper {
        width: 50%;
    }
}

.spinnerWrapper {
    align-self: center;
    height: 100%;
    width: 100%;
}
