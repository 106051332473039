@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.loginWrapper {
    @include common.flexColumn;
    @include common.loginBackground;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.topWrapper {
    @include common.centreFlexColumn;
    .logoWrapper {
        @include common.logoWrapper;
    }
}

.username,
.password {
    @include common.loginInput
}

.loginError {
    display: flex;
    align-items: center;
    height: 30px;
    color: theme.$color-red;
    font-weight: theme.$font-medium;
}

.loginErrorWarning {
    display: flex;
    align-items: center;
    height: 30px;
    color: theme.$color-red;
    font-weight: theme.$font-medium;
}

.loginButton,
.registrationButton {
    @include common.loginButton
}

.loginAssistance {
    @include common.flexColumn;
    .forgottenUsername,
    .forgottenPassword {
        @include common.transparentButton(theme.$color-white);
        font-size: 16px;
        font-weight: theme.$font-bold;
        margin: 3px 0;
        &:hover {
            color: theme.$color-amethyst;
            text-decoration: underline;
        }
    }
}

.summaryWrapper {
    @include common.centreFlexColumn;
    .summary {
        font-size: 40px;
        font-weight: theme.$font-bold;
        color: theme.$color-white;
    }
}

.registrationWrapper {
    @include common.flexColumn;
    margin-top: 20px;
    align-items: center;
    .registrationTitle {
        font-size: 24px;
        color: theme.$color-white;
        font-weight: theme.$font-medium;
    }
}

@media (max-height: 800px) {
    .summary {
        font-size: 25px !important;
    }
    .registrationWrapper {
        margin-top: 10px !important;
        .registrationTitle {
            font-size: 18px !important;
        }
    }
}

.logoutOverlay,
.switchAccountOverlay {
    height: 100vh;
    width: 100vw;
    opacity: 0.8;
}

.inputLabel {
    font-size: 16px;
    font-weight: theme.$font-bold;
    margin: 3px 0;
    color: theme.$color-white;
}

.loginInputWrapper {
    @include common.centreFlexColumn;
    height: 290px;
}
