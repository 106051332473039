@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.theoreticalWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.survey {
    height: calc(100% - 20px);
    margin-left: 8vw;
    margin-right: 8vw;
    margin-bottom: 10px;
    padding: 10px;
    border: solid 1px theme.$color-light-grey;
    border-radius: 5px;
    background-color: theme.$color-lightest-grey;
    .surveyWrapper {
        @include common.flexColumn;
        justify-content: center;
        height: calc(100% - 60px);
        align-items: center;
        .surveyQuestionSlider {
            display: flex;
            height: 70px;
            width: 90%;
            margin: 0 auto;
            align-items: center;
        }
        .surveyQuestionsWrapper {
            @include common.centreFlexColumn;
            height: calc(100% - 90px);
            width: 100%;
            .surveyQuestion {
                display: flex;
                align-items: flex-end;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-size: 28px;
                font-weight: theme.$font-medium;
                margin-bottom: 20px;
                height: 50%;
            }
            .surveyQuestionFadeIn {
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-size: 28px;
                font-weight: theme.$font-medium;
                margin-bottom: 20px;
                animation: fadeIn 2.5s;
            }
            .surveyQuestionFadeOut {
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-size: 28px;
                font-weight: theme.$font-medium;
                margin-bottom: 20px;
                animation: fadeOut 1.5s;
            }
            .surveyButtonWrapper {
                @include common.spaceBetweenFlexRow;
                width: 400px;
                height: 20%;
                align-items: flex-start;
            }
            .surveyMessage {
                @include common.flexRow;
                height: 30%;
                color: theme.$color-amber;
                padding: 0 40px;
            }
            .surveyInput {
                @include common.flexRow;
                width: calc(50% + 80px);
                height: 50%;
                justify-content: center;
                .inputWrapper {
                    @include common.flexRow;
                    align-items: flex-start;
                    width: 40px;
                }
                .surveyDropdownWrapper {
                    @include common.flexColumn;
                    width: calc(100% - 80px);
                    height: 100%;
                    margin-left: 40px;
                    .subQuestionWrapper {
                        @include common.flexColumn;
                        margin-top: 10px;
                        .counterpartySubQuestion {
                            color: theme.$color-primary-night;
                            font-family: theme.$font-family;
                            font-size: 16px;
                            font-weight: theme.$font-medium;
                            margin-bottom: 10px;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .surveySummaryWrapper {
            @include common.centreFlexColumn;
            height: calc(100% - 90px);
            width: 100%;
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            .summaryTitle {
                font-size: theme.$font-size-h1;
                text-decoration: underline;
                margin-bottom: 20px;
            }
            .surveySummary {
                @include common.flexRow;
                padding: 10px;
                border: solid 1px theme.$color-light-grey;
                border-radius: 5px;
                width: 60%;
                margin-bottom: 5px;
                font-size: theme.$font-size-h4;
                background-color: theme.$color-white;
                .surveySummaryQuestion {
                    width: 60%;
                    margin-right: 10px;
                    text-align: left;
                }
                .surveySummaryAnswer {
                    width: 40%;
                    text-align: left;
                }
            }
        }
    }
    .surveyButtonWrapper {
        @include common.flexRow;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        height: 50px;
        width: 90%;
        .surveyButtonPrevious {
            display: flex;
            align-items: center;
            min-width: 30%;
            justify-content: flex-start;
        }
        .surveyQuestionCounter {
            min-width: 30%;
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h1;
            font-weight: theme.$font-medium;
        }
        .surveyButtonNext {
            display: flex;
            align-items: center;
            min-width: 30%;
            justify-content: flex-end;
        }
    }
}

.confirmationModalWrapper {
    @include common.flexColumn;
    padding: 5px;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    width: 400px;
}

.sliderMarker {
    height: 40px;
    width: 40px;
    position: relative;
    top: -30px;
    left: 0px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

:export {
    primary: theme.$color-primary-night;
    red: theme.$color-red;
    green: theme.$color-green;
    french: theme.$color-french;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.3);
}
