@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.documentsWrapper {
    @include common.tablePageWrapper;
    .documentsTitleContainer {
        @include common.tablePageTitleContainer;
        height: 35px;
        .documentsTitle {
            @include common.tablePageTitle;
        }
    }
}

.documentRenameWrapper {
    width: 50vw;
    height: fit-content;
    max-height: 350px;
}

.mlCompletedWrapper {
    @include common.flexColumn;
    width: fit-content;
    max-width: 75vw;
    .mlCompletedBody {
        @include common.flexColumn;
        margin-bottom: 10px;
        color: theme.$color-primary-night;
        .mlCompleted {
            padding: 3px 0;
        }
        .mlCheckWarning {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px dotted theme.$color-grey;
            font-weight: 600;
            color: theme.$color-amber;
        }
    }
}

.unknownEntityWrapper {
    @include common.spaceBetweenFlexRow;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px dotted theme.$color-grey;
    .unknownEntityInfoWrapper {
        @include common.flexColumn;
        margin-right: 20px;
        padding: 3px 0;
        .unknownEntityInfo {
            font-size: 15px;
        }
    }
    .unknownEntityButtonWrapper {
        @include common.centreFlexRow;
    }
}

:export {
    lightGrey: theme.$color-light-grey;
}
