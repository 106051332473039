@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.searchEntitiesWrapper {
    @include common.flexColumn;
    width: 90vw;
    padding-bottom: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    .searchByWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        margin: 10px 0;
        .searchByTitle {
            font-size: 16px;
            font-weight: theme.$font-medium;
        }
        .radioWrapper {
            @include common.flexRow;
            width: 300px;
            margin-left: 20px;
        }
    }
    .searchResultsWrapper {
        @include common.flexColumn;
        width: 100%;
        height: 50vh;
    }
}

.fuzzyAutoCompletionsModalWrapper {
    @include common.flexRow;
    align-items: flex-start;
    .completionsWrapper {
        @include common.flexColumn;
        .completionHeader {
            @include common.flexRow;
            width: 100%;
            margin-bottom: 5px;
            padding: 0 10px 5px 10px;
            align-items: center;
            text-align: left;
            border-bottom: 1px solid theme.$color-light-grey;
            font-weight: theme.$font-medium;
        }
        .completionValuesWrapper {
            @include common.flexColumn;
            height: calc(100% - 40px);
            width: calc(100% - 20px);
            padding: 0 10px;
            .completionTitle {
                width: calc(100% - 10px);
                cursor: pointer;
                font-size: 13px;
                padding: 5px;
                &:not(:last-of-type) {
                    border-bottom: 1px dotted theme.$color-lightest-grey;
                }
                .boldHighlight {
                    font-weight: theme.$font-medium;
                }
            }
        }
    }
}
:export {
    lightGrey: theme.$color-light-grey;
}
