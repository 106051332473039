@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.unknownFieldType {
    color: theme.$color-red;
    border: 1px solid theme.$color-red;
    border-radius: 5px;
    padding: 10px;
}

.fieldLabel {
    margin-right: 10px;
}

.datasetSectionText {
    font-size: 20px;
}

.emptyFieldLabel {
    color: rgba(theme.$color-grey, 0.5);
    font-style: italic;
}
