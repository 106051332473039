@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.signOffWrapper {
    margin-left: 5px;
    .iconWrapper {
        position: relative;
        .iconValue {
            position: absolute;
            @include common.centreFlexRow;
            justify-content: center;
            top: 12px;
            left: 17px;
            width: 18px;
            height: 18px;
            border: solid theme.$color-primary-night 0.8px;
            border-radius: 50%;
            background-color: theme.$color-white;
            pointer-events: none;
        }
    }
}

.signOffAnswerIndicator {
    @include common.flexRow;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 5px 0;
    .indicatorWrapper {
        opacity: 0.5;
        &:hover {
            opacity: 0.25;
            cursor: pointer;
        }
    }
    .onClickOpacity {
        opacity: 1;
    }
}

.signOffNotesWrapper {
    @include common.flexColumn;
    align-items: center;
    width: 70vw;
    .fieldLabel {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        justify-self: flex-start;
        text-align: left;
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
    .conversationResponseWrapper {
        @include common.flexColumn;
        width: 100%;
        height: calc(100% - 83px);
        .signOffNotesConversation {
            @include common.flexColumn;
            width: 100%;
        }
        .signOffNotesAddNotes {
            @include common.centreFlexColumn;
            width: 100%;
            font-family: theme.$font-family;
            font-size: 18px;
            border: 1px solid theme.$color-primary-night;
            border-radius: 5px;
            margin-top: 5px;
            padding: 5px 0;
            height: 200px;
        }
    }
}

.dropdownFieldWrapper {
    @include common.flexRow;
    width: 100%;
    justify-content: center;
    .dropdownField {
        width: 100%;
        max-width: 300px;
    }
}

.signOffNotesAddNotesHeader {
    @include common.flexColumn;
    align-items: center;
    width: 100%;
    font-family: theme.$font-family;
    font-size: 20px;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
    padding-top: 5px;
}

.conversationNotesWrapper {
    @include common.flexColumn;
    width: calc(100% - 2px);
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    position: relative;
}

.conversationScrollableWrapper {
    @include common.flexColumn;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    align-items: center;
    padding: 5px;
    .message {
        @include common.flexColumn;
        align-items: center;
        height: fit-content;
        width: 100%;
        .signOffNotesConversationWrapper {
            @include common.flexRow;
            width: 80%;
            align-self: flex-start;
            .messageWrapper {
                @include common.flexRow;
                align-items: flex-start;
                border-radius: 5px;
                margin-bottom: 15px;
                margin-left: 5px;
                background-color: theme.$color-mandatory-light;
                width: 100%;
                border: 1px solid theme.$color-grey;
                .messageIconWrapper {
                    @include common.flexRow;
                    align-items: flex-start;
                    height: calc(100% - 10px);
                    border-right: 1px solid theme.$color-grey;
                    margin-right: 5px;
                    padding: 5px;
                }
                .messageContentWrapper {
                    @include common.flexColumn;
                    width: calc(100% - 46px);
                    .dropdownLabel {
                        padding: 5px 10px 0 10px;
                        font-weight: theme.$font-bold;
                        font-size: 18px;
                    }
                }
            }
            .messageResponseWrapper {
                background-color: theme.$color-body-disabled-light;
            }
        }
        .userMessage {
            align-self: flex-end;
        }
    }
    .messageMetaData {
        @include common.spaceBetweenFlexRow;
        width: 80%;
        margin-bottom: 5px;
        align-self: flex-start;
        .displayName {
            font-weight: theme.$font-medium;
            font-size: 14px;
            color: theme.$color-primary-night;
        }
        .messageDate {
            text-align: center;
            font-weight: theme.$font-medium;
            color: theme.$color-grey;
            font-size: theme.$font-size-h7;
        }
    }
    .centralisedData {
        align-self: flex-end;
        justify-content: flex-end;
    }
}

.signedOffScrollableHeight {
    height: calc(100% - 20px);
}

.editableNoteWrapper {
    width: calc(100% - 10px);
    padding: 0 5px;
    .smileyWrapper {
        @include common.flexColumn;
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    lightGrey: theme.$color-light-grey;
    amethyst: theme.$color-amethyst;
    green: theme.$color-green;
    amber: theme.$color-amber;
    red: theme.$color-red;
    gold: theme.$color-gold;
    white: theme.$color-white;
}
