@use "../../../../styles/theme" as theme;
@use "../../../../styles/common" as common;

.historicalSliderWrapper {
    width: calc(100% - 70px);
    margin: 5px 35px 35px 35px;
}

.marker {
    color: theme.$color-primary-night;
    font-family: theme.$font-family;
    font-size: 14px;
    overflow: visible;
    font-weight: theme.$font-medium;
    white-space: nowrap;
}

.iconWrapper {
    @include common.flexRow;
}

.overlayWrapper {
    @include common.flexColumn;
    word-wrap: break-word;
    .contentDescription {
        color: theme.$color-primary-night;
        padding-bottom: 3px;
        font-weight: theme.$font-bold;
        font-size: 14px;
    }
    .contentUsername,
    .contentType,
    .contentExecutedDate,
    .contentModifiedDate,
    .isLegacy {
        padding-top: 4px;
        font-weight: theme.$font-medium;
    }
    .deleteAmendmentWrapper {
        @include common.spaceBetweenFlexRow;
        @include common.transparentButton;
        padding: 3px 0 0 0;
        margin-top: 3px;
        border-top: 1px solid theme.$color-primary-night;
        font-size: 12px;
        &:hover {
            color: theme.$color-red;
        }
    }
}

:export {
    french: theme.$color-french;
    primary:theme.$color-primary-night;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.3);
    restatementGrey: theme.$color-grey;
    lightGrey: theme.$color-lightest-grey;
}
