@use '../../styles/common' as common;
@use '../../styles/theme' as theme;

.playbookWrapper {
    @include common.flexColumn;
    height: 100%;
    width: calc(100% - 40px);
    padding: 0 20px;
    .playbookHeader {
        display: flex;
        align-items: center;
        width: 100%;
        @include common.tablePageTitle;
    }
    .playbookContent {
        @include common.flexColumn;
        height: calc(100% - 70px);
        padding: 20px 0;
    }
}

.playbookViewWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .playbookPreview {
        @include common.flexColumn;
        height: calc(100% - 122px);
        border-top: 1px solid theme.$color-grey;
    }
    .buttonWrapper {
        @include common.flexRow;
        height: 50px;
        width: calc(100% - 10px);
        padding: 0 5px;
        border-top: 1px solid theme.$color-grey;
    }
}

.suggestedChangesModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 70vh;
    .suggestedChangesWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .provisionWrapper {
            @include common.flexColumn;
            justify-content: space-evenly;
            height: calc(100% - 114px);
            width: 100%;
            margin-bottom: 10px;
        }
        .buttonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.confirmationModalWrapper {
    @include common.flexColumn;
    width: 400px;
    .confirmationModalHeader {
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .confirmationModalSubheader {
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.newMessageWrapper {
    @include common.flexColumn;
    width: 100%;
    .newMessageHeader {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        color: theme.$color-primary-night;
        cursor: pointer;
        margin-bottom: 5px;
        &:hover {
            color: theme.$color-french;
        }
        .newMessageCollapseIcon {
            @include common.centreFlexRow;
            margin-right: 5px;
        }
        .newMessageTitle {
            font-weight: theme.$font-medium;
        }
    }
}

:export {
    white: theme.$color-white;
}
