@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.playbookWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.playbookWizardWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
}

.contentWrapper {
    @include common.flexColumn;
    height: calc(100vh - 249px);
    width: 100%;
    overflow: hidden;
}

.generalTabWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    text-align: initial;
}

.versionControlWrapper {
    @include common.flexRow;
    align-items: center;
    min-width: 175px;
    .versionWrapper {
        @include common.flexRow;
        margin-right: 10px;
        .versionInputLabel {
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 5px;
            color: theme.$color-primary-night;
        }
        .versionTooltipWrapper {
            align-self: center;
        }
    }
    .versionInput {
        @include common.flexRow;
        .versionNumber {
            width: 30px;
            border-radius: 5px;
            border: 1px solid theme.$color-primary-night;
            outline: none;
            &:focus {
                border: 1px solid theme.$color-french;
            }
        }
        .versionSeparator {
            font-weight: theme.$font-bold;
            margin: 5px;
        }
    }
}

.isSystemWrapper {
    margin-top: 10px;
    @include common.flexRow;
    .inputLabel {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-right: 5px;
        color: theme.$color-primary-night;
    }
}

.buildWrapper {
    height: 100%;
    text-align: left;
    @include common.flexColumn;
    justify-content: space-between;
    .addProvisionButton {
        @include common.flexRow;
    }
}

.buildTitlePlaybookViewWrapper {
    float: right;
    width: fit-content;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
    font-size: theme.$font-size-h4;
    margin-bottom: 10px;
    .buildPlaybookViewTitleWrapper {
        @include common.flexRow;
        align-items: center;
        .buildPlaybookViewTitle {
            align-items: center;
            justify-content: space-between;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            width: fit-content;
            margin-bottom: 5px;
            margin-right: 5px;
            text-decoration: underline;
        }
    }
    .buildPlaybookViewToggleWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: space-evenly;
        justify-content: flex-start;
        .buildPlaybookViewToggleTitle {
            margin: 0px 20px 0px 5px;
            color: theme.$color-primary-night;
        }
    }
}

@mixin buildSectionInputWrapper {
    border-radius: 5px;
    border: 1px solid theme.$color-primary-night;
    margin-bottom: 10px;
    padding: 10px;
}

.provisionButtonWrapper {
    height: 50px;
    @include common.flexRow;
}

.publishWrapper {
    @include common.flexColumn;
    padding: 5px;
    .publishError {
        display: flex;
        align-items: center;
        height: 30px;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
    }
}

.draftPublishWrapper {
    @include common.flexColumn;
    margin: 5px 0;
    width: 500px;
    .draftPublishToggleWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .draftPublishLabel {
            font-weight: theme.$font-medium;
            margin-right: 20px;
        }
        .draftPublishToggle {
            @include common.centreFlexRow;
        }
    }
    .draftPublishInfo {
        font-size: 14px;
    }
}

.suggestedChangesPublishToggleWrapper {
    @include common.flexRow;
    width: 500px;
    align-items: center;
    margin-bottom: 5px;
    .suggestedChangesPublishLabel {
        font-size: 16px;
        font-weight: theme.$font-medium;
        color: theme.$color-red;
        margin-right: 20px;
    }
    .suggestedChangesPublishToggle {
        @include common.centreFlexRow;
    }
}


.saveWrapper {
    @include common.flexColumn;
    width: 500px;
    .saveSubheader {
        font-size: 16px;
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .saveWarning {
        font-size: 16px;
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
}

.playbookViewWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .playbookPreview {
        @include common.flexColumn;
        height: calc(100% - 122px);
        border-top: 1px solid theme.$color-grey;
    }
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        height: 50px;
        width: calc(100% - 10px);
        padding: 0 5px;
        border-top: 1px solid theme.$color-grey;
        .buttonGroup {
            @include common.flexRow;
        }
    }
}

.reorderModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 40vw;
    height: 70vh;
    .reorderWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .scrollableWrapper {
            @include common.flexColumn;
            height: calc(100% - 114px);
            width: 100%;
            margin-bottom: 10px;
            .reorderTile {
                background: theme.$color-french;
                border-radius: 5px;
                padding: 10px;
                margin: 10px;
                &:active {
                    box-shadow: 1px 1px 5px theme.$color-french;
                    background: theme.$color-french-spectrum-2;
                }
                .reorderTileTitle {
                    color: theme.$color-white;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                }
            }
            .instepTile {
                margin-left: 40px;
            }
        }
    }
    .buttonWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
}

.playbookSuggestedChangesWrapper {
    @include common.flexColumn;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.suggestedChangesContentWrapper {
    @include common.flexRow;
    height: calc(100% - 50px);
    border-top: 1px solid theme.$color-grey;
}

.splitViewWrapper {
    @include common.flexRow;
    height: 100%;
}

.suggestedChangesCurrentPlaybook {
    padding: 5px;
    min-height: calc(100vh - 162px);
    flex-grow: 1;
}

.suggestedChangesList {
    padding: 5px;
    flex-grow: 1;
}

.buttonWrapper {
    @include common.flexRow;
    height: 50px;
    width: calc(100% - 10px);
    padding: 0 5px;
    border-top: 1px solid theme.$color-grey;
    .buttonGroupLeft {
        @include common.spaceBetweenFlexRow;
        align-items: center;
        margin-left: 36px;
        flex-grow: 1;
        .saveAndPublishWrapper {
            @include common.flexRow;
        }
    }
    .buttonGroupRight {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
    }
}

.reviewSuggestionModal {
    width: 80vw;
    height: 70vh;
    .reviewSuggestionWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 10px);
        width: 100%;
        margin-bottom: 10px;
        .adminConversationWrapper {
            @include common.flexColumn;
            height: calc(100% - 32px);
            width: 100%;
        }
    }
}

.outstandingToggleWrapper {
    @include common.flexRow;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0;
    .outstandingToggleLabel {
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        margin-right: 10px;
    }
    .outstandingToggleInfo {
        @include common.centreFlexRow;
        margin-right: 5px;
    }
}

.sectionSuggestedChangesWrapper {
    margin-bottom: 10px;
    @include common.flexColumn;
    max-width: 100%;
}

.splitViewSuggestedChangeTilesWrapper {
    @include common.flexRow;
    min-height: 70px;
    position: sticky;
    top: 0;
    background-color: theme.$color-white;
    z-index: 5;
}

.suggestedChangeTilesWrapper {
    @include common.flexRow;
    min-height: 70px;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: theme.$color-white;
    z-index: 5;
}

.suggestedChangeTilesWrapperWithBorder {
    @include common.flexRow;
    min-height: 70px;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: theme.$color-white;
    z-index: 5;
    border-bottom: 1px solid theme.$color-light-grey;
    padding-bottom: 5px;
}

.suggestedChangeAbstractWrapper {
    margin-top: 10px;
}

.suggestedChangeTileWrapper {
    width: 200px;
    border-radius: 5px;
    height: 58px;
    margin: 0 5px 0 0;
    padding: 5px;
    @include common.flexRow;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 5px theme.$color-french;
    }
    .suggestedChangeTileLabelWrapper {
        max-width: calc(100% - 30px);
        color: theme.$color-white;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h6;
    }
    .suggestedChangeMarker {
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h6;
        position: absolute;
        padding: 5px;
        color: theme.$color-white;
        border-radius: 50%;
        top: 0px;
        right: 0px;
    }
}

.resolvedSuggestedChangesModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: theme.$color-white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 70vh;
    .suggestedChangesWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .provisionWrapper {
            @include common.flexColumn;
            justify-content: space-evenly;
            height: calc(100% - 114px);
            width: 100%;
            margin-bottom: 10px;
        }
        .resolvedConversationPlaceholder {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            text-align: center;
            font-weight: theme.$font-medium;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h3;
            color: theme.$color-grey;
        }
        .resolvedSignOff {
            @include common.flexRow;
            justify-content: flex-end;
            align-items: center;
            font-weight: theme.$font-bold;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h3;
            text-align: right;
            padding-right: 10px;
        }
        .resolvedChangesIcon {
            height: 18px;
            width: 18px;
            align-items: center;
            justify-content: center;
            background-color: theme.$color-mandatory;
            border-radius: 50%;
            margin-left: 5px;
        }
        .buttonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

:export {
    lightGrey: theme.$color-light-grey;
    french: theme.$color-french;
    frenchLight: theme.$color-french-spectrum-2;
    white: theme.$color-white;
    primary: theme.$color-primary-night;
    resolved: theme.$color-mandatory-spectrum-1;
    resolvedUnselected: rgba(theme.$color-mandatory-spectrum-1, 0.5);
}
