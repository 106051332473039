@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.chartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .chartHeader {
        @include common.centreFlexRow;
        height: 30px;
        width: 100%;
        margin-bottom: 5px;
        .chartTitle {
            font-size: 20px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            margin-right: 10px;
        }
        .chartDescription {
            @include common.centreFlexRow;
            margin-top: 2.5px;
            margin-right: 5px;
        }
    }
}

.pieChartIcon {
    @include common.centreFlexRow;
    color: theme.$color-primary-night;
    position: absolute;
    border-radius: 50%;
    margin-top: 35px;
}

:export {
    french: theme.$color-french;
    mandatory: theme.$color-mandatory;
    amber: theme.$color-amber;
    grey: theme.$color-french-spectrum-1;
}
