@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.riskToleranceDatasetWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    color: theme.$color-primary-night;
    .riskToleranceDatasetHeader {
        text-align: left;
        padding-left: 20px;
        font-size: 22px;
        font-weight: theme.$font-bold;
    }
    .contentWrapper {
        @include common.flexRow;
        height: calc(100% - 100px);
        width: calc(100% - 20px);
        margin: 10px 10px 0 10px;
        border-top: 1px solid theme.$color-grey;
        .fieldsWrapper {
            @include common.flexColumn;
            justify-content: flex-start;
            width: calc(20% - 11px);
            height: 100%;
            padding-right: 10px;
            border-right: 1px solid theme.$color-grey;
        }
    }
}

.datasetWrapper {
    @include common.flexColumn;
    padding: 5px 0;
    color: theme.$color-primary-night;
    .datasetHeader {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        font-weight: 600;
        cursor: pointer;
        .datasetOpenIcon {
            margin-right: 10px;
        }
    }
}

.datasetRiskFields {
    @include common.flexColumn;
    width: calc(100% - 10px);
    margin-left: 10px;
    .riskFieldWrapper {
        @include common.spaceBetweenFlexRow;
        padding: 5px;
        border: 1px solid theme.$color-light-grey;
        border-radius: 5px;
        margin: 3px 0;
        cursor: pointer;
        &:hover {
            background-color: theme.$color-french-spectrum-1;
            border-color: theme.$color-french-spectrum-3;
        }
        .riskFieldLabel {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-start;
            margin-right: 10px;
            font-size: 15px;
        }
    }
    .selectedRiskField {
        color: theme.$color-white;
        background-color: theme.$color-french;
        border-color: theme.$color-french;
        &:hover {
            color: theme.$color-primary-night;
        }
    }
}

.configWrapper {
    @include common.flexColumn;
    width: 80%;
    height: 100%;
    .configHeader {
        @include common.flexRow;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 20px);
        height: 50px;
        padding: 5px 10px;
        border-bottom: 1px solid theme.$color-grey;
        .configTitle {
            margin-right: 10px;
            text-align: left;
            font-size: 18px;
            font-weight: 700;
        }
        .currencyWrapper {
            @include common.flexRow;
            align-items: center;
            width: 430px;
            justify-content: flex-end;
            .configuredCurrenciesButton {
                cursor: pointer;
                margin: auto;
            }
            .currencySelect {
                width: 200px;
            }
        }
        .configType {
            @include common.centreFlexRow;
        }
    }
    .configBody {
        @include common.flexRow;
        width: 100%;
        height: calc(100% - 61px);
        position: relative;
        .configRiskWrapper {
            width: 70%;
            height: 100%;
            border-right: 1px solid theme.$color-grey;
            .riskConfiguration {
                @include common.flexColumn;
                width: 100%;
                height: calc(100% - 41px);
                .riskAssociatedOption {
                    @include common.flexRow;
                    width: calc(100% - 12px);
                    min-height: 100px;
                    height: fit-content;
                    margin: 10px;
                    border: 1px solid theme.$color-light-grey;
                    border-radius: 5px;
                    .riskAssociatedLabel {
                        @include common.centreFlexRow;
                        height: 100%;
                        width: calc(20% - 6px);
                        min-height: 100px;
                        padding-right: 5px;
                        border-right: 1px solid theme.$color-grey;
                        font-weight: 800;
                        color: theme.$color-white;
                        text-shadow: 1px 2px 2px theme.$color-primary-night;
                    }
                    .riskAssociatedValue {
                        @include common.flexRow;
                        min-height: 100px;
                        height: 100%;
                        width: 80%;
                    }
                }
            }
        }
        .configWeightingWrapper {
            width: 30%;
            height: 100%;
            .weightingConfiguration {
                width: 100%;
                height: calc(100% - 41px);
                .weightingWrapper {
                    @include common.flexColumn;
                    width: 100%;
                    height: 100%;
                    .sliderWrapper {
                        padding: 15px 20px;
                        width: calc(100% - 40px);
                    }
                    .includeFieldWrapper {
                        @include common.flexRow;
                        width: 100%;
                        align-items: center;
                        margin-top: 20px;
                        padding-top: 10px;
                        border-top: 1px solid theme.$color-grey;
                        height: 20%;
                        .includeFieldLabel {
                            width: calc(100% - 28px);
                            margin-right: 10px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .configRiskHeader,
        .configWeightingHeader {
            @include common.spaceBetweenFlexRow;
            width: calc(100% - 20px);
            padding: 5px 10px;
            border-bottom: 1px solid theme.$color-grey;
            height: 30px;
            font-weight: 600;
        }
        .currencyOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: theme.$color-white;
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}

.emptyContentWrapper {
    @include common.flexRow;
    justify-content: center;
    height: calc(100% - 70px);
    width: calc(100% - 20px);
    margin: 10px 10px 0 10px;
    border-top: 1px solid theme.$color-grey;
    padding: 20px 0;
}

.sectionWrapper {
    @include common.flexColumn;
    padding: 5px 0;
    color: theme.$color-primary-night;
    .sectionHeader {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        font-weight: 600;
        cursor: pointer;
        .sectionOpenIcon {
            margin-right: 10px;
        }
    }
}

.markerWrapper {
    font-family: theme.$font-family;
    font-weight: 600;
    font-size: 14px;
    width: 150px;
    text-align: left;
    color: theme.$color-primary-night;
}

.horizontalWeightingWrapper {
    @include common.centreFlexRow;
    align-self: center;
    width: calc(100% - 40px);
    .horizontalSliderWrapper {
        height: 60%;
        min-height: 40px;
        width: calc(100% - 140px);
    }
}

.riskToleranceHelpWrapper {
    @include common.flexColumn;
    font-family: theme.$font-family;
    width: 60vw;
    height: 70vh;
    padding: 0 10px;
    color: theme.$color-primary-night;
    .helpContent {
        @include common.flexColumn;
        height: calc(100% - 56px);
        .generalHelpContent {
            @include common.flexColumn;
            border-bottom: 1px solid theme.$color-primary-night;
            font-size: 15px;
            height: 220px;
            .generalHelp {
                margin-bottom: 10px;
            }
        }
        .riskLevelContentWrapper {
            @include common.flexColumn;
            height: calc(100% - 220px);
            .riskLevelHelpTitle {
                font-size: 18px;
                font-weight: 600;
                margin: 10px 0;
            }
            .riskLevelContent {
                @include common.flexColumn;
                width: 100%;
                height: calc(100% - 44px);
                .riskLevelWrapper {
                    @include common.flexRow;
                    align-items: center;
                    margin-bottom: 10px;
                }
            }
        }
    }
}


.configuredCurrenciesModal {
    @include common.flexColumn;
    font-family: theme.$font-family;
    width: 60vw;
    height: 70vh;
    padding: 0 10px;
    color: theme.$color-primary-night;
    .currencyFilter {
        font-family: theme.$font-family;
        font-size: 13px;
        outline: none;
        padding: 3px;
        border: 1px solid theme.$color-primary-night;
        color: theme.$color-primary-night;
        border-radius: 5px;
        width: 200px;
        margin-left: 10px;
        &:focus {
            border: 1px solid theme.$color-french;
        }
    }
    .currencies {
        height: calc(100% - 106px);
        .columnsWrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .currencyWrapper {
                @include common.flexColumn;
                min-width: 150px;
                width: calc((100% - 180px) / 4);
                padding: 10px;
                border: solid 1px theme.$color-light-grey;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                margin: 10px;
                &:hover {
                    opacity: 0.8;
                }
                .currencyLabel {
                    font-family: theme.$font-family;
                    font-weight: theme.$font-bold;
                    font-size: theme.$font-size-h4;
                    text-decoration: underline;
                    margin-bottom: 10px;
                }
                .riskAssociatedOption {
                    @include common.flexRow;
                    .riskLevel {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                    .riskComparator {
                        display: flex;
                        margin-right: 5px;
                        width: 25px;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

:export {
    red: theme.$color-red;
    amber: theme.$color-amber;
    gold: theme.$color-gold;
    yellowGreen: theme.$color-yellow-green;
    green: theme.$color-mandatory;
    white: theme.$color-white;
    grey: rgba(theme.$color-grey, 0.3);
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    frenchSelected: theme.$color-french-spectrum-2;
}
