@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin queryMarker($backgroundColor: theme.$color-french) {
    @include common.backgroundButton($backgroundColor, theme.$color-white, 2px solid $backgroundColor);
    border-radius: 50px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: 0.8;
        box-shadow: 1px 1px 5px theme.$color-grey;
    }
    height: 10px;
    width: 10px;
    margin-right: 5px;
}

.queryModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 50vw;
    max-height: 90vh;
    min-height: 50vh;
    padding: 0 10px;
    .queryModalWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        width: 100%;
        padding: 10px;
        .queryButtonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
        }
    }
}

.adminQueryToggleWrapper {
    @include common.flexColumn;
}

.queryToggleWrapper {
    @include common.flexRow;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 4px);
    margin: 5px 2px;
    .queryToggleLabel {
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h6;
        margin-right: 5px;
    }
}

.raisedQueryWrapper {
    @include queryMarker(theme.$color-amber);
}

.resolvedQuery {
    @include queryMarker(theme.$color-green);
}

.pastDefinitionQuery {
    @include queryMarker(theme.$color-light-grey);
}

.futureDefinitionQuery {
    @include queryMarker(theme.$color-grey);
}

:export {
    white: theme.$color-white;
}
