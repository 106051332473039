@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

.filterSettingsWrapper {
    @include common.flexColumn;
    height: 100%;
    .inputWithLabel {
        @include common.flexRow;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        .dropdownWidth {
            width: 300px;
        }
    }
    .filterInputLabel {
        margin-right: 5px;
        font-weight: theme.$font-medium;
        justify-self: flex-start;
    }
    .exclusiveWrapper {
        align-self: flex-end;
        width: 100%;
    }
    .dateWrapper {
        justify-content: center;
        @include common.flexColumn;
        .fromDateWrapper {
            @include common.spaceBetweenFlexRow;
            align-items: center;
            margin-bottom: 8px;
            .fromDateWithLabel {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-end;
                flex: 1;
            }
        }
        .dateLabel {
            font-weight: theme.$font-medium;
            text-align: left;
            width: 100px;
        }
        .toDateWithLabel {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 8px;
        }
    }
}