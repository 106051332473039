@use "../../../styles/theme" as theme;
@use "../../../styles/common" as common;

@mixin input {
    padding: 8px;
    margin: 2px 5px;
    display: inline-block;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border: 1px solid theme.$color-french;
    }
}

.builderModal {
    @include common.centreFlexColumn;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    .builderWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        height: 80vh;
        width: 80vw;
        padding: 10px;
        .builderButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            padding-top: 10px;
        }
    }
}

.definitionBuilderWrapper {
    @include common.flexColumn;
    width: 100%;
    height: calc(100% - 58px);
    color: theme.$color-primary-night;
    .builderHeaderWrapper {
        @include common.spaceBetweenFlexRow;
        .builderHeaderLabel {
            font-size: 30px;
            font-weight: theme.$font-bold;
        }
        .builderHeaderButtonWrapper {
            @include common.flexRow;
            align-items: center;
            .reorderSectionsButton {
                @include common.centreFlexRow;
                margin-right: 10px;
            }
            .builderHelp {
                @include common.centreFlexRow;
                @include common.transparentButton;
                border: 2px solid theme.$color-primary-night;
                border-radius: 50%;
                font-size: 16px;
                font-weight: theme.$font-bold;
                font-family: theme.$font-family;
                min-height: 28px;
                min-width: 28px;
                &:hover {
                    border-color: theme.$color-french;
                    color: theme.$color-french;
                }
            }
        }
    }
    .builderHeader {
        @include common.spaceBetweenFlexRow;
        .leftHeader {
            @include common.spaceBetweenFlexRow;
            width: 80%;
            .builderTitle {
                flex: 1;
                padding: 10px;
                margin: 10px 20px 10px 0;
                display: inline-block;
                border: 1px solid theme.$color-grey;
                border-radius: 5px;
                box-sizing: border-box;
                font-family: theme.$font-family;
                &:focus {
                    outline: none;
                    border: 1px solid theme.$color-french;
                }
            }
            .builderTitleRequired {
                border: 1px solid theme.$color-red;
            }
            .agencyToggleWrapper {
                @include common.flexRow;
                align-items: center;
                .agencyToggleTitle {
                    font-size: 16px;
                    font-family: theme.$font-family;
                    margin-right: 10px;
                    font-weight: theme.$font-medium;
                }
            }
        }
        .builderTypeWrapper {
            @include common.flexRow;
            align-items: center;
            color: theme.$color-french;
            .builderType {
                font-size: 20px;
                font-family: theme.$font-family;
                margin-right: 5px;
                font-weight: theme.$font-medium;
            }
        }
    }
    .builderContent {
        @include common.flexRow;
        flex: 1;
        .builderTable {
            width: 80%;
        }
    }
}

.fieldListWrapper {
    @include common.flexColumn;
    width: calc(20% - 10px);
    padding: 0 0 10px 10px;
    .fieldListItem {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        background-color: white;
        width: 100%;
        padding: 2px 8px;
        margin: 3px 0;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
        }
        &:disabled {
            color: theme.$color-grey;
            cursor: not-allowed;
            box-shadow: none;
        }
        .fieldListLabel {
            font-family: theme.$font-family;
            font-size: theme.$font-size-h5;
            font-weight: theme.$font-medium;
            margin-right: 20px;
        }
    }
    .firstItem {
        margin-top: 0;
    }
    .lastItem {
        margin-bottom: 0;
    }
}

.tableBuilder {
    flex: 1;
    height: 100%;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .tableBuilderDroppable {
        width: 100%;
    }
    .placeholder {
        @include common.centreFlexRow;
        height: 100%;
        width: 100%;
        .placeholderText {
            font-size: 20px;
        }
    }
}

.contentWrapper {
    @include common.flexRow;
    align-items: center;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 5px;
    margin: 7px 10px;
    background-color: theme.$color-white;
    .contentLabel {
        @include input;
        width: 30%;
    }
    .contentLabelRequired {
        border: 1px solid theme.$color-red;
    }
    .contentDescription {
        @include input;
        width: 40%;
    }
    .type {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        background-color: theme.$color-white;
        padding: 0 8px;
        min-width: 20%;
        margin-right: 10px;
        font-weight: theme.$font-medium;
        .typeLabel {
            font-size: theme.$font-size-h5;
            margin-right: 10px;
        }
        .typeIcons {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
            .datasetLinkWrapper {
                @include common.centreFlexRow;
                margin-right: 10px;
            }
        }
    }
    .removeRowWrapper {
        &:hover {
            color: theme.$color-red;
        }
    }
}

.removeRowWrapper,
.settingsWrapper,
.groupOpenToggle,
.refButton {
    @include common.centreFlexRow;
    @include common.transparentButton;
    height: 100%;
    &:hover {
        color: theme.$color-french;
    }
}

.systemIdButton {
    @include common.centreFlexRow;
    @include common.transparentButton;
    border: 1px solid theme.$color-primary-night;
    border-radius: 50%;
    font-size: 12px;
    font-weight: theme.$font-bold;
    font-family: theme.$font-family;
    height: 25px;
    width: 25px;
    &:hover {
        border-color: theme.$color-french;
        color: theme.$color-french;
    }
}

.refAndIdWrapper {
    @include common.centreFlexRow;
    padding: 0 8px;
    min-width: 10%;
}

.editDatasetButton {
    @include common.tableIconButton;
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    aqua: theme.$color-aqua;
    whiteColor: theme.$color-white;
    grey: theme.$color-grey;
    red: theme.$color-red;
}
