@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.carouselWrapper {
    @include common.centreFlexRow;
    position: relative;
    .carouselContentWrapper {
        @include common.centreFlexRow;
        width: 100%;
        height: calc(100% - 15px);
        margin-top: 0;
        .nextArrow {
            cursor: pointer;
        }
        .previousArrow {
            transform: rotateY(180deg);
            cursor: pointer;
        }
        .placeholderArrow {
            width: 28px;
        }
        .carousel {
            @include common.flexRow;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            .tile {
                @include common.flexRow;
                position: absolute;
                transition: left 0.5s ease-out;
            }
        }
    }
    .markers {
        @include common.centreFlexRow;
        position: absolute;
        .marker {
            margin: 2.5px;
            width: 10px;
            height: 10px;
            border-radius: 50px;
            display: inline-block;
            background-color: theme.$color-french-spectrum-3;
            opacity: 1;
            transition: opacity 0.1s linear;
            cursor: pointer;
            &:hover {
                opacity: 0.65;
            }
        }
        .selectedMarker {
            background-color: theme.$color-french-spectrum-1;
        }
    }
}
