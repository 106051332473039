@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.previewWrapper {
    @include common.flexColumn;
    align-items: center;
    width: 100%;
    height: 100%;
    .previewTitle {
        font-size: 30px;
        font-weight: theme.$font-medium;
        width: 100%;
        border-bottom: 1px solid theme.$color-grey;
        padding-bottom: 10px;
    }
}
.emptyPreviewTitle {
    color: rgba(theme.$color-grey, 0.5);
}
