@use '../../styles/theme' as theme;
@use '../../styles/common' as common;

@mixin sideMenu {
    display: absolute;
    height: 100%;
    padding-top: 10px;
    width: 300px;
    background-color: theme.$color-primary-night;
}

.wrapper {
    @include common.flexColumn;
    flex: 1;
    height: 100vh;
    overflow: hidden;
}

.main {
    @include common.flexColumn;
    flex: 1;
    overflow: hidden;
    font-family: theme.$font-family;
}

.content {
    @include common.centreFlexColumn;
    flex-grow: 1;
    width: 100%;
    height: calc(100vh - 100px);
    overflow: hidden;
    background-color: theme.$color-white;
    position: relative;
}

.topNavWrapper {
    @include common.spaceBetweenFlexRow;
    padding: 0 20px;
    margin-bottom: 10px;
    min-height: 90px;
    height: 90px;
    background-color: theme.$color-primary-night;
    .logoWrapper {
        @include common.centreFlexRow;
        height: 100%;
        width: fit-content;
        cursor: pointer;
        .logo {
            height: 70px;
        }
    }
    .usernameWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        width: 25%;
        margin: 30px 0 20px 0;
        .usernameButton {
            @include common.transparentButton(theme.$color-white, 2px solid theme.$color-white);
            border-radius: 50%;
            height: 40px;
            width: 40px;
            padding: 1px;
        }
    }
    .menuWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 25%;
        .menuButton {
            @include common.transparentButton(theme.$color-white);
            margin-right: 20px;
            &:hover {
                color: theme.$color-aqua;
            }
        }
        .backButton {
            font-family: theme.$font-family;
            font-weight: theme.$font-bold;
            font-size: 20px;
            color: theme.$color-white;
            text-decoration: none;
            &:hover {
                color: theme.$color-aqua;
            }
        }
    }
}

.leftMenu {
    @include sideMenu();
    .leftMenuWrapper {
        @include common.flexColumn;
        height: calc(100% - 10px);
        justify-content: space-between;
        .leftMenuButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;
            padding: 20px;
            .leftMenuButton {
                @include common.transparentButton(theme.$color-aqua);
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.scrollableWrapper {
    @include common.flexColumn;
    width: 100%;
    height: calc(100% - 1px);
}

.rightMenu {
    @include sideMenu();
    position: absolute;
    right: 0;
    .rightMenuWrapper {
        @include common.flexColumn;
        justify-content: space-between;
        width: 300px;
        .rightMenuUserWrapper {
            @include common.flexRow;
            width: calc(100% - 40px);
            align-self: flex-end;
            align-items: center;
            padding: 20px;
            .rightMenuUser {
                display: flex;
                align-self: flex-end;
                border: 2px solid theme.$color-white;
                border-radius: 50%;
                height: 34px;
                width: 34px;
                padding: 1px;
                margin-left: 10px;
                color: theme.$color-white;
            }
            .rightMenuUsername {
                display: block;
                width: calc(100% - 52px);
                text-align: end;
                font-size: 25px;
                height: 34px;
                font-weight: theme.$font-bold;
                text-overflow: ellipsis;
                font-family: theme.$font-family;
                color: theme.$color-aqua;
                overflow: hidden;
            }
        }
    }
}

.sideMenuItem {
    @include common.transparentButton(theme.$color-aqua);
    width: 100%;
    font-size: 18px;
    padding: 10px 20px;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    text-decoration: none;
    &:disabled {
        color: theme.$color-grey;
        &:hover {
            background-color: rgba(theme.$color-light-grey, 0.4);
        }
    }
    &:hover {
        background-color: rgba(theme.$color-aqua, 0.4);
    }
}

.disabledMenuItem {
    color: theme.$color-grey;
    &:hover {
        background-color: rgba(theme.$color-light-grey, 0.4);
    }
}

.selectedSideMenuItem {
    @include common.transparentButton;
    background-color: theme.$color-aqua;
    width: 100%;
    font-size: 18px;
    padding: 10px 20px;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
}

.openSideMenuItem {
    width: 100%;
    font-size: 18px;
    padding: 10px 20px;
    font-weight: theme.$font-bold;
}

.rightMenuItem {
    text-align: right;
}

.leftMenuItem {
    text-align: left;
}

.childItemPaddingLeft {
    padding-left: 40px;
}

.sectionWrapper {
    @include common.flexColumn;
}

.openCollapsibleChildren {
    border-bottom: 1px solid rgba(theme.$color-aqua, 0.7);
}

.sectionSpacer {
    width: 100%;
    border-top: 1px solid rgba(theme.$color-aqua, 0.7);
}

.unauthorisedModuleWrapper {
    @include common.flexColumn;
    width: 40vw;
    .warningHeader {
        font-size: 18px;
        font-weight: 600;
        color: theme.$color-red;
    }
    .unauthorisedModule {
        @include common.flexColumn;
        align-items: center;
        width: calc(100% - 12px);
        padding: 5px;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        margin: 10px 0;
        .featuresTitle {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .availableFeature {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            padding: 5px 0;
            .availableFeatureLabel {
                max-width: calc(100% - 40px);
                font-weight: 600;
                font-size: 18px;
                margin-left: 10px;
            }
        }
    }
    .unauthorisedModuleAccess {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: 600;
        color: theme.$color-primary-night;
    }
}
