@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin titleWrapper {
    margin-bottom: 10px;
    text-align: center;
    font-weight: theme.$font-bold;
    color: theme.$color-french;
}

@mixin changeMarker($top: -8px, $right: 0px) {
    max-height: 14px;
    position: relative;
    background-color: rgba(theme.$color-red, 0.9);
    color: theme.$color-white;
    padding: 3px;
    border-radius: 50%;
    top: $top;
    right: $right;
}

.previewWrapper {
    @include common.flexRow;
    height: 100%;
    text-align: left;
    justify-content: space-between;
}

.contentsWrapper {
    @include common.flexColumn;
    flex: 2 1 0;
    padding: 20px;
    .previewTitleWrapper {
        @include common.flexColumn;
        font-size: theme.$font-size-h3;
        height: fit-content;
        align-items: center;
        .previewTitle {
            @include titleWrapper;
            font-size: theme.$font-size-h2;
            color: theme.$color-primary-night;
        }
        .previewInfo {
            @include titleWrapper;
            font-size: theme.$font-size-h3;
            color: theme.$color-primary-night;
        }
    }
    .previewAbstractWrapper {
        height: fit-content;
        @include common.flexColumn;
    }
}

.readOnlySubheader {
    @include common.centreFlexRow;
    @include titleWrapper;
    font-size: theme.$font-size-h2;
    color: theme.$color-primary-night;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px dotted theme.$color-primary-night;
    max-width: 100vw;
}

.readOnlySection {
    margin-top: 20px;
    max-width: 100vw;
    .readOnlySectionTitleWrapper {
        @include common.flexRow;
        width: calc(100% - 10px);
        align-content: flex-start;
        font-size: theme.$font-size-h4;
        font-weight: theme.$font-medium;
        color: theme.$color-french;
        margin-bottom: 10px;
        justify-content: space-between;
        .readOnlySectionTitle {            
            position: relative;
            @include common.flexRow;
            max-width: 90%;
            .readOnlySectionTitleOverflow {
                min-width: 100%;
            }
            .suggestedChangeMarker {
                @include changeMarker;
            }
        }
        .readOnlySectionLinksWrapper {
            display: flex;
            justify-content: center;
        }
    }
    .readOnlyStakeholdersWrapper {
        margin-bottom: 10px;
        @include common.flexRow;
        .readOnlyStakeholdersTitle {
            font-size: theme.$font-size-h4;
            font-weight: theme.$font-medium;
            color: theme.$color-french;
            margin-right: 5px;
            width: fit-content;
        }
        .readOnlyStakeholdersInput {
            flex: 2 1 0;
            text-align: left;
        }
    }
    .readOnlySectionList {
        @include common.flexColumn;
        .readOnlyProvisionWrapper {
            width: calc(100% - 2px);
            border: 1px solid theme.$color-french;
            margin-bottom: 10px;
            .readOnlyProvisionTitleWrapper {
                @include common.flexRow;
                background-color: theme.$color-french;
                color: theme.$color-white;
                font-size: theme.$font-size-h4;
                font-weight: theme.$font-medium;
                padding: 5px;
                height: 30px;
                align-items: center;
                .readOnlyProvisionTitleMarkerWrapper {
                    @include common.flexRow;
                    position: relative;
                    max-width: 95%;
                    .readOnlyProvisionTitle {
                        font-weight: theme.$font-bold;
                        margin-right: 5px;
                        width: fit-content;
                    }
                    .readOnlyProvisionTitleInput {
                        flex: 2 1 0;
                        text-align: left;
                        width: 100%;
                    }
                }
                .suggestedChangeMarker {
                    @include changeMarker;
                }
            }
            .readOnlyWysiwygWrapper {
                @include common.flexRow;
                position: relative;
                .suggestedChangeMarker {
                    @include changeMarker(2px, 10px);
                }
            }
        }
    }
    .readOnlySectionTable {
        @include common.flexRow;
        justify-content: space-between;
        .readOnlyProvisionWrapper {
            border: 1px solid theme.$color-french;
            margin-bottom: 10px;
            width: calc(50% - 2px);
            .readOnlyProvisionTitleWrapper {
                @include common.flexRow;
                background-color: theme.$color-french;
                color: theme.$color-white;
                font-size: theme.$font-size-h4;
                font-weight: theme.$font-medium;
                padding: 5px;
                height: 30px;
                align-items: center;
                .readOnlyProvisionTitle {
                    font-weight: theme.$font-bold;
                    margin-right: 5px;
                    min-width: fit-content;
                }
            }
        }
    }
    .readOnlyPreferredTerms {
        width: calc(100% - 2px);
        border: 1px solid theme.$color-french;
        margin-bottom: 10px;
        border-radius: 5px;
        .readOnlyPreferredTermsTitleWrapper {
            @include common.flexRow;
            background-color: theme.$color-french;
            color: theme.$color-white;
            font-size: theme.$font-size-h4;
            font-weight: theme.$font-medium;
            padding: 5px;
            height: 30px;
            align-items: center;
            .readOnlyPreferredTermsTitle {
                font-weight: theme.$font-bold;
                margin-right: 5px;
                width: fit-content;
            }
        }
        .readOnlyWysiwygWrapper {
            @include common.flexRow;
            position: relative;
            .suggestedChangeMarker {
                @include changeMarker(2px, 10px);
            }
        }
    }
}

.readOnlyDeviationsWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    .readOnlyDeviationTitleWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 10px;
        .readOnlyDeviationCollapseTitleWrapper {
            @include common.flexRow;
            position: relative;
            align-items: center;
            .readOnlyDeviationTitle {
                font-weight: theme.$font-medium;
                color: theme.$color-french;
                font-family: theme.$font-family;
            }
            .readOnlyDeviationsCollapseIcon {
                margin-right: 5px;
                color: theme.$color-french;
            }
        }
        .suggestedChangeMarker {
            @include changeMarker;
        }
    }
}

.scrollableTableWrapper {
    border-bottom: solid theme.$color-grey 1px;
    border-left: solid theme.$color-grey 1px;
    border-right: solid theme.$color-grey 1px;
    border-radius: 5px;
}

.readOnlyDeviationTableWrapper {
    @include common.flexColumn;
    min-width: fit-content;
    .readOnlyTableHeaders {
        border-radius: 5px 5px 0 0;
        border: solid theme.$color-french 1px;
        background-color: theme.$color-french;
        @include common.flexRow;
        .readOnlyColumnHeaderWrapper {
            height: 100%;
        }
        .readOnlyColumnHeader {
            font-weight: theme.$font-medium;
            font-size: 14px;
            margin-left: 5px;
            color: theme.$color-white;
            font-family: theme.$font-family;       
        }
    }
    .readOnlyTableRowWrapper {
        @include common.flexRow;
        border-bottom: solid theme.$color-grey 1px;
        color: theme.$color-primary-night;
        font-family: theme.$font-family;
        font-weight: 400;
        &:last-of-type {
            border-bottom: none;
        }
        .readOnlyTopicWrapper {
            display: flex;
            width: 17%;
            min-width: 140px;
            padding: 10px 5px;
            border-right: solid theme.$color-grey 1px;
            .suggestedChangeMarker {
                @include changeMarker(-8px, 15px);
            }
        }
        .readOnlyWysiwygColumnWrapper {
            width: 30%;
            min-width: 400px;
            min-height: 100%;
            border-right: solid theme.$color-grey 1px;
            .readOnlyWysiwygWrapper {
                @include common.flexRow;
                position: relative;
                .suggestedChangeMarker {
                    @include changeMarker(2px, 10px);
                }
            }
        }
        .readOnlyTextColumnWrapper {
            display: flex;
            width: 13%;
            min-width: 140px;
            border-right: solid theme.$color-grey 1px;
            padding: 10px 5px;
            .textOverflowWrapper {
                height: fit-content;
                width: 100%;
                position: relative;
            }
            .suggestedChangeMarker {
                @include changeMarker(-8px, 10px);
            }
        }
        .readOnlyApprovalDateWrapper {
            display: flex;
            width: 10%;
            min-width: 140px;
            padding: 10px 5px;
            font-weight: theme.$font-medium;
            .textOverflowWrapper {
                height: fit-content;
                width: 100%;
                position: relative;
            }
            .suggestedChangeMarker {
                @include changeMarker(-8px, 16px);
            }
        }
    }
}

.queriesWrapper {
    min-height: 20px;
    @include common.flexRow;
    justify-content: flex-end;
}

:export {
    lightGrey: theme.$color-light-grey;
    white: theme.$color-white;
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    green: theme.$color-green;
    red: theme.$color-red;
}
