@use '../../styles/common' as common;
@use '../../styles/theme' as theme;

.annexBuilderContent {
    @include common.flexRow;
    padding-bottom: 5px;
    .annexBuilderTable {
        width: 70%;
    }
}

.fieldListWrapper {
    @include common.flexColumn;
    width: calc(30% - 10px);
    padding-left: 10px;
    .fieldListItem {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        background-color: white;
        width: 100%;
        padding: 2px 5px;
        margin: 3px 0;
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
            box-shadow: 1px 1px 5px theme.$color-french;
        }
        .fieldListLabel {
            width: calc(100% - 40px);
            font-family: theme.$font-family;
            font-size: 14px;
            font-weight: theme.$font-medium;
            margin-right: 10px;
        }
    }
    .selectedFieldListItem {
        color: theme.$color-grey;
        box-shadow: none;
        &:hover {
            color: theme.$color-grey;
            box-shadow: none;
        }
    }
}

.annexBuilder {
    flex: 1;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .annexBuilderDroppable {
        width: 100%;
    }
}

.fieldWrapper {
    @include common.flexRow;
    font-family: theme.$font-family;
    align-items: center;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 5px;
    margin: 7px 10px;
    background-color: theme.$color-white;
    .fieldContentWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        .label,
        .aliasLabel {
            width: calc(40% - 10px);
            font-family: theme.$font-family;
            padding: 5px 8px;
            margin: 2px 5px;
            display: inline-block;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            box-sizing: border-box;
            &:focus {
                outline: none;
                border: 1px solid theme.$color-french;
            }
        }
        .fieldContent {
            @include common.spaceBetweenFlexRow;
            color: theme.$color-primary-night;
            background-color: theme.$color-white;
            padding: 0 8px;
            width: 60%;
            margin-right: 10px;
            font-weight: theme.$font-medium;
            .fieldLabel {
                font-size: 14px;
                margin-right: 10px;
                flex: 1;
            }
        }
        .removeRowWrapper {
            @include common.centreFlexRow;
            @include common.transparentButton;
            padding: 0;
            margin-left: 3px;
            height: 100%;
            &:hover {
                color: theme.$color-red;
            }
        }
    }
}

.partyWrapper {
    @include common.flexRow;
    align-items: center;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 5px;
    margin: 7px 10px 0 10px;
    background-color: theme.$color-white;
    height: 34px;
    .partyContent {
        @include common.spaceBetweenFlexRow;
        color: theme.$color-primary-night;
        background-color: theme.$color-white;
        padding: 0 8px;
        width: 100%;
        font-weight: theme.$font-medium;
        .fieldLabel {
            font-size: 14px;
            margin-right: 20px;
        }
    }
}

.documentAnnexModal {
    @include common.centreFlexRow;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    height: calc(100vh - 180px);
    width: calc(42vw - 20px);
    .wrapper {
        @include common.flexColumn;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        .contentWrapper {
            @include common.flexColumn;
            width: 100%;
            height: calc(100% - 48px);
        }
        .buttonWrapper {
            @include common.flexRow;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
