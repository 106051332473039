@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wrapper {
    @include common.flexRow;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.dropzone {
    @include common.centreFlexRow;
    height: 200px;
    width: 200px;
    color: theme.$color-grey;
    border: 1px solid theme.$color-grey;
    border-radius: 50%;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    &:hover {
        color: theme.$color-french;
        border: 1px solid theme.$color-french;
    }
}

.dropzoneDisabled {
    &:hover {
        cursor: default;
        color: theme.$color-grey;
        border: 1px solid theme.$color-grey;
    }
}

.updateProfilePicture {
    position: absolute;
    background-color: theme.$color-french;
    color: theme.$color-white;
    padding: 3px;
    border-radius: 50%;
    top: 170px;
    left: 140px;
}

.initials,
.user {
    @include common.centreFlexRow;
    width: 100%;
    height: 100%;
    font-weight: theme.$font-medium;
    font-family: theme.$font-family;
}

.pictureWrapper {
    @include common.flexRow;
    align-items: center;
    height: 100%;
    width: 100%;
    align-self: center;
    overflow: hidden;
    border-radius: 50%;
}
