@use '../../../../styles/common' as common;
@use '../../../../styles/theme' as theme;

.dropdownWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 10px);
    height: 100%;
    min-height: 90px;
    padding: 5px;
    .remainingWrapper {
        @include common.flexRow;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        .remainingLabel {
            margin-right: 10px;
        }
    }
}

.numberWrapper {
    @include common.flexRow;
    align-items: center;
    width: calc(100% - 10px);
    padding: 5px;
    height: 100%;
    min-height: 90px;  
    .numberInputWrapper {
        @include common.flexRow;
        align-items: center;
        width: 70%;
        .numberField {
            @include common.inputField;
            width: calc(100% - 12px);
            height: 16px;
            border: 1px solid theme.$color-grey;
        }
    }
}

.comparatorWrapper {
    @include common.flexRow;
    align-items: center;
    width: calc(30% - 10px);
    margin-right: 10px;
}  

.dateWrapper {
    @include common.flexRow;
    align-items: center;
    width: calc(100% - 10px);
    padding: 5px;
    height: 100%;
    min-height: 90px;   
    .dateInputWrapper {
        @include common.flexRow;
        align-items: center;
        width: 70%;
    }
}

.timeWrapper {
    @include common.flexRow;
    align-items: center;
    width: calc(100% - 10px);
    padding: 5px;
    height: 100%;
    min-height: 90px;   
    .timeValue {
        @include common.inputField;
        width: 30%;
        margin-right: 10px;
        height: 16px;
        border: 1px solid theme.$color-grey;
    }
    .cityValue {
        @include common.flexRow;
        align-items: center;
        width: 30%;
    }
}

:export {
    primary: theme.$color-primary-night;
    grey: theme.$color-grey;
}
