@use '../../styles/theme' as theme;
@use '../../styles/common' as common;

.wrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .tilesWrapper {
        @include common.flexRow;
        height: 100%;
        padding: 0 20px;
        .tilesColumnWrapper {
            @include common.flexColumn;
            height: 100%;
            width: calc((100% / 3) - (20px / 3));
            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
        .tileWrapper {
            @include common.centreFlexRow;
            height: calc(50% - 14px);
            border-radius: 5px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            background-color: theme.$color-lightest-grey;
        }
    }
}

.modal {
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    height: 80vh;
    width: 70vw;
    min-width: 800px;
    padding: 10px;
    .modalWrapper {
        @include common.flexColumn;
        justify-content: space-evenly;
        .myProfileWrapper {
            @include common.flexRow;
            height: calc(80vh - 114px);
            margin-bottom: 10px;
            .sideMenuWrapper {
                @include common.flexColumn;
                border-right: 1px solid theme.$color-grey;
                min-width: 150px;
                max-width: 150px;
                .myProfileSection {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    font-weight: theme.$font-bold;
                    font-size: theme.$font-size-h4;
                    &:hover {
                        color: theme.$color-french;
                    }
                    .sectionLabel {
                        cursor: pointer !important;
                    }
                    .sectionLabelDisabled {
                        cursor: not-allowed !important;
                    }
                }
            }
            .scrollableContentWrapper {
                @include common.flexColumn;
                padding: 0px 10px;
                width: calc(100% - 150px);
            }
        }
        .buttonWrapper {
            @include common.spaceBetweenFlexRow;
            align-self: flex-end;
            width: 100%;
            .leftButtonWrapper {
                @include common.flexRow;
            }
            .rightButtonWrapper {
                @include common.flexRow;
            }
        }
    }
}

.generalSectionWrapper {
    @include common.flexColumn;
    align-items: center;
    height: 100%;
    .generalInfoWrapper {
        @include common.flexColumn;
        min-width: 700px;
        align-items: center;
        margin-bottom: 50px;
        .username {
            width: fit-content;
            max-width: 80%;
            font-weight: theme.$font-medium;
            font-size: theme.$font-size-h2;
        }
        .userInfoWrapper {
            @include common.flexRow;
            align-items: center;
            width: 80%;
            margin-bottom: 10px;
            .profileInfoLabel {
                font-size: theme.$font-size-h6;
                font-weight: theme.$font-medium;
                width: 90px;
                margin-right: 5px;
            }
            .profileInfoValue {
                flex: 1;
                font-family: theme.$font-family;
                outline: none;
                padding: 5px;
                border: none;
                border-bottom: 1px dotted theme.$color-primary-night;
                color: theme.$color-primary-night;
                &:focus {
                    border-bottom: 1px dotted theme.$color-french;
                }
                &:disabled {
                    background-color: theme.$color-white;
                    border-bottom: 1px dotted theme.$color-grey;
                }
            }
        }
    }
    .accountButtonsWrapper {
        @include common.flexRow;
        justify-content: space-between;
        width: 80%;
        margin: 0 10%;
        color: theme.$color-primary-night;
        .accountButton {
            @include common.flexRow;
            align-items: center;
            width: calc(100% / 3);
            cursor: pointer;
            &:hover {
                color: theme.$color-french;
            }
            .resetPasswordButtonLabel {
                margin-left: 5px;
                font-weight: theme.$font-medium;
            }
        }
        .accountButtonDisabled {
            color: theme.$color-light-grey;
            cursor: not-allowed;
            &:hover {
                color: theme.$color-light-grey;
            }
        }
    }
}

.notificationsSectionWrapper {
    @include common.flexColumn;
    .notificationsTitleWrapper {
        border-bottom: 1px solid theme.$color-grey;
        margin-bottom: 5px;
        @include common.flexRow;
        justify-content: space-between;
        .notificationsTitle {
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
        }
        .dismissAllNotificationsButton {
            @include common.transparentButton(inherit);
            font-size: theme.$font-size-h7;
            padding: 0 !important;
            &:hover {
                color: theme.$color-red;
            }
        }
    }
    .notifications {
        @include common.flexColumn;
        height: calc(70vh - 46px);
        width: 100%;
        .emptyNotifications {
            font-size: theme.$font-size-h7;
            text-align: center;
            color: theme.$color-primary-night;
        }
    }
}

.homeScreenWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    .headersWrapper {
        @include common.flexRow;
        align-items: center;
        width: 100%;
        height: 15%;
        .topNavWrapper {
            @include common.flexRow;
            width: calc(75% - 20px);
            margin: 0px 10px;
            .logoWrapper {
                @include common.centreFlexRow;
                height: 100%;
                width: 100%;
                .logo {
                    height: 70px;
                }
            }
        }
        .title {
            @include common.centreFlexRow;
            width: calc(25% - 1px);
            height: 100%;
            border-left: 1px solid theme.$color-primary-night;
            border-bottom: 1px solid theme.$color-primary-night;
            z-index: 9;
        }
    }
    .homeScreenConfigTilesContentWrapper {
        @include common.flexRow;
        height: 85%;
        width: 100%;
        .droppableWrapper {
            @include common.flexColumn;
            height: 100%;
            overflow: hidden;
        }
        .scrollableDroppableWrapper {
            @include common.flexColumn;
            height: 100%;
            border-left: 1px solid theme.$color-primary-night;
        }
        .homeScreenConfigTilesWrapper {
            @include common.flexColumn;
            align-items: center;
            height: 100%;
            border-radius: 5px;
            margin: 0px 10px;
            .homeScreenConfigTileWrapper {
                @include common.centreFlexRow;
                padding: 10px;
                margin: 10px;
                border-radius: 5px;
                box-shadow: 0 3px 5px rgb(0, 0, 0, 0.2);
                background-color: theme.$color-lightest-grey;
                transition-duration: 0.5s;
                cursor: grab;
            }
        }
        .homeTileDragging {
            box-shadow: 1px 1px 5px theme.$color-grey;
            background: theme.$color-light-grey;
            color: theme.$color-french;
            cursor: grabbing;
        }
        .homeTileOver {
            transform: scale(0.8) rotate(8deg);
        }
        .homeTileIdle {
            :hover {
                color: theme.$color-french;
            }
        }
        .homeScreenTileScreenshot {
            width: 10vw;
            height: 100%;
            border-radius: 5px;
        }
    }
}

.tableFiltersSectionWrapper {
    height: 100%;
    .headerWrapper {
        @include common.flexRow;
        justify-content: space-between;
        .addWrapper {
            @include common.flexRow;
            align-items: center;
            padding-bottom: 10px;
            .customFiltersContentAddNewTitle {
                font-family: theme.$font-family;
                color: theme.$color-primary-night;
                font-size: 20px;
                font-weight: theme.$font-medium;
                margin-right: 5px;
            }
        }
        .tabsWrapper {
            display: flex;
            align-items: flex-end;
            margin-right: 15px;
        }
    }
    .defaultContentMessage {
        @include common.flexColumn;
        font-weight: theme.$font-light;
        color: theme.$color-primary-night;
        font-size: 16px;
        padding: 10px 10px 10px 30px;
    }
    .tableWrapper {
        @include common.flexColumn;
        height: calc(100% - 42px);
        .tableHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            width: calc(100% - 10px);
            justify-content: space-between;
            font-weight: theme.$font-medium;
            color: theme.$color-white;
            font-size: 20px;
            background-color: theme.$color-french;
            min-height: 42px;
            padding: 0px 5px;
            border-radius: 5px;
            .tableFilterInput {
                font-family: theme.$font-family;
                font-size: 13px;
                outline: none;
                padding: 3px;
                border: 1px solid theme.$color-primary-night;
                color: theme.$color-primary-night;
                border-radius: 5px;
                width: calc(30% - 15px);
                &:focus {
                    border: 1px solid theme.$color-french;
                }
            }
        }
        .customFiltersWrapper {
            @include common.flexRow;
            height: calc(100% - 52px);
            .customFilters {
                @include common.flexColumn;
                .filterTile {
                    @include common.flexRow;
                    border-radius: 5px;
                    border: 1px solid theme.$color-grey;
                    margin: 5px;
                    height: 40px;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h5;
                    align-items: center;
                    padding: 0 8px;
                    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                    justify-content: space-between;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.8;
                        color: theme.$color-french;
                    }
                    .deleteIcon {
                        color: theme.$color-primary-night;
                        &:hover {
                            opacity: 0.8;
                            color: theme.$color-red;
                        }
                    }
                }
                .filterTile:nth-of-type(even) {
                    background-color: theme.$color-lightest-grey;
                }
                .filterTile:nth-of-type(odd) {
                    background-color: theme.$color-white;
                }
                .selectedFilterTile {
                    @include common.flexRow;
                    border-radius: 5px;
                    border: 1px solid theme.$color-grey;
                    margin: 5px;
                    height: 40px;
                    font-weight: theme.$font-medium;
                    font-family: theme.$font-family;
                    font-size: theme.$font-size-h5;
                    align-items: center;
                    padding: 0 8px;
                    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                    justify-content: space-between;
                    cursor: pointer;
                    color: theme.$color-white;
                    background-color: theme.$color-french-spectrum-4 !important;
                    &:hover {
                        opacity: 0.8;
                        color: theme.$color-white;
                    }
                    .deleteIcon {
                        color: theme.$color-white;
                        &:hover {
                            opacity: 0.8;
                            color: theme.$color-red;
                        }
                    }
                }
            }
            .selectedFilter {
                padding: 5px 10px;
                .filterHeaderLabel {
                    font-weight: theme.$font-medium;
                    color: theme.$color-primary-night;
                    font-size: theme.$font-size-h3;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.myPreferencesSectionWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
    .preferencesHeader {
        font-size: theme.$font-size-h3;
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 10px;
    }
    .defaultWrapper {
        @include common.flexRow;
        height: 60px;
        align-items: center;
        margin-bottom: 10px;
        .defaultLabel {
            font-size: theme.$font-size-h4;
            font-weight: theme.$font-medium;
            margin-right: 10px;
        }
        .opinionOptions {
            @include common.flexRow;
            width: 85px;
            justify-content: space-between;
        }
    }
}

.unsavedChangesNotesWrapper {
    @include common.flexColumn;
    font-size: theme.$font-size-h6;
    font-weight: theme.$font-medium;
    margin-bottom: 10px;
    .unsavedChangesNote {
        font-weight: theme.$font-family;
        margin-left: 5px;
    }
}

.buttonsWrapper {
    @include common.flexRow;
    justify-content: space-between;
    margin-bottom: 10px;
}

.resetCustomiseAndFiltersButtons {
    @include common.flexRow;
    align-items: center;
    color: theme.$color-primary-night;
    &:hover {
        color: theme.$color-french;
    }
    cursor: pointer;
    .resetCustomiseAndFiltersLabel {
        margin-left: 5px;
        font-weight: theme.$font-medium;
    }
}

.pieChartWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
    .pieChartIcon {
        @include common.centreFlexRow;
        color: theme.$color-primary-night;
        position: absolute;
        border-radius: 50%;
        margin-top: auto;
    }
}

.attestationProgressWrapper {
    @include common.centreFlexColumn;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    padding: 10px;
    position: relative;
}

.linkButton {
    @include common.centreFlexColumn;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.homeButton {
    @include common.centreFlexColumn;
    @include common.transparentButton;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    &:hover {
        color: theme.$color-ocean;
    }
    .homeButtonIcon {
        @include common.centreFlexColumn;
        color: inherit;
        padding: 20px;
    }
}

.disabledHomeButton {
    color: theme.$color-grey;
    &:hover {
        color: theme.$color-grey;
    }
}

.homeButtonLabel {
    position: absolute;
    font-family: theme.$font-family;
    color: theme.$color-ocean;
    font-size: 30px;
    font-weight: theme.$font-bold;
    top: 85%;
}

.disabledHomeButtonLabel {
    color: theme.$color-grey;
}

.homeConfigTile {
    @include common.centreFlexColumn;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    .homeConfigTileIcon {
        @include common.centreFlexColumn;
        color: inherit;
        padding: 10px;
        max-width: calc(100% - 20px);
        width: 'fit-content';
        height: 'fit-content';
    }
}

.homeConfigTileLabel {
    @include common.centreFlexColumn;
    font-family: theme.$font-family;
    color: inherit;
    font-size: 18px;
    font-weight: theme.$font-bold;
    top: 85%;
}

.childWrapper {
    @include common.flexColumn;
    width: 100%;
    height: 100%;
}

.attestationsHeader {
    height: 30px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
}

@media (max-height: 800px) {
    .homeButtonLabel {
        font-size: 20px !important;
    }
}

.withCircle {
    border-radius: 50%;
    border: 10px solid inherit;
    &:hover {
        border: 10px solid inherit;
    }
}

.switchAccountWrapper {
    @include common.flexColumn;
    align-items: center;
    height: 100%;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    color: theme.$color-primary-night;
    .usernameWrapper {
        @include common.flexColumn;
        width: 100%;
        margin-bottom: 10px;
        .usernameLabel {
            font-weight: 600;
            margin-bottom: 3px;
        }
    }
    .passwordWrapper {
        @include common.flexColumn;
        width: 100%;
        .passwordLabel {
            font-weight: 600;
            margin-bottom: 3px;
        }
        .passwordInput {
            @include common.inputField;
            height: 26px;
        }
    }
    .clientLabel {
        font-weight: 600;
        margin: 10px 0;
    }
    .switchAccountButtonWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
    }
    .resetPasswordWrapper {
        @include common.centreFlexColumn;
        width: 100%;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px dotted theme.$color-grey;
        .resetPasswordLabel {
            font-size: 14px;
        }
        .resetPasswordButton {
            @include common.transparentButton;
            font-size: 16px;
            font-weight: 600;
            &:hover {
                color: theme.$color-french;
            }
        }
    }
    .loginError {
        @include common.centreFlexRow;
        height: 30px;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
    }
}

.cardsWrapper {
    @include common.flexRow;
    height: 100%;
    width: 100%;
    .card {
        @include common.flexRow;
        height: 200px;
        width: 200px;
        border: 2px solid black;
        border-radius: 10px;
    }
}

.customFilterWrapper {
    @include common.flexColumn;
    padding-bottom: 10px;
    .filterTypeHeader {
        font-weight: theme.$font-medium;
        color: theme.$color-french;
        font-size: 22px;
        margin-bottom: 10px;
        border-bottom: 1px solid theme.$color-primary-night;
    }
    .filterHeaderLabel {
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        font-size: theme.$font-size-h3;
        padding: 5px 0 5px 5px;
        min-width: 300px;
    }
    .filterNameInputWrapper {
        @include common.flexRow;
        align-items: center;
        min-width: 300px;
    }
    .customFilterTileModalWrapper {
        @include common.flexColumn;
        .customFilterInformationWrapper {
            @include common.flexColumn;
            align-items: center;
        }
    }
}

.customFilterInformationSection {
    @include common.flexColumn;
    width: 100%;
    .customFilterTileModalSectionHeader {
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        font-size: theme.$font-size-h3;
        padding: 5px 0;
    }
    .noTopPadding {
        padding-top: 0px;
    }
}

.customFilterModal {
    @include common.flexColumn;
    .customFilterInput {
        font-family: theme.$font-family;
        font-size: 13px;
        outline: none;
        padding: 3px;
        border: 1px solid theme.$color-primary-night;
        color: theme.$color-primary-night;
        border-radius: 5px;
        &:focus {
            border: 1px solid theme.$color-french;
        }
    }
    .dropdownFilterWrapper {
        @include common.centreFlexRow;
        margin-top: 5px;
    }
}

.topNavWrapper {
    @include common.spaceBetweenFlexRow;
    margin-bottom: 10px;
    min-height: 90px;
    height: 90px;
    background-color: theme.$color-primary-night;
    width: calc(75% - 10px);
    margin: 5px;
    border-radius: 5px;
    .logoWrapper {
        @include common.centreFlexRow;
        height: 100%;
        width: 100%;
        .logo {
            height: 70px;
        }
    }
}

:export {
    grey: theme.$color-lightest-grey;
    white: theme.$color-white;
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    lightGrey: theme.$color-light-grey;
}
