@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin tableHeader {
    width: calc(100% - 10px);
    padding: 5px;
    font-weight: theme.$font-medium;
    background-color: theme.$color-french;
    color: theme.$color-white;
    border-radius: 5px 5px 0 0;
    font-size: 14px;
}

.myCompaniesWrapper {
    @include common.tablePageWrapper;
    .myCompaniesTitleContainer {
        @include common.tablePageTitleContainer;
        height: 50px;
        .myCompaniesTitle {
            @include common.tablePageTitle;
        }
    }
}

.myCompanyModalWrapper {
    @include common.flexColumn;
    padding: 5px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    height: fit-content;
    height: 70vh;
    width: 80vw;
    .myCompanyDetailsWrapper {
        @include common.flexRow;
        margin-bottom: 20px;
        justify-content: space-between;
        .entityDetailsWrapper {
            width: calc(50% - 20px);
            .detailWrapper {
                width: 100%;
                @include common.flexColumn;
                margin-bottom: 10px;
                .entityDetailLabel {
                    font-size: theme.$font-size-h4;
                    text-decoration: underline;
                    margin-bottom: 10px;
                    min-width: fit-content;
                }
                .entityDetail {
                    font-size: theme.$font-size-h6;
                    border: 1px solid theme.$color-grey;
                    border-radius: 5px;
                    padding: 5px;
                    height: 20px;
                }
            }
        }
        .branchesWrapper {
            width: calc(50% - 20px);
            .branchesLabelWrapper {
                @include common.flexRow;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 5px;
                .branchesLabel {
                    font-size: theme.$font-size-h4;
                    text-decoration: underline;
                    margin-right: 5px;
                    min-width: fit-content;
                }
            }
            .entityBranchesWrapper {
                @include common.flexColumn;
                border: 1px solid theme.$color-grey;
                border-radius: 5px;
                padding: 5px;
                height: calc(100% - 37px);
                margin-bottom: 20px;
                .entityBranch {
                    @include common.flexRow;
                    align-items: center;
                    border: 1px solid theme.$color-grey;
                    border-radius: 5px;
                    padding: 5px;
                    margin-bottom: 5px;
                    .entityBranchName {
                        width: calc(100% - 40px);
                    }
                }
                .noEntityBranches {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: theme.$color-grey;
                }
            }
        }
    }
}

.confirmationModalWrapper {
    @include common.flexColumn;
    padding: 5px;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    .warningTitle {
        font-size: 18px;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .functionsPerformed {
        @include common.flexColumn;
        margin-bottom: 10px;
        .functionPerformed {
            font-size: 18px;
        }
    }
    .warningInfo {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.deleteFunctionConfirmationWrapper {
    @include common.flexColumn;
    padding: 5px;
    width: 500px;
    .deleteFunctionWarning {
        font-size: theme.$font-size-h4;
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        color: theme.$color-red;
    }
    .functionUsageWrapper {
        @include common.flexColumn;
        margin-bottom: 20px;
        .functionUsageLabel {
            font-size: theme.$font-size-h4;
            font-weight: theme.$font-medium;
            margin-bottom: 10px;
        }
        .scrollableWrapper {
            @include common.flexColumn;
            max-height: 200px;
            border: solid 1px theme.$color-light-grey;
            border-radius: 5px;
            padding: 5px;
            .companyWrapper {
                @include common.flexRow;
                font-size: theme.$font-size-h4;
                font-weight: theme.$font-family;
                align-items: center;
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
                .companyName {
                    margin-right: 5px;
                    min-width: 40%;
                }
            }
        }
    }
}

:export {
    green: theme.$color-green;
    red: theme.$color-red;
}
