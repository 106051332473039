@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

@mixin buildSectionInputWrapper {
    @include common.flexColumn;
    width: calc(100% - 12px);
    height: calc(100% - 20px);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px;
}

.scrollableTableWrapper {
    border-bottom: solid theme.$color-grey 1px;
    border-left: solid theme.$color-grey 1px;
    border-right: solid theme.$color-grey 1px;
    border-radius: 5px;
}

.buildAbstractWrapper {
    @include buildSectionInputWrapper;
    justify-content: center;
    .buildAbstractTitleWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: space-between;
        color: theme.$color-primary-night;
    }
    .buildAbstract {
        font-weight: theme.$font-medium;
    }
    .abstractCollapseIcon {
        width: 20px;
        margin-right: 40px;
    }
    .abstractWysiwygWrapper {
        margin-top: 10px;
    }
}

.buildSectionWrapper {
    @include buildSectionInputWrapper;
    .buildSectionTitleWrapper {
        @include common.spaceBetweenFlexRow;
        margin: 10px 0px;
        .buildSectionTitleInputWrapper {
            @include common.flexRow;
            width: calc(100% - 110px);
            margin-right: 10px;
            .buildSectionFirstTitleInput {
                width: 22.5%;
            }
            .buildSectionSecondTitleInput {
                width: 75%;
            }
            .buildSectionTitleInputSeparator {
                width: 2.5%;
                display: flex;
                justify-content: center;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h1;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
            }
        }
        .buildSectionCollapseIcon {
            width: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
        .buildSectionLinkIcon {
            display: flex;
            width: 40px;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            position: relative;
            .buildSectionLinkIconNumber {
                position: absolute;
                background-color: theme.$color-french;
                color: theme.$color-white;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                font-size: 12px;
                padding: 0 3px;
                border-radius: 5px;
                top: -8px;
                right: 0px;
            }
        }
        .buildSectionDeleteIcon {
            width: 20px;
        }
    }
}

.buildSubheaderWrapper {
    @include common.spaceBetweenFlexRow;
    margin: 10px 0px;
}

.buildDeviationsWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 10px;
    .buildDeviationTitleWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 10px;
        .buildDeviationTitle {
            margin-right: 5px;
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
        }
        .buildDeviationsCollapseIcon {
            margin-right: 5px;
        }
    }
}

.buildDeviationTableWrapper {
    min-width: fit-content;
    @include common.flexColumn;
    .tableHeaders {
        border-radius: 5px 5px 0 0;
        border: solid theme.$color-french 1px;
        background-color: theme.$color-french;
        @include common.flexRow;
        .columnHeaderWrapper {
            padding: 5px;
            &:last-of-type {
                padding: 5px 0px;
            }
        }
        .columnHeader {
            font-weight: theme.$font-medium;
            font-size: 14px;
            margin-left: 5px;
            color: theme.$color-white;
            font-family: theme.$font-family;
        }
    }
    .tableRowWrapper {
        @include common.flexRow;
        border-bottom: solid theme.$color-grey 1px;
        &:last-of-type {
            border-bottom: none;
        }
        .wysiwygColumnWrapper {
            min-width: 400px;
            width: 28%;
            justify-content: center;
            border-right: solid theme.$color-grey 1px;
            padding: 5px;
        }
        .topicColumnWrapper {
            min-width: 150px;
            width: 14%;
            border-right: solid theme.$color-grey 1px;
            justify-content: center;
            padding: 5px;
            .topicInput {
                border-radius: 5px;
                padding: 5px;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                outline: none;
                height: calc(100% - 12px);
                width: calc(100% - 12px);
                border: solid 1px theme.$color-light-grey;
                resize: none;
            }
        }
        .nidColumnWrapper {
            min-width: 150px;
            width: 8%;
            border-right: solid theme.$color-grey 1px;
            justify-content: center;
            padding: 5px;
        }
        .datePickerColumnWrapper {
            min-width: 150px;
            width: 8%;
            border-right: solid theme.$color-grey 1px;
            justify-content: center;
            padding: 5px;
        }
        .validFromColumnWrapper {
            min-width: 160px;
            width: 10%;
            border-right: solid theme.$color-grey 1px;
            justify-content: center;
            padding: 5px;
            .dropdownWrapper {
                width: 150px;
                padding-bottom: 10px;
            }
        }
        .deleteColumnWrapper {
            min-width: 80px;
            width: 4%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.buildStakeholdersWrapper {
    @include common.flexRow;
    margin-bottom: 10px;
    align-items: center;
    .buildStakeholdersTitle {
        min-width: fit-content;
        margin-right: 5px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
    }
    .buildStakeholdersInput {
        width: 100%;
        margin-right: 80px;
    }
}

.buildSectionList {
    .buildProvisionWrapper {
        margin-bottom: 10px;
        .buildProvision {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            .buildProvisionTitle {
                min-width: fit-content;
                margin-right: 5px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
            }
            .buildProvisionTitleInput {
                width: 100%;
                margin-right: 80px;
            }
        }
    }
}

.buildSectionTable {
    @include common.flexRow;
    justify-content: space-between;
    max-height: 48vh;
    .buildProvisionWrapper {
        width: calc(50% - 10px);
        .buildProvision {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            .buildProvisionTitle {
                min-width: fit-content;
                margin-right: 5px;
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
            }
            .buildProvisionTitleInput {
                width: 100%;
                margin-right: 0px !important;
            }
        }
    }
}

.buildPreferredTermsWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 10px;
    .buildPreferredTermsTitle {
        min-width: fit-content;
        margin-right: 5px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        align-self: flex-start;
    }
}

:export {
    lightGrey: theme.$color-light-grey;
    white: theme.$color-white;
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    green: theme.$color-green;
    red: theme.$color-red;
    redHighlight: rgba(theme.$color-red, 0.2)
}
