@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin tableHeaderIcons {
    @include common.flexRow;
    align-items: center;
    justify-content: flex-end;
}

.clauseTagsModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 80vh;
    .clauseTagsWrapper {
        @include common.flexColumn;
        height: calc(100% - 60px);
        width: calc(100% - 20px);
        padding: 10px;
    }
}

.currentClientTagsWrapper {
    @include common.flexRow;
    height: calc(100% - 210px);
    width: 100%;
    margin-bottom: 10px;
    .currentTagsWrapper {
        @include common.flexColumn;
        margin: 0px 10px 0px 20px;
        align-self: center;
        min-height: calc(80vh - 200px);
        position: relative;
        .currentTagsHeaders {
            @include common.flexRow;
            position: sticky;
            top: 0px;
            font-weight: theme.$font-medium;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h4;
            height: 40px;
            border-bottom: 1px solid theme.$color-french;
            border-top: 1px solid theme.$color-french;
            padding: 0 8px;
            background-color: theme.$color-french;
            color: theme.$color-white;
            margin: 0px 5px;
            border-radius: 5px 5px 0 0;
            z-index: 10;
            .headerSeparator {
                display: flex;
                justify-content: center;
                height: 100%;
                border-right: solid 1px theme.$color-white;
                min-width: 2px;
            }
            .tagLabelHeader {
                @include common.flexRow;
                margin-left: 5px;
                width: calc(50% - 10px);
                align-items: center;
                min-width: 75px;
                .tagHeaderLabel {
                    margin-right: 5px;
                }
                .tableHeaderIcons {
                    @include tableHeaderIcons;
                }
            }
            .tagCategoryHeader {
                @include common.flexRow;
                margin-left: 5px;
                width: calc(30% - 10px);
                align-items: center;
                min-width: 120px;
                .tagHeaderLabel {
                    margin-right: 5px;
                }
                .tableHeaderIcons {
                    @include tableHeaderIcons;
                }
            }
            .tagCountHeader {
                @include common.flexRow;
                margin-left: 5px;
                align-items: center;
                width: calc(20% - 10px);
                min-width: 70px;
                .tagHeaderLabel {
                    margin-right: 5px;
                }
                .tableHeaderIcons {
                    @include tableHeaderIcons;
                }
            }
        }
        .currentTagWrapper {
            @include common.flexRow;
            border-radius: 5px;
            border: 1px solid theme.$color-grey;
            margin: 5px;
            height: 40px;
            font-weight: theme.$font-family;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h5;
            align-items: center;
            padding: 0 8px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            .tagLabelWrapper {
                display: flex;
                margin-left: 5px;
                width: calc(50% - 10px);
                align-items: center;
                color: theme.$color-primary-night;
                min-width: 75px;
                .tagLabel {
                    margin-right: 5px;
                }
            }
            .tagCategoryWrapper {
                display: flex;
                margin-left: 10px;
                width: calc(30% - 10px);
                align-items: center;
                color: theme.$color-primary-night;
                min-width: 120px;
            }
            .tagCountWrapper {
                display: flex;
                width: calc(20% - 10px);
                min-width: 70px;
                margin-left: 10px;
                .tagCount {
                    display: flex;
                    margin-left: 10px;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    padding: 2px;
                    color: theme.$color-primary-night;
                    font-weight: theme.$font-family;
                    font-size: theme.$font-size-h5;
                }
            }
        }
    }
    .selectedTagWrapper {
        @include common.flexColumn;
        margin: 0px 20px 10px 10px;
        min-width: 40%;
        height: 100%;
        .tagInputWrapper {
            margin-bottom: 10px;
        }
        .selectedTagButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;
        }
    }
}

.totalTagsWrapper {
    @include common.flexRow;
    font-weight: theme.$font-medium;
    font-family: theme.$font-family;
    font-size: theme.$font-size-h4;
    height: 30px;
    padding: 0 8px;
    margin-left: 20px;
    background-color: theme.$color-white;
    color: theme.$color-primary-night;
    align-items: center;
    .clearAllFiltersWrapper {
        display: flex;
        min-width: 50%;
        justify-content: flex-start;
        align-items: center;
        .clearAllFilters {
            @include common.transparentButton;
            padding: 0 3px;
            align-items: flex-start;
            color: theme.$color-primary-night;
            font-weight: theme.$font-medium;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h6;
            &:hover {
                color: theme.$color-french;
            }
        }
    }
    .totalTags {
        min-width: 50%;
        display: flex;
        justify-content: flex-end;
    }
}

.clientTagInputWrapper {
    @include common.flexRow;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    .clientTagModalInput {
        width: calc(60% - 225px);
        margin-left: 25px;
    }
}

.closeClientTagsModal {
    display: flex;
    justify-content: flex-start;
    margin-left: 25px;
    min-height: 50px;
}

.tagTypeDropdown {
    margin: 0px 10px 0px 25px;
    width: calc(60% - 50px);
}

.currentTemplateTagsWrapper {
    @include common.flexRow;
    height: calc(100% - 275px);
    min-height: calc(100% - 275px);
    max-height: calc(100% - 275px);
    width: 100%;
    margin-bottom: 10px;
    .currentTagsWrapper {
        @include common.flexColumn;
        margin: 0px 10px 0px 20px;
        align-self: center;
        position: relative;
        .tagLabelHeader {
            @include common.flexRow;
            position: sticky;
            top: 0px;
            font-weight: theme.$font-medium;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h4;
            height: 40px;
            border-bottom: 1px solid theme.$color-french;
            border-top: 1px solid theme.$color-french;
            padding: 0 8px;
            background-color: theme.$color-french;
            color: theme.$color-white;
            margin: 0px 5px;
            border-radius: 5px 5px 0 0;
            z-index: 10;
            margin-left: 5px;
            align-items: center;
            min-width: 75px;
            .tagHeaderLabel {
                margin-right: 5px;
            }
            .tableHeaderIcons {
                @include tableHeaderIcons;
            }
        }
        .currentTagWrapper {
            @include common.flexRow;
            border-radius: 5px;
            border: 1px solid theme.$color-grey;
            margin: 5px;
            height: 40px;
            font-weight: theme.$font-family;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h5;
            align-items: center;
            padding: 0 8px;
            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            .tagLabel {
                display: flex;
                margin-left: 5px;
                align-items: center;
                color: theme.$color-primary-night;
                min-width: 75px;
                margin-right: 5px;
            }
        }
    }
    .selectedTagWrapper {
        @include common.flexColumn;
        margin: 0px 20px 10px 10px;
        width: 40%;
        height: 100%;
        .tagInputWrapper {
            margin-bottom: 10px;
        }
        .selectedTagButtonWrapper {
            @include common.flexRow;
            justify-content: flex-end;
        }
        .systemTagInfoMessage {
            margin-top: 20px;
            font-weight: theme.$font-medium;
            font-family: theme.$font-family;
            font-size: theme.$font-size-h4;
            color: theme.$color-amber;
        }
    }
}

:export {
    agreementBackgroundSelected: rgba(theme.$color-french, 0.5);
    jurisdictionBackgroundSelected: rgba(theme.$color-gold, 0.5);
    counterpartyBackgroundSelected: rgba(theme.$color-amethyst, 0.5);
    productBackgroundSelected: rgba(theme.$color-green, 0.5);
    provisionBackgroundSelected: rgba(theme.$color-mandatory, 0.5);
    miscellaneousBackgroundSelected: rgba(theme.$color-ocean, 0.5);
    opinionsBackgroundSelected: rgba(theme.$color-red, 0.5);
    french: theme.$color-french;
    frenchSelected: theme.$color-french-spectrum-1;
    white: theme.$color-white;
    lightestGrey: theme.$color-lightest-grey;
    lightGrey: theme.$color-light-grey;
}
