@use '../../../../styles/theme' as theme;
@use '../../../../styles/common' as common;

.tagsPageWrapper {
    @include common.flexRow;
    height: 100%;
    justify-content: space-between;
    .tagsWrapper {
        @include common.flexColumn;
        height: 100%;
        width: calc(20% - 11px);
        border-right: 1px solid theme.$color-primary-night;
        margin-right: 10px;
        .tagsViewWrapper {
            @include common.spaceBetweenFlexRow;
            height: 39px;
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid theme.$color-primary-night;
            .tagIconsWrapper {
                @include common.flexRow;
                align-items: center;
                .searchButtonWrapper {
                    @include common.centreFlexRow;
                    margin-right: 10px;
                }
                .sortTagsWrapper {
                    @include common.centreFlexRow;
                    margin-left: 10px;
                }
            }
            .tagsHeader {
                font-size: 18px;
                font-weight: theme.$font-medium;
                margin-right: 10px;
            }
        }
        .tagSearchWrapper {
            @include common.flexColumn;
            height: calc(100% - 50px);
            padding-right: 10px;
            width: calc(100% - 10px);
            .resultsWrapper {
                @include common.flexColumn;
                color: theme.$color-primary-night;
                height: calc(100% - 93px);
                .totalWrapper {
                    @include common.spaceBetweenFlexRow;
                    border-bottom: 1px solid theme.$color-light-grey;
                    font-weight: theme.$font-medium;
                    .totalLabel {
                        @include common.flexRow;
                        width: 90%;
                        border-right: 1px dashed theme.$color-light-grey;
                        padding-bottom: 2px;
                    }
                }
                .matchingProvisions {
                    @include common.flexColumn;
                    height: calc(100% - 25px);
                    .matchingProvision {
                        @include common.spaceBetweenFlexRow;
                        .provisionTitle {
                            @include common.flexRow;
                            padding: 2px 0;
                            width: 90%;
                            border-right: 1px dashed theme.$color-light-grey;
                        }
                    }
                }
            }
            .createTagWrapper {
                @include common.flexRow;
                width: 100%;
            }
        }
    }
}

.createTagModalWrapper {
    @include common.flexColumn;
    height: fit-content;
    max-height: 50vh;
    width: 500px;
    color: theme.$color-primary-night;
    .tagDetailsWrapper {
        @include common.flexColumn;
        width: 100%;
        .tagInputWrapper {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            .tagInputTitle {
                width: 60px;
            }
            .tagInput {
                @include common.flexRow;
                width: calc(100% - 60px);
                .searchTerm {
                    font-family: theme.$font-family;
                    outline: none;
                    padding: 5px;
                    border: none;
                    color: theme.$color-primary-night;
                    width: calc(100%  - 10px);
                    font-style: italic;
                }
            }
        }
    }
    .resultsWrapper {
        @include common.flexColumn;
        color: theme.$color-primary-night;
        height: 'fit-content';
        max-height: calc(50vh - 170px);
        margin: 10px 0;
        .totalWrapper {
            @include common.spaceBetweenFlexRow;
            border-bottom: 1px solid theme.$color-light-grey;
            font-weight: theme.$font-medium;
            .totalLabel {
                @include common.flexRow;
                width: 90%;
                border-right: 1px dashed theme.$color-light-grey;
                padding-bottom: 2px;
            }
        }
        .matchingProvisions {
            @include common.flexColumn;
            height: calc(100% - 25px);
            .matchingProvision {
                @include common.spaceBetweenFlexRow;
                .provisionTitle {
                    @include common.flexRow;
                    padding: 2px 0;
                    width: 90%;
                    border-right: 1px dashed theme.$color-light-grey;
                }
            }
        }
    }
}

.tagListWrapper {
    @include common.flexColumn;
    height: calc(100% - 50px);
    .tagWrapper {
        @include common.flexColumn;
        padding: 4px 0;
        border-bottom: 1px dashed theme.$color-grey;;
        .tagHeader {
            @include common.spaceBetweenFlexRow;
            .sectionOpenIcon {
                margin-right: 10px;
            }
            .tagLabel {
                @include common.flexRow;
                width: calc(100% - 100px);
                justify-content: flex-start;
            }
            .iconWrapper {
                @include common.flexRow;
                align-items: center;
                justify-content: flex-end;
                max-width: 100px;
                .totalMatches {
                    width: 30px;
                    margin-left: 5px;
                    font-weight: theme.$font-medium;
                }
                .deleteButtonWrapper {
                    @include common.centreFlexRow;
                    height: 100%;
                }
            }
        }
        .tagToggleWrapper {
            @include common.spaceBetweenFlexRow;
            align-self: center;
            max-width: calc(100% - 10px);
            width: fit-content;
            padding: 2px 5px;
            height: 20px;
            .previousButton,
            .nextButton {
                @include common.centreFlexRow;
                @include common.transparentButton;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                vertical-align: middle;
                width: 25px;
                font-size: 20px;
                &:hover {
                    color: rgba(theme.$color-french, 0.5);
                    font-weight: theme.$font-bold;
                }
                &:disabled {
                    color: theme.$color-grey;
                    font-weight: theme.$font-medium;
                    cursor: default;
                }
            }
            .matchingTagCount {
                flex: 1;
                margin: 0 5px;
            }
        }
    }
    .selectedTagWrapper {
        background-color: theme.$color-lightest-grey;
    }
}

:export {
    french: theme.$color-french;
    primary: theme.$color-primary-night;
}
