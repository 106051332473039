@use "../../../../styles/common" as common;
@use "../../../../styles/theme" as theme;

.modal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    left: 0px;
    right: 0px;
    width: fit-content;
}

.modalInstanceWrapper {
    @include common.flexColumn;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    padding: 10px;
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        .leftButtonWrapper,
        .rightButtonWrapper {
            @include common.flexRow;
            align-items: center;
        }
        .unsavedDatasetWarning {
            @include common.flexRow;
            align-items: center;
            margin-left: 10px;
            margin-right: auto;
            color: theme.$color-red;
            .unsavedDatasetWarningTitle {
                margin-left: 5px;
            }
        }
    }
}
