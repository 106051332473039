@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wrapper {
    @include common.flexColumn;
    border-radius: 5px;
}

.editor {
    font-family: theme.$font-family;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
}

.removeBorder {
    border: none;
}

.toolbar {
    background-color: theme.$color-french;
    border-radius: 5px;
}

.toolbarIcon {
    border-radius: 5px;
    z-index: 2 !important;
    &:hover {
        background-color: theme.$color-light-grey;
    }
}

:export {
    grey: theme.$color-grey;
    amethyst: theme.$color-amethyst;
    green: theme.$color-green;
}
