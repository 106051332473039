@use '../../../../styles/common' as common;
@use '../../../../styles/theme' as theme;

.formsWrapper {
    @include common.tablePageWrapper;
    .formsHeaderWrapper {
        @include common.spaceBetweenTablePageTitleContainer;
        .formsTitleButtonWrapper {
            @include common.flexRow;
            align-items: center;
            .formsHeaderTitle {
                @include common.tablePageTitle;
            }
        }
    }
}

.tableIconWrapper {
    @include common.centreFlexRow;
    width: 100%;
    padding: 10px;
    &:hover {
        color: theme.$color-french;
    }
}

.wizardTabContentWrapper {
    width: 60vw;
    height: 50vh;
    .defineTab {
        @include common.flexColumn;
        height: 100%;
        width: 100%;
    }
    .attestationsTab { 
        @include common.flexColumn;
        height: 100%;
        .attestationsHeader {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            padding: 0 16px 5px 16px;
            border-bottom: 1px solid theme.$color-primary-night;
            font-weight: theme.$font-medium;
            .questionHeader {
                width: 50%;
                margin-right: 10px;
            }
            .descriptionHeader {
                width: calc(50% - 48px);
                margin-right: 10px;
            }
            .addAttestation {
                width: 28px;
            }
        }
        .attestationsDroppableWrapper {
            width: calc(100% - 2px);
            height: calc(100% - 51px);
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            .attestationWrapper {
                @include common.flexRow;
                width: calc(100% - 32px);
                border: 1px dotted theme.$color-french;
                border-radius: 5px;
                margin: 5px;
                padding: 10px;
                .questionWrapper {
                    width: 50%;
                    margin-right: 10px;
                }
                .descriptionWrapper {
                    width: calc(50% - 48px);
                    margin-right: 10px;
                }
                .iconWrapper {
                    @include common.flexColumn;
                    align-items: center;
                    justify-content: space-between;
                    width: 28px;
                }
            }
        }
    }
}

.isSystemToggleWrapper {
    @include common.flexRow;
    align-items: center;
    align-self: flex-end;
    .isSystemToggleLabel {
        font-size: 16px;
        margin-right: 10px;
        font-weight: theme.$font-medium;
    }
}

.userAnswersWrapper {
    @include common.flexColumn;
    .userAnswersHeader {
        @include common.spaceBetweenFlexRow;
        .userAnswersTitle {
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-bottom: 5px;
            color: theme.$color-primary-night;
        }
    }
    .userAnswerOptions {
        @include common.flexColumn;
        height: 200px;
        border: 1px solid theme.$color-primary-night;
        border-radius: 5px;
        padding: 10px;
        .userAnswer {
            @include common.flexRow;
            margin: 5px;
            padding: 5px;
            border: 1px solid theme.$color-grey;
            border-radius: 5px;
            .userAnswerLabel {
                border: none;
                color: theme.$color-primary-night;
                border-bottom: 1px dotted theme.$color-grey;
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                flex: 1;
                margin-right: 10px;
                outline: none !important;
                &:hover {
                    border-bottom: 1px dotted theme.$color-primary-night;
                }
            }
            .deleteUser {
                @include common.transparentButton(inherit);
                padding: 0 !important;
                &:hover {
                    color: theme.$color-red;
                }
            }
        }
    }
}

.configModalWrapper {
    @include common.flexColumn;
    height: calc(45px + 50vh);
    padding: 0 10px;
    .configModalDropdownWrapper {
        @include common.flexColumn;
        width: 100%;
        margin-bottom: 10px;
    }
    .configModalToggleWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;
    }
    .configModalLabelWrapper {
        @include common.flexRow;
        align-items: center;
    }
    .configModalToggleLabel {
        font-weight: theme.$font-medium;
        margin-right: 5px;
    }
    .configQuestionWrapper {
        width: 350px;
    }
}

.customAnswersHeader {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    .customAnswersLabel {
        font-size: 14px;
        font-weight: theme.$font-medium;
    }
}

.customAnswerBuilder {
    @include common.flexColumn;
    width: 350px;
    height: 105px;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 5px 10px;
    .customAnswersDroppableWrapper {
        @include common.flexColumn;
        height: 100%;
        .customAnswerBuilderDroppable {
            @include common.flexColumn;
        }
    }
}

.answerWrapper {
    @include common.flexRow;
    margin: 5px;
    padding: 5px;
    justify-content: space-evenly;
    border: 1px solid theme.$color-grey;
    border-radius: 5px;
    .answerInput {
        width: 90%;
        border: none;
        border-bottom: 1px dotted theme.$color-grey;
        font-family: theme.$font-family;
        &:hover {
            border-bottom: 1px dotted theme.$color-primary-night;
        }
        &:focus {
            outline: none !important;
        }
    }
    .deleteAnswer {
        @include common.transparentButton(inherit);
        padding: 0 !important;
        &:hover {
            color: theme.$color-red;
        }
    }
}

.questionPlaceholder {
    font-style: italic;
}

.confirmationModalWrapper {
    @include common.flexColumn;
    width: 400px;
    .confirmationModalHeader {
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .confirmationModalSubheader {
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-size: 16px;
        margin-bottom: 20px;
    }
}
