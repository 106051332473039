@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.adminAttestationsWrapper {
    @include common.flexColumn;
    height: 100%;
    width: 100%;
    margin: 20px 0;
}

:export {
    red: theme.$color-red;
}
