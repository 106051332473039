@use '../../../../../styles/theme' as theme;
@use '../../../../../styles/common' as common;

.tableInstanceWrapper {
    padding-top: 10px;
    width: 100%;
}

.tableHeader {
    @include common.flexRow;
    border-radius: 5px 5px 0 0;
    background-color: theme.$color-french;
    width: fit-content;
    .lastCell {
        border-right: 1px solid theme.$color-french;
        border-radius: 0 5px 0 0;
    }
    .firstCell {
        border-left: 1px solid theme.$color-french;
        border-radius: 5px 0 0 0;
    }
}

.tableRow {
    @include common.flexRow;
    border-bottom: 1px solid theme.$color-grey;
    border-radius: 0 0 5px 5px;
    width: fit-content;
    .cellWrapper {
        @include common.centreFlexRow;
        padding: 5px;
        border-right: 1px solid theme.$color-grey;
        border-left: 1px solid theme.$color-white;
    }
    .firstCell {
        border-left: 1px solid theme.$color-grey;
        border-radius: 0 0 0 5px;
    }
    .lastCell {
        border-radius: 0 0 5px 0;
    }
}

.tableHeaderWrapper {
    @include common.flexRow;
    align-items: center;
    padding: 10px 5px;
    height: 30px;
    color: theme.$color-white;
    font-family: theme.$font-family;
    font-weight: theme.$font-medium;
    border-right: 1px solid theme.$color-white;
    border-left: 1px solid theme.$color-white;
    .headerLabel {
        @include common.overflowText;
        font-size: 12px;
        margin-right: 10px;
    }
    .headerDescription {
        @include common.centreFlexRow;
        border: 1px solid theme.$color-white;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        font-size: 12px;
        height: 13px;
        width: 13px;
    }
}

.emptyHeaderLabel {
    color: rgba(theme.$color-white, 0.5);
    font-style: italic;
}
