@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.notificationsWrapper {
    @include common.flexColumn;
    .notificationsTitleWrapper {
        border-bottom: 1px solid theme.$color-grey;
        margin-bottom: 5px;
        @include common.flexRow;
        justify-content: space-between;
        .notificationsTitle {
            font-weight: theme.$font-medium;
            color: theme.$color-primary-night;
        }
        .dismissAllNotificationsButton {
            @include common.transparentButton(inherit);
            font-size: theme.$font-size-h7;
            padding: 0 !important;
            &:hover {
                color: theme.$color-red;
            }
        }
    }
    .notifications {
        @include common.flexColumn;
        height: 300px;
        width: 100%;
        .emptyNotifications {
            font-size: theme.$font-size-h7;
            text-align: center;
            color: theme.$color-primary-night;
        }
    }
}

.notificationWrapper {
    border-bottom: 1px solid theme.$color-grey;
    @include common.flexRow;
    &:hover {
        background-color: theme.$color-light-grey;
    }
    .notificationTypeIcon {
        position: relative;
        align-self: center;
        margin-right: 15px;
        .userIconWrapper {
            @include common.centreFlexRow;
            color: theme.$color-primary-night;
            background-color: theme.$color-lightest-grey;
            position: absolute;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            top: 15px;
            left: 20px;
        }
    }
    .systemNotification {
        @include common.flexRow;
        @include common.transparentButton;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
        max-height: 60px;
    }
    .notificationTitleAndDescriptionWrapper {
        @include common.flexRow;
        width: 100%;
        max-height: 60px;
        text-decoration: none;
        &:hover {
            cursor: pointer;
        }
    }
    .dismissNotificationIcon {
        @include common.transparentButton(inherit);
        padding: 0 !important;
        align-self: flex-start;
        margin-right: 10px;
        &:hover {
            color: theme.$color-red;
        }
    }
}

.notificationTitleWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2px;
    justify-content: space-between;

    .notificationTitle {
        @include common.overflowText;
        font-size: theme.$font-size-h7;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
        max-height: 20px;
    }

    .notificationDescription {
        @include common.flexColumn;
        align-items: flex-start;
        width: 100%;
        font-size: theme.$font-size-h7;
        font-weight: 200;
        color: theme.$color-primary-night;
        margin-bottom: 3px;
    }
}

.logoWrapper {
    @include common.flexRow;
    align-items: center;
    height: 100%;
    width: 100%;
    align-self: center;
    overflow: hidden;
    border-radius: 50%;
}

.systemNotificationWrapper {
    width: 50vw;
    padding: 5px;

    .contentWrapper {
        width: 100%;
    }
}

.systemModalHeader {
    font-weight: 600;
    font-size: 20px;
}

.activeMLDocumentsWrapper {
    @include common.flexColumn;
    width: 100%;

    .activeMLDocumentsHeader {
        @include common.flexRow;
        justify-content: flex-start;
        width: 100%;
        border-bottom: 1px solid theme.$color-grey;
        margin-bottom: 5px;
        font-weight: theme.$font-medium;
        color: theme.$color-primary-night;
    }

    .activeMLDocuments {
        @include common.flexColumn;
        max-height: 50vh;
        width: 100%;

        .activeMLDocument {
            @include common.flexColumn;
            width: calc(100% - 12px);
            padding: 5px;
            border: 1px solid theme.$color-light-grey;
            border-radius: 5px;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            .documentDetailsWrapper {
                @include common.flexRow;
                align-items: center;
                .documentIconWrapper {
                    width: 10%;
                }
                .documentInformationWrapper {
                    @include common.flexColumn;
                    width: calc(90% - 10px);
                    margin-left: 10px;
                    .documentName {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .documentDescription {
                        font-size: 14px;
                    }
                    .entitiesWrapper {
                        @include common.flexRow;
                        width: 100%;
                        align-items: center;
                        margin: 5px 0;
                        .entityWrapper {
                            width: calc(50% - 5px);
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        background: linear-gradient(to right, theme.$color-french, theme.$color-french-spectrum-1 20%);
    }

    20% {
        background: linear-gradient(to right, theme.$color-french, theme.$color-french-spectrum-1 40%);
    }

    40% {
        background: linear-gradient(
            to right,
            theme.$color-french,
            theme.$color-french-spectrum-3 40%,
            theme.$color-french-spectrum-1 60%
        );
    }

    60% {
        background: linear-gradient(
            to right,
            theme.$color-french,
            theme.$color-french-spectrum-3 60%,
            theme.$color-french-spectrum-1 80%
        );
    }

    80% {
        background: linear-gradient(
            to right,
            theme.$color-french,
            theme.$color-french-spectrum-3 80%,
            theme.$color-french-spectrum-1 100%
        );
    }

    100% {
        background: linear-gradient(
            to right,
            theme.$color-french,
            theme.$color-french-spectrum-3 100%,
            theme.$color-french-spectrum-1 100%
        );
    }
}

.progressBar {
    @include common.spaceBetweenFlexRow;
    height: 10px;
    width: calc(100% - 10px);
    margin: 5px 5px 2px 5px;
    border-radius: 5px;
    background: repeating-linear-gradient(
        -45deg,
        theme.$color-light-grey,
        theme.$color-light-grey 10px,
        theme.$color-lightest-grey 10px,
        theme.$color-lightest-grey 20px
    );
    border: 1px solid theme.$color-light-grey;

    .progressPercentage {
        position: relative;
        margin-top: -1px;
        height: 100%;
        border-radius: 5px;
    }

    .activeDocumentProgress {
        background-color: theme.$color-french;
        border: 1px solid theme.$color-french;
        animation: pulse 5s infinite;
        box-shadow: 1px 1px 3px theme.$color-french;
    }

    .completedAI {
        background-color: theme.$color-mandatory;
        border: 1px solid theme.$color-mandatory;
        box-shadow: 1px 1px 3px theme.$color-mandatory;
    }

    .failedAI {
        background-color: theme.$color-red;
        border: 1px solid theme.$color-red;
        box-shadow: 1px 1px 3px theme.$color-red;
    }
}

.progressInfo {
    @include common.spaceBetweenFlexRow;
    align-items: center;
    width: 100%;
    font-size: 12px;
    .progressString {
        @include common.flexRow;
        width: calc(80% - 10px);
        margin-right: 10px;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        color: theme.$color-primary-night;
    }
    .displayPercentage {
        @include common.flexRow;
        width: 20%;
        align-items: center;
        justify-content: flex-end;
        font-weight: 800;
        color: theme.$color-primary-night;
    }
}

:export {
    white: theme.$color-white;
    french: theme.$color-french;
    primary: theme.$color-primary-night;
    green: theme.$color-green;
    amber: theme.$color-amber;
    red: theme.$color-red;
}
