@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.portfolioStatisticsWrapper {
    @include common.spaceBetweenFlexRow;
    align-items: center;
    position: absolute;
    width: calc(100% - 20px);
    padding: 0 10px;
    bottom: 0;
    background-color: rgba(theme.$color-ocean, 0.8);
    height: 30px;
    border-radius: 5px 5px 0 0;
    .portfolioStatistics {
        @include common.flexRow;
        align-items: center;
        width: calc(100% - 26px);
        height: 30px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        .portfolioMoveWrapper {
            @include common.flexRow;
            position: absolute;
            align-items: center;
            width: fit-content;
            white-space: nowrap;
            .preStatisticsWrapper {
                @include common.centreFlexRow;
                height: 100%;
                .logo {
                    height: 25px;
                }
            }
            .postStatisticsWrapper {
                @include common.centreFlexRow;
                height: 100%;
                .logo {
                    height: 25px;
                }
            }
            .statisticWrapper {
                @include common.flexRow;
                align-items: center;
                width: fit-content;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                &:not(:first-child) {
                    margin-left: 10px;
                }
                &:not(:last-child) {
                    margin-right: 10px;
                }
                .statisticSpacer {
                    margin: 0 5px;
                    padding: 3px;
                    border-radius: 50%;
                    height: 1px;
                    width: 1px;
                    background-color: theme.$color-primary-night;
                }
                .statistic {
                    font-weight: 600;
                }
            }
        }
    }
    .portfolioStatisticsClose {
        @include common.centreFlexRow;
        margin-left: 10px;
    }
}

.portfolioStatisticsOpen {
    @include common.centreFlexRow;
    position: absolute;
    right: 0;
    padding: 0 10px;
    bottom: 0;
    background-color: rgba(theme.$color-ocean, 0.8);
    height: 30px;
    border-radius: 5px 5px 0 0;
}

.iconButton {
    @include common.transparentButton;
    padding: 0;
    color: theme.$color-primary-night;
    width: 20px;
    height: 20px;
    &:hover {
        color: theme.$color-white;
    }
}
