@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.overlayTextStyle {
    font-size: 11px;
    font-weight: theme.$font-medium;
    margin: 0 auto;
    word-wrap: break-word;
}

.listHeader {
    font-size: 11px;
    font-weight: theme.$font-medium;
    margin-bottom: 3px;
}

.overlayTextWrapper {
    @include common.flexRow;
}

.tooltipListWrapper {
    @include common.flexColumn;
    max-height: 300px;
}

.informationTooltipIcon {
    @include common.centreFlexRow;
    font-family: theme.$font-family;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    font-weight: theme.$font-bold;
    &:not(:last-child) {
        margin-right: 5px;
    }
    &:hover {
        color: theme.$color-french;
    }
    &:disabled {
        color: theme.$color-light-grey;
    }
}

.overlayMarginBottom {
    margin-bottom: 2px;
}

.listWrapper {
    @include common.flexRow;
}

.overlayBulletPoint {
    margin-right: 4px;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-top: 4px;
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    aqua: theme.$color-aqua;
    whiteColor: theme.$color-white;
    red: theme.$color-red;
}
