@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.clientOptionsWrapper {
    @include common.flexColumn;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;
    color: theme.$color-primary-night;
    .clientOptionsTitle {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
        text-decoration: underline;
    }
    .clientOptionsTable {
        border: 1px solid theme.$color-grey;
        border-radius: 5px;
        font-size: 13px;
        width: calc(100% - 2px);
        .allOptionsRow {
            @include common.spaceBetweenFlexRow;
            padding: 5px;
            border-radius: 5px 5px 0 0;
            background-color: theme.$color-light-grey;
        }
        .clientOptionRow {
            @include common.spaceBetweenFlexRow;
            padding: 5px;
        }
        .clientName {
            font-weight: theme.$font-medium;
        }
    }
}

:export {
    white: theme.$color-white;
    lightGrey: theme.$color-light-grey;
}
