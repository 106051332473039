@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

@mixin tableHeaderIcons {
    @include common.flexRow;
    align-items: center;
    justify-content: flex-end;
}

.clauseModal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    width: 80vw;
    height: 90vh;
    .clauseWrapper {
        @include common.flexColumn;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        padding: 10px;
        .isSystemWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 10px;
            height: 30px;
            .systemTemplateLabel {
                font-weight: theme.$font-medium;
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                min-width: fit-content;
                margin-right: 5px;
            }
            .systemTemplateInfo {
                font-weight: theme.$font-medium;
                color: theme.$color-amber;
                font-family: theme.$font-family;
                min-width: fit-content;
                margin-right: 5px;
            }
        }
        .clauseModalContent {
            .clauseContent {
                height: calc(60% - 20px);
                margin-bottom: 10px;
                .clauseTitleWrapper {
                    @include common.flexRow;
                    align-items: center;
                    margin-bottom: 5px;
                    .clauseTitleLabel {
                        font-weight: theme.$font-medium;
                        color: theme.$color-primary-night;
                        font-family: theme.$font-family;
                        min-width: fit-content;
                        margin-right: 5px;
                    }
                    .clauseTitleInput {
                        width: 99%;
                    }
                }
                .clauseDescriptionWrapper {
                    @include common.flexColumn;
                    margin-bottom: 5px;
                    .clauseDescriptionLabelWrapper {
                        @include common.flexRow;
                        align-items: center;
                        margin-bottom: 5px;
                        .clauseDescriptionLabel {
                            font-weight: theme.$font-medium;
                            color: theme.$color-primary-night;
                            font-family: theme.$font-family;
                            min-width: fit-content;
                            margin-right: 5px;
                        }
                    }
                    .clauseDescriptionInput {
                        width: calc(100% - 12px);
                        min-height: 60px !important;
                        height: 100%;
                    }
                }
                .clauseContentInput {
                    margin-bottom: 5px;
                    .clauseContentLabelWrapper {
                        @include common.flexRow;
                        align-items: center;
                        margin-bottom: 5px;
                        justify-content: space-between;
                        .clauseContentLabel {
                            font-weight: theme.$font-medium;
                            color: theme.$color-primary-night;
                            font-family: theme.$font-family;
                            min-width: fit-content;
                            margin-right: 5px;
                        }
                    }
                    .linkedOpinions {
                        cursor: pointer !important;
                    }
                }
            }
            .tagsWrapper {
                @include common.flexColumn;
                height: calc(40% - 20px);
                .currentTagsScrollableWrapper {
                    height: 50%;
                    border: 1px solid theme.$color-grey;
                    border-radius: 5px;
                    width: 100%;
                    align-self: center;
                    margin-bottom: 10px;
                    .currentTagsWrapper {
                        display: flex;
                        flex-wrap: wrap;
                        .noTagsWrapper {
                            padding: 10px;
                            font-weight: theme.$font-medium;
                            color: theme.$color-grey;
                            font-family: theme.$font-family;
                        }
                        .tagWrapper {
                            @include common.flexRow;
                            border-radius: 5px;
                            margin: 5px;
                            height: 40px;
                            width: fit-content;
                            font-weight: theme.$font-medium;
                            font-family: theme.$font-family;
                            font-size: theme.$font-size-h6;
                            color: theme.$color-primary-night;
                            align-items: center;
                            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                            border: 1px solid theme.$color-grey;
                            &:hover {
                                box-shadow: 1px 1px 5px theme.$color-french;
                            }
                        }
                        .tagWrapperDisabled {
                            @include common.flexRow;
                            border-radius: 5px;
                            margin: 5px;
                            height: 40px;
                            width: fit-content;
                            font-weight: theme.$font-medium;
                            font-family: theme.$font-family;
                            font-size: theme.$font-size-h6;
                            color: theme.$color-primary-night;
                            align-items: center;
                            box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                            border: 1px solid theme.$color-grey;
                            &:hover {
                                box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
                            }
                        }
                        .tagLabelWrapper {
                            @include common.flexColumn;
                            margin: 0 auto;
                            height: 100%;
                            justify-content: center;
                            .tagLabel {
                                margin-left: 5px;
                                width: fit-content;
                                align-items: center;
                                max-width: 400px;
                            }
                        }
                        .removeTagWrapper {
                            display: flex;
                            width: 15px;
                            justify-content: center;
                            align-items: flex-start;
                            margin-top: 5px;
                            height: 100%;
                            cursor: pointer;
                        }
                    }
                }
                .tagsDropdownWrapper {
                    @include common.flexRow;
                    justify-content: space-between;
                    .tagTypeDropdown {
                        width: 45%;
                    }
                }
                .addClientTagWrapper {
                    @include common.flexColumn;
                    margin-bottom: 10px;
                    .addClientTagInputWrapper {
                        @include common.flexRow;
                        align-items: center;
                        .clauseTagsSearchInput {
                            width: calc(45% - 160px);
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .buttonWrapper {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            .leftButtonGroup {
                @include common.flexRow;
                justify-content: flex-start;
                width: 30%;
            }
            .rightButtonGroup {
                @include common.flexRow;
                justify-content: flex-end;
                width: 70%;
            }
        }
    }
}

.fuzzyMatchesModalWrapper {
    @include common.flexColumn;
    .fuzzyMatchWrapper {
        display: flex;
        width: 300px;
        align-items: center;
        margin-bottom: 2px;
        cursor: pointer;
    }
    .fuzzyMatchTitle {
        display: flex;
        max-width: 85%;
        align-items: center;
        font-weight: theme.$font-family;
        font-family: theme.$font-family;
        font-size: theme.$font-size-h5;
        color: theme.$color-primary-night;
        &:hover {
            color: theme.$color-french;
        }
    }
    .fuzzyMatchType {
        @include common.centreFlexRow;
        width: 20px;
        height: 20px;
        border-radius:50%;
        margin-right: 10px;
        color: theme.$color-french;
        background-color: theme.$color-white;
    }
}

.confirmationModalWrapper {
    @include common.flexColumn;
    width: 400px;
    .confirmationModalHeader {
        font-weight: theme.$font-medium;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .confirmationModalSubheader {
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.tilesWrapper {
    width: 100%;
    height: 100%;
}

@mixin systemTagCategoryButton($backgroundColour: theme.$color-lightest-grey) {
    @include common.flexRow;
    border: 1px solid theme.$color-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
    background-color: $backgroundColour;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    font-size: theme.$font-size-h4;
    border-radius: 5px;
    padding: 5px;
    &:hover {
        color: theme.$color-french;
        box-shadow: 1px 1px 5px theme.$color-french;
    }
}

.systemTagCategoriesWrapper {
    @include common.flexColumn;
    width: 500px;
    .tagTermWrapper {
        @include common.flexRow;
        width: 100%;
        margin-bottom: 10px;
        .tagTermLabel {
            width: fit-content;
            margin-right: 5px;
        }
        .tagTerm {
            width: 45%;
        }
    }
    .categoriesWrapper {
        @include common.flexRow;
        .categoryColumn {
            @include common.flexColumn;
            width: 45%;
            margin: 0 10px;
            .tagCategoryWrapper {
                @include systemTagCategoryButton;
                .tagCategory {
                    width: calc(100% - 30px);
                }
                .tagColour {
                    width: 20px;
                    height: 20px;
                    border-radius:50%;
                }
            }
            .selectedTagCategoryWrapper {
                @include systemTagCategoryButton(theme.$color-french-spectrum-1);
                .tagCategory {
                    width: calc(100% - 30px);
                }
                .tagColour {
                    width: 20px;
                    height: 20px;
                    border-radius:50%;
                }
            }
        }
    }
}

.opinionLinksWrapper {
    height: fit-content;
    max-height: 400px;
    .opinionLink {
        @include common.flexRow;
        color: theme.$color-primary-night;
        font-weight: theme.$font-medium;
        font-size: theme.$font-size-h4;
        align-items: center;
        &:hover {
            color: theme.$color-french;
        }
    }
}

:export {
    agreementBackground: rgba(theme.$color-french, 0.3);
    jurisdictionBackground: rgba(theme.$color-gold, 0.3);
    counterpartyBackground: rgba(theme.$color-amethyst, 0.3);
    productBackground: rgba(theme.$color-green, 0.3);
    provisionBackground: rgba(theme.$color-mandatory, 0.3);
    miscellaneousBackground: rgba(theme.$color-ocean, 0.3);
    opinionsBackground: rgba(theme.$color-red, 0.3);
    french: theme.$color-french;
    frenchSelected: theme.$color-french-spectrum-2;
    white: theme.$color-white;
    lightGrey: theme.$color-lightest-grey;
}
