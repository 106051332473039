@use './styles/theme' as theme;
@use './styles/common' as common;
@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin';

* {
    font-variant-ligatures: none;
}

body {
    margin: 0;
    font-family: theme.$font-family;
    font-weight: theme.$font-light;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Toastify container and progress bar styling
.Toastify__toast {
    background-color: rgba(theme.$color-ocean, 0.5);
    color: theme.$color-primary-night;
    text-align: center;
}

.Toastify__close-button {
    color: theme.$color-primary-night;
}

.Toastify__toast--error {
    background-color: rgba(theme.$color-red, 0.3);
    color: theme.$color-primary-night;
    text-align: center;
}

.Toastify__progress-bar {
    background: theme.$color-ocean;
}

.Toastify__progress-bar--error {
    background: theme.$color-red;
}

// rc-tooltip styling
.rc-tooltip-arrow {
    display: none;
}

.rc-tooltip {
    opacity: 1;
    line-height: 1;
}

.rc-slider-disabled {
    background-color: transparent !important;
}

// Datepicker styling
.react-datepicker {
    font-family: theme.$font-family;
    &__triangle {
        display: none;
    }
    &__header {
        background-color: theme.$color-french;
        color: theme.$color-white;
        border-bottom: 1px solid theme.$color-primary-night;
    }
    &__day-name {
        font-family: theme.$font-family;
        color: theme.$color-white;
        font-weight: theme.$font-medium;
    }
    &__current-month {
        font-family: theme.$font-family;
        color: theme.$color-white;
    }
    &__day {
        font-family: theme.$font-family;
        color: theme.$color-primary-night;
        font-weight: theme.$font-medium;
        &:hover {
            background-color: theme.$color-french-spectrum-3;
            color: theme.$color-white;
        }
    }
    &__day--keyboard-selected {
        background-color: theme.$color-french;
        border: 1px solid theme.$color-french;
        color: theme.$color-white;
        &:hover {
            background-color: theme.$color-french-spectrum-3;
            border: 1px solid theme.$color-french;
            color: theme.$color-white;
        }
    }
    &__year-dropdown-container {
        color: theme.$color-white !important;
        background-color: theme.$color-french !important;
        border-radius: 5px;
        border-color: theme.$color-french !important;
        outline: none;
        font-weight: theme.$font-bold;
        font-family: theme.$font-family;
        font-size: 16px;
    }
    &__year-dropdown {
        color: theme.$color-primary-night;
        background-color: theme.$color-white !important;
        border-radius: 5px;
        border-color: theme.$color-primary-night;
        outline: none;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        font-size: 16px;
    }
    &__year-option {
        &:hover {
            background-color: theme.$color-french-spectrum-3;
            font-weight: theme.$font-bold;
        }
    }
    &__year-option--selected {
        color: theme.$color-green;
    }
    &__year-read-view--down-arrow {
        border-color: theme.$color-white;
    }
    &-popper {
        z-index: 999 !important;
    }
    &__navigation-icon::before {
        border-color: theme.$color-white;
    }
    &__navigation:hover *::before {
        border-color: theme.$color-primary-night;
    }
}

// Dropdown styling
.ark-dropdown {
    &__control--is-focused {
        border-color: theme.$color-french !important;
    }
    &__option--is-focused {
        background-color: rgba(theme.$color-french, 0.3) !important;
    }
    &__multi-value {
        border-radius: 5px !important;
        &--is-disabled {
            background-color: rgba(theme.$color-grey, 0.3) !important;
        }
    }
    &__value-container--is-multi {
        max-height: 100px;
        overflow-y: auto !important;
    }
    &__menu {
        text-align: left;
    }
}

textarea {
    ::-webkit-scrollbar {
        width: 4px;
        height: 0px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(theme.$color-grey, 0.05);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(theme.$color-grey, 0.5);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(theme.$color-grey, 0.7);
    }
}

.DraftEditor-root {
    height: calc(100% - 10px);
    width: 100%;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 4px;
        height: 0px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(theme.$color-grey, 0.05);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(theme.$color-grey, 0.5);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(theme.$color-grey, 0.7);
    }
}

.public-DraftStyleDefault-block {
    margin-top: 0;
    margin-bottom: 3px;
}

// Grid styling
.ag-root-wrapper {
    border-radius: 5px;
    font-family: theme.$font-family !important;
    border: 1px solid theme.$color-french !important;
    .ag-paging-panel {
        border-radius: 5px;
        background-color: rgba(theme.$color-aqua, 0.3);
        color: theme.$color-primary-night !important;
        border-top: 1px solid theme.$color-french !important;
    }
}

.ag-cell-focus,
.ag-cell-no-focus {
    border: 1px solid transparent !important;
    &:focus {
        border: 1px solid transparent !important;
        outline: none;
    }
}

.ag-row .ag-cell {
    display: flex;
    align-items: center;
    color: theme.$color-primary-night !important;
}

.ag-react-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ag-theme-balham .ag-header {
    background-color: theme.$color-french !important;
    &-cell-text {
        color: theme.$color-white !important;
        font-size: 14px;
        font-weight: theme.$font-bold;
    }
    .ag-header-cell {
        padding: 0 8px !important;
    }
}

.ag-center-cols-viewport {
    overflow-x: hidden !important;
}

// React-Big-Calendar
.rbc-calendar {
    height: 100%;
    width: 100%;
    * {
        box-sizing: unset;
    }
}

.rbc-month-view {
    border-radius: 5px;
    border: 1px solid theme.$color-french !important;
}

.rbc-row-content {
    z-index: unset;
    height: 100%;
}

.rbc-month-header {
    height: 50px;
    font-family: theme.$font-family !important;
    color: theme.$color-white !important;
    background-color: theme.$color-french !important;
    font-size: 16px;
    font-weight: theme.$font-bold;
    align-items: center;
}

.rbc-header {
    border-bottom: none !important;
    height: 100%;
    @include common.centreFlexRow;
}

.rbc-today {
    background-color: rgba(theme.$color-ocean, 0.15) !important;
}

.rbc-off-range-bg {
    background-color: rgba(theme.$color-body-disabled, 0.25) !important;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid theme.$color-french !important;
}

.rbc-date-cell {
    padding: 0;
    cursor: pointer;
}

.rbc-day-bg {
    padding: 5px;
    &:hover {
        background-color: rgba(theme.$color-french, 0.25) !important;
        cursor: pointer;
    }
}

.rbc-month-row {
    border-top: 1px solid theme.$color-french !important;
}

.rbc-row {
    width: 100%;
}

.rbc-row-segment {
    padding: 0;
}

.rbc-event {
    width: calc(100% - 10px);
    background-color: transparent;
    font-size: 16px;
    font-family: theme.$font-family !important;
    &:hover {
        opacity: 0.75;
    }
}

.rbc-event.rbc-selected {
    background-color: transparent;
}

// Workaround for react-error-overlay v6.0.10 which displays a non clickable error iframe when file updated within app
// https://github.com/facebook/create-react-app/issues/11773
// Affecting anyone with CRA react-scripts v4 ARK-264 https://cassrp.atlassian.net/browse/ARK-264 aims to resolve properly
iframe[style='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
    display: none;
}

.scene-tooltip {
    font-size: theme.$font-size-h2;
    font-weight: theme.$font-medium;
    color: theme.$color-primary-night;
    position: absolute;
    top: 0;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    background-color: theme.$color-white;
}
