@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.toggleWrapper {
    @include common.centreFlexRow;
}

:export {
    french: theme.$color-french;
    grey: rgba(theme.$color-grey, 0.7);
}
