@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.modal {
    @include common.centreFlexRow;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
}

.wrapper {
    @include common.flexColumn;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    padding: 10px;
}

.buttonWrapper {
    @include common.flexRow;
    justify-content: flex-end;
}

.modalHeader {
    @include common.spaceBetweenFlexRow;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid theme.$color-grey;
    color: theme.$color-primary-night;
}

.defaultModalHeader {
    font-size: 22px;
    font-weight: 800;
}

.filterModalHeader {
    font-size: 14px;
    font-weight: 600;
}

// INCOMPLETE DETAILS MODAL
.incompleteDocumentsModal {
    @include common.centreFlexColumn;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    padding: 10px;
    width: 80vw;
    .documentDetailsModalWrapper {
        @include common.flexRow;
        // Full screen height minus the modal header, button wrapper, modal padding and height of navbar
        height: calc(100vh - 304px);
        width: 100%;
        .documentPreviewWrapper {
            width: 60%;
            margin-right: 10px;
        }
        .documentDetailsWrapper {
            @include common.flexColumn;
            width: calc(40% - 10px);
            .documentDetails {
                width: 100%;
            }
        }
    }
    .buttonWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        margin-right: 0;
        .buttonGroupRight {
            @include common.flexRow;
        }
    }
}

// UPLOAD MODAL
.uploadDocumentWrapper {
    @include common.flexColumn;
    width: 60vw;
}

@mixin inputHeader {
    font-size: 16px;
    font-weight: theme.$font-medium;
    text-decoration: underline;
    margin-bottom: 5px;
    color: theme.$color-primary-night;
}

.dropzone {
    @include common.centreFlexRow;
    border: 2px dashed theme.$color-french;
    padding: 80px 20px;
    color: theme.$color-primary-night;
    margin: 10px 0;
    cursor: pointer;
    width: calc(100% - 44px);
    &:hover {
        color: theme.$color-french;
    }
}

.dropzoneDisabled {
    border: 2px dashed theme.$color-grey;
    color: theme.$color-grey;
}

.dropzonePlaceholder {
    text-align: center;
}

.selectedDocumentWrapper,
.executedDateWrapper,
.entityLinkWrapper {
    margin-top: 10px;
    .executedDateHeader,
    .selectedDocumentHeader {
        @include inputHeader;
    }
    .entityLinkHeader {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .entityLinkTitle {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 10px;
            color: theme.$color-primary-night;
        }
    }
    .selectedDocument {
        @include common.overflowText;
    }
}

.selectedDocumentsWrapper {
    @include common.flexColumn;
    width: 100%;
    max-height: 250px;
    .tableHeader {
        @include common.flexRow;
        border-radius: 5px 5px 0 0;
        background-color: theme.$color-french;
        width: 100%;
        .nameHeaderCell {
            @include common.flexRow;
            width: calc(80% - 2px);
            align-items: center;
            padding: 5px;
            height: 30px;
            color: theme.$color-white;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            border-right: 1px solid theme.$color-white;
            border-left: 1px solid theme.$color-french;
            border-radius: 5px 0 0 0;
        }
        .sizeHeaderCell {
            @include common.flexRow;
            width: calc(20% - 2px);
            align-items: center;
            padding: 5px;
            height: 30px;
            color: theme.$color-white;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            border-left: 1px solid theme.$color-white;
            border-right: 1px solid theme.$color-french;
            border-radius: 0 5px 0 0;
        }
        .headerLabel {
            @include common.overflowText;
            font-size: 12px;
            margin-right: 10px;
        }
    }
    .tableRow {
        @include common.flexRow;
        border-bottom: 1px solid theme.$color-grey;
        width: 100%;
        .nameCellWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc(80% - 2px);
            padding: 5px;
            border-right: 1px solid theme.$color-grey;
            border-left: 1px solid theme.$color-grey;
        }
        .sizeCellWrapper {
            @include common.flexRow;
            align-items: center;
            width: calc(20% - 2px);
            padding: 5px;
            border-right: 1px solid theme.$color-grey;
            border-left: 1px solid theme.$color-white;
        }
        .cellLabel {
            @include common.overflowText;
            font-size: 14px;
        }
    }
    .lastTableRow {
        border-radius: 0 0 5px 5px;
        .nameCellWrapper {
            border-radius: 0 0 0 5px;
        }
        .sizeCellWrapper {
            border-radius: 0 0 5px 0;
        }
    }
}
.uploadToggleOptionsWrapper {
    @include common.flexColumn;
    width: 100%;
    align-items: flex-end;
    margin-top: 10px;
    .toggleOptionWrapper {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 5px;
        font-weight: theme.$font-medium;
        font-family: theme.$font-family;
        .toggleOptionLabelWrapper {
            @include common.flexRow;
            align-items: center;
            margin-right: 20px;
            .toggleOptionLabel {
                margin-right: 5px;
                color: theme.$color-primary-night;
            }
        }
    }
}

.spinnerWrapper {
    height: 323px;
    width: 484px;
}

.uploadingOverlay {
    height: 100vh;
    width: 100vw;
    opacity: 0.8;
    .spinnerWrapper {
        @include common.centreFlexColumn;
        width: 100%;
        height: 100%;
        .spinnerLabel {
            font-size: 20px;
            margin: 10px 0;
            font-weight: theme.$font-bold;
        }
        .spinnerWarning {
            font-size: 16px;
            font-weight: theme.$font-medium;
            color: theme.$color-amber;
        }
    }
}

.logoutWarningMessage {
    font-size: 16px;
    font-weight: theme.$font-medium;
    margin: 10px 0 20px 0;
    color: theme.$color-red;
}

.selectDocumentWrapper {
    @include common.centreFlexRow;
    height: 100%;
}

.selectedDocumentCell {
    @include common.centreFlexRow;
    height: 100%;
    color: theme.$color-french;
}

// ACTION MODAL
.actionModal {
    padding: 3px;
    .actionsList {
        @include common.spaceBetweenFlexRow;
        @include common.transparentButton;
        width: 100%;
        margin: 2px 0;
        padding: 1px 2px;
        height: 22px;
        cursor: pointer;
        &:hover {
            color: theme.$color-french;
        }
        &:disabled {
            color: theme.$color-grey;
            cursor: not-allowed;
        }
        .actionLabel {
            margin-right: 10px;
        }
    }
    .underlineAction {
        border-bottom: 1px solid theme.$color-grey;
        padding-bottom: 3px;
        height: 25px;
    }
    .deleteAction {
        &:hover {
            color: theme.$color-red;
        }
    }
}

.positionModal {
    .positionModalInputWrapper {
        @include common.labelModalTextAreaWrapper;
    }
}

.deleteConfirmationWrapper {
    @include common.flexColumn;
    min-height: 100px;
    padding: 5px;
    color: theme.$color-primary-night;
    font-weight: theme.$font-medium;
    .warningTitle {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .name {
        color: theme.$color-french;
        margin-bottom: 10px;
        max-width: 600px;
    }
    .warningLabel {
        font-size: 13px;
        color: theme.$color-red;
        margin-bottom: 20px;
        max-width: 600px;
    }
    .confirmationInputLabel {
        font-size: 13px;
        margin-bottom: 5px;
    }
}

.tableFilterModal {
    @include common.flexColumn;
    max-width: 300px;
    .tableFilterInput {
        font-family: theme.$font-family;
        font-size: 13px;
        outline: none;
        padding: 3px;
        border: 1px solid theme.$color-primary-night;
        color: theme.$color-primary-night;
        border-radius: 5px;
        &:focus {
            border: 1px solid theme.$color-french;
        }
    }
    .dropdownFilterWrapper {
        @include common.centreFlexRow;
        width: 300px;
        margin-top: 5px;
    }
}

.unsavedChangesModalWrapper {
    @include common.flexColumn;
    padding-bottom: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    width: 400px;
    .modalWarning {
        @include common.flexRow;
        align-items: center;
        color: theme.$color-red;
        font-weight: theme.$font-medium;
        margin: 10px 0 20px 0;
        .modalWarningTitle {
            margin-left: 10px;
        }
    }
    .unsavedChanges {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .modalContent {
        font-weight: theme.$font-medium;
        margin-bottom: 5px;
    }
}

.popoutWrapper {
    cursor: pointer;
}

// DoraDescriptionModal
.serviceProvidedDescriptionModal {
    @include common.flexColumn;
    width: 40vw;
    height: 60vh;
    padding: 10px;
    margin: auto;
    background-color: white;
    border: 1px solid theme.$color-primary-night;
    border-radius: 5px;
    outline: none;
    .serviceProvidedDescriptionWrapper {
        @include common.flexColumn;
        padding: 10px;
        height: 100%;
        .serviceProvidedDescriptionList {
            @include common.flexColumn;
            height: calc(100% - 114px);
            margin-bottom: 10px;
        }
        .list {
            @include common.flexRow;
            justify-content: space-between;
            margin: 5px 3px 5px 0;
            padding: 5px 3px 5px 0;
            width: 100%;
            border-bottom: 1px solid theme.$color-primary-night;
            .serviceTypeName {
                font-family: theme.$font-family;
                font-weight: theme.$font-medium;
                font-size: theme.$font-size-h3;
                color: theme.$color-french;
                width: calc(50% - 5px);
            }
            .serviceTypeDescription {
                font-family: theme.$font-family;
                font-size: theme.$font-size-h4;
                color: theme.$color-primary-night;
                width: calc(50% - 5px);
            }
        }
    }
    .buttonWrapper {
        @include common.flexRow;
        justify-content: flex-end;
        background-color: theme.$color-white;
    }
}

:export {
    grey: rgba(theme.$color-grey, 0.5);
    white: theme.$color-white;
}
