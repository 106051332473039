@use '../../../styles/theme' as theme;
@use '../../../styles/common' as common;

.wrapper {
    @include common.flexColumn;
    .label {
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
    }
    .textArea {
        @include common.inputFieldDisabled;
        resize: none;
        color: theme.$color-primary-night;
    }
    .textAreaWithLightBorder {
        @include common.inputFieldDisabled;
        resize: none;
        color: theme.$color-primary-night;
        border: solid 1px theme.$color-light-grey;
    }
}
