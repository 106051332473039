@use "../../../../styles/theme" as theme;
@use "../../../../styles/common" as common;

.uploadDocumentWrapper {
    @include common.flexColumn;
    padding: 10px;
    font-family: theme.$font-family;
    color: theme.$color-primary-night;
    .bulkUploadRadioWrapper {
        align-self: flex-end;
        margin-bottom: 5px
    }
}

.contentWrapper {
    @include common.flexColumn;
    width: 100%;
}

.dateWrapper {
    @include common.flexRow;
    width: 100%;
    align-items: center;
    margin-top: 10px;
}

.executedDateWrapper,
.datedAsOfWrapper {
    width: 50%;
    .executedDateHeader,
    .datedAsOfHeader {
        @include common.inputHeader;
    }
}

.linkedDocumentsWrapper,
.selectedDocumentWrapper,
.entityLinkWrapper {
    margin-top: 10px;
    .linkedDocumentsHeader,
    .executedDateHeader,
    .datedAsOfHeader,
    .selectedDocumentHeader {
        @include common.inputHeader;
    }
    .entityLinkHeader {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 5px;
        .entityLinkTitle {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 10px;
            color: theme.$color-primary-night;
        }
    }
    .selectedDocument {
        @include common.overflowText;
    }
}
.documentNameWrapper,
.documentTypeWrapper {
    @include common.flexColumn;
    width: 100%;
    margin-bottom: 10px;
    .documentNameHeader,
    .documentTypeHeader {
        font-size: 16px;
        font-weight: theme.$font-medium;
        text-decoration: underline;
        margin-bottom: 5px;
        color: theme.$color-primary-night;
    }
}
.entityLinkDropdownWrapper {
    @include common.spaceBetweenFlexRow;
    .entityLinkDropdownA {
        width: 100%;
        padding-right: 10px;
    }
    .entityLinkDropdownB {
        width: 100%;
        padding-left: 10px;
    }
}

.selectAgencyWrapper {
    @include common.flexColumn;
    margin-top: 10px;
    .descriptionWrapper {
        @include common.flexRow;
        align-items: center;
        margin-bottom: 10px;
        .description {
            color: theme.$color-primary-night;
            font-family: theme.$font-family;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 5px;
        }
    }
    .agencyToggleWrapper {
        @include common.spaceBetweenFlexRow;
        width: 100%;
        .partyToggleWrapper {
            @include common.flexRow;
            align-items: center;
            width: 100%;
            .partyLabel {
                color: theme.$color-primary-night;
                font-family: theme.$font-family;
                font-weight: 400;
                margin-bottom: 5px;
                margin-right: 20px;
            }
        }
    }
}

.dropzone {
    @include common.centreFlexRow;
    border: 2px dashed theme.$color-french;
    padding: 50px 20px;
    color: theme.$color-primary-night;
    margin: 10px 0;
    cursor: pointer;
    width: calc(100% - 44px);
    &:hover {
        color: theme.$color-french;
    }
}

.dropzoneDisabled {
    border: 2px dashed theme.$color-grey;
    color: theme.$color-grey;
}

.dropzonePlaceholder {
    text-align: center;
}

.originalDocumentHeader {
    @include common.flexRow;
    align-items: center;
    margin-top: 10px;
    .originalDocumentTitle {
        @include common.overflowText;
        margin-left: 10px;
        font-weight: theme.$font-medium;
    }
}

.baseDocumentHeader {
    @include common.flexRow;
    align-items: center;
    margin-top: 10px;
    .baseDocumentTitle {
        @include common.overflowText;
        margin-left: 10px;
        font-weight: theme.$font-medium;
    }
}

.typeSelectWrapper {
    @include common.flexColumn;
    .typeButton {
        @include common.flexRow;
        @include common.transparentButton;
        align-items: center;
        width: 100%;
        padding: 15px;
        margin-bottom: 10px;
        border: 1px solid theme.$color-grey;
        box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
        border-radius: 5px;
        &:hover {
            cursor: pointer;
            box-shadow: 1px 1px 5px theme.$color-french;
        }
        &:disabled {
            opacity: 0.4;
            background-color: lightgray;
            cursor: not-allowed;
            box-shadow: none;
        }
        .typeButtonTextWrapper {
            padding-left: 20px;
            flex: 1;
        }
        .typeButtonTitle {
            font-size: 18px;
            font-weight: theme.$font-medium;
            margin-bottom: 10px;
        }
        .typeButtonSubtitle {
            font-size: 12px;
        }
    }
}

.agencyAnnexWrapper {
    @include common.flexColumn;
    margin-bottom: 10px;
    .agencyTitleWrapper {
        @include common.flexRow;
        margin-bottom: 5px;
        align-items: center;
        .agencyToggleTitle {
            font-size: 16px;
            font-weight: theme.$font-medium;
            text-decoration: underline;
            margin-right: 5px;
        }
    }
    .agencyToggleWrapper {
        @include common.spaceBetweenFlexRow;
        height: 30px;
        .agencyDefinitionButton {
            @include common.transparentButton(theme.$color-primary-night, 1px solid theme.$color-primary-night);
            @include common.overflowText;
            padding: 3px 10px;
            border-radius: 5px;
            font-family: theme.$font-family;
            font-size: 14px;
        }
    }
}

.baseDocuments {
    @include common.flexColumn;
    width: 100%;
    .baseDocumentHeader {
        @include common.flexRow;
        width: calc(100% - 2px);
        align-items: center;
        background-color: theme.$color-french;
        color: theme.$color-white;
        height: 30px;
        border-radius: 5px 5px 0 0;
        border: 1px solid theme.$color-french;
        font-weight: theme.$font-medium;
    }
    .baseDocumentBody {
        @include common.flexColumn;
        width: calc(100% - 2px);
        height: fit-content;
        max-height: 300px;
        border-radius: 0 0 5px 5px;
        border: 1px solid theme.$color-french;
        .baseDocumentRow {
            @include common.flexRow;
            width: 100%;
            align-items: center;
            color: theme.$color-primary-night;
            padding: 5px 0;
            font-size: 14px;
            &:last-child {
                border-radius: 0 0 5px 5px;
            }
        }
        .noDocumentsRow {
            @include common.centreFlexRow;
            width: 100%;
            color: theme.$color-primary-night;
            padding: 5px 0;
            font-size: 14px;
            border-radius: 0 0 5px 5px;
            background-color: theme.$color-white;
        }
    }
    .baseDocumentDescription {
        @include common.flexRow;
        width: calc(45% - 10px);
        padding: 0 5px;
    }
    .baseDocumentName {
        @include common.flexRow;
        width: calc(45% - 10px);
        padding: 0 5px;
    }
    .baseDocumentDelete {
        @include common.flexRow;
        align-items: center;
        justify-content: flex-end;
        width: calc(10% - 10px);
        padding: 0 5px;
    }
}

.schedulePageInfo {
    @include common.flexRow;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .schedulePageWrapper {
        @include common.flexColumn;
        .schedulePageLabelWrapper {
            @include common.flexRow;
            align-items: center;
            margin-bottom: 5px;
            .schedulePageLabel {
                font-size: 16px;
                font-weight: theme.$font-medium;
                text-decoration: underline;
                color: theme.$color-primary-night;
                margin-right: 5px;
            }
            .schedulePageTooltip {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .schedulePageInput {
            @include common.flexRow;
        }
    }
}

:export {
    primary: theme.$color-primary-night;
    french: theme.$color-french;
    disabledGrey: rgba(theme.$color-grey, 0.5);
    lightGrey: theme.$color-light-grey;
    disabledBackground: rgba(theme.$color-grey, 0.05);
    white: theme.$color-white;
    fontFamily: theme.$font-family;
    grey: theme.$color-grey;
    amber: theme.$color-amber;
}
