@use '../../../styles/common' as common;
@use '../../../styles/theme' as theme;

.registerOfInformationWrapper {
    @include common.flexColumn;
    height: 100%;
    width: calc(100% - 8vw);
    .registerOfInformationHeaderWrapper {
        margin-bottom: 20px;
        .registerOfInformationHeader {
            @include common.spaceBetweenFlexRow;
            font-size: 22px;
            font-weight: theme.$font-bold;
        }
    }
    .registerOfInformationContentWrapper {
        @include common.flexColumn;
        height: calc(100% - 70px);
        width: 100%;
        .registerOfInformationExplanation {
            margin-bottom: 20px;
            .registerOfInformationParagraph {
                margin-bottom: 5px;
            }
            .registerOfInformationImpact {
                color: theme.$color-amethyst;
                font-weight: theme.$font-bold;
            }
        }
        .registerOfInformationDetails {
            @include common.flexColumn;
            height: calc(100% - 350px);
            font-weight: theme.$font-medium;
            .registerCurrencyWrapper {
                @include common.flexColumn;
                width: calc(50% - 10px);
                margin-top: 10px;
                align-self: center;
            }
            .branchesLabelWrapper {
                @include common.flexRow;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                color: theme.$color-primary-night;
                text-decoration: underline;
                .entityBranchesLabel {
                    margin-bottom: 5px;
                }
            }
            .entitiesInScopeWrapper {
                @include common.flexRow;
                height: calc(100% - 112px);
                justify-content: space-between;
                .entitiesInScope {
                    @include common.flexColumn;
                    border: 1px solid theme.$color-grey;
                    border-radius: 5px;
                    padding: 5px;
                    width: calc(40% - 20px);
                    height: calc(100% - 10px);
                    .entityInScope {
                        border: 1px solid theme.$color-grey;
                        border-radius: 5px;
                        color: theme.$color-primary-night;
                        padding: 5px;
                        margin-bottom: 5px;
                        cursor: pointer !important;
                    }
                    .selectedEntityInScope {
                        border: 1px solid theme.$color-grey;
                        border-radius: 5px;
                        color: theme.$color-white;
                        background-color: theme.$color-french;
                        padding: 5px;
                        margin-bottom: 5px;
                        cursor: pointer !important;
                    }
                }
                .entityInScopeDetails {
                    @include common.flexColumn;
                    border-radius: 5px;
                    width: calc(60% - 20px);
                    border: 1px solid theme.$color-grey;
                    padding: 5px;
                    .branchesLabelWrapper {
                        @include common.flexRow;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 5px;
                        .branchesLabel {
                            color: theme.$color-primary-night;
                            font-family: theme.$font-family;
                            font-weight: theme.$font-medium;
                            text-decoration: underline;
                            margin-right: 5px;
                            width: fit-content;
                        }
                    }
                    .entityBranchesWrapper {
                        @include common.flexColumn;
                        height: fit-content;
                        min-height: 50px;
                        margin-bottom: 20px;
                        font-size: theme.$font-size-h6;
                        .entityBranch {
                            @include common.flexRow;
                            align-items: center;
                            border: 1px solid theme.$color-grey;
                            border-radius: 5px;
                            padding: 5px;
                            margin-bottom: 5px;
                            justify-content: space-between;
                            .entityBranchName {
                                width: calc(100% - 40px);
                                text-align: left;
                            }
                        }
                        .noEntityBranches {
                            text-align: left;
                            color: theme.$color-grey;
                        }
                    }
                    .detailWrapper {
                        width: calc(100% - 12px);
                        @include common.flexRow;
                        margin-bottom: 10px;
                        align-items: center;
                        .entityDetailLabel {
                            font-size: theme.$font-size-h4;
                            text-decoration: underline;
                            margin-right: 5px;
                            min-width: fit-content;
                        }
                        .entityDetail {
                            font-size: theme.$font-size-h6;
                        }
                    }
                }
            }
        }
        .registerOfInformationButtonWrapper {
            @include common.centreFlexRow;
        }
    }
}

.spinnerWrapper {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    .spinnerBackground {
        height: 100%;
        width: 100%;
        background-color: white;
        opacity: 0.8;
        position: absolute;
        top: 0;
        left: 0;
    }
    .spinnerDots {
        height: 100%;
        width: 100%;
        opacity: 1;
    }
}

:export {
    lightFrench: theme.$color-french-spectrum-1;
    green: theme.$color-green;
    red: theme.$color-red;
}
